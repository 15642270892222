@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, textarea, select {
  outline: none;
  font-size: 14px; }

body {
  line-height: normal; }

a {
  text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

/*--------------------
MAIN
--------------------*/
/*--------------------
SOCIAL
--------------------*/
/*$color_twitter: 		#00aced;
$color_facebook: 		#3b5998;
$color_googleplus: 		#dd4b39;
$color_pinterest:		#cb2027;
$color_linkedin:		#007bb6;
$color_youtube:		 	#bb0000;
$color_instagram:		#125688;
$color_dribbble:		#ea4c89;
$color_wordpress:		#21759b;
$color_mail:	    	#21759b;
$color_whatsapp:        #25D366;
$color_messenger:       #0084ff;*/
/*--------------------
FONT
--------------------*/
@font-face {
  font-family: DroidSerif;
  src: url("/public/font/DroidSerif.eot?#iefix") format("embedded-opentype"), url("/public/font/DroidSerif.woff") format("woff"), url("/public/font/DroidSerif.ttf") format("truetype"), url("/public/font/DroidSerif.svg#DroidSerif") format("svg");
  font-weight: normal;
  font-style: normal; }
/*--------------------
MIXINS
--------------------*/
.clear:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
  padding: 0;
  margin: 0; }

/* ---------------------------------- */
/* ==Grillade : Simple Grid System    */
/* ---------------------------------- */
/* Doc : http://grillade.knacss.com */
@media (min-width: 576px) {
  [class*=" grid-"],
  [class^="grid-"] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    [class*=" grid-"] > *,
    [class^="grid-"] > * {
      box-sizing: border-box;
      min-width: 0;
      min-height: 0; } }
@media (min-width: 576px) {
  .grid,
  .grid--reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .grid > *,
    .grid--reverse > * {
      flex: 1 1 0%;
      box-sizing: border-box;
      min-width: 0;
      min-height: 0; }
    .grid.has-gutter > * + *,
    .grid--reverse.has-gutter > * + * {
      margin-left: calc(5px - 0.01px); }
    .grid.has-gutter-l > * + *,
    .grid--reverse.has-gutter-l > * + * {
      margin-left: calc(15px - 0.01px); }
    .grid.has-gutter-xl > * + *,
    .grid--reverse.has-gutter-xl > * + * {
      margin-left: calc(30px - 0.01px); } }
@media (min-width: 576px) {
  [class*="grid-2"] > * {
    width: calc(100% / 2 - 0.01px); }
  [class*="grid-2"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-2"].has-gutter > * {
      width: calc(100% / 2 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-2"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-2"].has-gutter-l > * {
      width: calc(100% / 2 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-2"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-2"].has-gutter-xl > * {
      width: calc(100% / 2 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-3"] > * {
    width: calc(100% / 3 - 0.01px); }
  [class*="grid-3"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-3"].has-gutter > * {
      width: calc(100% / 3 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-3"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-3"].has-gutter-l > * {
      width: calc(100% / 3 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-3"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-3"].has-gutter-xl > * {
      width: calc(100% / 3 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-4"] > * {
    width: calc(100% / 4 - 0.01px); }
  [class*="grid-4"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-4"].has-gutter > * {
      width: calc(100% / 4 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-4"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-4"].has-gutter-l > * {
      width: calc(100% / 4 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-4"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-4"].has-gutter-xl > * {
      width: calc(100% / 4 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-5"] > * {
    width: calc(100% / 5 - 0.01px); }
  [class*="grid-5"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-5"].has-gutter > * {
      width: calc(100% / 5 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-5"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-5"].has-gutter-l > * {
      width: calc(100% / 5 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-5"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-5"].has-gutter-xl > * {
      width: calc(100% / 5 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-6"] > * {
    width: calc(100% / 6 - 0.01px); }
  [class*="grid-6"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-6"].has-gutter > * {
      width: calc(100% / 6 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-6"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-6"].has-gutter-l > * {
      width: calc(100% / 6 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-6"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-6"].has-gutter-xl > * {
      width: calc(100% / 6 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-7"] > * {
    width: calc(100% / 7 - 0.01px); }
  [class*="grid-7"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-7"].has-gutter > * {
      width: calc(100% / 7 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-7"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-7"].has-gutter-l > * {
      width: calc(100% / 7 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-7"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-7"].has-gutter-xl > * {
      width: calc(100% / 7 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-8"] > * {
    width: calc(100% / 8 - 0.01px); }
  [class*="grid-8"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-8"].has-gutter > * {
      width: calc(100% / 8 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-8"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-8"].has-gutter-l > * {
      width: calc(100% / 8 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-8"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-8"].has-gutter-xl > * {
      width: calc(100% / 8 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-9"] > * {
    width: calc(100% / 9 - 0.01px); }
  [class*="grid-9"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-9"].has-gutter > * {
      width: calc(100% / 9 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-9"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-9"].has-gutter-l > * {
      width: calc(100% / 9 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-9"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-9"].has-gutter-xl > * {
      width: calc(100% / 9 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-10"] > * {
    width: calc(100% / 10 - 0.01px); }
  [class*="grid-10"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-10"].has-gutter > * {
      width: calc(100% / 10 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-10"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-10"].has-gutter-l > * {
      width: calc(100% / 10 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-10"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-10"].has-gutter-xl > * {
      width: calc(100% / 10 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-11"] > * {
    width: calc(100% / 11 - 0.01px); }
  [class*="grid-11"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-11"].has-gutter > * {
      width: calc(100% / 11 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-11"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-11"].has-gutter-l > * {
      width: calc(100% / 11 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-11"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-11"].has-gutter-xl > * {
      width: calc(100% / 11 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); }

  [class*="grid-12"] > * {
    width: calc(100% / 12 - 0.01px); }
  [class*="grid-12"].has-gutter {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2); }
    [class*="grid-12"].has-gutter > * {
      width: calc(100% / 12 - 5px - 0.01px);
      margin-right: calc(5px / 2);
      margin-left: calc(5px / 2); }
  [class*="grid-12"].has-gutter-l {
    margin-right: calc(-15px / 2);
    margin-left: calc(-15px / 2); }
    [class*="grid-12"].has-gutter-l > * {
      width: calc(100% / 12 - 15px - 0.01px);
      margin-right: calc(15px / 2);
      margin-left: calc(15px / 2); }
  [class*="grid-12"].has-gutter-xl {
    margin-right: calc(-30px / 2);
    margin-left: calc(-30px / 2); }
    [class*="grid-12"].has-gutter-xl > * {
      width: calc(100% / 12 - 30px - 0.01px);
      margin-right: calc(30px / 2);
      margin-left: calc(30px / 2); } }
.push {
  margin-left: auto !important; }

.pull {
  margin-right: auto !important; }

.item-first {
  order: -1; }

.item-last {
  order: 1; }

[class*="grid-"][class*="--reverse"] {
  flex-direction: row-reverse; }

@media (min-width: 576px) {
  .full {
    flex: 0 0 auto;
    width: calc(100% / 1 - 0.01px); }

  .has-gutter > .full {
    width: calc(100% / 1 - 5px - 0.01px); }

  .has-gutter-l > .full {
    width: calc(100% / 1 - 15px - 0.01px); }

  .has-gutter-xl > .full {
    width: calc(100% / 1 - 30px - 0.01px); }

  .one-half {
    flex: 0 0 auto;
    width: calc(100% / 2 - 0.01px); }

  .has-gutter > .one-half {
    width: calc(100% / 2 - 5px - 0.01px); }

  .has-gutter-l > .one-half {
    width: calc(100% / 2 - 15px - 0.01px); }

  .has-gutter-xl > .one-half {
    width: calc(100% / 2 - 30px - 0.01px); }

  .one-third {
    flex: 0 0 auto;
    width: calc(100% / 3 - 0.01px); }

  .has-gutter > .one-third {
    width: calc(100% / 3 - 5px - 0.01px); }

  .has-gutter-l > .one-third {
    width: calc(100% / 3 - 15px - 0.01px); }

  .has-gutter-xl > .one-third {
    width: calc(100% / 3 - 30px - 0.01px); }

  .one-quarter {
    flex: 0 0 auto;
    width: calc(100% / 4 - 0.01px); }

  .has-gutter > .one-quarter {
    width: calc(100% / 4 - 5px - 0.01px); }

  .has-gutter-l > .one-quarter {
    width: calc(100% / 4 - 15px - 0.01px); }

  .has-gutter-xl > .one-quarter {
    width: calc(100% / 4 - 30px - 0.01px); }

  .one-fifth {
    flex: 0 0 auto;
    width: calc(100% / 5 - 0.01px); }

  .has-gutter > .one-fifth {
    width: calc(100% / 5 - 5px - 0.01px); }

  .has-gutter-l > .one-fifth {
    width: calc(100% / 5 - 15px - 0.01px); }

  .has-gutter-xl > .one-fifth {
    width: calc(100% / 5 - 30px - 0.01px); }

  .one-sixth {
    flex: 0 0 auto;
    width: calc(100% / 6 - 0.01px); }

  .has-gutter > .one-sixth {
    width: calc(100% / 6 - 5px - 0.01px); }

  .has-gutter-l > .one-sixth {
    width: calc(100% / 6 - 15px - 0.01px); }

  .has-gutter-xl > .one-sixth {
    width: calc(100% / 6 - 30px - 0.01px); }

  .two-thirds {
    flex: 0 0 auto;
    width: calc(100% / 3 * 2 - 0.01px); }

  .has-gutter > .two-thirds {
    width: calc(100% / 3 * 2 - 5px - 0.01px); }

  .has-gutter-l > .two-thirds {
    width: calc(100% / 3 * 2 - 15px - 0.01px); }

  .has-gutter-xl > .two-thirds {
    width: calc(100% / 3 * 2 - 30px - 0.01px); }

  .three-quarters {
    flex: 0 0 auto;
    width: calc(100% / 4 * 3 - 0.01px); }

  .has-gutter > .three-quarters {
    width: calc(100% / 4 * 3 - 5px - 0.01px); }

  .has-gutter-l > .three-quarters {
    width: calc(100% / 4 * 3 - 15px - 0.01px); }

  .has-gutter-xl > .three-quarters {
    width: calc(100% / 4 * 3 - 30px - 0.01px); }

  .five-sixths {
    flex: 0 0 auto;
    width: calc(100% / 6 * 5 - 0.01px); }

  .has-gutter > .five-sixths {
    width: calc(100% / 6 * 5 - 5px - 0.01px); }

  .has-gutter-l > .five-sixths {
    width: calc(100% / 6 * 5 - 15px - 0.01px); }

  .has-gutter-xl > .five-sixths {
    width: calc(100% / 6 * 5 - 30px - 0.01px); } }
/* Responsive Small Breakpoint */
@media (min-width: 576px) and (max-width: 767px) {
  [class*="-small-1"] > * {
    width: calc(100% / 1 - 0.01px); }
  [class*="-small-1"].has-gutter > * {
    width: calc(100% / 1 - 5px - 0.01px); }
  [class*="-small-1"].has-gutter-l > * {
    width: calc(100% / 1 - 15px - 0.01px); }
  [class*="-small-1"].has-gutter-xl > * {
    width: calc(100% / 1 - 30px - 0.01px); }

  [class*="-small-2"] > * {
    width: calc(100% / 2 - 0.01px); }
  [class*="-small-2"].has-gutter > * {
    width: calc(100% / 2 - 5px - 0.01px); }
  [class*="-small-2"].has-gutter-l > * {
    width: calc(100% / 2 - 15px - 0.01px); }
  [class*="-small-2"].has-gutter-xl > * {
    width: calc(100% / 2 - 30px - 0.01px); }

  [class*="-small-3"] > * {
    width: calc(100% / 3 - 0.01px); }
  [class*="-small-3"].has-gutter > * {
    width: calc(100% / 3 - 5px - 0.01px); }
  [class*="-small-3"].has-gutter-l > * {
    width: calc(100% / 3 - 15px - 0.01px); }
  [class*="-small-3"].has-gutter-xl > * {
    width: calc(100% / 3 - 30px - 0.01px); }

  [class*="-small-4"] > * {
    width: calc(100% / 4 - 0.01px); }
  [class*="-small-4"].has-gutter > * {
    width: calc(100% / 4 - 5px - 0.01px); }
  [class*="-small-4"].has-gutter-l > * {
    width: calc(100% / 4 - 15px - 0.01px); }
  [class*="-small-4"].has-gutter-xl > * {
    width: calc(100% / 4 - 30px - 0.01px); } }
.grid--center {
  align-items: center; }

.grid--auto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .grid--auto > * {
    flex: 1 1 auto; }

.grid--align > * {
  flex: 0 0 auto; }

/*--------------------
RESPONSIVE
A lire de cette façon : "Cette regle s'applique à tout ce qui est au dessus de $point ..."
--------------------*/
/*--------------------
SYMBOL
--------------------*/
/*--------------------
ROUND
--------------------*/
.s-round {
  display: inline-block;
  vertical-align: middle;
  width: 4px;
  height: 4px;
  background-color: #9b9b9b;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 100%; }

.s-hr {
  border: none;
  height: 1px;
  width: 100%;
  background-color: #ddd; }

/*--------------------
COMPONENT
--------------------*/
/*--------------------
COMPONENT
--------------------*/
.c-block {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
  position: relative;
  display: block;
  background-color: #fff;
  padding: 15px;
  height: 100%; }
  @media (min-width: 576px) {
    .c-block {
      padding: 30px; } }

.c-block--grey {
  box-shadow: none;
  border-radius: 4px;
  background-color: #f9f9f9;
  border: 1px solid #ddd; }

.c-block__header {
  padding: 30px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd; }

.c-block--nv {
  box-shadow: none;
  border-bottom: 1px solid #b6b6b6;
  padding: 45px 0; }

.c-block--nv-noborder {
  border-bottom: 0; }

.c-block--nv-bordertop {
  border-top: 1px solid #b6b6b6; }

.c-block--auto {
  height: auto; }

/*--------------------
COMPONENT
--------------------*/
.c-btn {
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  position: relative;
  display: inline-block;
  border: 0;
  cursor: pointer;
  padding: 0 15px;
  width: auto;
  min-height: 40px;
  text-align: center;
  text-decoration: none;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .c-btn:hover {
    box-shadow: 0 0 0 rgba(28, 44, 79, 0.1), 0 0 0 rgba(0, 0, 0, 0.08); }
  .c-btn:active, .c-btn:focus {
    text-decoration: none; }
  .c-btn:hover, .c-btn:active, .c-btn:focus {
    text-decoration: none; }

/*--------------------
ELEMENT
--------------------*/
.c-btn__icon {
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  display: inline-block;
  width: auto;
  height: auto;
  vertical-align: middle;
  text-align: center;
  line-height: 1; }
  .c-btn__icon.fa {
    position: relative;
    font-size: 18px; }

/*--------------------
MODIFIER
--------------------*/
.c-btn__icon--left {
  margin-right: 10px; }

.c-btn__icon--right {
  margin-left: 10px; }

.c-btn--green {
  background: #28C76F;
  border: 1px solid #01BE53; }
  .c-btn--green:hover {
    background: #00903F;
    color: #fff; }

.c-btn--red {
  background: #FFAB9E;
  border: 1px solid #FFAB9E; }
  .c-btn--red:hover {
    background: #FF8573;
    color: #fff; }

.c-btn--white {
  background: #fff;
  border: 1px solid #ddd;
  color: #000000; }
  .c-btn--white:hover {
    border: 1px solid #b6b6b6; }
  .c-btn--white:active {
    border: 1px solid #9b9b9b;
    background: #f9f9f9; }

.c-btn--black {
  background: #000000;
  border: 1px solid #000000; }
  .c-btn--black:hover {
    background: #000000;
    color: #fff; }

.c-btn_is-loading.c-btn_green {
  background: #28C76F;
  color: transparent !important; }
  .c-btn_is-loading.c-btn_green:hover {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1); }
  .c-btn_is-loading.c-btn_green:active, .c-btn_is-loading.c-btn_green:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
    text-decoration: none; }
  .c-btn_is-loading.c-btn_green .s-loader {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto; }
    .c-btn_is-loading.c-btn_green .s-loader .s-loader__round {
      background-color: #00903F; }
      .c-btn_is-loading.c-btn_green .s-loader .s-loader__round::after {
        background-color: #28C76F; }
.c-btn_is-loading.c-btn_red {
  background: #f05d61;
  color: transparent !important; }
  .c-btn_is-loading.c-btn_red:hover {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1); }
  .c-btn_is-loading.c-btn_red:active, .c-btn_is-loading.c-btn_red:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
    text-decoration: none; }
  .c-btn_is-loading.c-btn_red .s-loader {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto; }
    .c-btn_is-loading.c-btn_red .s-loader .s-loader__round {
      background-color: #bf1313; }
      .c-btn_is-loading.c-btn_red .s-loader .s-loader__round::after {
        background-color: #f05d61; }
.c-btn_is-loading.c-btn_white {
  background: #fff;
  color: transparent !important; }
  .c-btn_is-loading.c-btn_white:hover {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1); }
  .c-btn_is-loading.c-btn_white:active, .c-btn_is-loading.c-btn_white:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
    text-decoration: none; }
  .c-btn_is-loading.c-btn_white .s-loader {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin: 0 auto; }
    .c-btn_is-loading.c-btn_white .s-loader .s-loader__round {
      background-color: #9b9b9b; }
      .c-btn_is-loading.c-btn_white .s-loader .s-loader__round::after {
        background-color: #fff; }

.c-btn--disabled.c-btn--green {
  opacity: .6;
  background: #28C76F;
  cursor: not-allowed; }
  .c-btn--disabled.c-btn--green:hover {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1); }
  .c-btn--disabled.c-btn--green:active, .c-btn--disabled.c-btn--green:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
    text-decoration: none; }
  .c-btn--disabled.c-btn--green:hover, .c-btn--disabled.c-btn--green:active, .c-btn--disabled.c-btn--green:focus {
    border: 1px solid #01BE53;
    background: #28C76F; }
.c-btn--disabled.c-btn--red {
  opacity: .6;
  background: #f05d61;
  cursor: not-allowed; }
  .c-btn--disabled.c-btn--red:hover {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1); }
  .c-btn--disabled.c-btn--red:active, .c-btn--disabled.c-btn--red:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
    text-decoration: none; }
  .c-btn--disabled.c-btn--red:hover, .c-btn--disabled.c-btn--red:active, .c-btn--disabled.c-btn--red:focus {
    border: 1px solid #e93232;
    background: #f05d61; }
.c-btn--disabled.c-btn--white {
  opacity: .6;
  background: #fff;
  cursor: not-allowed;
  border: 1px solid #ddd;
  color: #000000; }
  .c-btn--disabled.c-btn--white:hover {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1); }
  .c-btn--disabled.c-btn--white:active, .c-btn--disabled.c-btn--white:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
    text-decoration: none; }
  .c-btn--disabled.c-btn--white:hover, .c-btn--disabled.c-btn--white:active, .c-btn--disabled.c-btn--white:focus {
    border: 1px solid #ddd;
    color: #000000; }

.c-btn--size-small {
  min-height: 30px;
  line-height: 30px; }

.c-btn--size-extra-small {
  min-height: 25px;
  line-height: 25px; }

.c-btn--size-expand {
  width: 100%; }

@media (min-width: 576px) {
  .xs-expand {
    width: 100%; } }
@media (min-width: 768px) {
  .sm-expand {
    width: 100%; } }
@media (min-width: 992px) {
  .md-expand {
    width: 100%; } }
@media (min-width: 1200px) {
  .lg-expand {
    width: 100%; } }
/*--------------------
COMPONENT
--------------------*/
.c-gridProducts-part-title {
  width: 100%; }

.c-gridProducts-part {
  width: 100%;
  padding-top: 15px;
  margin: 0 !important; }
  .c-gridProducts-part.c-gridProducts-part-new {
    background: #c2e0ec; }
  .c-gridProducts-part.c-gridProducts-part-gold {
    background: #EEDFB2; }
  .c-gridProducts-part.c-gridProducts-part-silver {
    background: #E4E9EE; }
  .c-gridProducts-part.c-gridProducts-part-bronze {
    background: #E6CEB9; }

.c-gridProducts-part-title {
  height: 40px;
  color: #fff;
  padding: 7px 10px;
  font-size: 16px;
  font-weight: bold; }
  .c-gridProducts-part-title.c-gridProducts-part-title-new {
    background: #00aced; }
  .c-gridProducts-part-title.c-gridProducts-part-title-gold {
    background: #F1C43B; }
  .c-gridProducts-part-title.c-gridProducts-part-title-silver {
    background: #BFCDD9; }
  .c-gridProducts-part-title.c-gridProducts-part-title-bronze {
    background: #D98841; }
  .c-gridProducts-part-title .c-gridProducts-medal-new {
    color: #00aced;
    margin-right: 10px;
    background: #fff;
    border-radius: 20px;
    padding: 5px; }
  .c-gridProducts-part-title .c-gridProducts-medal-gold {
    color: #F1C43B;
    margin-right: 10px;
    background: #fff;
    border-radius: 20px;
    padding: 5px; }
  .c-gridProducts-part-title .c-gridProducts-medal-silver {
    color: #BFCDD9;
    margin-right: 10px;
    background: #fff;
    border-radius: 20px;
    padding: 5px; }
  .c-gridProducts-part-title .c-gridProducts-medal-bronze {
    color: #D98841;
    margin-right: 10px;
    background: #fff;
    border-radius: 20px;
    padding: 5px; }

.c-cardProduct {
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
  position: relative;
  background-color: #fff;
  overflow: hidden;
  color: #9b9b9b;
  padding: 10px 10px 5px 10px;
  cursor: pointer; }
  .c-cardProduct .c-cardProduct--new {
    background: #00aced;
    color: #fff;
    border-radius: 20px;
    padding: 5px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    opacity: 1; }
  .c-cardProduct .c-cardProduct--gold {
    background: #F1C43B;
    color: #fff;
    border-radius: 20px;
    padding: 5px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    opacity: 1; }
  .c-cardProduct .c-cardProduct--silver {
    background: #BFCDD9;
    color: #fff;
    border-radius: 20px;
    padding: 5px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    opacity: 1; }
  .c-cardProduct .c-cardProduct--bronze {
    background: #D98841;
    color: #fff;
    border-radius: 20px;
    padding: 5px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    opacity: 1; }
  .c-cardProduct.c-cardProduct--size-small {
    height: 100px;
    padding: 10px; }
  .c-cardProduct.c-cardProduct--inBox .c-cardProduct__img, .c-cardProduct.c-cardProduct--inBox .c-cardProduct__info {
    opacity: 0.4; }
  .c-cardProduct.c-cardProduct--inBox button {
    display: none; }
  .c-cardProduct.c-cardProduct--false {
    box-shadow: none;
    border: 1px dashed #b6b6b6;
    background-color: transparent; }
  .c-cardProduct.c-cardProduct--error {
    border: 1px solid #f05d61; }
  .c-cardProduct.c-cardProduct--highlighted {
    border: 1px solid #128d19; }
  .c-cardProduct:hover {
    box-shadow: none; }
  .c-cardProduct .c-cardProduct__findAlternative {
    position: absolute;
    right: 0;
    top: 0; }

.c-cardProduct__img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 160px; }
  .c-cardProduct--size-small .c-cardProduct__img {
    max-height: 100%; }

.c-cardProduct__info {
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  width: 100%;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden; }
  .c-cardProduct--size-small .c-cardProduct__info {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.8); }

.c-cardProduct__name {
  font-family: 'DroidSerif', sans-serif;
  font-size: 12px; }

.c-cardProduct__brand {
  font-family: 'DroidSerif', sans-serif;
  text-transform: uppercase;
  font-size: 12px; }

.c-cardProduct__topLeftLabels {
  position: absolute;
  top: 0px;
  left: 25px; }
  .c-cardProduct__topLeftLabels .c-cardProduct__reduction {
    border-radius: 4px;
    display: inline-block;
    color: #fff;
    font-size: 14px;
    background: #28C76F;
    padding: 0 5px;
    opacity: 0.7; }
  .c-cardProduct__topLeftLabels .c-cardProduct__previousRef {
    display: inline-block;
    color: #ffdb28;
    padding: 4px; }
  .c-cardProduct__topLeftLabels .c-cardProduct__outOfBudget, .c-cardProduct__topLeftLabels .c-cardProduct__isWrongCut {
    display: inline-block;
    background: #8c7813;
    padding: 4px 6px 4px 4px;
    color: #fff;
    border-radius: 10px;
    width: 28px;
    text-align: center;
    line-height: 15px;
    margin-top: 1px; }
  .c-cardProduct__topLeftLabels .c-cardProduct__isSimilarPrice {
    display: inline-block;
    background: #c31313;
    padding: 4px 6px 4px 4px;
    color: #fff;
    border-radius: 10px;
    width: 28px;
    text-align: center;
    line-height: 15px;
    margin-top: 1px; }
  .c-cardProduct__topLeftLabels .c-cardProduct__filterReasons {
    display: inline-block;
    margin: 0 auto;
    color: white;
    padding: 3px 10px;
    border-radius: 20px;
    line-height: 17px;
    z-index: 10;
    width: 25px; }
    .c-cardProduct__topLeftLabels .c-cardProduct__filterReasons.c-cardProduct__filterReasons--yellow {
      background: #edd51c; }
    .c-cardProduct__topLeftLabels .c-cardProduct__filterReasons.c-cardProduct__filterReasons--orange {
      background: #ff8900; }
    .c-cardProduct__topLeftLabels .c-cardProduct__filterReasons.c-cardProduct__filterReasons--red {
      background: #ff0000; }

.c-cardProduct__price {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #000000;
  font-weight: bold; }
  .c-cardProduct--size-small .c-cardProduct__price {
    font-size: 14px; }

.c-cardProduct__size {
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  padding: 2px 4px;
  color: #fff;
  font-size: 14px;
  background-color: #b6b6b6; }
  .c-cardProduct--size-small .c-cardProduct__size {
    font-size: 14px; }
  .c-cardProduct__size.c-cardProduct__size--real {
    color: #000000;
    background-color: transparent; }

.c-cardProduct__informationHover {
  position: absolute;
  top: 30px;
  right: 10px;
  color: #FFA758;
  z-index: 10;
  font-size: 18px;
  -webkit-transition: all ease 0.1s;
  -moz-transition: all ease 0.1s;
  -o-transition: all ease 0.1s;
  transition: all ease 0.1s; }

.c-cardProduct__informationHover:hover {
  -moz-transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg); }

.c-cardProduct__informationHoverLeftPanel {
  position: absolute;
  top: 0;
  right: 0;
  color: #b6b6b6;
  z-index: 10; }

.c-cardProduct__ecoresponsible {
  background: #1a9f04;
  position: absolute;
  top: 27px;
  right: 5px;
  color: #fff;
  padding: 5px;
  border-radius: 20px; }

.c-cardProduct__madeInEurope {
  position: absolute;
  width: 34px;
  height: 34px;
  right: 1px;
  padding: 4px; }

.c-cardProduct__madeInFrance {
  position: absolute;
  width: 34px;
  height: 34px;
  right: 1px;
  padding: 4px;
  border-radius: 20px; }

.c-cardProduct__handWashing {
  position: absolute;
  width: 34px;
  height: 34px;
  right: 1px;
  color: #fff;
  padding: 4px;
  border-radius: 20px; }

.c-cardProduct__zoomHover {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 9;
  padding: 0 3px;
  border-radius: 5px;
  font-size: 16px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  background: #b6b6b6; }

.c-cardProduct__zoomHover:hover {
  background: #3CAEFF; }

.c-cardProduct__zoomHover2 {
  position: absolute;
  top: 30px;
  left: 0;
  color: #fff;
  z-index: 9;
  padding: 0 3px;
  border-radius: 5px;
  font-size: 16px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  background: #b6b6b6; }

.c-cardProduct__zoomHover2:hover {
  background: #3CAEFF; }

.c-cardProduct__storageStall {
  color: #fff;
  z-index: 10;
  padding: 0 3px;
  border-radius: 5px;
  font-size: 24px; }

.c-cardProduct__algoV2debug {
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  background: white;
  color: #000;
  width: 100%;
  height: 100%;
  padding: 10px;
  z-index: 10;
  transition: all ease 0.1s; }
  .c-cardProduct__algoV2debug .c-cardProduct__user_data {
    width: 240px;
    border: 1px solid #555;
    position: relative;
    margin-top: 10px;
    height: 20px; }
    .c-cardProduct__algoV2debug .c-cardProduct__user_data .c-cardProduct__user_sp_fp_lp {
      width: 1px;
      height: 18px;
      border-left: 2px solid #000;
      position: absolute;
      top: 0; }
  .c-cardProduct__algoV2debug .c-cardProduct__product_data {
    width: 240px;
    border: 1px solid #555;
    position: relative;
    margin-top: 2px;
    height: 20px; }
    .c-cardProduct__algoV2debug .c-cardProduct__product_data .c-cardProduct__product_sp_fp_lp {
      width: 1px;
      height: 18px;
      border-left: 2px solid #000;
      position: absolute;
      top: 0; }

.c-cardProduct__predictionScoreV2 {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0px; }
  .c-cardProduct__predictionScoreV2 .fa-star {
    padding: 3px 5px;
    border-radius: 5px; }
    .c-cardProduct__predictionScoreV2 .fa-star.gold-star {
      color: #F1C43B; }
    .c-cardProduct__predictionScoreV2 .fa-star.silver-star {
      color: #BFCDD9; }
  .c-cardProduct__predictionScoreV2 .fa-arrow-down, .c-cardProduct__predictionScoreV2 .fa-arrow-up {
    color: #b02020;
    padding: 3px 5px;
    border-radius: 5px; }

.c-cardProduct__isNew {
  position: absolute;
  top: 6px;
  right: 50px;
  background: #00aced;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px; }

.c-cardProduct__hasRecentSalesRateSpike {
  position: absolute;
  top: 1px;
  right: 75px;
  padding: 0;
  font-size: 20px;
  color: green; }

.c-cardProduct__predictionScore {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #fff;
  background: #3CAEFF;
  padding: 0px 3px;
  border-radius: 5px; }
  .c-cardProduct__predictionScore.trustScore0 {
    background: #ff3c3c;
    color: #fff; }
  .c-cardProduct__predictionScore.trustScore1 {
    background: #f8c200;
    color: #fff; }
  .c-cardProduct__predictionScore.trustScore2 {
    background: #dddddd;
    color: #fff; }
  .c-cardProduct__predictionScore.trustScore3 {
    background: #00e01d;
    color: #fff; }
  .c-cardProduct__predictionScore.trustScore4 {
    background: #38be00;
    color: #fff; }

.c-cardProduct__prediction {
  position: absolute;
  top: 5px;
  right: 30px;
  background: #F2CA27;
  color: #fff;
  padding: 0px 3px;
  border-radius: 5px; }

.c-cardProduct__information {
  position: absolute;
  z-index: 9;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #efefef;
  opacity: 0;
  visibility: hidden; }
  .c-cardProduct__information.is-visible {
    opacity: 1;
    visibility: visible; }

.c-cardProduct__comments {
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 25px 15px 25px 15px;
  background-color: #fff;
  border-top: 1px solid #efefef;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  text-transform: capitalize;
  height: 185px;
  font-size: 12px; }
  .c-cardProduct__comments.is-visible {
    opacity: 1;
    visibility: visible; }

.c-cardProduct__comments_input {
  position: absolute;
  z-index: 9;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  text-transform: capitalize;
  height: 45px;
  background: #fff; }
  .c-cardProduct__comments_input.is-visible {
    opacity: 1;
    visibility: visible; }

.c-cardProduct__comments_input input {
  width: 80%;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  bottom: 0; }

.c-cardProduct__comments_input button {
  width: 21%;
  display: inline-block;
  height: 34px;
  vertical-align: top;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #5bc0de;
  color: #fff;
  border-color: #5bc0de; }

.c-cardProduct__comments hr {
  margin: 10px 0; }

/*--------------------
COMPONENT
--------------------*/
.c-cartProduct.c-cartProduct--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
  z-index: 11;
  padding-left: 380px;
  padding-right: 80px;
  background-color: #efefef; }
.c-cartProduct .js-disableAlternativeMode {
  position: fixed;
  bottom: 140px;
  right: calc(50% - 190px);
  background: #0d47a1;
  color: #fff;
  padding: 5px 0;
  width: 200px;
  clip-path: polygon(5% 0, 95% 0, 100% 100%, 0 100%);
  cursor: pointer;
  text-align: center;
  border-radius: 20px 20px 0 0;
  display: none; }
.c-cartProduct .js-alternativeModeDiv {
  position: fixed;
  background: #0d47a1;
  bottom: 110px;
  color: #fff;
  padding: 5px;
  display: none;
  width: 100%;
  font-weight: bold; }

.c-cartProduct--fixed .c-cartProduct__btn {
  position: absolute;
  top: 5px;
  right: 15px;
  height: 100%;
  width: 65px; }

/*--------------------
COMMENT BLOCK
--------------------*/
.c-commentBlock {
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
  position: relative;
  color: #000000;
  background-color: #efefef;
  padding: 15px; }

.c-commentBlock--maxHeight {
  max-height: 200px;
  overflow: auto; }

/*--------------------
COMPONENT
--------------------*/
.c-dashboard__block {
  text-decoration: none; }
  .c-dashboard__block:hover {
    text-decoration: none; }
    .c-dashboard__block:hover .c-dashboard__number {
      color: #f05d61; }
    .c-dashboard__block:hover .c-dashboard__text--link {
      text-decoration: underline;
      color: #f05d61; }

.c-dashboard__title {
  font-size: 24px;
  font-weight: bold;
  color: #666;
  text-transform: uppercase;
  margin: 0; }

.c-dashboard__number {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  line-height: 1;
  margin-bottom: 5px; }

.c-dashboard__number__small {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  line-height: 1;
  margin-bottom: 5px; }

.c-dashboard__text {
  color: #666;
  font-size: 14px; }

.c-dashboard__text__small {
  color: #666;
  font-size: 14px; }

#datepicker_commande {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
  border: 1px solid #ddd;
  display: none;
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  background-color: #fff;
  padding: 30px;
  margin: 15px 0 0;
  /*.sale:first-child:before{
      content: "";
      background: #DFFFEB;
      left: 10px;
      width: 58px;
      position: absolute;
      height: 40px;
      line-height: 40px;
  }
  .sale.firstSale::before{
      content: "Soldes";
      background: #DFFFEB;
      left: 10px;
      width: 58px;
      position: absolute;
      height: 40px;
      line-height: 40px;
  }*/
  /*.sale.firstSale::before{
      content: "Soldes";
      background: #DFFFEB;
      right: 10px;
      bottom: 10px;
      width: 58px;
      position: absolute;
      height: 40px;
      line-height: 40px;
  }*/ }
  #datepicker_commande .ui-datepicker-header,
  #datepicker_commande .ui-datepicker-calendar {
    width: 100%;
    max-width: 420px;
    margin: 0 auto; }
  #datepicker_commande .ui-datepicker-header {
    position: relative;
    min-height: 30px;
    line-height: 30px; }
    #datepicker_commande .ui-datepicker-header .ui-datepicker-title {
      font-family: 'DroidSerif', sans-serif;
      display: block;
      color: #000000;
      font-size: 18px;
      text-transform: uppercase; }
    #datepicker_commande .ui-datepicker-header .ui-corner-all {
      border-radius: 4px;
      position: absolute;
      top: 0;
      display: block;
      width: 30px;
      height: 30px;
      cursor: pointer;
      color: #666; }
      #datepicker_commande .ui-datepicker-header .ui-corner-all:not(.ui-state-disabled):hover {
        background-color: #efefef;
        color: #f05d61; }
      #datepicker_commande .ui-datepicker-header .ui-corner-all.ui-state-disabled {
        color: #ddd;
        cursor: default; }
      #datepicker_commande .ui-datepicker-header .ui-corner-all.ui-datepicker-prev {
        left: 0; }
        #datepicker_commande .ui-datepicker-header .ui-corner-all.ui-datepicker-prev:before {
          font-family: "FontAwesome";
          content: "\f0d9"; }
      #datepicker_commande .ui-datepicker-header .ui-corner-all.ui-datepicker-next {
        right: 0; }
        #datepicker_commande .ui-datepicker-header .ui-corner-all.ui-datepicker-next:before {
          font-family: "FontAwesome";
          content: "\f0da"; }
    #datepicker_commande .ui-datepicker-header .ui-icon {
      text-indent: -9999px;
      position: absolute;
      display: none; }
  #datepicker_commande thead th {
    color: #ccc;
    padding: 15px 0 10px; }
  #datepicker_commande tbody tr:hover td:not(.ui-datepicker-current-day) {
    background-color: #f9f9f9; }
    #datepicker_commande tbody tr:hover td:not(.ui-datepicker-current-day) a {
      color: #f05d61; }
  #datepicker_commande tbody tr:hover td:not(.ui-datepicker-current-day).sale {
    background-color: #DCF3F7; }
    #datepicker_commande tbody tr:hover td:not(.ui-datepicker-current-day).sale a {
      color: #666; }
  #datepicker_commande tbody tr:hover td.ui-datepicker-unselectable {
    background: transparent; }
    #datepicker_commande tbody tr:hover td.ui-datepicker-unselectable a {
      color: #ddd; }
  #datepicker_commande tbody tr:hover td.ui-datepicker-current-day {
    background-color: #ff7b7b; }
    #datepicker_commande tbody tr:hover td.ui-datepicker-current-day a {
      color: #fff;
      text-shadow: 0 1px 0 #bf1313; }
  #datepicker_commande tbody td {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    width: 40px;
    height: 40px; }
    #datepicker_commande tbody td a, #datepicker_commande tbody td span {
      display: block;
      margin: 0 auto;
      width: 100%; }
  #datepicker_commande .ui-datepicker-unselectable,
  #datepicker_commande .ui-state-disabled {
    /*background: transparent!important;*/ }
    #datepicker_commande .ui-datepicker-unselectable .ui-state-default,
    #datepicker_commande .ui-state-disabled .ui-state-default {
      color: #ddd; }
  #datepicker_commande .ui-state-default {
    display: block;
    height: 40px;
    border: 0;
    line-height: 40px;
    color: #666; }
  #datepicker_commande .sale {
    background: #E1F9FD; }
  #datepicker_commande .sale:first-child:before {
    content: attr(data-sales-group);
    background: #E1F9FD;
    left: 10px;
    width: 58px;
    position: absolute;
    height: 40px;
    line-height: 40px;
    font-size: 13px; }
  #datepicker_commande .ui-datepicker-current-day {
    background-color: #ff7b7b; }
    #datepicker_commande .ui-datepicker-current-day .ui-state-default {
      color: #fff;
      text-shadow: 0 1px 0 #bf1313; }

#datepicker_commande_input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -999;
  height: 0;
  width: 0; }

.c-datepicker__select {
  margin: 0 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ff7b7b;
  font-family: 'DomaineDisp-Semibold', sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #ff7b7b;
  cursor: pointer; }

.c-datepicker__sales_label {
  background: #ff7b7b;
  color: #fff;
  padding: 5px 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: none; }

.c-withdrawalPoint {
  position: relative;
  height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
  border: 1px solid #ddd; }
  @media (min-width: 576px) {
    .c-withdrawalPoint {
      margin-bottom: 0;
      border-right: 0; } }

.c-withdrawalPoint__item {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  padding: 20px 10px;
  cursor: pointer; }
  .c-withdrawalPoint__item:hover {
    background-color: #fff; }
  .c-withdrawalPoint__item.active {
    background-color: #fff; }
    .c-withdrawalPoint__item.active .c-withdrawalPoint__choosed {
      display: block; }
  .c-withdrawalPoint__item.loading .c-withdrawalPoint__loader {
    display: block; }

.c-withdrawalPoint__name {
  color: #000000; }

.c-withdrawalPoint__address {
  text-transform: lowercase; }

.c-withdrawalPoint__address--city:first-letter {
  text-transform: uppercase; }

.c-withdrawalPoint__choosed,
.c-withdrawalPoint__loader {
  display: none;
  margin-top: 10px;
  color: #28C76F; }

.c-withdrawalPoint__loader {
  color: #666; }

.c-withdrawalPoint__schedule {
  margin-top: 10px; }

.c-withdrawalPoint__fakeData {
  border-radius: 20px;
  height: 8px;
  width: 100%;
  max-width: 200px;
  background-color: #666;
  background: linear-gradient(to right, #666 8%, #ddd 18%, #666 33%);
  background-size: cover;
  background-size: 800px 104px;
  margin-bottom: 5px;
  opacity: 0.25;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear; }

.c-withdrawalPoint__fakeData--title {
  height: 12px; }

.c-withdrawalPoint__fakeData--schedule-1 {
  max-width: 280px; }

.c-withdrawalPoint__fakeData--schedule-2 {
  max-width: 120px; }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }
/*--------------------
Filter
--------------------*/
.c-filter.c-filter--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  padding-left: 385px;
  background-color: #f9f9f9; }

.c-filter__item {
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
  position: relative;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  text-align: center;
  white-space: normal; }
  .c-filter__item.c-filter__item-alert-1 {
    border: 1px solid #4CAF50; }
    .c-filter__item.c-filter__item-alert-1 .c-form__field--radio .c-form__label {
      color: #4CAF50 !important;
      font-weight: bold; }
  .c-filter__item.c-filter__item-alert-2 .c-form__field--radio .c-form__label {
    color: #4CAF50 !important; }
  .c-filter__item.js-typeNotWanted {
    border: none; }
    .c-filter__item.js-typeNotWanted .c-form__field--radio .c-form__label {
      color: #f05d61 !important;
      font-weight: normal; }

.c-filter--displayFilters {
  background-color: #efefef;
  font-weight: normal;
  color: #000;
  display: inline-block;
  width: 79px;
  height: 44px;
  vertical-align: top;
  font-size: 16px; }

.c-filter__item--selected {
  border: 1px solid #28C76F;
  color: #28C76F; }
  .c-filter__item--selected .c-filter__icon {
    display: inline-block; }

.c-filter__icon {
  display: none;
  vertical-align: middle;
  margin-right: 10px; }

.c-filter__name {
  display: inline-block;
  vertical-align: middle; }

.c-salesRate-anchors {
  background: #fff;
  position: fixed;
  padding: 3px 5px 3px 377px;
  top: 50px;
  width: 100%;
  font-size: 20px;
  z-index: 9;
  border-radius: 4px;
  text-align: center; }
  .c-salesRate-anchors .c-salesRate-anchors-button {
    cursor: pointer;
    padding: 5px 8px;
    display: inline-block;
    width: 47%;
    margin-right: 1%;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.08), 0 1px 12px rgba(28, 44, 79, 0.1); }
    .c-salesRate-anchors .c-salesRate-anchors-button:hover {
      transition: all ease 0.1s;
      background: #f6f6f6; }
    .c-salesRate-anchors .c-salesRate-anchors-button.c-salesRate-anchors-new {
      color: #00aced; }
    .c-salesRate-anchors .c-salesRate-anchors-button.c-salesRate-anchors-gold {
      color: #F1C43B; }
    .c-salesRate-anchors .c-salesRate-anchors-button.c-salesRate-anchors-silver {
      color: #BFCDD9; }
      .c-salesRate-anchors .c-salesRate-anchors-button.c-salesRate-anchors-silver .c-salesRates-alert-icon {
        display: none;
        color: #f05d61; }
      .c-salesRate-anchors .c-salesRate-anchors-button.c-salesRate-anchors-silver.c-salesRate-anchors-silver-alert {
        border: 1px solid #f05d61; }
        .c-salesRate-anchors .c-salesRate-anchors-button.c-salesRate-anchors-silver.c-salesRate-anchors-silver-alert span {
          color: #f05d61 !important; }
    .c-salesRate-anchors .c-salesRate-anchors-button.c-salesRate-anchors-bronze {
      color: #D98841; }
    .c-salesRate-anchors .c-salesRate-anchors-button.c-salesRate-anchors-highlight {
      background: #efeee6; }

.c-cutsFilters {
  position: fixed;
  top: 50px;
  right: 0;
  width: 41%;
  padding: 5px;
  z-index: 9;
  background: white;
  font-size: 16px;
  line-height: 35px; }
  .c-cutsFilters .c-jeansCuts, .c-cutsFilters .c-dressFits, .c-cutsFilters .c-skirtFits {
    margin-right: 40px;
    display: none; }
    .c-cutsFilters .c-jeansCuts.showFilter, .c-cutsFilters .c-dressFits.showFilter, .c-cutsFilters .c-skirtFits.showFilter {
      display: inline-block; }
    .c-cutsFilters .c-jeansCuts label, .c-cutsFilters .c-dressFits label, .c-cutsFilters .c-skirtFits label {
      margin-bottom: 0;
      font-weight: normal;
      padding-left: 20px;
      cursor: pointer; }

/*--------------------
COMPONENT
--------------------*/
.c-form {
  margin: 0; }

/*--------------------
ELEMENT
--------------------*/
/* Completion */
.c-form__completion {
  position: relative;
  margin: 0;
  border: 1px solid #ddd;
  box-shadow: none;
  background: #efefef;
  padding: 0 12px;
  height: 42px;
  line-height: 42px;
  color: #000000;
  font-size: 14px; }

/* Field */
.c-form__field {
  position: relative;
  width: 100%;
  text-decoration: none;
  line-height: normal; }
  .c-form__field:hover {
    text-decoration: none; }

/* Help */
.c-form__help {
  border-radius: 100%;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
  margin-left: 5px;
  background: #b6b6b6;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  text-align: center;
  line-height: 16px;
  font-size: 9px; }
  .c-form__help .fa {
    color: #fff;
    font-size: 11px; }

.c-form__help--float {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  margin-bottom: 0;
  margin-left: 0;
  top: 50%;
  right: 10px; }

/* Icon */
/* Icon-container */
/* Info */
.c-form__info {
  display: block;
  color: #666;
  font-size: 14px; }

/* Input */
.c-form__input {
  /* 
  .timepicker must be delete in the futur.
  The specificity &.c-form__input must be delete in the futur.
  */ }
  .c-form__input.c-form__input {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.06) inset;
    border-radius: 4px;
    -webkit-transition: all 0 ease-in 0;
    -moz-transition: all 0 ease-in 0;
    -o-transition: all 0 ease-in 0;
    transition: all 0 ease-in 0;
    display: block;
    position: relative;
    margin: 0;
    outline: none;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 0 12px;
    width: 100%;
    height: 42px;
    line-height: normal;
    color: #000000;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    .c-form__input.c-form__input::-webkit-input-placeholder {
      opacity: 1;
      color: #b6b6b6; }
    .c-form__input.c-form__input:-ms-input-placeholder {
      opacity: 1;
      color: #b6b6b6; }
    .c-form__input.c-form__input:-moz-placeholder {
      opacity: 1;
      color: #b6b6b6; }
    .c-form__input.c-form__input:hover {
      border: 1px solid #9b9b9b; }
    .c-form__input.c-form__input:focus, .c-form__input.c-form__input:active {
      box-shadow: none;
      outline: none;
      border: 1px solid #F3A090;
      background-color: #fff; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none; }

input[type=number] {
  -moz-appearance: textfield; }

/* Label */
.c-form__label {
  display: inline-block;
  width: auto;
  margin: 0 0 3px 0;
  font-size: 16px;
  color: #000000;
  min-height: 20px;
  font-weight: normal; }

/* Label container */
/* Line */
.c-form__line {
  position: relative; }

/* One Line form */
/*--------------------
MODIFIER
--------------------*/
/* Line */
.c-form__line--error.c-form__line--error .c-form__input {
  border: 1px solid #FF0000; }
.c-form__line--error.c-form__line--error .c-form__info--error {
  text-align: left;
  display: block; }

.c-form__line--error--1.c-form__line--error--1 .c-form__input--1 {
  border: 1px solid #FF0000; }
.c-form__line--error--1.c-form__line--error--1 .c-form__info--error--1 {
  display: block; }

.c-form__line--error--2.c-form__line--error--2 .c-form__input--2 {
  border: 1px solid #FF0000; }
.c-form__line--error--2.c-form__line--error--2 .c-form__info--error--2 {
  display: block; }

.c-form__line_valide.c-form__line_valide .c-form__label {
  color: #28C76F; }
.c-form__line_valide.c-form__line_valide .c-form__input {
  border: 1px solid #28C76F; }
.c-form__line_valide.c-form__line_valide .c-form__info_valide {
  display: block; }

/* Field */
.c-form__field--checkbox {
  margin-bottom: -15px; }
  @media (min-width: 768px) {
    .c-form__field--checkbox {
      margin-bottom: -5px; } }
  .c-form__field--checkbox .c-form__input {
    position: absolute;
    left: -9999px;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    width: 0;
    height: 0; }
    .c-form__field--checkbox .c-form__input + .c-form__label {
      position: relative;
      cursor: pointer;
      padding-left: 30px;
      min-height: 20px;
      color: #000000;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 15px; }
      @media (min-width: 768px) {
        .c-form__field--checkbox .c-form__input + .c-form__label {
          margin-bottom: 5px;
          font-size: 14px; } }
      .c-form__field--checkbox .c-form__input + .c-form__label::after {
        -webkit-transition: all 0.15s ease-in 0s;
        -moz-transition: all 0.15s ease-in 0s;
        -o-transition: all 0.15s ease-in 0s;
        transition: all 0.15s ease-in 0s;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        border: 1px solid #c0bdba;
        background: #fff;
        width: 20px;
        height: 20px;
        content: ''; }
      .c-form__field--checkbox .c-form__input + .c-form__label::before {
        -webkit-transition: all 0.15s ease-in 0s;
        -moz-transition: all 0.15s ease-in 0s;
        -o-transition: all 0.15s ease-in 0s;
        transition: all 0.15s ease-in 0s;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        display: block;
        position: absolute;
        top: 50%;
        left: 4px;
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        background-image: url("/public/images/form/check.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 10px;
        height: 10px;
        color: #fff;
        content: ''; }
    .c-form__field--checkbox .c-form__input + .labelBefore::before {
      filter: invert(32%) sepia(70%) saturate(3951%) hue-rotate(120deg) brightness(93%) contrast(94%); }
    .c-form__field--checkbox .c-form__input:checked + .c-form__label::after {
      border: 1px solid #F3A090; }
    .c-form__field--checkbox .c-form__input:checked + .c-form__label::before {
      visibility: visible;
      opacity: 1; }
    .c-form__field--checkbox .c-form__input:checked + .labelBefore::after {
      border: 1px solid #088F13; }
    .c-form__field--checkbox .c-form__input:checked + .labelBefore::before {
      visibility: visible;
      opacity: 1; }
  .c-form__field--checkbox .c-form__input--tag + .c-form__label {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px 10px 10px 30px;
    border-radius: 4px; }
    .c-form__field--checkbox .c-form__input--tag + .c-form__label::after {
      left: 5px; }
    .c-form__field--checkbox .c-form__input--tag + .c-form__label::before {
      left: 8px; }
  .c-form__field--checkbox .c-form__input--tag-notWanted:not(.c-form__input--tag) + .c-form__label {
    color: #f05d61; }
  .c-form__field--checkbox .c-form__input--tag-notWanted.c-form__input--tag + .c-form__label {
    border: 1px solid #f05d61; }

.c-form__field--radio {
  margin-bottom: -15px; }
  @media (min-width: 768px) {
    .c-form__field--radio {
      margin-bottom: -5px; } }
  .c-form__field--radio .c-form__input {
    position: absolute;
    left: -9999px;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    width: 0;
    height: 0; }
    .c-form__field--radio .c-form__input + .c-form__label {
      font-family: 'sofia-pro', sans-serif;
      font-weight: 300;
      position: relative;
      cursor: pointer;
      padding-left: 30px;
      min-height: 20px;
      color: #000000;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 15px; }
      @media (min-width: 768px) {
        .c-form__field--radio .c-form__input + .c-form__label {
          margin-bottom: 5px;
          font-size: 14px; } }
      .c-form__field--radio .c-form__input + .c-form__label::after {
        border-radius: 100%;
        -webkit-transition: all 0.15s ease-in 0s;
        -moz-transition: all 0.15s ease-in 0s;
        -o-transition: all 0.15s ease-in 0s;
        transition: all 0.15s ease-in 0s;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        border: 1px solid #9b9b9b;
        background: #fff;
        width: 16px;
        height: 16px;
        content: ''; }
      .c-form__field--radio .c-form__input + .c-form__label::before {
        border-radius: 100%;
        -webkit-transition: all 0.15s ease-in 0s;
        -moz-transition: all 0.15s ease-in 0s;
        -o-transition: all 0.15s ease-in 0s;
        transition: all 0.15s ease-in 0s;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
        display: block;
        position: absolute;
        top: 50%;
        left: 4.5px;
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        background-color: #fff;
        width: 6px;
        height: 6px;
        color: #fff;
        content: ''; }
    .c-form__field--radio .c-form__input:checked + .c-form__label::after {
      border: 1px solid #F3A090;
      background: #F3A090; }
    .c-form__field--radio .c-form__input:checked + .c-form__label::before {
      visibility: visible;
      opacity: 1; }
  .c-form__field--radio .c-form__input--tag + .c-form__label {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px 10px 10px 30px;
    border-radius: 4px; }
    .c-form__field--radio .c-form__input--tag + .c-form__label::after {
      left: 5px; }
    .c-form__field--radio .c-form__input--tag + .c-form__label::before {
      left: 10px; }

.c-form__field--select .c-form__input {
  background-color: #f9f9f9;
  background-image: url("/public/images/form/arrow.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  padding-right: 40px; }
  .c-form__field--select .c-form__input:-moz-focusring {
    text-shadow: 0 0 0 #000000;
    color: transparent; }
  .c-form__field--select .c-form__input:hover {
    background-image: url("/public/images/form/arrow--hover.svg"); }
  .c-form__field--select .c-form__input:focus, .c-form__field--select .c-form__input:active {
    background-image: url("/public/images/form/arrow--focus.svg"); }

.c-form__field--textarea .c-form__input {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  -webkit-transition: all 0s ease-in 0s;
  -moz-transition: all 0s ease-in 0s;
  -o-transition: all 0s ease-in 0s;
  transition: all 0s ease-in 0s;
  padding: 15px;
  min-width: 100%;
  max-width: 100%;
  height: 120px;
  min-height: 120px; }

.c-form__field--textarea--small .c-form__input {
  height: 80px;
  min-height: 80px; }

.c-form__field_toggle {
  display: block;
  margin: 0 auto;
  width: 40px; }
  .c-form__field_toggle .c-form__input {
    display: none;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0; }
    .c-form__field_toggle .c-form__input:not(:checked) + .c-form__label::before, .c-form__field_toggle .c-form__input:checked + .c-form__label::before {
      border-radius: 20px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.15s ease-in 0s;
      -moz-transition: all 0.15s ease-in 0s;
      -o-transition: all 0.15s ease-in 0s;
      transition: all 0.15s ease-in 0s;
      position: absolute;
      top: 50%;
      left: 0;
      background: #b6b6b6;
      cursor: pointer;
      width: 40px;
      height: 20px;
      content: ''; }
    .c-form__field_toggle .c-form__input:not(:checked) + .c-form__label::after, .c-form__field_toggle .c-form__input:checked + .c-form__label::after {
      border-radius: 100%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: all 0.15s ease-in 0s;
      -moz-transition: all 0.15s ease-in 0s;
      -o-transition: all 0.15s ease-in 0s;
      transition: all 0.15s ease-in 0s;
      position: absolute;
      top: 50%;
      left: 2px;
      background: #fff;
      cursor: pointer;
      width: 16px;
      height: 16px;
      content: ''; }
    .c-form__field_toggle .c-form__input:checked + .c-form__label::before {
      background: #28C76F; }
    .c-form__field_toggle .c-form__input:checked + .c-form__label::after {
      left: 22px; }

/* CompletionContainer */
.c-form__completionContainer {
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start; }
  .c-form__completionContainer .c-form__input {
    /*
    flex-grow
    flex-shrink
    flex-basis
    */
    -webkit-flex: 1 1 auto;
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    /* The !important must be delete in the futur, it's a fix for bootstrap. */
    border-radius: 4px 0 0 4px !important;
    width: auto;
    min-width: 100px; }
    .c-form__completionContainer .c-form__input + .c-form__completion {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid #ddd;
      border-left: 0; }
  .c-form__completionContainer .c-form__completion {
    border-radius: 4px 0 0 4px;
    border-right: 0; }
    .c-form__completionContainer .c-form__completion + .c-form__input {
      /* The !important must be delete in the futur, it's a fix for bootstrap. */
      border-radius: 0 4px 4px 0 !important; }

/* Icon */
/* Input */
.c-form__input--size-xs.c-form__input--size-xs {
  max-width: 90px; }

.c-form__input--size-s.c-form__input--size-s {
  max-width: 150px; }

.c-form__input--size-m.c-form__input--size-m {
  max-width: 240px; }

.c-form__input--size-l.c-form__input--size-l {
  max-width: 300px; }

/* Info */
.c-form__info--error {
  margin-top: 5px;
  color: #FF0000;
  display: none; }

.c-form__info--border {
  border-radius: 4px;
  position: relative;
  margin-top: 5px;
  border: 1px solid #FF0000;
  padding: 15px; }

.c-form__info_valide {
  color: #28C76F; }

/* Fileupload */
.c-form__field_fileupload .c-form__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
  border: 0;
  width: .1px;
  height: .1px;
  overflow: hidden; }
  .c-form__field_fileupload .c-form__input + .c-form__label {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.06) inset;
    border-radius: 4px;
    -webkit-transition: all 0.15s ease-in 0s;
    -moz-transition: all 0.15s ease-in 0s;
    -o-transition: all 0.15s ease-in 0s;
    transition: all 0.15s ease-in 0s;
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    align-items: flex-start;
    margin: 0;
    outline: none;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 0;
    width: 100%;
    height: 42px;
    line-height: normal; }
    .c-form__field_fileupload .c-form__input + .c-form__label .c-form__fileupload {
      /*
      flex-grow
      flex-shrink
      flex-basis
      */
      -webkit-flex: 0 1 auto;
      -webkit-box-flex: 0 1 auto;
      -moz-box-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-transition: all 0.15s ease-in 0s;
      -moz-transition: all 0.15s ease-in 0s;
      -o-transition: all 0.15s ease-in 0s;
      transition: all 0.15s ease-in 0s;
      display: inline-block;
      padding: 0 12px;
      width: auto;
      height: 40px;
      line-height: 40px;
      font-size: 12px; }
      .c-form__field_fileupload .c-form__input + .c-form__label .c-form__fileupload .fa {
        margin-right: 4px;
        font-size: 16px; }
    .c-form__field_fileupload .c-form__input + .c-form__label .c-form__fileupload_label {
      border-right: 1px solid #ddd;
      background: #efefef;
      cursor: pointer; }
  .c-form__field_fileupload .c-form__input:hover + .c-form__label {
    border: 1px solid #9b9b9b; }
    .c-form__field_fileupload .c-form__input:hover + .c-form__label .c-form__fileupload_label {
      border-right: 1px solid #9b9b9b; }
  .c-form__field_fileupload .c-form__input:focus + .c-form__label, .c-form__field_fileupload .c-form__input:active + .c-form__label {
    box-shadow: none;
    outline: none;
    border: 1px solid #f05d61;
    background-color: #fff; }

/*--------------------
COMPONENT
--------------------*/
.c-link {
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  position: relative;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  color: #3CAEFF;
  font-size: 16px;
  text-decoration: underline; }
  .c-link:hover {
    color: #109CFF; }
  .c-link:active {
    color: #0394FB; }
  .c-link:hover, .c-link:active, .c-link:focus {
    text-decoration: underline; }

.c-link--color-green {
  color: #28C76F; }
  .c-link--color-green:hover {
    color: #01BE53; }
  .c-link--color-green:active {
    color: #00903F; }

.c-link--color-red {
  color: #ff7b7b; }
  .c-link--color-red:hover {
    color: #e93232; }
  .c-link--color-red:active {
    color: #bf1313; }

.c-link--color-grey {
  color: #b6b6b6; }
  .c-link--color-grey:hover {
    color: #9b9b9b; }
  .c-link--color-grey:active {
    color: #000000; }

/*--------------------
LOADER BAR
--------------------*/
.c-loaderBar {
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  position: fixed;
  z-index: 999;
  width: 0;
  height: 4px;
  top: 0;
  margin: 0;
  background-color: #28C76F;
  opacity: 1; }

.c-loaderBar--state-error {
  background-color: #f05d61;
  width: 100% !important; }

/*--------------------
NOTIFICATION Clement 1
--------------------*/
.c-notification {
  position: fixed;
  z-index: 999;
  top: 60px;
  right: 10px;
  width: 400px;
  text-align: right; }

.c-notification__item {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.08), 0 1px 12px rgba(28, 44, 79, 0.1);
  border-radius: 4px;
  position: relative;
  display: inline-block;
  text-align: left;
  padding: 15px;
  padding-left: 45px;
  margin-bottom: 10px;
  background-color: #fff; }
  .c-notification__item::after {
    content: '';
    display: block;
    width: 1px;
    height: 1px;
    background-color: transparent; }

.c-notification__item--state-error .c-notification__icon {
  color: #f05d61; }

.c-notification__item--state-success .c-notification__icon {
  color: #28C76F; }

.c-notification__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 100%;
  text-align: center; }

.c-notification__fa {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 20px; }

/*--------------------
REFERAL
--------------------*/
.c-referral-wrapper {
  display: inline-block;
  width: 40px; }

.c-referal {
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  position: relative;
  display: block;
  padding: 0 15px 0 50px;
  border: 1px solid #ddd;
  height: 40px;
  padding: 0;
  border-radius: 25px; }

.c-referal__social {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #000000;
  border-radius: 25px; }

.c-referal__text {
  height: 40px;
  line-height: 40px;
  font-size: 14px; }

.c-referal__mobile {
  display: none;
  visibility: hidden; }
  @media only screen and (max-width: 767px) {
    .c-referal__mobile {
      display: inline-block;
      visibility: visible; } }

.c-referal--link {
  color: #f05d61;
  border: 1px solid #dedede;
  border-radius: 0 !important; }
  .c-referal--link .c-referal__social {
    background-color: #f05d61; }
  .c-referal--link .c-referal__btn {
    background-color: #f79a8d; }

.c-referal--mail {
  color: #000000;
  border: 1px solid #000000; }
  .c-referal--mail .c-referal__social {
    background-color: transparent;
    font-size: 16px; }

.c-referal--facebook {
  color: #000000;
  border: 1px solid #000000; }
  .c-referal--facebook .c-referal__social {
    background-color: transparent; }

.c-referal--messenger {
  color: #000000;
  border: 1px solid #000000; }
  .c-referal--messenger .c-referal__social {
    background-color: transparent; }
    .c-referal--messenger .c-referal__social img {
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 18px;
      height: 18px; }

.c-referal--pinterest {
  color: #000000;
  border: 1px solid #000000; }
  .c-referal--pinterest .c-referal__social {
    background-color: transparent; }

.c-referal--whatsapp {
  color: #000000;
  border: 1px solid #000000; }
  .c-referal--whatsapp .c-referal__social {
    background-color: transparent;
    font-size: 20px; }

.c-referal--twitter {
  color: #000000;
  border: 1px solid #000000; }
  .c-referal--twitter .c-referal__social {
    background-color: transparent; }

.c-referal__copy {
  display: block;
  min-width: 100%;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  line-height: 38px;
  height: 38px; }

.c-referal__btn {
  position: absolute;
  font-family: "sofia-pro", sans-serif;
  font-weight: 700;
  top: -1px;
  right: -1px;
  height: 40px;
  padding: 0 10px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer; }
  .c-referal__btn .fa {
    margin-left: 5px;
    font-size: 14px; }

.c-smallValidation {
  display: none;
  margin-right: 0;
  text-align: center;
  font-size: 14px;
  opacity: 0;
  visibility: hidden; }
  .c-smallValidation.c-smallValidation--show {
    display: inline-block;
    margin-right: 5px;
    opacity: 1;
    visibility: visible; }
  .c-smallValidation.c-smallValidation--loading .c-smallValidation__icon--loading {
    display: block; }
  .c-smallValidation.c-smallValidation--validation .c-smallValidation__icon--validation {
    display: block; }
  .c-smallValidation.c-smallValidation--error .c-smallValidation__icon--error {
    display: block; }
  .c-smallValidation .c-smallValidation__icon--validation {
    display: none;
    color: #28C76F; }
  .c-smallValidation .c-smallValidation__icon--loading {
    display: none;
    color: #666; }
  .c-smallValidation .c-smallValidation__icon--error {
    display: none;
    color: #FFA758; }

/*--------------------
COMPONENT
--------------------*/
.c-styleFavorite {
  border-radius: 4px;
  padding: 10px;
  overflow: hidden; }

.c-styleFavorite--fe {
  background-color: #ff9999; }

.c-styleFavorite--av {
  background-color: #28C76F; }

.c-styleFavorite--cl {
  background-color: #2980b9; }

.c-styleFavorite--ch {
  background-color: #e43a15; }

.c-styleFavorite--bo {
  background-color: #F7971E; }

.c-styleFavorite--ca {
  background-color: #7A7560; }

.c-styleFavorite__value {
  font-family: 'DroidSerif', sans-serif;
  color: #fff;
  font-size: 20px; }

.c-styleFavorite__name {
  color: #fff;
  white-space: nowrap; }

.c-styleFavorite__star {
  color: #fff;
  font-size: 16px; }

.c-styleFavorite--opacity-1,
.c-styleFavorite--opacity-2 {
  opacity: 0.6;
  background: #b6b6b6; }

/*--------------------
COMPONENT
--------------------*/
.c-table {
  position: relative;
  width: 100%;
  margin: 0;
  border-collapse: initial;
  border-spacing: 0;
  border: 1px solid #ddd; }
  .c-table.c-table--invisible {
    width: auto;
    border: 0; }

/*--------------------
ELEMENT
--------------------*/
/* Header */
.c-table__header {
  position: relative;
  z-index: 1;
  border: none;
  padding: 30px;
  font-family: 'DroidSerif', sans-serif; }
  .c-table__header .c-table__cell {
    font-family: 'DroidSerif', sans-serif;
    color: #9b9b9b;
    border-top: none; }
    .c-table__header .c-table__cell a {
      font-family: 'DroidSerif', sans-serif;
      color: #9b9b9b; }
  .c-table--invisible .c-table__header {
    padding: 0; }

/* Body */
.c-table__body {
  position: relative;
  z-index: 1; }

/* Row */
.c-table__row {
  border: none; }

.c-table__row--state-actif .c-table__cell {
  background-color: #D8FCE8; }

.c-table__row--state-inactif .c-table__cell {
  background-color: #FFE7E7; }

.c-table__row_disabled .c-table__cell {
  background: #f9f9f9;
  opacity: 0.6;
  cursor: not-allowed; }
  .c-table__row_disabled .c-table__cell .c-form__input,
  .c-table__row_disabled .c-table__cell .c-form__label,
  .c-table__row_disabled .c-table__cell .c-form__label:after,
  .c-table__row_disabled .c-table__cell .c-form__label:before {
    cursor: not-allowed !important; }

/* Cell */
.c-table__cell {
  position: relative;
  padding: 10px;
  vertical-align: middle;
  background-color: #fff;
  border: none;
  border-top: 1px solid #ddd;
  color: #000000;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  text-transform: none; }
  .c-table--invisible .c-table__cell {
    padding: 0 10px 0 0;
    background-color: transparent;
    border: 0; }

.c-table__img {
  display: block;
  height: 60px;
  margin: 0 auto; }

/*--------------------
COMPONENT
--------------------*/
.c-title {
  font-family: 'DroidSerif', sans-serif;
  display: block;
  color: #000000;
  margin-bottom: 30px;
  font-size: 20px; }

.c-title--size-m {
  font-size: 18px; }

.c-title--size-s {
  font-size: 16px; }

.c-title--size-xs {
  font-size: 14px; }

.c-title--size-xxl {
  font-size: 32px; }

/*--------------------
COMPONENT
--------------------*/
.c-tooltip-container {
  z-index: 999;
  position: relative; }
  .c-tooltip-container:hover .c-tooltip {
    visibility: visible;
    opacity: 1; }

.c-tooltip {
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 15px;
  width: 300px;
  text-align: center;
  line-height: 1;
  color: #000000;
  font-size: 14px; }
  .c-tooltip::after {
    position: absolute;
    content: ''; }

.c-tooltip--show {
  visibility: visible;
  opacity: 1; }

.c-tooltip--flow {
  position: relative; }

.c-tooltip--error {
  position: relative;
  color: #FFA758; }

.c-tooltip_icon {
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px; }

.c-tooltip--scroll {
  padding: 15px 15px 5px; }
  .c-tooltip--scroll .c-tooltip__container {
    max-height: 300px;
    overflow-y: auto; }

/*--------------------
ELEMENT
--------------------*/
/* Arrow */
.c-tooltip__arrow {
  position: absolute;
  width: 0;
  height: 0;
  background: transparent; }
  .c-tooltip__arrow:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    background: transparent; }

.c-tooltip_top .c-tooltip__arrow {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  bottom: -18px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-top: 12px solid #ddd;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent; }
  .c-tooltip_top .c-tooltip__arrow:after {
    top: -13px;
    left: -12px;
    border-top: 12px solid #fff;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent; }

.c-tooltip_bottom .c-tooltip__arrow {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  top: -7px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-bottom: 12px solid #ddd;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent; }
  .c-tooltip_bottom .c-tooltip__arrow:after {
    bottom: -13px;
    left: -12px;
    border-bottom: 12px solid #fff;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent; }
.c-tooltip_bottom.c-tooltip_bottom_left .c-tooltip__arrow {
  left: inherit;
  right: 22px;
  margin: 0; }

.c-tooltip_right .c-tooltip__arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -12px;
  border-right: 12px solid #ddd;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent; }
  .c-tooltip_right .c-tooltip__arrow:after {
    top: -12px;
    left: 1px;
    border-right: 12px solid #fff;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent; }

.c-tooltip_left .c-tooltip__arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -12px;
  border-left: 12px solid #ddd;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent; }
  .c-tooltip_left .c-tooltip__arrow:after {
    top: -12px;
    right: 1px;
    border-left: 12px solid #fff;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent; }

/* Item */
.c-tooltip__item {
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s;
  display: block;
  margin-bottom: 15px;
  cursor: pointer;
  text-align: left;
  color: #9b9b9b;
  font-size: 14px; }
  .c-tooltip__item:last-child {
    margin-bottom: 0; }
  .c-tooltip__item:hover {
    text-decoration: none;
    color: #f05d61; }

/*--------------------
MODIFIER
--------------------*/
/* Width */
.c-tooltip_xs {
  width: 100px; }

.c-tooltip_s {
  width: 200px; }

.c-tooltip_l {
  width: 400px; }

.c-tooltip_xl {
  width: 500px; }

/* Orientation */
.c-tooltip_top {
  left: 50%;
  bottom: calc(100% + 22px);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }
  .c-tooltip_top:after {
    height: 23px;
    width: 100%;
    bottom: -23px;
    left: 0; }

.c-tooltip_bottom {
  left: 50%;
  top: calc(100% + 22px);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }
  .c-tooltip_bottom:after {
    height: 23px;
    width: 100%;
    top: -23px;
    left: 0; }
  .c-tooltip_bottom.c-tooltip_bottom_left {
    left: inherit;
    right: 0;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }

.c-tooltip_right {
  left: calc(100% + 22px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  .c-tooltip_right:after {
    width: 23px;
    height: 100%;
    left: -23px;
    top: 0; }

.c-tooltip_left {
  right: calc(100% + 22px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }
  .c-tooltip_left:after {
    width: 23px;
    height: 100%;
    right: -23px;
    top: 0; }

/* Scrollable */
.c-tooltip__scrollable {
  max-height: 200px;
  overflow-y: auto; }

.c-tooltip_nav {
  border-radius: 0 0 4px 4px; }
  .c-tooltip_nav .c-tooltip__arrow {
    display: none; }
  .c-tooltip_nav.c-tooltip_bottom {
    top: calc(100% - 1px);
    left: 0;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    border-top: 1px solid #fff; }

/*--------------------
UTILITIES
--------------------*/
.u-whiteSpace--nowrap {
  white-space: nowrap; }

.u-w100p {
  width: 100% !important; }

.u-hide {
  position: absolute !important;
  opacity: 0 !important;
  visibility: hidden !important; }

.u-show {
  position: static !important;
  opacity: 1 !important;
  visibility: visible !important; }

.u-display-inline-block {
  display: inline-block; }

.u-vertical-align-top {
  vertical-align: top; }

@media (min-width: 576px) {
  .xs-u-hide {
    position: absolute !important;
    opacity: 0 !important;
    visibility: hidden !important; } }
@media (min-width: 768px) {
  .sm-u-hide {
    position: absolute !important;
    opacity: 0 !important;
    visibility: hidden !important; } }
@media (min-width: 992px) {
  .md-u-hide {
    position: absolute !important;
    opacity: 0 !important;
    visibility: hidden !important; } }
@media (min-width: 1200px) {
  .lg-u-hide {
    position: absolute !important;
    opacity: 0 !important;
    visibility: hidden !important; } }
@media (min-width: 576px) {
  .xs-u-show {
    position: static !important;
    opacity: 1 !important;
    visibility: visible !important; } }
@media (min-width: 768px) {
  .sm-u-show {
    position: static !important;
    opacity: 1 !important;
    visibility: visible !important; } }
@media (min-width: 992px) {
  .md-u-show {
    position: static !important;
    opacity: 1 !important;
    visibility: visible !important; } }
@media (min-width: 1200px) {
  .lg-u-show {
    position: static !important;
    opacity: 1 !important;
    visibility: visible !important; } }
/*--------------------
IMPORT
--------------------*/
/* Color */
/*--------------------
COLOR
--------------------*/
.u-color--black {
  color: #000000; }

.u-color--grey-shady {
  color: #9b9b9b; }

.u-color--red {
  color: #f05d61; }

.u-color--red-light {
  color: #ff7b7b; }

.u-color--green {
  color: #28C76F; }

.u-color--orange {
  color: #FFA758; }

.u-bg--green {
  background-color: #28C76F; }

.u-bg--green-light {
  background-color: #4AD386 !important; }

.u-color--blue {
  color: #3CAEFF; }

.u-color--white {
  color: #fff; }

.u-color--pinterest {
  color: transparent; }

.u-color--instagram {
  color: transparent; }

/* Margin */
/*--------------------
MARGIN
--------------------*/
.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt45 {
  margin-top: 45px !important; }

.mt60 {
  margin-top: 60px !important; }

.ml0 {
  margin-left: 0 !important; }

.ml5 {
  margin-left: 5px !important; }

.ml10 {
  margin-left: 10px !important; }

.ml15 {
  margin-left: 15px !important; }

.ml30 {
  margin-left: 30px !important; }

.ml45 {
  margin-left: 45px !important; }

.ml60 {
  margin-left: 60px !important; }

.mr0 {
  margin-right: 0 !important; }

.mr5 {
  margin-right: 5px !important; }

.mr10 {
  margin-right: 10px !important; }

.mr15 {
  margin-right: 15px !important; }

.mr30 {
  margin-right: 30px !important; }

@media (min-width: 576px) {
  .xs-mb0 {
    margin-bottom: 0 !important; }

  .xs-mb5 {
    margin-bottom: 5 !important; }

  .xs-mb10 {
    margin-bottom: 10px !important; }

  .xs-mb15 {
    margin-bottom: 15px !important; }

  .xs-mb30 {
    margin-bottom: 30px !important; }

  .xs-mb45 {
    margin-bottom: 45px !important; }

  .xs-mb60 {
    margin-bottom: 60px !important; } }
@media (min-width: 768px) {
  .sm-mb0 {
    margin-bottom: 0 !important; }

  .sm-mb5 {
    margin-bottom: 5 !important; }

  .sm-mb10 {
    margin-bottom: 10px !important; }

  .sm-mb15 {
    margin-bottom: 15px !important; }

  .sm-mb30 {
    margin-bottom: 30px !important; }

  .sm-mb45 {
    margin-bottom: 45px !important; }

  .sm-mb60 {
    margin-bottom: 60px !important; } }
@media (min-width: 992px) {
  .md-mb0 {
    margin-bottom: 0 !important; }

  .md-mb5 {
    margin-bottom: 5 !important; }

  .md-mb10 {
    margin-bottom: 10px !important; }

  .md-mb15 {
    margin-bottom: 15px !important; }

  .md-mb30 {
    margin-bottom: 30px !important; }

  .md-mb45 {
    margin-bottom: 45px !important; }

  .md-mb60 {
    margin-bottom: 60px !important; } }
@media (min-width: 1200px) {
  .lg-mb0 {
    margin-bottom: 0 !important; }

  .lg-mb5 {
    margin-bottom: 5 !important; }

  .lg-mb10 {
    margin-bottom: 10px !important; }

  .lg-mb15 {
    margin-bottom: 15px !important; }

  .lg-mb30 {
    margin-bottom: 30px !important; }

  .lg-mb45 {
    margin-bottom: 45px !important; }

  .lg-mb60 {
    margin-bottom: 60px !important; } }
@media (min-width: 576px) {
  .xs-ml0 {
    margin-left: 0 !important; }

  .xs-ml10 {
    margin-left: 10px !important; }

  .xs-ml15 {
    margin-left: 15px !important; }

  .xs-ml30 {
    margin-left: 30px !important; }

  .xs-ml45 {
    margin-left: 45px !important; }

  .xs-ml60 {
    margin-left: 60px !important; } }
@media (min-width: 768px) {
  .sm-ml0 {
    margin-left: 0 !important; }

  .sm-ml10 {
    margin-left: 10px !important; }

  .sm-ml15 {
    margin-left: 15px !important; }

  .sm-ml30 {
    margin-left: 30px !important; }

  .sm-ml45 {
    margin-left: 45px !important; }

  .sm-ml60 {
    margin-left: 60px !important; } }
@media (min-width: 992px) {
  .md-ml0 {
    margin-left: 0 !important; }

  .md-ml10 {
    margin-left: 10px !important; }

  .md-ml15 {
    margin-left: 15px !important; }

  .md-ml30 {
    margin-left: 30px !important; }

  .md-ml45 {
    margin-left: 45px !important; }

  .md-ml60 {
    margin-left: 60px !important; } }
@media (min-width: 1200px) {
  .lg-ml0 {
    margin-left: 0 !important; }

  .lg-ml10 {
    margin-left: 10px !important; }

  .lg-ml15 {
    margin-left: 15px !important; }

  .lg-ml30 {
    margin-left: 30px !important; }

  .lg-ml45 {
    margin-left: 45px !important; }

  .lg-ml60 {
    margin-left: 60px !important; } }
@media (min-width: 576px) {
  .xs-mt0 {
    margin-top: 0 !important; }

  .xs-mt10 {
    margin-top: 10px !important; }

  .xs-mt15 {
    margin-top: 15px !important; }

  .xs-mt30 {
    margin-top: 30px !important; }

  .xs-mt45 {
    margin-top: 45px !important; }

  .xs-mt60 {
    margin-top: 60px !important; } }
@media (min-width: 768px) {
  .sm-mt0 {
    margin-top: 0 !important; }

  .sm-mt10 {
    margin-top: 10px !important; }

  .sm-mt15 {
    margin-top: 15px !important; }

  .sm-mt30 {
    margin-top: 30px !important; }

  .sm-mt45 {
    margin-top: 45px !important; }

  .sm-mt60 {
    margin-top: 60px !important; } }
@media (min-width: 992px) {
  .md-mt0 {
    margin-top: 0 !important; }

  .md-mt10 {
    margin-top: 10px !important; }

  .md-mt15 {
    margin-top: 15px !important; }

  .md-mt30 {
    margin-top: 30px !important; }

  .md-mt45 {
    margin-top: 45px !important; }

  .md-mt60 {
    margin-top: 60px !important; } }
@media (min-width: 1200px) {
  .lg-mt0 {
    margin-top: 0 !important; }

  .lg-mt10 {
    margin-top: 10px !important; }

  .lg-mt15 {
    margin-top: 15px !important; }

  .lg-mt30 {
    margin-top: 30px !important; }

  .lg-mt45 {
    margin-top: 45px !important; }

  .lg-mt60 {
    margin-top: 60px !important; } }
/* Padding */
/*--------------------
PADDING
--------------------*/
.p0 {
  padding: 0 !important; }

.p10 {
  padding: 10px !important; }

.p15 {
  padding: 15px !important; }

.p30 {
  padding: 30px !important; }

.p45 {
  padding: 45px !important; }

.p60 {
  padding: 60px !important; }

.p75 {
  padding: 75px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt5 {
  padding-top: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt30 {
  padding-top: 30px !important; }

.pt40 {
  padding-top: 40px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl0 {
  padding-left: 0 !important; }

.pl5 {
  padding-left: 5px !important; }

.pl7-5 {
  padding-left: 7.5px !important; }

.pl10 {
  padding-left: 10px !important; }

.pl15 {
  padding-left: 15px !important; }

.pl30 {
  padding-left: 30px !important; }

.pl45 {
  padding-left: 45px !important; }

.pr0 {
  padding-right: 0 !important; }

.pr5 {
  padding-right: 5px !important; }

.pr10 {
  padding-right: 10px !important; }

.pr15 {
  padding-right: 15px !important; }

.pr30 {
  padding-right: 30px !important; }

@media (min-width: 576px) {
  .xs-p0 {
    padding: 0 !important; }

  .xs-p10 {
    padding: 10px !important; }

  .xs-p15 {
    padding: 15px !important; }

  .xs-p30 {
    padding: 30px !important; } }
@media (min-width: 768px) {
  .sm-p0 {
    padding: 0 !important; }

  .sm-p10 {
    padding: 10px !important; }

  .sm-p15 {
    padding: 15px !important; }

  .sm-p30 {
    padding: 30px !important; } }
@media (min-width: 992px) {
  .md-p0 {
    padding: 0 !important; }

  .md-p10 {
    padding: 10px !important; }

  .md-p15 {
    padding: 15px !important; }

  .md-p30 {
    padding: 30px !important; } }
@media (min-width: 1200px) {
  .lg-p0 {
    padding: 0 !important; }

  .lg-p10 {
    padding: 10px !important; }

  .lg-p15 {
    padding: 15px !important; }

  .lg-p30 {
    padding: 30px !important; } }
@media (min-width: 576px) {
  .xs-pb0 {
    padding-bottom: 0 !important; }

  .xs-pb5 {
    padding-bottom: 5px !important; }

  .xs-pb10 {
    padding-bottom: 10px !important; }

  .xs-pb15 {
    padding-bottom: 15px !important; }

  .xs-pb30 {
    padding-bottom: 30px !important; } }
@media (min-width: 768px) {
  .sm-pb0 {
    padding-bottom: 0 !important; }

  .sm-pb5 {
    padding-bottom: 5px !important; }

  .sm-pb10 {
    padding-bottom: 10px !important; }

  .sm-pb15 {
    padding-bottom: 15px !important; }

  .sm-pb30 {
    padding-bottom: 30px !important; } }
@media (min-width: 992px) {
  .md-pb0 {
    padding-bottom: 0 !important; }

  .md-pb5 {
    padding-bottom: 5px !important; }

  .md-pb10 {
    padding-bottom: 10px !important; }

  .md-pb15 {
    padding-bottom: 15px !important; }

  .md-pb30 {
    padding-bottom: 30px !important; } }
@media (min-width: 1200px) {
  .lg-pb0 {
    padding-bottom: 0 !important; }

  .lg-pb5 {
    padding-bottom: 5px !important; }

  .lg-pb10 {
    padding-bottom: 10px !important; }

  .lg-pb15 {
    padding-bottom: 15px !important; }

  .lg-pb30 {
    padding-bottom: 30px !important; } }
@media (min-width: 576px) {
  .xs-pt0 {
    padding-top: 0 !important; }

  .xs-pt5 {
    padding-top: 5px !important; }

  .xs-pt10 {
    padding-top: 10px !important; }

  .xs-pt15 {
    padding-top: 15px !important; }

  .xs-pt30 {
    padding-top: 30px !important; } }
@media (min-width: 768px) {
  .sm-pt0 {
    padding-top: 0 !important; }

  .sm-pt5 {
    padding-top: 5px !important; }

  .sm-pt10 {
    padding-top: 10px !important; }

  .sm-pt15 {
    padding-top: 15px !important; }

  .sm-pt30 {
    padding-top: 30px !important; } }
@media (min-width: 992px) {
  .md-pt0 {
    padding-top: 0 !important; }

  .md-pt5 {
    padding-top: 5px !important; }

  .md-pt10 {
    padding-top: 10px !important; }

  .md-pt15 {
    padding-top: 15px !important; }

  .md-pt30 {
    padding-top: 30px !important; } }
@media (min-width: 1200px) {
  .lg-pt0 {
    padding-top: 0 !important; }

  .lg-pt5 {
    padding-top: 5px !important; }

  .lg-pt10 {
    padding-top: 10px !important; }

  .lg-pt15 {
    padding-top: 15px !important; }

  .lg-pt30 {
    padding-top: 30px !important; } }
@media (min-width: 576px) {
  .xs-pl0 {
    padding-left: 0 !important; }

  .xs-pl5 {
    padding-left: 5px !important; }

  .xs-pl10 {
    padding-left: 10px !important; }

  .xs-pl15 {
    padding-left: 15px !important; }

  .xs-pl30 {
    padding-left: 30px !important; } }
@media (min-width: 768px) {
  .sm-pl0 {
    padding-left: 0 !important; }

  .sm-pl5 {
    padding-left: 5px !important; }

  .sm-pl10 {
    padding-left: 10px !important; }

  .sm-pl15 {
    padding-left: 15px !important; }

  .sm-pl30 {
    padding-left: 30px !important; } }
@media (min-width: 992px) {
  .md-pl0 {
    padding-left: 0 !important; }

  .md-pl5 {
    padding-left: 5px !important; }

  .md-pl10 {
    padding-left: 10px !important; }

  .md-pl15 {
    padding-left: 15px !important; }

  .md-pl30 {
    padding-left: 30px !important; } }
@media (min-width: 1200px) {
  .lg-pl0 {
    padding-left: 0 !important; }

  .lg-pl5 {
    padding-left: 5px !important; }

  .lg-pl10 {
    padding-left: 10px !important; }

  .lg-pl15 {
    padding-left: 15px !important; }

  .lg-pl30 {
    padding-left: 30px !important; } }
@media (min-width: 576px) {
  .xs-pr0 {
    padding-right: 0 !important; }

  .xs-pr5 {
    padding-right: 5px !important; }

  .xs-pr10 {
    padding-right: 10px !important; }

  .xs-pr15 {
    padding-right: 15px !important; }

  .xs-pr30 {
    padding-right: 30px !important; } }
@media (min-width: 768px) {
  .sm-pr0 {
    padding-right: 0 !important; }

  .sm-pr5 {
    padding-right: 5px !important; }

  .sm-pr10 {
    padding-right: 10px !important; }

  .sm-pr15 {
    padding-right: 15px !important; }

  .sm-pr30 {
    padding-right: 30px !important; } }
@media (min-width: 992px) {
  .md-pr0 {
    padding-right: 0 !important; }

  .md-pr5 {
    padding-right: 5px !important; }

  .md-pr10 {
    padding-right: 10px !important; }

  .md-pr15 {
    padding-right: 15px !important; }

  .md-pr30 {
    padding-right: 30px !important; } }
@media (min-width: 1200px) {
  .lg-pr0 {
    padding-right: 0 !important; }

  .lg-pr5 {
    padding-right: 5px !important; }

  .lg-pr10 {
    padding-right: 10px !important; }

  .lg-pr15 {
    padding-right: 15px !important; }

  .lg-pr30 {
    padding-right: 30px !important; } }
/* Size */
.u-size-expand {
  width: 100%; }

.u-size-auto {
  width: auto; }

@media (min-width: 576px) {
  .u-size-xs-expand {
    width: 100%; }

  .u-size-xs-auto {
    width: auto; } }
@media (min-width: 768px) {
  .u-size-sm-expand {
    width: 100%; }

  .u-size-sm-auto {
    width: auto; } }
@media (min-width: 992px) {
  .u-size-md-expand {
    width: 100%; }

  .u-size-md-auto {
    width: auto; } }
@media (min-width: 1200px) {
  .u-size-lg-expand {
    width: 100%; }

  .u-size-lg-auto {
    width: auto; } }
/* Text */
/*--------------------
TEXT
--------------------*/
.u-droid  {
  font-family: 'DroidSerif', sans-serif; }

.txt--bold {
  font-weight: bold !important; }

.txt--s {
  text-decoration: line-through; }

/*--------------------
TEXT ALIGN
--------------------*/
.txt--right {
  text-align: right !important; }

.txt--left {
  text-align: left !important; }

.txt--center {
  text-align: center !important; }

@media (min-width: 576px) {
  .xs-txt--right {
    text-align: right !important; }

  .xs-txt--left {
    text-align: left !important; }

  .xs-txt--center {
    text-align: center !important; } }
@media (min-width: 768px) {
  .sm-txt--right {
    text-align: right !important; }

  .sm-txt--left {
    text-align: left !important; }

  .sm-txt--center {
    text-align: center !important; } }
@media (min-width: 992px) {
  .md-txt--right {
    text-align: right !important; }

  .md-txt--left {
    text-align: left !important; }

  .md-txt--center {
    text-align: center !important; } }
@media (min-width: 1200px) {
  .lg-txt--right {
    text-align: right !important; }

  .lg-txt--left {
    text-align: left !important; }

  .lg-txt--center {
    text-align: center !important; } }
.txt--uppercase {
  display: inline-block;
  text-transform: uppercase; }

.txt--uppercase-letter {
  display: inline-block; }
  .txt--uppercase-letter::first-letter {
    text-transform: uppercase; }

.txt-size--16 {
  font-size: 16px !important; }

/*--------------------
PAGE
--------------------*/
.page--frontTemplate {
  background-color: #fff; }
  .page--frontTemplate.page--frontTemplate-about {
    padding-bottom: 90px; }
  .page--frontTemplate.page--frontTemplate-press {
    padding-bottom: 90px;
    background-color: #efefef; }

.page--frontTemplate__img {
  display: block;
  width: 100%;
  max-width: 380px;
  margin: 0 auto; }
  .page--frontTemplate__img.page--frontTemplate__img--size-l {
    max-width: 550px; }
  .page--frontTemplate__img.page--frontTemplate__img--paddingR {
    padding-right: 0; }
    @media (min-width: 992px) {
      .page--frontTemplate__img.page--frontTemplate__img--paddingR {
        padding-right: 60px; } }
  .page--frontTemplate__img.page--frontTemplate__img--paddingL {
    padding-left: 0; }
    @media (min-width: 992px) {
      .page--frontTemplate__img.page--frontTemplate__img--paddingL {
        padding-left: 60px; } }
  .page--frontTemplate__img.page--frontTemplate__img--press {
    position: relative;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%; }

.page--frontTemplate__subtitle {
  font-size: 18px;
  line-height: 1.5; }

.page--frontTemplate__text {
  line-height: 1.4;
  font-size: 18px;
  text-align: center; }
  @media (min-width: 576px) {
    .page--frontTemplate__text {
      text-align: left; } }

.page--frontTemplate__hr {
  height: 1px;
  border: none;
  width: 100%;
  background-color: #ddd;
  margin: 60px 0; }

.page--frontTemplate__list {
  padding-bottom: 45px; }
  .page--frontTemplate__list li {
    display: block;
    text-align: center;
    margin: 15px 0 15px 0; }
    @media (min-width: 576px) {
      .page--frontTemplate__list li {
        display: inline-block;
        margin: 15px 45px 15px 0;
        text-align: left; } }
    .page--frontTemplate__list li .page--frontTemplate__icon {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
      width: auto;
      height: 18px; }
    .page--frontTemplate__list li .page--frontTemplate__listText {
      display: inline-block;
      vertical-align: middle;
      font-weight: bold; }

.page--frontTemplate__jobContainer {
  padding: 30px 0;
  background-color: #efefef; }
  @media (min-width: 768px) {
    .page--frontTemplate__jobContainer {
      padding: 60px; } }

.page--frontTemplate__name {
  font-family: 'DroidSerif', sans-serif;
  color: #f05d61;
  font-weight: bold;
  font-size: 16px; }

.page--menuTop {
  padding-top: 50px; }

.cf_core_box_composition, .cf_core_box_composition_v2, .cf_core_box_composition_v2_test, .cf_core_box_composition_v2_1, .cf_core_box_composition_v2_1_test {
  padding-top: 0;
  background-color: #f9f9f9; }
  .cf_core_box_composition .menu_admin, .cf_core_box_composition_v2 .menu_admin, .cf_core_box_composition_v2_test .menu_admin, .cf_core_box_composition_v2_1 .menu_admin, .cf_core_box_composition_v2_1_test .menu_admin {
    display: none; }
  .cf_core_box_composition .p-fixed--link, .cf_core_box_composition_v2 .p-fixed--link, .cf_core_box_composition_v2_test .p-fixed--link, .cf_core_box_composition_v2_1 .p-fixed--link, .cf_core_box_composition_v2_1_test .p-fixed--link {
    position: fixed;
    top: 0;
    left: 0;
    width: 380px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    z-index: 999; }
  .cf_core_box_composition .c-filters-colors-wrapper, .cf_core_box_composition_v2 .c-filters-colors-wrapper, .cf_core_box_composition_v2_test .c-filters-colors-wrapper, .cf_core_box_composition_v2_1 .c-filters-colors-wrapper, .cf_core_box_composition_v2_1_test .c-filters-colors-wrapper {
    position: relative;
    width: 120px;
    top: -5px; }
  .cf_core_box_composition .c-filter-colors--select, .cf_core_box_composition_v2 .c-filter-colors--select, .cf_core_box_composition_v2_test .c-filter-colors--select, .cf_core_box_composition_v2_1 .c-filter-colors--select, .cf_core_box_composition_v2_1_test .c-filter-colors--select {
    position: absolute;
    left: 0; }
  .cf_core_box_composition .p-btn, .cf_core_box_composition_v2 .p-btn, .cf_core_box_composition_v2_test .p-btn, .cf_core_box_composition_v2_1 .p-btn, .cf_core_box_composition_v2_1_test .p-btn {
    margin-bottom: 2px; }
  .cf_core_box_composition #productSearch, .cf_core_box_composition_v2 #productSearch, .cf_core_box_composition_v2_test #productSearch, .cf_core_box_composition_v2_1 #productSearch, .cf_core_box_composition_v2_1_test #productSearch {
    width: 300px;
    padding: 5px 5px;
    height: 25px;
    margin-top: 5px;
    display: inline-block; }
  .cf_core_box_composition #emptyProductSearch, .cf_core_box_composition_v2 #emptyProductSearch, .cf_core_box_composition_v2_test #emptyProductSearch, .cf_core_box_composition_v2_1 #emptyProductSearch, .cf_core_box_composition_v2_1_test #emptyProductSearch {
    position: absolute;
    left: 290px;
    top: 86px;
    height: 25px;
    line-height: 25px;
    width: 25px;
    padding: 0 7px;
    cursor: pointer; }
  .cf_core_box_composition .p-panel--left, .cf_core_box_composition_v2 .p-panel--left, .cf_core_box_composition_v2_test .p-panel--left, .cf_core_box_composition_v2_1 .p-panel--left, .cf_core_box_composition_v2_1_test .p-panel--left {
    width: 380px;
    overflow-y: auto;
    height: calc(100% - 95px);
    vertical-align: top;
    position: fixed;
    top: 110px;
    padding: 35px 15px 300px 15px;
    z-index: 12;
    background-color: #fff; }
    .cf_core_box_composition .p-panel--left .is--influencer, .cf_core_box_composition_v2 .p-panel--left .is--influencer, .cf_core_box_composition_v2_test .p-panel--left .is--influencer, .cf_core_box_composition_v2_1 .p-panel--left .is--influencer, .cf_core_box_composition_v2_1_test .p-panel--left .is--influencer {
      border-radius: 4px;
      background-color: #f05d61;
      color: #fff;
      font-size: 12px;
      padding: 4px;
      text-align: center;
      margin-bottom: 10px; }
  .cf_core_box_composition .c-panel--product-infos, .cf_core_box_composition_v2 .c-panel--product-infos, .cf_core_box_composition_v2_test .c-panel--product-infos, .cf_core_box_composition_v2_1 .c-panel--product-infos, .cf_core_box_composition_v2_1_test .c-panel--product-infos {
    left: -380px;
    -webkit-transition: all ease 0.1s;
    -moz-transition: all ease 0.1s;
    -ms-transition: all ease 0.1s;
    -o-transition: all ease 0.1s;
    transition: all ease 0.1s; }
    .cf_core_box_composition .c-panel--product-infos table td, .cf_core_box_composition .c-panel--product-infos table th, .cf_core_box_composition_v2 .c-panel--product-infos table td, .cf_core_box_composition_v2 .c-panel--product-infos table th, .cf_core_box_composition_v2_test .c-panel--product-infos table td, .cf_core_box_composition_v2_test .c-panel--product-infos table th, .cf_core_box_composition_v2_1 .c-panel--product-infos table td, .cf_core_box_composition_v2_1 .c-panel--product-infos table th, .cf_core_box_composition_v2_1_test .c-panel--product-infos table td, .cf_core_box_composition_v2_1_test .c-panel--product-infos table th {
      padding: 5px; }
  .cf_core_box_composition .c-panel--product-infos.open, .cf_core_box_composition_v2 .c-panel--product-infos.open, .cf_core_box_composition_v2_test .c-panel--product-infos.open, .cf_core_box_composition_v2_1 .c-panel--product-infos.open, .cf_core_box_composition_v2_1_test .c-panel--product-infos.open {
    left: 0; }
  .cf_core_box_composition .p-panel--right, .cf_core_box_composition_v2 .p-panel--right, .cf_core_box_composition_v2_test .p-panel--right, .cf_core_box_composition_v2_1 .p-panel--right, .cf_core_box_composition_v2_1_test .p-panel--right {
    padding-left: 380px;
    width: 100%;
    vertical-align: top;
    position: relative;
    padding-top: 64px;
    padding-bottom: 110px; }
  .cf_core_box_composition #mot-styliste, .cf_core_box_composition_v2 #mot-styliste, .cf_core_box_composition_v2_test #mot-styliste, .cf_core_box_composition_v2_1 #mot-styliste, .cf_core_box_composition_v2_1_test #mot-styliste {
    visibility: hidden;
    display: none;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 780px;
    border-radius: 4px;
    z-index: 10000;
    position: fixed;
    padding: 15px 40px;
    box-shadow: 0 1px 20px #717171;
    background: #F9F9F9;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%); }
  .cf_core_box_composition .p-count-line, .cf_core_box_composition_v2 .p-count-line, .cf_core_box_composition_v2_test .p-count-line, .cf_core_box_composition_v2_1 .p-count-line, .cf_core_box_composition_v2_1_test .p-count-line {
    font-size: 14px;
    color: #666;
    padding: 0 0 10px; }
  .cf_core_box_composition #mot-styliste #js-textarea-message, .cf_core_box_composition_v2 #mot-styliste #js-textarea-message, .cf_core_box_composition_v2_test #mot-styliste #js-textarea-message, .cf_core_box_composition_v2_1 #mot-styliste #js-textarea-message, .cf_core_box_composition_v2_1_test #mot-styliste #js-textarea-message {
    border: 1px solid #ddd;
    padding: 5px;
    background: #FFF;
    margin: 0 auto;
    width: 700px;
    height: 440px;
    resize: none;
    font-size: 16px;
    text-align: center;
    overflow: hidden; }
  .cf_core_box_composition #mot-styliste .gpt-loader, .cf_core_box_composition_v2 #mot-styliste .gpt-loader, .cf_core_box_composition_v2_test #mot-styliste .gpt-loader, .cf_core_box_composition_v2_1 #mot-styliste .gpt-loader, .cf_core_box_composition_v2_1_test #mot-styliste .gpt-loader {
    position: absolute;
    top: 40%;
    left: 40%;
    font-size: 40px;
    display: none; }
  .cf_core_box_composition #mot-styliste .texte-valide-box, .cf_core_box_composition_v2 #mot-styliste .texte-valide-box, .cf_core_box_composition_v2_test #mot-styliste .texte-valide-box, .cf_core_box_composition_v2_1 #mot-styliste .texte-valide-box, .cf_core_box_composition_v2_1_test #mot-styliste .texte-valide-box {
    padding: 10px 20px;
    background: #F0415B;
    color: #fff;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer; }
  .cf_core_box_composition #overlay, .cf_core_box_composition_v2 #overlay, .cf_core_box_composition_v2_test #overlay, .cf_core_box_composition_v2_1 #overlay, .cf_core_box_composition_v2_1_test #overlay {
    visibility: hidden;
    display: none;
    position: fixed;
    background: #000;
    opacity: 0.4;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9999; }
  .cf_core_box_composition #overlay.open,
  .cf_core_box_composition #mot-styliste.open, .cf_core_box_composition_v2 #overlay.open,
  .cf_core_box_composition_v2 #mot-styliste.open, .cf_core_box_composition_v2_test #overlay.open,
  .cf_core_box_composition_v2_test #mot-styliste.open, .cf_core_box_composition_v2_1 #overlay.open,
  .cf_core_box_composition_v2_1 #mot-styliste.open, .cf_core_box_composition_v2_1_test #overlay.open,
  .cf_core_box_composition_v2_1_test #mot-styliste.open {
    visibility: visible;
    display: block; }

.p-img--success {
  display: block;
  width: 100%;
  max-width: 60px;
  margin: 0 auto 30px; }
  @media (min-width: 768px) {
    .p-img--success {
      margin: 0px auto 30px; } }

.page--order .page--order__block-illustration {
  text-align: center; }
  .page--order .page--order__block-illustration img {
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -27px; }
.page--order .page--order__container-adresse {
  position: relative; }
.page--order .p-billingAddress {
  display: none;
  visibility: hidden; }
  .page--order .p-billingAddress.p-billingAddress--open {
    display: block;
    visibility: visible; }
.page--order .page--order__check {
  color: #28C76F;
  margin-right: 10px; }
.page--order .page--order__stylismFees {
  font-size: 14px;
  padding-left: 26px; }
.page--order .page--order__cb {
  position: relative;
  top: 0;
  display: block;
  vertical-align: middle;
  margin-top: 10px;
  margin-left: 0; }
  @media (min-width: 576px) {
    .page--order .page--order__cb {
      top: -2px;
      display: inline-block;
      margin-left: 10px;
      margin-top: 0; } }
.page--order .page--order__cb-img {
  display: inline-block;
  max-width: 40px;
  vertical-align: middle; }
.page--order .last-step-collapsible-block-wrapper {
  padding: 10px;
  border: 1px solid #e5e5e5; }
  .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block-title {
    text-align: left;
    font-size: 32px;
    font-family: "DomaineDisp-Regular", sans-serif;
    margin-bottom: 10px;
    padding-left: 15px;
    cursor: pointer; }
  .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block.last-step-collapsible-block-collapsed {
    display: none; }
  .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper {
    box-sizing: border-box; }
    .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper:first-child {
      padding-right: 5px; }
    .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper:nth-child(2) {
      padding-right: 5px; }
    .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .fa-leaf {
      color: #8cc646; }
    .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .c-delivery-mode-toggle-parent {
      width: 50%; }
    .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .c-delivery-mode-toggle {
      width: 100%; }
  .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-btn--red {
    font-family: "sofia-pro", sans-serif;
    font-weight: 700;
    font-size: 16px;
    min-width: 270px;
    width: auto;
    border: none; }
    @media (max-width: 480px) {
      .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-btn--red {
        width: 100%; } }
  .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-home-panel {
    margin-top: 10px; }
    .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-home-panel #address-recap-block {
      border: 1px solid #e5e5e5;
      line-height: 40px;
      padding-left: 10px;
      padding-right: 75px;
      position: relative;
      text-align: left; }
      .page--order .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-home-panel #address-recap-block #modify-address-button {
        position: absolute;
        right: 10px;
        top: 0;
        color: #939192;
        font-size: 12px;
        cursor: pointer; }
.page--order .c-btn--red {
  font-family: "sofia-pro", sans-serif;
  font-weight: 700;
  font-size: 16px;
  min-width: 270px;
  width: auto;
  border: none; }
  @media (max-width: 480px) {
    .page--order .c-btn--red {
      width: 100%; } }

.page--order-first {
  background-color: #fff;
  border-bottom: 1px solid #ddd; }

.page--order-return {
  background-color: #fff; }

.page--adresse .p-billingAddress {
  display: none;
  visibility: hidden; }
  .page--adresse .p-billingAddress.p-billingAddress--open {
    display: block;
    visibility: visible; }

.page--profil .c-form__label {
  font-family: 'DroidSerif', sans-serif; }
.page--profil .p-marginBottom:last-child {
  margin-bottom: 0 !important; }
.page--profil .p-marginTop.p-marginTop--all {
  margin-top: 30px !important; }
.page--profil .p-marginTop:first-child {
  margin-top: 30px !important; }
.page--profil .p-marginTop.p-marginTop--s:first-child {
  margin-top: 15px !important; }
.page--profil .p-marginTop.p-marginTop--s.p-marginTop--all {
  margin-top: 15px !important; }
.page--profil .p-imageStyle {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
  position: relative;
  width: 100%;
  left: 0;
  padding: 15px;
  background-color: #fff; }
  .page--profil .p-imageStyle img {
    display: block;
    width: 100%;
    height: auto; }
  @media (min-width: 768px) {
    .page--profil .p-imageStyle {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.08), 0 1px 12px rgba(28, 44, 79, 0.1);
      width: calc(100% + 100px);
      left: -50px; } }
  @media (min-width: 992px) {
    .page--profil .p-imageStyle {
      width: calc(100% + 120px);
      left: -60px; } }

.c-filterApi {
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  display: none; }
  .c-filterApi.show {
    opacity: 1;
    visibility: visible;
    display: block; }

.c-filterApi__container {
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
  background-color: #fff;
  padding: 30px; }

.cf_core_product_index {
  background-color: #efefef; }

.p-info-product {
  color: #666; }
  .p-info-product .container-right {
    padding: 30px 30px 30px 250px !important;
    background-color: #efefef; }

.p-info-product__info {
  font-style: 14px;
  color: #666;
  margin-bottom: 15px; }
  .p-info-product__info:first-child {
    padding-right: 45px; }

.p-info-product__state {
  margin: 0 30px 5px 0;
  white-space: nowrap; }

.p-info-product__circle {
  display: inline-block;
  min-width: 14px;
  height: 14px;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  border: 1px solid #fff; }
  .p-info-product__circle.green {
    background: #4caf50; }
  .p-info-product__circle.yellow {
    background: #ffeb3b; }
  .p-info-product__circle.red {
    background: #f44336; }
  .p-info-product__circle.pink {
    background: #e91e63; }
  .p-info-product__circle.blue {
    background: #2196f3; }
  .p-info-product__circle.dark {
    background: #000; }
  .p-info-product__circle.gray {
    background: #757575; }
  .p-info-product__circle.beige {
    background: #b7a38d; }
  .p-info-product__circle.bordeaux {
    background: #6D071A; }
  .p-info-product__circle.purple {
    background: #7f00ff; }

.p-info-product__state-name {
  display: inline-block;
  padding-left: 5px;
  vertical-align: middle; }

.flag-icon {
  margin-right: 5px; }

.p-info-product__flag {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 15px;
  margin-right: 5px; }

.p-info-product__price-info {
  position: absolute;
  bottom: -12px;
  left: 10px; }

.p-info-product__reduction {
  display: inline-block;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #ddd;
  font-size: 12px; }
  .p-info-product__reduction.p-info-product__reduction--green {
    background-color: #28C76F;
    color: #fff; }

.p-info-product__relatif {
  position: relative; }
  .p-info-product__relatif:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    background: transparent;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, transparent, #fff);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, transparent, #fff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.p-info-product__container-img {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 30px; }

.p-info-product__img {
  overflow: hidden;
  border-radius: 4px;
  display: inline-block;
  margin-right: 15px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1); }
  .p-info-product__img img {
    display: block;
    height: 180px;
    width: auto; }

.cf_core_product_edit_attributes {
  background-color: #efefef; }

.p-product--attributes {
  background-color: #efefef;
  color: #666;
  padding-bottom: 400px !important; }
  .p-product--attributes .container-right {
    padding: 15px 15px 15px 235px !important;
    background-color: #efefef; }
  .p-product--attributes .p-product--attributes__tag {
    display: block;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px; }
  .p-product--attributes .p-product--attributes__img {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto 10px; }
  .p-product--attributes .p-product--attributes__btn {
    font-size: 10px;
    color: #b6b6b6;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .p-product--attributes .p-product--attributes__child {
    margin-left: 90px; }
  .p-product--attributes .p-product--block--24 .p-product--attributes__img, .p-product--attributes .p-product--block--77 .p-product--attributes__img {
    position: relative;
    top: -2px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-bottom: 0;
    vertical-align: middle; }
  .p-product--attributes .p-product--block {
    display: none;
    visibility: hidden; }
  .p-product--attributes .p-product--block--visible {
    display: block;
    visibility: visible; }

.pagination {
  margin: 0 !important; }
  .pagination span {
    border-radius: 4px;
    display: inline-block;
    border: 1px solid #ddd;
    height: 30px;
    width: 30px;
    margin-right: 5px;
    text-align: center;
    line-height: 28px; }
  .pagination a {
    color: #000000; }
    .pagination a:hover {
      text-decoration: underline;
      color: #f05d61; }
  .pagination .current {
    color: #f05d61; }

/*--------------------
SHAME
--------------------*/
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body.frontpage {
  font-family: 'sofia-pro', sans-serif;
  background-color: #fff;
  color: #666; }
  body.frontpage .u-droid,
  body.frontpage .c-title {
    font-family: 'sofia-pro', sans-serif; }

body.frontpage.webfont-loaded {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  line-height: normal; }
  body.frontpage.webfont-loaded .u-droid,
  body.frontpage.webfont-loaded .c-title {
    font-family: 'DroidSerif', sans-serif; }
