@charset "UTF-8";
/*
-----------------
Variable
-----------------
*/
/*
-----------------
-----------------
CLASS
-----------------
-----------------
*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.clear:after, #formulaire-style .question:after, .homepage .block_2 .ul:after, .homepage2 .block_2 .ul:after, .homepage2 .block_2 .block_step:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
  padding: 0;
  margin: 0; }

.vam:after {
  display: inline-block;
  vertical-align: middle;
  content: "";
  height: 100%;
  width: 0;
  line-height: 1;
  font-size: 0px;
  margin: 0;
  padding: 0; }

.transition, #navigation ul li a, #navigation-menu, .form-1 .row-form-design .label-form-1, .link-text, .link-text-grey, .question-general .question-link .input, #same-address [type="checkbox"]:not(:checked) + label:before,
#same-address [type="checkbox"]:checked + label:before,
#same-address [type="checkbox"]:not(:checked) + label:after,
#same-address [type="checkbox"]:checked + label:after, #footer .link ul li a, #formulaire-style [type="radio"]:not(:checked) + label:after,
#formulaire-style [type="radio"]:checked + label:after,
#formulaire-style [type="checkbox"]:not(:checked) + label:after,
#formulaire-style [type="checkbox"]:checked + label:after {
  transition: All 0.5s ease;
  -webkit-transition: All 0.5s ease;
  -moz-transition: All 0.5s ease;
  -o-transition: All 0.5s ease; }

.alignVerticalAbsolute, #footer .link ul li:not(:last-child):after, #formulaire-style .question .select-content:after {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%); }

.alignHorizonAbsolute {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%); }

.no-margin {
  margin: 0 !important; }

/*
-----------------
-----------------
MIXIN
-----------------
-----------------
*/
/*
-----------------
Font Always Forever Regular
-----------------
*/
@font-face {
  font-family: 'alwaysforeverregular';
  src: url("/public/font/always_forever-webfont.eot");
  src: url("/public/font/always_forever-webfont.eot?#iefix") format("embedded-opentype"), url("/public/font/always_forever-webfont.woff2") format("woff2"), url("/public/font/always_forever-webfont.woff") format("woff"), url("/public/font/always_forever-webfont.ttf") format("truetype"), url("/public/font/always_forever-webfont.svg#alwaysforeverregular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'DroidSerif';
  src: url("/public/font/DroidSerif.eot?#iefix") format("embedded-opentype"), url("/public/font/DroidSerif.woff") format("woff"), url("/public/font/DroidSerif.ttf") format("truetype"), url("/public/font/DroidSerif.svg#DroidSerif") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'LibreBaskerville-Regular';
  src: url("/public/font/LibreBaskerville-Regular.eot?#iefix") format("embedded-opentype"), url("/public/font/LibreBaskerville-Regular.otf") format("opentype"), url("/public/font/LibreBaskerville-Regular.woff") format("woff"), url("/public/font/LibreBaskerville-Regular.ttf") format("truetype"), url("/public/font/LibreBaskerville-Regular.svg#LibreBaskerville-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'DomaineDisp-Regular';
  src: url("/public/font/DomaineDisp-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'DomaineDisp-Semibold';
  src: url("/public/font/DomaineDisp-Semibold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Montserrat-Regular';
  src: url("/public/font/Montserrat-Regular.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url("/public/font/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Montserrat-Bold';
  src: url("/public/font/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Montserrat-LightItalic';
  src: url("/public/font/Montserrat-LightItalic.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'BrandonText-Regular';
  src: url("/public/font/HVD Fonts - BrandonText-Regular.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }
@font-face {
  font-family: 'Roustel';
  src: url("/public/font/roustel-webfont.woff") format("woff"), url("/public/font/roustel-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }
/*
-----------------
Navigation
-----------------
*/
#navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 90px;
  background: #fcfcfc;
  border-bottom: 1px solid #ddd;
  color: #6D737F; }
  #navigation .line {
    height: 45px; }
  #navigation .line-top {
    background: #b2dfdb;
    border-bottom: 1px solid #8ed0ca; }
    #navigation .line-top .logo a {
      display: block;
      height: 45px; }
      @media (max-width: 768px) {
        #navigation .line-top .logo a {
          display: inline-block; } }
    #navigation .line-top .logo img {
      width: 100%;
      height: auto;
      width: 120px;
      margin-top: 9px; }
    @media (max-width: 768px) {
      #navigation .line-top .logo {
        float: none;
        margin: 0 auto;
        text-align: center; } }
    #navigation .line-top .assurance {
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      margin-left: 15px;
      line-height: 45px; }
      @media (max-width: 768px) {
        #navigation .line-top .assurance {
          display: none; } }
      #navigation .line-top .assurance i {
        margin-right: 2px; }
  @media (max-width: 768px) {
    #navigation .line-bottom {
      display: none; } }
  #navigation ul {
    list-style-type: none;
    white-space: nowrap;
    height: 45px; }
    #navigation ul li {
      display: inline-block;
      position: relative;
      vertical-align: middle; }
      #navigation ul li.name {
        display: inline-block; }
      #navigation ul li a {
        display: block;
        font-size: 16px;
        position: relative;
        padding: 0 10px;
        color: inherit; }
        #navigation ul li a.logout {
          margin-left: 5px;
          border-left: 1px solid #ddd;
          padding-left: 15px; }
        #navigation ul li a:hover {
          color: #000; }
  #navigation .line-mobile {
    display: none;
    text-align: center; }
    #navigation .line-mobile ul {
      line-height: 45px; }
      #navigation .line-mobile ul li {
        padding: 0;
        height: 45px;
        display: block;
        float: left; }
    #navigation .line-mobile .border {
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd; }
    @media (max-width: 768px) {
      #navigation .line-mobile {
        display: block; } }

#navigation-menu {
  position: fixed;
  z-index: 19;
  padding-top: 90px;
  -ms-overflow-y: auto;
  overflow-y: auto;
  background: #fcfcfc;
  right: -240px;
  top: 0;
  width: 240px;
  height: 100%;
  border-left: 1px solid #eee;
  opacity: 0;
  visibility: hidden; }
  #navigation-menu.open {
    right: 0;
    opacity: 1;
    visibility: visible; }
  #navigation-menu ul {
    list-style-type: none; }
    #navigation-menu ul li {
      height: 50px;
      line-height: 50px; }
      #navigation-menu ul li a {
        display: block;
        height: 100%;
        font-size: 16px;
        color: #6D737F;
        text-align: left;
        border-bottom: 1px solid #eee;
        padding-left: 20px; }
        #navigation-menu ul li a:hover {
          background: #000;
          color: #fff; }

#navigation.navigation-extended {
  height: 125px; }

.header-message {
  width: 100%;
  padding: 5px 0px; }

/*
-----------------
Formulaire type - 1
-----------------
*/
.form-1 .title-form-1 {
  font-size: 26px;
  text-align: left; }
  @media (max-width: 768px) {
    .form-1 .title-form-1 {
      font-size: 20px; } }
.form-1 .row-form-design {
  position: relative; }
  .form-1 .row-form-design.error-field-open .error-field {
    display: block;
    font-size: 14px; }
  .form-1 .row-form-design .facultatif {
    font-size: 14px;
    font-style: italic; }
  .form-1 .row-form-design .textarea-form-1 {
    display: block;
    width: 100%; }
  .form-1 .row-form-design .input-form-1 {
    display: inline-block;
    width: 70%; }
  .form-1 .row-form-design .label-form-1 {
    display: inline-block;
    width: 29%;
    font-size: 16px;
    padding-bottom: 4px;
    font-family: "Montserrat-Regular", sans-serif; }
  .form-1 .row-form-design .input-form-1 {
    height: 40px;
    padding: 0 5px;
    text-align: left;
    background: #F8F8F8;
    border: 1px solid #ddd; }
  .form-1 .row-form-design .textarea-form-1 {
    resize: none;
    padding: 10px;
    background: #F8F8F8;
    border: 1px solid #ddd;
    height: 120px; }
  .form-1 .row-form-design .error-field {
    display: none;
    color: #f0a918; }
  .form-1 .row-form-design .slide_relative_content {
    position: relative;
    display: block; }
.form-1 .btn {
  margin-left: 20px;
  margin-top: 20px; }
.form-1 .loader {
  color: #F0415B;
  font-size: 22px;
  display: block;
  opacity: 0;
  position: absolute;
  top: 10px;
  left: 120px; }
  .form-1 .loader.open {
    opacity: 1; }
.form-1 .text {
  padding: 20px 0 0;
  position: relative; }
  .form-1 .text a {
    display: inline-block; }
  .form-1 .text.success .icon {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: #77CC7D;
    visibility: visible;
    display: inline-block;
    margin-right: 10px;
    position: relative; }
    .form-1 .text.success .icon .fa {
      color: #fff;
      font-size: 16px;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute; }
  .form-1 .text .icon {
    display: none;
    visibility: hidden; }
.form-1 input:required,
.form-1 input:invalid {
  box-shadow: 0 0 0 50px white inset !important;
  -webkit-box-shadow: 0 0 0 50px white inset !important; }
.form-1 input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset !important;
  -webkit-box-shadow: 0 0 0 50px white inset !important; }

.show-password {
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #555;
  right: 20px;
  top: 20px;
  text-align: center;
  cursor: pointer; }
  .show-password.open .fa-eye {
    opacity: 0;
    margin-top: -10px; }
  .show-password.open .fa-eye-slash {
    opacity: 1;
    margin-top: 0; }
  .show-password .fa {
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute; }
  .show-password .fa-eye {
    opacity: 1;
    margin-top: 0; }
  .show-password .fa-eye-slash {
    opacity: 0;
    margin-top: 10px; }

.twitter-background {
  background: #00aced; }

.facebook-background {
  background: #3b5998; }

.pinterest-background {
  background: #cb2027; }

.instagram-background {
  background: #517fa4; }

.email-background {
  background: #776F6B; }

.copier-background {
  background: #68AFAA; }

/*
-----------------
Accessibility
-----------------
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*
-----------------
Center bloc
-----------------
*/
/*
-----------------
Row
-----------------
// */
/*
-----------------
Hide
-----------------
*/
@media (max-width: 992px) {
  .hidden-md {
    display: none;
    visibility: hidden; } }
@media (max-width: 768px) {
  .hidden-sm {
    display: none;
    visibility: hidden; } }
.inline {
  display: inline-block !important; }

/*
-----------------
Width
-----------------
*/
.w-40 {
  max-width: 40px;
  width: 100%; }

.w-80 {
  max-width: 80px;
  width: 100%; }

.w-100 {
  max-width: 100px;
  width: 100%; }

.w-120 {
  max-width: 120px;
  width: 100%; }

.w-140 {
  max-width: 140px;
  width: 100%; }

.w-160 {
  max-width: 160px;
  width: 100%; }

.w-180 {
  max-width: 180px;
  width: 100%; }

.w-200 {
  max-width: 200px;
  width: 100%; }

.w-220 {
  max-width: 220px;
  width: 100%; }

.w-240 {
  max-width: 240px;
  width: 100%; }

.w-260 {
  max-width: 260px;
  width: 100%; }

.w-300 {
  max-width: 300px;
  width: 100%; }

.w-360 {
  max-width: 360px;
  width: 100%; }

.w-20p {
  width: 20%; }

.w-25p {
  width: 25%; }

.w-33p {
  width: 33.3333333333%; }

.w-40p {
  width: 40%; }

.w-50p {
  width: 50%; }

.w-100p {
  width: 100%;
  display: block; }

@media (max-width: 768px) {
  .w-33p-sm {
    width: 33.3333333333%; }

  .w-50p-sm {
    width: 50%; }

  .w-100p-sm {
    width: 100%;
    display: block; } }
.h-40 {
  min-height: 40px;
  width: 100%; }

.h-80 {
  min-height: 80px;
  width: 100%; }

.h-100 {
  min-height: 100px;
  width: 100%; }

.h-120 {
  min-height: 120px;
  width: 100%; }

.h-140 {
  min-height: 140px;
  width: 100%; }

.h-160 {
  min-height: 160px;
  width: 100%; }

.h-180 {
  min-height: 180px;
  width: 100%; }

.h-200 {
  min-height: 200px;
  width: 100%; }

.h-220 {
  min-height: 220px;
  width: 100%; }

.h-240 {
  min-height: 240px;
  width: 100%; }

.h-260 {
  min-height: 260px;
  width: 100%; }

.h-300 {
  min-height: 300px;
  width: 100%; }

.h-360 {
  min-height: 360px;
  width: 100%; }

/*
-----------------
Columns
-----------------
*/
/*
-----------------
Columns width
-----------------
*/
/*
-----------------
Text align
-----------------
*/
.text-align-left {
  text-align: left; }

.text-align-center {
  text-align: center; }

.text-align-right {
  text-align: right; }

.fl-left {
  float: left; }

.fl-right {
  float: right; }

/*
-----------------
Button and Link
-----------------
*/
.link-text {
  color: #FF5859;
  text-decoration: underline; }
  .link-text:hover {
    color: #be0001; }
  .link-text-grey {
    color: #828ca4;
    font-size: 14px;
    text-decoration: underline; }
  .link-text-grey:hover {
    color: #3b4252; }

.button-link-text {
  cursor: pointer;
  border: 0;
  padding: 0;
  background: #fff;
  color: #00aced;
  margin-bottom: 10px; }

/*
-----------------
Separator
-----------------
*/
[class*='hr-'] {
  width: 100%;
  height: 0;
  margin: 0 auto;
  background: none;
  border: none; }

.hr-dot {
  border-top: 1px dotted #ddd; }

.hr-line {
  border-top: 1px solid  #ddd; }

.hr-square {
  width: 12px;
  height: 12px;
  border: 1px solid #828CA4;
  margin: 0 auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg); }

/*
-----------------
Header categorie
-----------------
*/
.header-categorie {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(50, 50, 50, 0.1);
  padding: 30px 0; }
  .header-categorie .header-title {
    color: #4F9893;
    font-size: 24px; }
  .header-categorie h2 {
    font-weight: normal;
    color: #383838;
    font-size: 18px; }
  .header-categorie .text {
    font-weight: normal;
    display: block; }
  .header-categorie ul {
    padding-left: 40px;
    padding-top: 20px;
    color: #383838;
    font-size: 18px;
    padding-bottom: 20px; }
  .header-categorie .btn {
    margin-top: 20px; }
  @media (max-width: 992px) {
    .header-categorie {
      text-align: center;
      margin-bottom: 10px; } }
  @media (max-width: 768px) {
    .header-categorie {
      padding: 20px 10px; } }

/*
-----------------
Icon set
-----------------
*/
.icon-set {
  background: url("/public/images/clicandfit-icon-set.png") no-repeat;
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; }

.icon-adr-livraison {
  background-position: -160px 0; }

.icon-adr-facture {
  background-position: -40px 0; }

.icon-tools {
  background-position: -80px 0; }

.icon-lock {
  background-position: -120px 0; }

.icon-profil {
  background-position: -200px 0; }

.icon-gift {
  background-position: -240px 0; }

.icon-msg {
  background-position: -280px 0; }

/*
-----------------
Bloc
-----------------
*/
.bloc-page {
  padding: 30px; }
  @media (max-width: 768px) {
    .bloc-page {
      padding: 10px 5px; } }

.bloc-shadow {
  box-shadow: 0px 2px 4px rgba(50, 50, 50, 0.1);
  height: 100%;
  padding: 30px;
  background: #ffffff; }
  .bloc-shadow .title-bloc-shadow {
    padding-bottom: 20px;
    font-size: 20px;
    color: #000000; }
    .bloc-shadow .title-bloc-shadow .icon-set {
      margin-right: 10px; }
  .bloc-shadow hr {
    padding-bottom: 20px; }
  .bloc-shadow .text-bloc-shadow {
    padding-bottom: 20px; }
  .bloc-shadow .link-text {
    display: block; }
  @media (max-width: 768px) {
    .bloc-shadow {
      margin-bottom: 10px;
      padding: 20px;
      -webkit-box-sizing: border-box !important; } }

.bloc-validation .icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  position: relative;
  vertical-align: middle;
  margin-right: 20px; }
  .bloc-validation .icon.rond {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%; }
  .bloc-validation .icon.success {
    background: #3FC380; }
  .bloc-validation .icon.error {
    background: #f0a918; }
  .bloc-validation .icon .fa {
    color: #ffffff;
    position: absolute;
    left: 0;
    right: 0; }
  @media (max-width: 768px) {
    .bloc-validation .icon {
      display: block;
      margin: 0 auto 10px; } }
.bloc-validation .msg {
  display: inline-block;
  vertical-align: middle;
  margin: 0; }
  @media (max-width: 768px) {
    .bloc-validation .msg {
      text-align: center;
      display: block; } }

/*
-----------------
Input / Textarea
-----------------
*/
input,
textarea {
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

/*
-----------------
Form
-----------------
*/
.question-general {
  position: relative; }

.question-general .question-link {
  display: block;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px; }

.question-general .question-link .label {
  display: block; }

.question-general .question-link .content-input {
  display: block;
  float: left;
  position: relative; }

.question-general .question-link .input {
  height: 40px;
  display: block;
  padding: 10px;
  margin-top: 5px;
  text-align: left;
  width: 100%;
  border: 1px solid #ddd;
  background: #fff; }

.question-general .question-link .input:focus {
  border: 1px solid #B2DFDB; }

.question-general .question-link.error .label {
  color: #a41c19; }

.question-general .question-link.error .input {
  border: 1px solid #a41c19; }

.anchor {
  display: block;
  height: 90px;
  margin-top: -90px;
  visibility: hidden;
  opacity: 0; }

.img-full {
  position: relative;
  width: 100%;
  height: 100%; }

/*
-----------------
Cookie
-----------------
*/
#cookie-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 5px 0;
  width: 100%;
  border-top: 1px solid #ddd;
  background: #fff;
  font-size: 14px;
  z-index: 3; }
  #cookie-popup.hide {
    display: none;
    opacity: 0;
    visibility: hidden; }
  #cookie-popup .cookie-text {
    display: inline-block; }
  #cookie-popup .cookie-btn {
    display: inline-block;
    color: #ff5859;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline; }
    @media (max-width: 768px) {
      #cookie-popup .cookie-btn {
        margin-top: 10px;
        display: block;
        margin-left: 0; } }
  @media (max-width: 768px) {
    #cookie-popup {
      padding: 10px; } }

/*
-----------------
Smartsup
-----------------
*/
@media (max-width: 992px) {
  #chat-application {
    display: none !important;
    visibility: hidden !important;
    height: 0 !important;
    width: 0 !important; } }
/*
-----------------
Tunnel commande
-----------------
*/
#bloc-tunnel-commande {
  padding: 20px 0 40px; }
  #bloc-tunnel-commande.step-1-color .step-1 {
    color: #f0415b; }
  #bloc-tunnel-commande.step-2-color .step-1,
  #bloc-tunnel-commande.step-2-color .step-2 {
    color: #f0415b; }
  #bloc-tunnel-commande .fa {
    margin-left: 15px; }
  #bloc-tunnel-commande .color {
    color: #f0415b; }
  @media (max-width: 992px) {
    #bloc-tunnel-commande {
      display: none; } }

.small-font {
  font-size: 14px; }

.big-font {
  font-size: 24px; }

.bold-font {
  font-weight: bold; }

/*
-----------------
Tableau infos
-----------------
*/
.info-table {
  background: #faf9f8;
  padding: 10px;
  text-align: center; }

.info-table-line {
  padding: 5px 10px;
  text-align: left; }

.green-background {
  background: #b2dfdb;
  color: #ffffff; }

.red-background {
  background: #FFA7A7;
  color: #ffffff; }

.white-background {
  background: #ffffff; }

.center {
  text-align: center; }

#same-address {
  display: block;
  cursor: pointer; }

#same-address [type="checkbox"]:not(:checked),
#same-address [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
  display: none; }

#same-address [type="checkbox"]:not(:checked) + label,
#same-address [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer; }

#same-address [type="checkbox"]:not(:checked) + label:before,
#same-address [type="checkbox"]:checked + label:before,
#same-address [type="checkbox"]:not(:checked) + label:after,
#same-address [type="checkbox"]:checked + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 1px solid #aaa;
  background: #f8f8f8;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); }

#same-address [type="checkbox"]:not(:checked) + label:after,
#same-address [type="checkbox"]:checked + label:after {
  background: #b2dfdb; }

#same-address [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

#same-address [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
  color: #3FC380; }

#invoice-address {
  display: none; }
  #invoice-address.open {
    display: block; }

.ui-autocomplete {
  background: #f9f9f9;
  border: 1px solid #eee;
  max-height: 300px;
  width: 270px;
  overflow-y: scroll;
  cursor: pointer;
  font-size: 14px;
  z-index: 100;
  position: relative; }

.ui-menu-item {
  padding: 3px 6px; }

.ui-menu-item:hover {
  background: #ff7b7b; }

.form_address, .form_informations {
  padding: 10px;
  margin: 20px;
  border: 1px solid #e5e5e5; }
  .form_address .c-deliveryAddress .row-2, .form_address .c-billingAddress .row-2, .form_address .c-userInformations .row-2, .form_informations .c-deliveryAddress .row-2, .form_informations .c-billingAddress .row-2, .form_informations .c-userInformations .row-2 {
    display: inline-block;
    padding-right: 7.5px;
    padding-left: 7.5px;
    width: 50%; }
  .form_address .c-deliveryAddress .row-3, .form_address .c-billingAddress .row-3, .form_address .c-userInformations .row-3, .form_informations .c-deliveryAddress .row-3, .form_informations .c-billingAddress .row-3, .form_informations .c-userInformations .row-3 {
    display: inline-block;
    padding-right: 7.5px;
    padding-left: 7.5px;
    width: 33.33%; }
  .form_address .c-deliveryAddress .c-addressTitle, .form_address .c-billingAddress .c-addressTitle, .form_address .c-userInformations .c-addressTitle, .form_informations .c-deliveryAddress .c-addressTitle, .form_informations .c-billingAddress .c-addressTitle, .form_informations .c-userInformations .c-addressTitle {
    font-family: "DomaineDisp-Regular", sans-serif;
    color: #000;
    font-size: 24px;
    margin-bottom: 20px;
    padding: 10px 0 0 10px; }
  .form_address .c-deliveryAddress .c-form__input, .form_address .c-billingAddress .c-form__input, .form_address .c-userInformations .c-form__input, .form_informations .c-deliveryAddress .c-form__input, .form_informations .c-billingAddress .c-form__input, .form_informations .c-userInformations .c-form__input {
    border-radius: 0;
    background: #ffffff;
    height: 50px;
    line-height: 50px;
    padding-right: 10px;
    background-origin: content-box; }
    .form_address .c-deliveryAddress .c-form__input:hover, .form_address .c-deliveryAddress .c-form__input:focus, .form_address .c-deliveryAddress .c-form__input:active, .form_address .c-billingAddress .c-form__input:hover, .form_address .c-billingAddress .c-form__input:focus, .form_address .c-billingAddress .c-form__input:active, .form_address .c-userInformations .c-form__input:hover, .form_address .c-userInformations .c-form__input:focus, .form_address .c-userInformations .c-form__input:active, .form_informations .c-deliveryAddress .c-form__input:hover, .form_informations .c-deliveryAddress .c-form__input:focus, .form_informations .c-deliveryAddress .c-form__input:active, .form_informations .c-billingAddress .c-form__input:hover, .form_informations .c-billingAddress .c-form__input:focus, .form_informations .c-billingAddress .c-form__input:active, .form_informations .c-userInformations .c-form__input:hover, .form_informations .c-userInformations .c-form__input:focus, .form_informations .c-userInformations .c-form__input:active {
      background-repeat: no-repeat;
      background-position: right center; }
  .form_address .c-deliveryAddress .c-form__label_autohidden, .form_address .c-billingAddress .c-form__label_autohidden, .form_address .c-userInformations .c-form__label_autohidden, .form_informations .c-deliveryAddress .c-form__label_autohidden, .form_informations .c-billingAddress .c-form__label_autohidden, .form_informations .c-userInformations .c-form__label_autohidden {
    display: none;
    position: absolute;
    left: 8px;
    top: -7px;
    font-size: 10px;
    background: white;
    padding: 0 5px; }
    .form_address .c-deliveryAddress .c-form__label_autohidden.show, .form_address .c-billingAddress .c-form__label_autohidden.show, .form_address .c-userInformations .c-form__label_autohidden.show, .form_informations .c-deliveryAddress .c-form__label_autohidden.show, .form_informations .c-billingAddress .c-form__label_autohidden.show, .form_informations .c-userInformations .c-form__label_autohidden.show {
      display: block; }
    .form_address .c-deliveryAddress .c-form__label_autohidden.c-form__label_autohidden_error, .form_address .c-billingAddress .c-form__label_autohidden.c-form__label_autohidden_error, .form_address .c-userInformations .c-form__label_autohidden.c-form__label_autohidden_error, .form_informations .c-deliveryAddress .c-form__label_autohidden.c-form__label_autohidden_error, .form_informations .c-billingAddress .c-form__label_autohidden.c-form__label_autohidden_error, .form_informations .c-userInformations .c-form__label_autohidden.c-form__label_autohidden_error {
      color: #FF0000; }
  .form_address .c-deliveryAddress .show-password, .form_address .c-billingAddress .show-password, .form_address .c-userInformations .show-password, .form_informations .c-deliveryAddress .show-password, .form_informations .c-billingAddress .show-password, .form_informations .c-userInformations .show-password {
    top: 5px;
    right: 10px; }
  .form_address .c-deliveryAddress .c-form__password_validation_wrapper, .form_address .c-billingAddress .c-form__password_validation_wrapper, .form_address .c-userInformations .c-form__password_validation_wrapper, .form_informations .c-deliveryAddress .c-form__password_validation_wrapper, .form_informations .c-billingAddress .c-form__password_validation_wrapper, .form_informations .c-userInformations .c-form__password_validation_wrapper {
    width: 100%;
    padding-top: 5px; }
    .form_address .c-deliveryAddress .c-form__password_validation_wrapper .c-form__password_validation, .form_address .c-billingAddress .c-form__password_validation_wrapper .c-form__password_validation, .form_address .c-userInformations .c-form__password_validation_wrapper .c-form__password_validation, .form_informations .c-deliveryAddress .c-form__password_validation_wrapper .c-form__password_validation, .form_informations .c-billingAddress .c-form__password_validation_wrapper .c-form__password_validation, .form_informations .c-userInformations .c-form__password_validation_wrapper .c-form__password_validation {
      font-size: 10px;
      color: #b2b2b2;
      display: inline-block;
      transition: all ease 0.2s;
      padding: 0 5px;
      vertical-align: top; }
      .form_address .c-deliveryAddress .c-form__password_validation_wrapper .c-form__password_validation.checked, .form_address .c-billingAddress .c-form__password_validation_wrapper .c-form__password_validation.checked, .form_address .c-userInformations .c-form__password_validation_wrapper .c-form__password_validation.checked, .form_informations .c-deliveryAddress .c-form__password_validation_wrapper .c-form__password_validation.checked, .form_informations .c-billingAddress .c-form__password_validation_wrapper .c-form__password_validation.checked, .form_informations .c-userInformations .c-form__password_validation_wrapper .c-form__password_validation.checked {
        color: #00CA8D; }
  .form_address .c-btn--red, .form_informations .c-btn--red {
    border-radius: 0;
    width: 100%; }

#cf_core_cgv .content h2 {
  font-weight: normal;
  text-transform: uppercase;
  margin: 16px 0;
  line-height: 1em; }

#cf_core_cgv .content h3 {
  font-weight: normal;
  text-transform: uppercase;
  margin: 14px 0;
  line-height: 1em; }

#cf_core_cgv .content h4 {
  font-weight: normal;
  text-transform: uppercase;
  margin: 8px 0;
  line-height: 1em; }

#cf_core_cgv .content a {
  color: #4F9893; }

#cf_core_cgv .content ul {
  padding-left: 20px; }

#cf_core_cgv .content ul li {
  list-style: circle; }

@media (max-width: 992px) {
  #basic-content {
    padding: 40px 0; } }
body {
  background: #F8F8F8; }

#account-content {
  color: #383838; }

.p-account.p-account_credit {
  text-align: center; }
  .p-account.p-account_credit .link-text-orange {
    color: #f0a918; }

.archives-list .articles-list {
  width: 100%; }

.archives-list .articles-list th {
  text-align: left;
  padding: 10px 0; }

.archives-list .articles-list tr {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa; }

.archives-list .articles-list td {
  padding: 3px 0; }

.archives-list .articles-list tr.title-row {
  border: none; }

#contact-content {
  	/*#contact-aide{padding:0 40px;
  		.title{font-size: 20px;padding-bottom: 25px;color:#000;}
  		.question-liste{padding-left: 40px;list-style-type: circle;
  			li{padding-bottom: 15px;
  				a{color:#383838;
  				&:hover{color:$color_pink;text-decoration: underline;}
  				}
  			}
  		}
  		.hr-dot{margin:20px auto;}
  
  		@include breakpoint(phablet){
  			padding-top:20px;padding-bottom:20px;
  			.title{text-align: center;}
  			.question-liste{padding-left: 20px;}
  			.hr-dot{display: none;}
  		}
  	}*/
  color: #000;
  font-family: "Montserrat-Regular", sans-serif;
  margin-bottom: 50px; }
  #contact-content.contact-questions .title {
    font-size: 20px;
    font-weight: bold;
    font-family: "Montserrat-Bold", sans-serif;
    padding-bottom: 30px; }
  #contact-content.contact-questions .subtitle {
    font-size: 18px; }
  #contact-content.contact-questions .most-viewed-questions .title {
    font-weight: bold;
    font-family: "Montserrat-Bold", sans-serif;
    margin-bottom: 0;
    padding-bottom: 0; }
  #contact-content.contact-questions .most-viewed-questions ul {
    display: inline-block;
    padding-left: 0;
    padding-bottom: 0;
    width: 50%; }
    #contact-content.contact-questions .most-viewed-questions ul li {
      padding-bottom: 20px; }
      #contact-content.contact-questions .most-viewed-questions ul li a {
        color: #000;
        text-decoration: underline; }
  #contact-content.contact-questions .questions-container a {
    color: #000; }
  #contact-content.contact-questions .questions-container .question-liste {
    padding-left: 0;
    padding-bottom: 50px; }
    #contact-content.contact-questions .questions-container .question-liste li {
      list-style: none;
      margin-bottom: 10px; }
      #contact-content.contact-questions .questions-container .question-liste li a {
        color: #000;
        text-decoration: underline; }
    #contact-content.contact-questions .questions-container .question-liste .see-more {
      text-decoration: underline; }
  #contact-content.contact-category-question .header-categorie a {
    text-decoration: none;
    color: #000; }
  #contact-content.contact-category-question .category-list ul li {
    padding-bottom: 10px; }
    #contact-content.contact-category-question .category-list ul li a {
      text-decoration: none;
      color: #000; }
      #contact-content.contact-category-question .category-list ul li a.active {
        text-decoration: underline;
        font-family: "Montserrat-Bold", sans-serif; }
  #contact-content.contact-category-question .answers-list .c-title-big {
    font-size: 32px;
    font-family: "Montserrat-Bold", sans-serif; }
  #contact-content.contact-category-question .answers-list .answer-title > a {
    color: #000;
    text-decoration: underline; }
  #contact-content.contact-category-question .answers-list .answer-title > .answer-content {
    font-size: 16px; }
    #contact-content.contact-category-question .answers-list .answer-title > .answer-content > a {
      color: #000; }
  #contact-content.contact-category-question .answers-list .c-title b {
    display: block;
    margin-bottom: 10px; }
  #contact-content.contact-category-question .answers-list .same-category-question {
    display: block;
    text-decoration: underline;
    margin-bottom: 10px;
    color: #000; }
  #contact-content.contact-category-question .answers-list hr {
    margin: 60px 0 30px 0; }
  #contact-content.contact-category-question .answers-list .send-email-wrapper {
    width: 100%; }
    #contact-content.contact-category-question .answers-list .send-email-wrapper .send-email-block {
      text-align: center;
      width: 350px;
      margin: 0 auto; }
      #contact-content.contact-category-question .answers-list .send-email-wrapper .send-email-block img {
        width: 30px; }
      #contact-content.contact-category-question .answers-list .send-email-wrapper .send-email-block b {
        font-family: "Montserrat-Bold", sans-serif; }
      #contact-content.contact-category-question .answers-list .send-email-wrapper .send-email-block .send-email-link {
        text-decoration: none;
        border: 1px solid #000;
        color: #000;
        padding: 10px 20px;
        display: block;
        width: 190px;
        margin: 20px auto 0 auto; }
        #contact-content.contact-category-question .answers-list .send-email-wrapper .send-email-block .send-email-link:hover {
          background: #fafafa; }
  #contact-content.contact-form {
    background: #fff; }
    #contact-content.contact-form > .container > .link-text-grey {
      color: #000;
      font-size: 16px; }
    #contact-content.contact-form > .container > .row .form-1 {
      padding: 30px;
      width: 550px;
      position: relative;
      min-width: 350px;
      margin: 0 auto; }
      #contact-content.contact-form > .container > .row .form-1 .row-form-design {
        position: relative; }
        #contact-content.contact-form > .container > .row .form-1 .row-form-design .label-form-1 {
          position: absolute;
          top: 15px;
          left: 0;
          color: #5b5852;
          cursor: text;
          display: none;
          background: #fff; }
          #contact-content.contact-form > .container > .row .form-1 .row-form-design .label-form-1.label-file {
            position: initial;
            display: initial;
            color: #000; }
          #contact-content.contact-form > .container > .row .form-1 .row-form-design .label-form-1.label-select {
            display: block;
            position: initial;
            color: #7a7e8a;
            width: 100%;
            padding-left: 5px;
            font-size: 18px;
            margin-top: 5px;
            margin-bottom: 5px !important; }
        #contact-content.contact-form > .container > .row .form-1 .row-form-design .input-form-1 {
          width: 100%;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #c0bdba;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: "Montserrat-Regular", sans-serif;
          background: #fff;
          box-shadow: none !important;
          transition: all ease 0.1s; }
          #contact-content.contact-form > .container > .row .form-1 .row-form-design .input-form-1:focus {
            background: #fafafa; }
        #contact-content.contact-form > .container > .row .form-1 .row-form-design .textarea-form-1 {
          background: #fff;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #c0bdba;
          font-size: 18px;
          font-family: "Montserrat-Regular", sans-serif;
          transition: all ease 0.1s; }
          #contact-content.contact-form > .container > .row .form-1 .row-form-design .textarea-form-1:focus {
            background: #fafafa; }
        #contact-content.contact-form > .container > .row .form-1 .row-form-design.other-questions-list .other-question-category {
          display: none;
          height: auto; }
          #contact-content.contact-form > .container > .row .form-1 .row-form-design.other-questions-list .other-question-category .same-category-question {
            color: #000;
            text-decoration: underline;
            display: block;
            margin-bottom: 10px;
            font-family: "Montserrat-SemiBold", sans-serif; }
  @media (max-width: 550px) {
    #contact-content.contact-form > .container > .row .form-1 {
      width: 100%;
      padding: 60px 20px !important; } }

#error-404 .bloc-shadow {
  margin-bottom: 20px; }

#error-404 .bloc-design .link-text {
  display: inline-block; }

#faq-content #questions {
  text-align: left;
  padding-bottom: 40px; }
  #faq-content #questions .question-categorie {
    display: block;
    width: 100%;
    padding: 40px 0 5px;
    font-size: 24px;
    font-weight: normal;
    color: #000; }
    #faq-content #questions .question-categorie:first-child {
      padding-top: 0; }
  #faq-content #questions .question a {
    color: #383838; }
    #faq-content #questions .question a:hover {
      color: #f0415b; }

#faq-content #questions #contact-us {
  padding-top: 40px; }

#faq-content .bloc-shadow {
  margin-bottom: 20px; }

#faq-content .link-text {
  display: inline-block;
  margin-top: 0; }

#faq-content .anchor {
  height: 140px;
  margin-top: -140px; }

#cf_core_homepage #footer {
  padding-top: 40px; }

#footer {
  background: #fff;
  border-top: 1px solid #ddd;
  padding: 40px 0;
  position: relative;
  text-align: center;
  color: #949494; }

#footer .introduction {
  font-size: 18px; }

#footer .social {
  padding: 20px 0; }

#footer .social .button-social {
  display: inline-block;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  line-height: 40px; }

#footer .social .button-social .fa {
  font-size: 18px;
  text-align: center; }

#footer .social .facebook {
  background: #b2dfdb; }

#footer .social .pinterest {
  background: #b2dfdb; }

#footer .social .instagram {
  background: #b2dfdb; }

#footer .link {
  padding-top: 40px; }

#footer .link ul {
  list-style-type: none; }

#footer .link ul li {
  display: inline-block;
  padding: 0 20px;
  position: relative; }

#footer .link ul li:not(:last-child):after {
  content: "";
  position: absolute;
  right: -5px;
  position: absolute;
  height: 1px;
  background: #828ca4;
  width: 10px; }

#footer .link ul li a {
  font-size: 16px;
  color: #949494; }

#footer .link ul li a:hover {
  color: #4F9893; }

@media (max-width: 992px) {
  #footer {
    padding: 40px 5px; }

  #footer .link {
    padding-top: 20px; } }
@media (max-width: 768px) {
  #footer .link ul li {
    padding-bottom: 15px; }

  #footer .link ul li:after {
    content: none;
    display: none; } }
.c-footer .c-title h2 {
  font-family: 'sofia-pro', sans-serif;
  font-weight: var(--text-font-bold);
  font-size: 1.7rem; }

.c-footer .proposition {
  font-family: var(--text-font-manuscrit);
  font-size: 1.5rem;
  color: var(--black); }

.c-footer h4 {
  font-family: 'sofia-pro', sans-serif;
  font-weight: var(--text-font-light);
  font-size: 1.2rem;
  color: var(--black);
  padding-bottom: 5px; }

.c-footer {
  padding: 10px 0 0;
  background: #FFF;
  position: relative; }
  .c-footer #footer-menu-mobile-toggle {
    font-size: 16px;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 30px;
    left: 0;
    top: 0; }
    .c-footer #footer-menu-mobile-toggle #footer-menu-black-bar {
      width: 100%;
      height: 1px;
      background: #000;
      position: absolute;
      top: 16px;
      left: 0;
      z-index: 1; }
    .c-footer #footer-menu-mobile-toggle #footer-menu-mobile-toggle-button {
      color: #000;
      cursor: pointer;
      background: #fff;
      position: absolute;
      z-index: 2;
      width: 110px;
      margin: 0 auto;
      left: 0;
      right: 0;
      padding: 5px; }
      .c-footer #footer-menu-mobile-toggle #footer-menu-mobile-toggle-button #footer-menu-minus {
        display: none; }
  @media (max-width: 768px) {
    .c-footer {
      padding: 30px 0 0;
      text-align: center; } }
  .c-footer #footer-menu {
    display: flex;
    justify-content: center; }
    .c-footer #footer-menu .social-wrap-duplicate {
      text-align: center;
      width: 100%; }
    @media (min-width: 900px) {
      .c-footer #footer-menu .social-wrap-duplicate {
        display: none; } }
  @media (min-width: 768px) and (max-width: 899px) {
    .c-footer #footer-menu {
      display: flex;
      justify-content: center; }
      .c-footer #footer-menu .centering {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .c-footer #footer-menu .centering:nth-child(2) {
        display: flex; }
        .c-footer #footer-menu .centering:nth-child(2) .centering-wrap {
          width: 140px;
          margin: 0 auto;
          text-align: left; }
      .c-footer #footer-menu .centering:nth-child(3) {
        align-items: end;
        margin: 0;
        padding: 0; }
        .c-footer #footer-menu .centering:nth-child(3) .c-title {
          text-align: end; }
        .c-footer #footer-menu .centering:nth-child(3) .social-wrap {
          text-align: end;
          margin: 0 auto; }
      .c-footer #footer-menu .centering.footer-menu-mobile-hidden {
        display: none; }
        .c-footer #footer-menu .centering.footer-menu-mobile-hidden.footer-menu-mobile-shown {
          display: initial; } }
  @media (min-width: 900px) {
    .c-footer #footer-menu .centering {
      display: flex;
      flex-direction: column; }
    .c-footer #footer-menu .centering:nth-child(2) {
      align-items: center; }
      .c-footer #footer-menu .centering:nth-child(2) .centering-wrap {
        text-align: left; }
    .c-footer #footer-menu .centering:nth-child(3) {
      align-items: end; } }
  .c-footer .c-title {
    font-family: DomaineDisp-Regular, sans-serif;
    font-size: 24px; }
  .c-footer .c-footer__list {
    list-style: none;
    padding: 0;
    margin: 0; }
  .c-footer .c-footer__item {
    color: #000;
    margin-bottom: 4px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: var(--text-font-light);
    font-size: 1.1rem; }
  .c-footer .c-footer__link {
    color: #000; }
    .c-footer .c-footer__link:hover {
      color: #F3A090;
      text-decoration: underline; }
  .c-footer .c-footer__social {
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
    -webkit-transition: all 0.15s ease-in 0s;
    -moz-transition: all 0.15s ease-in 0s;
    -o-transition: all 0.15s ease-in 0s;
    transition: all 0.15s ease-in 0s; }
    .c-footer .c-footer__social:hover .fa {
      color: #FF5859; }
    .c-footer .c-footer__social .fa {
      font-size: 18px;
      color: #545454;
      -webkit-transition: all 0.15s ease-in 0s;
      -moz-transition: all 0.15s ease-in 0s;
      -o-transition: all 0.15s ease-in 0s;
      transition: all 0.15s ease-in 0s; }
    .c-footer .c-footer__social img {
      width: 50px; }
  .c-footer .c-footer__copyright {
    text-align: center;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 2; }
    .c-footer .c-footer__copyright .c-footer__copyright_element.left {
      text-align: left; }
    .c-footer .c-footer__copyright .c-footer__copyright_element.right {
      text-align: right; }
    .c-footer .c-footer__copyright .c-footer__copyright_element .c-footer__list .c-footer__item {
      display: inline-block;
      font-family: 'sofia-pro', sans-serif;
      font-weight: 300;
      font-size: 0.9rem;
      color: #666; }
      .c-footer .c-footer__copyright .c-footer__copyright_element .c-footer__list .c-footer__item.border-right {
        border-right: 1px solid #acacac;
        padding-right: 10px;
        margin-right: 5px; }
      .c-footer .c-footer__copyright .c-footer__copyright_element .c-footer__list .c-footer__item .c-footer__link {
        color: #666; }
  @media (min-width: 768px) {
    .c-footer .c-footer__copyright {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center; }
      .c-footer .c-footer__copyright .c-footer__list .c-footer__item {
        font-size: 0.9rem; }
      .c-footer .c-footer__copyright .c-footer__copyright_element {
        font-size: 0.9rem; } }
  @media (max-width: 900px) {
    .c-footer .footer-menu-mobile-hidden {
      display: none; }
      .c-footer .footer-menu-mobile-hidden.footer-menu-mobile-shown {
        display: initial; } }
  @media (min-width: 900px) {
    .c-footer #footer-menu-mobile-toggle {
      display: none; } }

.error_giftcard {
  -webkit-box-shadow: 0px 0px 3px 0px red;
  -moz-box-shadow: 0px 0px 3px 0px red;
  box-shadow: 0px 0px 3px 0px red; }

.p-giftcard.p-giftcard_presentation .block_1 {
  padding: 60px 0 180px;
  text-align: center;
  background: #fff url("/public/images/giftcard/illustration_gift_card.svg") no-repeat center bottom;
  background-size: initial; }
  .p-giftcard.p-giftcard_presentation .block_1 .h1 {
    color: #272C30;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .p-giftcard.p-giftcard_presentation .block_1 .text {
    padding-top: 15px;
    color: #545454;
    font-size: 16px; }
  .p-giftcard.p-giftcard_presentation .block_1 .btn-hover:hover, .p-giftcard.p-giftcard_presentation .block_1 .btn-hover:active, .p-giftcard.p-giftcard_presentation .block_1 .btn-hover:focus {
    transform: none;
    background: #FF8573;
    border: none; }
  .p-giftcard.p-giftcard_presentation .block_1 .c-btn-container {
    padding-top: 30px;
    text-align: center;
    justify-content: center; }
  @media (max-width: 768px) {
    .p-giftcard.p-giftcard_presentation .block_1 {
      padding: 30px 0 180px; } }
.p-giftcard.p-giftcard_presentation .block_2 {
  background: #F8F8F8;
  width: 100%;
  padding: 60px 0;
  text-align: center; }
  .p-giftcard.p-giftcard_presentation .block_2 .h2 {
    font-size: 24px;
    color: #272C30; }
  .p-giftcard.p-giftcard_presentation .block_2 .item {
    margin-top: 45px;
    color: #545454; }
  .p-giftcard.p-giftcard_presentation .block_2 .item__container-img {
    max-height: 40px;
    max-width: 40px;
    margin: 0 auto; }
    .p-giftcard.p-giftcard_presentation .block_2 .item__container-img img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%; }
  .p-giftcard.p-giftcard_presentation .block_2 .item__text {
    max-width: 320px;
    margin: 0 auto;
    padding-top: 8px;
    font-size: 16px; }
.p-giftcard.p-giftcard_presentation .block_3 {
  background: #f8f8f8;
  width: 100%;
  padding: 0 60px 60px;
  text-align: center;
  position: relative; }
  .p-giftcard.p-giftcard_presentation .block_3 .block_background {
    background: #FFF;
    position: relative; }
  .p-giftcard.p-giftcard_presentation .block_3 .block_padding {
    padding: 45px; }
  .p-giftcard.p-giftcard_presentation .block_3 .h2 {
    font-size: 24px;
    color: #272C30;
    text-align: center;
    max-width: 345px;
    margin: 0 auto; }
  .p-giftcard.p-giftcard_presentation .block_3 .item {
    margin-top: 45px;
    color: #545454; }
  .p-giftcard.p-giftcard_presentation .block_3 .item__title {
    font-size: 16px;
    text-transform: uppercase;
    color: #FF5859; }
  .p-giftcard.p-giftcard_presentation .block_3 .item__text {
    padding-top: 4px;
    font-size: 16px;
    max-width: 400px;
    margin: 0 auto; }
  .p-giftcard.p-giftcard_presentation .block_3 .c-btn-container {
    padding-top: 45px;
    text-align: center;
    justify-content: center; }
    .p-giftcard.p-giftcard_presentation .block_3 .c-btn-container .btn-hover:hover, .p-giftcard.p-giftcard_presentation .block_3 .c-btn-container .btn-hover:active, .p-giftcard.p-giftcard_presentation .block_3 .c-btn-container .btn-hover:focus {
      transform: none;
      background: #FF8573;
      border: none; }
  .p-giftcard.p-giftcard_presentation .block_3 .background {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: #fff url("/public/images/main/ouverture-box-clicandfit.jpg") no-repeat center center;
    background-size: cover; }
  @media (max-width: 992px) {
    .p-giftcard.p-giftcard_presentation .block_3 {
      padding: 0 15px 15px; }
      .p-giftcard.p-giftcard_presentation .block_3 .background {
        position: relative;
        left: 0;
        width: 100%;
        height: 460px;
        background: #fff url("/public/images/main/ouverture-box-clicandfit.jpg") no-repeat center top;
        background-size: cover; } }
  @media (max-width: 768px) {
    .p-giftcard.p-giftcard_presentation .block_3 .background {
      height: 300px; }
    .p-giftcard.p-giftcard_presentation .block_3 .block_padding {
      padding: 30px 15px; } }
.p-giftcard.p-giftcard_tooffer {
  background: #FFF;
  padding: 0 20px; }
  @media (max-width: 768px) {
    .p-giftcard.p-giftcard_tooffer {
      padding: 0 10px; } }
  .p-giftcard.p-giftcard_tooffer .block_header {
    padding: 60px 0 40px;
    text-align: center; }
    .p-giftcard.p-giftcard_tooffer .block_header .h1 {
      font-size: 24px;
      color: #272C30;
      margin: 0 auto;
      max-width: 460px; }
    .p-giftcard.p-giftcard_tooffer .block_header .info {
      padding-top: 8px;
      font-size: 14px;
      color: #545454;
      font-style: italic; }
  .p-giftcard.p-giftcard_tooffer .block_form .js-print.is-hidden {
    display: none;
    visibility: hidden;
    opacity: 0; }
  .p-giftcard.p-giftcard_tooffer .block_form .container {
    max-width: 780px; }
  .p-giftcard.p-giftcard_tooffer .block_form .c-block_shadow {
    padding: 20px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); }
  .p-giftcard.p-giftcard_tooffer .block_form .c-btn-container {
    padding-top: 20px;
    padding-bottom: 80px; }
  .p-giftcard.p-giftcard_tooffer .block_form .c-form__title {
    position: relative;
    color: #FFAB9E;
    font-size: 16px;
    padding: 40px 0 20px;
    text-transform: uppercase;
    display: inline-block;
    background: #fff;
    white-space: normal; }
  .p-giftcard.p-giftcard_tooffer .block_form .c-card {
    cursor: pointer;
    position: relative;
    padding: 30px;
    background: #fff;
    text-align: left;
    border: 1px solid #DDDDDD;
    min-height: 175px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px; }
    @media (max-width: 768px) {
      .p-giftcard.p-giftcard_tooffer .block_form .c-card {
        padding: 15px;
        min-height: auto;
        margin-bottom: 10px; } }
    .p-giftcard.p-giftcard_tooffer .block_form .c-card.is-active {
      top: -1px;
      border: 1px solid #FF7B7B; }
    .p-giftcard.p-giftcard_tooffer .block_form .c-card .c-card__title {
      position: relative;
      z-index: 2;
      font-size: 16px;
      color: #272C30;
      margin: 0 auto; }
    .p-giftcard.p-giftcard_tooffer .block_form .c-card .c-card__text {
      padding-top: 4px; }
    .p-giftcard.p-giftcard_tooffer .block_form .c-card .c-card__background {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("/public/images/main/ouverture-box-clicandfit.jpg") no-repeat center;
      background-size: cover;
      opacity: 0.4;
      z-index: 1; }
    .p-giftcard.p-giftcard_tooffer .block_form .c-card .c-form__input {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      height: 0;
      width: 0; }
    .p-giftcard.p-giftcard_tooffer .block_form .c-card img {
      width: 100%; }
  .p-giftcard.p-giftcard_tooffer .block_form .btn-hover:hover, .p-giftcard.p-giftcard_tooffer .block_form .btn-hover:active, .p-giftcard.p-giftcard_tooffer .block_form .btn-hover:focus {
    transform: none;
    background: #FF8573;
    border: none; }
  .p-giftcard.p-giftcard_tooffer .block_form #p-giftcard__to.is-hidden {
    visibility: hidden;
    opacity: 0;
    display: none; }
.p-giftcard.p-giftcard_entercode .btn-hover:hover, .p-giftcard.p-giftcard_entercode .btn-hover:active, .p-giftcard.p-giftcard_entercode .btn-hover:focus {
  transform: none;
  background: #FF8573;
  border: none; }
.p-giftcard #ui-datepicker-div {
  text-align: center;
  max-width: 360px;
  margin: 0 auto;
  background: #FFF;
  padding: 20px;
  border: 1px solid #ddd;
  display: none; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-title {
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-header {
    position: relative; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-next,
  .p-giftcard #ui-datepicker-div .ui-datepicker-prev {
    position: absolute;
    cursor: pointer;
    color: #ccc;
    font-size: 22px;
    top: 1px; }
    .p-giftcard #ui-datepicker-div .ui-datepicker-next:hover,
    .p-giftcard #ui-datepicker-div .ui-datepicker-prev:hover {
      color: #FF5859; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-prev {
    left: 0; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-next {
    right: 0; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-calendar {
    width: 100%; }
  .p-giftcard #ui-datepicker-div thead th {
    color: #ccc;
    padding: 10px 0; }
  .p-giftcard #ui-datepicker-div tbody td:hover a {
    color: #FF5859; }
  .p-giftcard #ui-datepicker-div tbody td:hover a.ui-state-active {
    color: #fff;
    background: #FF5859; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-unselectable .ui-state-default,
  .p-giftcard #ui-datepicker-div .ui-state-disabled .ui-state-default {
    color: #ccc; }
  .p-giftcard #ui-datepicker-div .ui-state-default {
    color: #555;
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid transparent;
    border-radius: 100%;
    line-height: 28px; }
  .p-giftcard #ui-datepicker-div .ui-state-active {
    color: #fff;
    background: #FF5859; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-next .ui-icon,
  .p-giftcard #ui-datepicker-div .ui-datepicker-prev .ui-icon {
    text-indent: -9999px;
    position: absolute;
    display: none; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-next:before {
    font-family: "FontAwesome";
    content: "\f105"; }
  .p-giftcard #ui-datepicker-div .ui-datepicker-prev:before {
    font-family: "FontAwesome";
    content: "\f104 "; }

.c-form__input_error {
  border: 1px solid #FFAB9E !important; }

.alert.alert-error, .alert.alert-success {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 2px 4px rgba(50, 50, 50, 0.1);
  padding: 20px 40px;
  margin: 10px auto; }

.grecaptcha-badge {
  visibility: hidden; }

.google-terms {
  font-size: 9px;
  color: #555;
  padding-top: 10px;
  display: block; }
  .google-terms a {
    color: #444; }
    .google-terms a:hover {
      text-decoration: underline; }

.alert.alert-error {
  color: #f0a918;
  background: #FF7B7B; }

.alert.alert-success {
  color: #4f9893; }

.original-price-crossed {
  color: #555;
  font-size: 18px; }

.discount-amount {
  background: #28C76F none repeat scroll 0 0;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  margin-left: 50px;
  padding: 3px 10px; }

.black-font {
  color: #555; }

.discounted-price {
  font-weight: bold; }

.c-form__field--mimic-select {
  padding: 7px;
  height: 40px;
  cursor: pointer;
  -webkit-transition: background ease 0.1s;
  -moz-transition: background ease 0.1s;
  -ms-transition: background ease 0.1s;
  -o-transition: background ease 0.1s;
  transition: background ease 0.1s; }
  @media (max-width: 480px) {
    .c-form__field--mimic-select span {
      margin: 0; } }

.c-form__field--mimic-select:hover {
  background: #F5F5F5; }

.c-form__field--mimic-select-dropdown {
  background: #F8F8F8;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10;
  width: 180px;
  display: none; }

.c-form__field--mimic-select-dropdown.open {
  display: block; }

.c-form__field--mimic-select-dropdown li {
  background: #F8F8F8;
  border: 1px solid #ddd;
  padding: 7px;
  cursor: pointer; }

.c-form__field--mimic-select-dropdown li:hover {
  background: #fff; }

.c-form__field--mimic-select:after {
  content: '\f0d7';
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  margin: 0 0 0 10px;
  text-decoration: none; }
  @media (max-width: 480px) {
    .c-form__field--mimic-select:after {
      margin: 0; } }

.c-form__field--mimic-select-wrapper-small {
  display: inline-block;
  /*width: 60px;*/
  position: relative; }
  @media (max-width: 992px) {
    .c-form__field--mimic-select-wrapper-small .c-form__field--mimic-select-small {
      background-position: 0px center !important;
      height: 19px;
      width: 25px;
      background-size: 25px 19px !important; } }
  @media (max-width: 480px) {
    .c-form__field--mimic-select-wrapper-small {
      width: 30px; } }

.c-form__field--mimic-select-small {
  height: 35px;
  cursor: pointer;
  width: 35px; }

.c-form__field--mimic-select-small:hover {
  background: #fff; }

/*.c-form__field--mimic-select-small:after{
  content: '\f0d7';
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  margin: 0px 0px 0px 35px;
  text-decoration:none;
}*/
.c-form__field--mimic-select-dropdown-small {
  background: #F8F8F8;
  position: absolute;
  top: 40px;
  left: 8px;
  z-index: 10;
  width: 120px;
  display: none; }
  @media (max-width: 768px) {
    .c-form__field--mimic-select-dropdown-small {
      top: 36px;
      left: -80px; } }

.c-form__field--mimic-select-dropdown-small.open {
  display: block; }

.c-form__field--mimic-select-dropdown-small li {
  background: #F8F8F8;
  border: 1px solid #ddd;
  padding: 7px;
  cursor: pointer; }

.c-form__field--mimic-select-dropdown-small li:hover {
  background: #fff; }

.align-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.c-navigation__right > a, .c-navigation__right > div {
  vertical-align: top; }

#countryChoice-under {
  position: absolute;
  top: 28px;
  right: 35px; }

#countryChoice {
  position: absolute;
  top: 5px;
  right: 8px; }

.c-display-inline-block {
  display: inline-block; }

#connexion .bloc-shadow, #nouveau-mot-de-passe .bloc-shadow, #nouveau-mot-de-passe .bloc-shadow {
  background: #fafafa;
  box-shadow: none; }
  #connexion .bloc-shadow .c-title, #nouveau-mot-de-passe .bloc-shadow .c-title, #nouveau-mot-de-passe .bloc-shadow .c-title {
    font-family: "DomaineDisp-Regular", sans-serif;
    font-size: 22px;
    text-transform: uppercase; }
  #connexion .bloc-shadow #form_login .row-form-design, #connexion .bloc-shadow .text .row-form-design, #connexion .bloc-shadow form .row-form-design, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design, #nouveau-mot-de-passe .bloc-shadow form .row-form-design, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design, #nouveau-mot-de-passe .bloc-shadow form .row-form-design {
    position: relative; }
    #connexion .bloc-shadow #form_login .row-form-design .label-form-1, #connexion .bloc-shadow .text .row-form-design .label-form-1, #connexion .bloc-shadow form .row-form-design .label-form-1, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design .label-form-1, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design .label-form-1, #nouveau-mot-de-passe .bloc-shadow form .row-form-design .label-form-1, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design .label-form-1, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design .label-form-1, #nouveau-mot-de-passe .bloc-shadow form .row-form-design .label-form-1 {
      position: absolute;
      top: 15px;
      left: 0;
      color: #5b5852;
      text-transform: lowercase;
      cursor: text;
      display: block;
      display: none !important;
      background: #fafafa; }
    #connexion .bloc-shadow #form_login .row-form-design .input-form-1, #connexion .bloc-shadow .text .row-form-design .input-form-1, #connexion .bloc-shadow form .row-form-design .input-form-1, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design .input-form-1, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design .input-form-1, #nouveau-mot-de-passe .bloc-shadow form .row-form-design .input-form-1, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design .input-form-1, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design .input-form-1, #nouveau-mot-de-passe .bloc-shadow form .row-form-design .input-form-1 {
      width: 100%;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #c0bdba;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      font-family: "Montserrat-Regular", sans-serif;
      background: #fafafa;
      box-shadow: none !important;
      transition: all ease 0.1s; }
      #connexion .bloc-shadow #form_login .row-form-design .input-form-1:focus, #connexion .bloc-shadow .text .row-form-design .input-form-1:focus, #connexion .bloc-shadow form .row-form-design .input-form-1:focus, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design .input-form-1:focus, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design .input-form-1:focus, #nouveau-mot-de-passe .bloc-shadow form .row-form-design .input-form-1:focus, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design .input-form-1:focus, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design .input-form-1:focus, #nouveau-mot-de-passe .bloc-shadow form .row-form-design .input-form-1:focus {
        background: #fdfdfd; }
    #connexion .bloc-shadow #form_login .row-form-design .show-password, #connexion .bloc-shadow .text .row-form-design .show-password, #connexion .bloc-shadow form .row-form-design .show-password, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design .show-password, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design .show-password, #nouveau-mot-de-passe .bloc-shadow form .row-form-design .show-password, #nouveau-mot-de-passe .bloc-shadow #form_login .row-form-design .show-password, #nouveau-mot-de-passe .bloc-shadow .text .row-form-design .show-password, #nouveau-mot-de-passe .bloc-shadow form .row-form-design .show-password {
      right: 5px;
      top: 0; }

.extend-delay-selected-dates {
  background: #ff7b7b; }
  .extend-delay-selected-dates > a {
    background: #ff7b7b !important;
    border: 1px solid #ff7b7b !important;
    color: #fff !important; }

.font-size-12 {
  font-size: 12px !important; }

.font-size-14 {
  font-size: 14px !important; }

.font-size-16 {
  font-size: 16px !important; }

.font-size-20 {
  font-size: 20px !important; }

.font-size-24 {
  font-size: 24px !important; }

.center {
  text-align: center; }

#my-subscription {
  color: #000; }
  #my-subscription .c-display-inline-block {
    vertical-align: top; }
  #my-subscription .cancel-pause-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300; }
  #my-subscription .deadlines-list, #my-subscription .payment_methods_list {
    width: 100%;
    border-collapse: separate;
    border-spacing: 3px; }
    #my-subscription .deadlines-list td, #my-subscription .deadlines-list th, #my-subscription .payment_methods_list td, #my-subscription .payment_methods_list th {
      border: 1px solid #d7d7d7;
      padding: 5px 10px;
      text-align: left;
      position: relative;
      background: #fff; }
      #my-subscription .deadlines-list td .card-details, #my-subscription .deadlines-list th .card-details, #my-subscription .payment_methods_list td .card-details, #my-subscription .payment_methods_list th .card-details {
        min-height: 50px; }
      #my-subscription .deadlines-list td:hover, #my-subscription .deadlines-list th:hover, #my-subscription .payment_methods_list td:hover, #my-subscription .payment_methods_list th:hover {
        background: #fafafa; }
      #my-subscription .deadlines-list td .is-default-text, #my-subscription .deadlines-list th .is-default-text, #my-subscription .payment_methods_list td .is-default-text, #my-subscription .payment_methods_list th .is-default-text {
        color: #f05d61; }
      #my-subscription .deadlines-list td .is-expired-text, #my-subscription .deadlines-list th .is-expired-text, #my-subscription .payment_methods_list td .is-expired-text, #my-subscription .payment_methods_list th .is-expired-text {
        color: #c5c5c5; }
      #my-subscription .deadlines-list td .is-default-text, #my-subscription .deadlines-list td .is-expired-text, #my-subscription .deadlines-list th .is-default-text, #my-subscription .deadlines-list th .is-expired-text, #my-subscription .payment_methods_list td .is-default-text, #my-subscription .payment_methods_list td .is-expired-text, #my-subscription .payment_methods_list th .is-default-text, #my-subscription .payment_methods_list th .is-expired-text {
        display: none;
        position: absolute; }
      #my-subscription .deadlines-list td .delete-payment-method, #my-subscription .deadlines-list th .delete-payment-method, #my-subscription .payment_methods_list td .delete-payment-method, #my-subscription .payment_methods_list th .delete-payment-method {
        position: absolute;
        right: 5px;
        top: 5px;
        text-decoration: underline;
        color: #FF5859;
        font-size: 12px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0; }
      #my-subscription .deadlines-list td.is-default, #my-subscription .deadlines-list th.is-default, #my-subscription .payment_methods_list td.is-default, #my-subscription .payment_methods_list th.is-default {
        border: 2px solid #f05d61; }
        #my-subscription .deadlines-list td.is-default .is-default-text, #my-subscription .deadlines-list th.is-default .is-default-text, #my-subscription .payment_methods_list td.is-default .is-default-text, #my-subscription .payment_methods_list th.is-default .is-default-text {
          display: block;
          right: 5px;
          top: 0;
          font-size: 12px; }
      #my-subscription .deadlines-list td.is-expired, #my-subscription .deadlines-list th.is-expired, #my-subscription .payment_methods_list td.is-expired, #my-subscription .payment_methods_list th.is-expired {
        background: #fafafa;
        /*border: none;*/ }
        #my-subscription .deadlines-list td.is-expired .is-expired-text, #my-subscription .deadlines-list th.is-expired .is-expired-text, #my-subscription .payment_methods_list td.is-expired .is-expired-text, #my-subscription .payment_methods_list th.is-expired .is-expired-text {
          display: block;
          right: 5px;
          bottom: 5px;
          font-size: 12px; }
      #my-subscription .deadlines-list td .mark-default, #my-subscription .deadlines-list th .mark-default, #my-subscription .payment_methods_list td .mark-default, #my-subscription .payment_methods_list th .mark-default {
        position: absolute;
        right: 5px;
        bottom: 10px;
        text-decoration: underline;
        color: #FF5859;
        font-size: 12px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0; }
      #my-subscription .deadlines-list td.canceled-deadline, #my-subscription .deadlines-list th.canceled-deadline, #my-subscription .payment_methods_list td.canceled-deadline, #my-subscription .payment_methods_list th.canceled-deadline {
        background: #eaeaea;
        color: #a6a6a6;
        text-decoration: line-through; }
      #my-subscription .deadlines-list td .cancel-deadline, #my-subscription .deadlines-list td .restore-deadline, #my-subscription .deadlines-list th .cancel-deadline, #my-subscription .deadlines-list th .restore-deadline, #my-subscription .payment_methods_list td .cancel-deadline, #my-subscription .payment_methods_list td .restore-deadline, #my-subscription .payment_methods_list th .cancel-deadline, #my-subscription .payment_methods_list th .restore-deadline {
        float: right;
        cursor: pointer; }
  #my-subscription .cancel-deadline-modal-wrapper, #my-subscription .restore-deadline-modal-wrapper {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: none; }
    #my-subscription .cancel-deadline-modal-wrapper .cancel-deadline-modal, #my-subscription .cancel-deadline-modal-wrapper .restore-deadline-modal, #my-subscription .restore-deadline-modal-wrapper .cancel-deadline-modal, #my-subscription .restore-deadline-modal-wrapper .restore-deadline-modal {
      background: #fff;
      width: 500px;
      text-align: center;
      padding: 20px;
      border: 1px solid #bababa;
      margin: 100px auto 0 auto; }
      #my-subscription .cancel-deadline-modal-wrapper .cancel-deadline-modal .deadline-button-keep, #my-subscription .cancel-deadline-modal-wrapper .cancel-deadline-modal .deadline-button-restore, #my-subscription .cancel-deadline-modal-wrapper .restore-deadline-modal .deadline-button-keep, #my-subscription .cancel-deadline-modal-wrapper .restore-deadline-modal .deadline-button-restore, #my-subscription .restore-deadline-modal-wrapper .cancel-deadline-modal .deadline-button-keep, #my-subscription .restore-deadline-modal-wrapper .cancel-deadline-modal .deadline-button-restore, #my-subscription .restore-deadline-modal-wrapper .restore-deadline-modal .deadline-button-keep, #my-subscription .restore-deadline-modal-wrapper .restore-deadline-modal .deadline-button-restore {
        border-radius: 0;
        border: 0;
        font-size: 16px;
        font-family: "sofia-pro", sans-serif;
        font-weight: 700;
        min-width: 200px;
        padding-top: 3px;
        padding-bottom: 3px;
        width: 280px; }
      #my-subscription .cancel-deadline-modal-wrapper .cancel-deadline-modal .deadline-button-cancel, #my-subscription .cancel-deadline-modal-wrapper .cancel-deadline-modal .deadline-button-let, #my-subscription .cancel-deadline-modal-wrapper .restore-deadline-modal .deadline-button-cancel, #my-subscription .cancel-deadline-modal-wrapper .restore-deadline-modal .deadline-button-let, #my-subscription .restore-deadline-modal-wrapper .cancel-deadline-modal .deadline-button-cancel, #my-subscription .restore-deadline-modal-wrapper .cancel-deadline-modal .deadline-button-let, #my-subscription .restore-deadline-modal-wrapper .restore-deadline-modal .deadline-button-cancel, #my-subscription .restore-deadline-modal-wrapper .restore-deadline-modal .deadline-button-let {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer; }
  #my-subscription .subscription-deadline-error {
    margin-bottom: 10px;
    display: none; }
  #my-subscription #modifier-frequence-livraison {
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300; }
    #my-subscription #modifier-frequence-livraison .subscription-top-text {
      font-size: 14px;
      width: 515px;
      margin: 0 auto; }
      @media (max-width: 562px) {
        #my-subscription #modifier-frequence-livraison .subscription-top-text {
          width: 100%; } }
    #my-subscription #modifier-frequence-livraison .subscription-options-wrapper {
      width: 385px;
      margin: 50px auto; }
      #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option {
        border: 2px solid #dcdcdd;
        width: 180px;
        height: 180px;
        display: inline-block;
        vertical-align: top;
        padding: 40px 5px 5px 5px;
        margin-right: 10px;
        text-align: center;
        position: relative;
        cursor: pointer;
        transition: all ease 0.1s;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option:hover {
          background: #e7e8e9; }
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option.selected-subscription {
          background: #ff988c;
          border-color: #ff988c;
          color: #fff; }
          #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option.selected-subscription:hover {
            background: #ff897b; }
          #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option.selected-subscription .most-popular:before {
            border-color: white transparent transparent transparent;
            border-width: 23px 23px 23px 0;
            left: 0;
            top: 0; }
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option .subscription-option-check {
          display: none; }
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option.selected-subscription .subscription-option-check {
          display: block;
          background: #fff;
          width: 30px;
          height: 30px;
          border-radius: 30px;
          margin: 0 auto;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 10px;
          color: #ff988c;
          line-height: 30px;
          font-size: 22px; }
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option:last-child {
          margin-right: 0; }
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option .most-popular {
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          font-family: "sofia-pro", sans-serif;
          font-weight: 700;
          padding-top: 5px;
          font-size: 16px; }
          #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option .most-popular:before {
            content: "\A";
            border-style: solid;
            border-width: 25px 25px 25px 0;
            border-color: black transparent transparent transparent;
            position: absolute;
            left: -1px;
            top: -1px; }
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option .subscription-option-title {
          font-weight: bold;
          font-family: "DomaineDisp-Regular", sans-serif;
          font-size: 20px;
          margin-bottom: 10px; }
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option .subscription-option-text {
          font-size: 13px;
          font-family: 'sofia-pro', sans-serif;
          font-weight: 300; }
      @media (max-width: 992px) {
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper {
          width: 515px;
          margin: 25px auto; }
          #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option {
            width: 250px;
            margin-bottom: 10px;
            height: 160px; }
            #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option:nth-child(2), #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option:nth-child(4) {
              margin-right: 0; } }
      @media (max-width: 562px) {
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper {
          width: 100%;
          margin: 25px auto; }
          #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option {
            width: 100%;
            margin: 0 auto 20px auto;
            display: block;
            height: 110px;
            padding-top: 30px;
            transition: all ease 0.1s; }
            #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option.selected-subscription {
              height: 140px; }
            #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option.selected-subscription .most-popular:before {
              border-width: 13px 13px 13px 0; }
            #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option .most-popular {
              /*text-align: left;*/
              padding-left: 10px;
              padding-top: 3px;
              font-size: 14px; }
              #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option .most-popular:before {
                border-width: 15px 15px 15px 0; } }
      @media (max-width: 436px) {
        #my-subscription #modifier-frequence-livraison .subscription-options-wrapper .subcription-option .most-popular {
          padding-top: 3px;
          font-size: 14px; } }
    #my-subscription #modifier-frequence-livraison .subscription-bottom-text {
      font-size: 14px;
      width: 515px;
      margin: 0 auto; }
      @media (max-width: 562px) {
        #my-subscription #modifier-frequence-livraison .subscription-bottom-text {
          width: 100%; } }

#modifier-mode-livraison .tooltip {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; }
#modifier-mode-livraison .tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #fadfda;
  color: #000;
  font-size: 0.8em;
  text-align: center;
  padding: 10px 2px;
  z-index: 1;
  top: 130%;
  margin-left: 2px; }
#modifier-mode-livraison .tooltip-absolute {
  position: absolute; }
#modifier-mode-livraison .tooltip-bloc {
  position: relative; }
#modifier-mode-livraison .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 30%;
  border-width: 11px;
  border-style: solid;
  border-color: transparent transparent #fadfda transparent; }
#modifier-mode-livraison #widthdrawal-tooltip::after {
  margin-left: -280px; }
#modifier-mode-livraison #home-tooltip::after {
  margin-left: 305px; }
  @media (max-width: 700px) {
    #modifier-mode-livraison #home-tooltip::after {
      margin-left: 100px; } }
#modifier-mode-livraison .tooltip .tooltiptext_hover {
  visibility: visible;
  opacity: 1; }

#logo {
  margin: 0 auto;
  display: block;
  opacity: 0.5;
  max-width: 200px;
  width: 100%;
  margin-bottom: 40px; }

#logo img {
  display: block;
  width: 100%;
  height: auto; }

#maintenance-content {
  max-width: 980px;
  margin: 0 auto;
  text-align: center; }

#maintenance-content .entete {
  padding: 20px; }

#maintenance-content .entete h1 {
  color: #4F9893;
  font-size: 26px;
  font-weight: normal;
  padding-bottom: 20px;
  text-transform: uppercase; }

#maintenance-content .entete p {
  font-size: 18px;
  font-weight: normal;
  display: block; }

#mentions-content .entete {
  padding-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #949494;
  margin-bottom: 40px; }

#mentions-content .entete p {
  font-size: 18px;
  font-weight: normal;
  display: block; }

#mentions-content .content h2 {
  font-weight: normal;
  margin: 22px 0;
  color: #4F9893; }

#mentions-content .content h2:first-child {
  margin-top: 0; }

#mentions-content .content p {
  margin-bottom: 15px;
  color: #777; }

#mentions-content .content a {
  color: #4F9893; }

#payer-ma-selection #payer-ma-selection-formulaire {
  max-width: 900px; }
  #payer-ma-selection #payer-ma-selection-formulaire .order-payment-collapsible-block {
    color: #000; }
    #payer-ma-selection #payer-ma-selection-formulaire .order-payment-collapsible-block .subtitle-payment {
      font-family: "sofia-pro", sans-serif;
      font-weight: 700;
      margin-bottom: 10px;
      cursor: pointer; }
      #payer-ma-selection #payer-ma-selection-formulaire .order-payment-collapsible-block .subtitle-payment .logo-card {
        width: 25px;
        vertical-align: middle; }
    #payer-ma-selection #payer-ma-selection-formulaire .order-payment-collapsible-block .same-payment-method-check {
      color: #00c100;
      margin-right: 5px; }
    #payer-ma-selection #payer-ma-selection-formulaire .order-payment-collapsible-block #cardBrand {
      margin-right: 4px; }
    #payer-ma-selection #payer-ma-selection-formulaire .order-payment-collapsible-block #cardNumber {
      margin-right: 13px; }
  #payer-ma-selection #payer-ma-selection-formulaire .order-payment-collapsible-block-separator {
    margin: 15px 0 10px 0;
    background: black;
    border: none;
    height: 1px; }

/*
-----------------
Commander ma box
-----------------
*/
#commander-ma-box-split {
  color: #000; }
  #commander-ma-box-split .relay_wrapper .leaflet-popup {
    width: 300px;
    height: 150px;
    left: -150px !important;
    bottom: 80px !important; }
  #commander-ma-box-split .relay_wrapper .MR-Widget.MR-Widget-Responsive .leaflet-popup-content .InfoWindow {
    width: auto !important; }
  #commander-ma-box-split .flash-message-block .flash-message-block-child {
    height: 90px;
    position: relative; }
    #commander-ma-box-split .flash-message-block .flash-message-block-child > .container {
      position: absolute;
      top: 120px;
      left: 0;
      right: 0;
      max-width: 685px; }
      #commander-ma-box-split .flash-message-block .flash-message-block-child > .container > .c-block {
        padding: 10px 30px; }
  #commander-ma-box-split #steps-previous-link {
    width: 650px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      #commander-ma-box-split #steps-previous-link {
        width: 100%; } }
  #commander-ma-box-split #steps-dots-wrapper {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100vw;
    height: 50px;
    z-index: 10;
    min-width: 320px;
    max-width: 100%; }
    #commander-ma-box-split #steps-dots-wrapper::before {
      content: "";
      width: 100vw;
      max-width: 100%;
      height: 4px;
      background: #f29d8f;
      display: block;
      position: absolute;
      top: 8px; }
    #commander-ma-box-split #steps-dots-wrapper #steps-dots {
      width: 620px;
      max-width: 100%;
      position: relative;
      margin: 0 auto; }
      #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot {
        text-align: center;
        vertical-align: top;
        position: absolute;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 300;
        font-size: 14px; }
        #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot::before {
          content: "";
          width: 20px;
          height: 20px;
          background: #f29d8f;
          border-radius: 20px;
          display: block;
          margin: 0 auto; }
        #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot#steps-dot-1 {
          left: 0; }
        #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot#steps-dot-2 {
          left: 272px; }
        #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot#steps-dot-3 {
          right: 0; }
        @media (max-width: 640px) {
          #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot#steps-dot-1 {
            left: 10px; }
          #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot#steps-dot-2 {
            left: 40.5%; }
          #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot#steps-dot-3 {
            right: 10px; } }
        #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot.checked {
          color: #cdd0cc; }
          #commander-ma-box-split #steps-dots-wrapper #steps-dots .steps-dot.checked::before {
            font-family: FontAwesome;
            content: "\f00c";
            width: 20px;
            height: 20px;
            background: #cdd0cc;
            border-radius: 20px;
            display: block;
            margin: 0 auto;
            color: #fff;
            font-size: 12px;
            line-height: 20px; }
      #commander-ma-box-split #steps-dots-wrapper #steps-dots #steps-go-back {
        position: absolute;
        left: -50px;
        top: 50px; }
        @media (max-width: 720px) {
          #commander-ma-box-split #steps-dots-wrapper #steps-dots #steps-go-back {
            left: 5px; } }
  #commander-ma-box-split .step-content-wrapper {
    padding-top: 60px;
    min-width: 320px; }
    @media (max-width: 600px) {
      #commander-ma-box-split .step-content-wrapper.step-1-wrapper {
        padding-top: 10px; } }
    #commander-ma-box-split .step-content-wrapper #step-title {
      text-align: center;
      font-size: 30px;
      color: #000;
      font-family: 'sofia-pro', sans-serif;
      font-weight: 400;
      margin-bottom: 30px; }
      #commander-ma-box-split .step-content-wrapper #step-title.step-title-left {
        text-align: left;
        padding-left: 15px; }
    #commander-ma-box-split .step-content-wrapper #step-content {
      padding: 20px 0 70px 0;
      font-family: 'sofia-pro', sans-serif;
      font-weight: 400; }
      #commander-ma-box-split .step-content-wrapper #step-content #cgv-block {
        margin: 0 auto 15px; }
        #commander-ma-box-split .step-content-wrapper #step-content #cgv-block .c-form__line .c-form__field--checkbox .c-form__label {
          font-family: 'sofia-pro', sans-serif;
          font-weight: 400;
          cursor: pointer; }
          #commander-ma-box-split .step-content-wrapper #step-content #cgv-block .c-form__line .c-form__field--checkbox .c-form__label a {
            color: #FF5859;
            text-decoration: underline;
            font-family: 'sofia-pro', sans-serif;
            font-weight: 400;
            cursor: pointer;
            font-size: 14px; }
        #commander-ma-box-split .step-content-wrapper #step-content #cgv-block .c-form__line .c-form__info--error {
          text-align: left;
          display: block;
          border-radius: 4px;
          position: relative;
          margin-top: 5px;
          border: 1px solid #FF0000;
          padding: 15px;
          color: #FF0000;
          font-size: 14px;
          font-family: 'sofia-pro', sans-serif;
          font-weight: 400; }
      @media (max-width: 800px) {
        #commander-ma-box-split .step-content-wrapper #step-content {
          padding-bottom: 0; } }
      #commander-ma-box-split .step-content-wrapper #step-content #step-1-left-panel {
        display: inline-block;
        vertical-align: top;
        border-right: 3px solid #dadada;
        padding-top: 14px;
        padding-right: 60px;
        width: 640px; }
        @media (max-width: 1200px) {
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-left-panel {
            width: 500px; } }
        @media (max-width: 1000px) {
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-left-panel {
            width: 400px; } }
        @media (max-width: 800px) {
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-left-panel {
            width: 480px;
            padding: 10px;
            margin: 0 auto;
            display: block;
            border-right: none;
            border-bottom: 3px solid #dadada; } }
        @media (max-width: 500px) {
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-left-panel {
            width: 100%; } }
        #commander-ma-box-split .step-content-wrapper #step-content #step-1-left-panel .left-panel-dots-wrapper {
          position: relative;
          padding-left: 50px;
          margin-bottom: 30px; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-left-panel .left-panel-dots-wrapper .step-dot {
            position: absolute;
            left: 0;
            top: 0;
            background: #f5a89a;
            width: 30px;
            height: 30px;
            display: inline-block;
            border-radius: 60px;
            line-height: 30px;
            text-align: center;
            font-family: 'sofia-pro', sans-serif;
            font-weight: 700;
            font-size: 20px;
            color: #fff; }
      #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel {
        display: inline-block;
        vertical-align: top;
        width: 485px;
        text-align: center;
        padding-left: 10px; }
        #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel img {
          width: 150px;
          margin-bottom: 52px;
          margin-top: 30px; }
        @media (max-width: 1200px) {
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel {
            width: 400px; } }
        @media (max-width: 1000px) {
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel {
            width: 300px; } }
        @media (max-width: 800px) {
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel {
            width: 480px;
            margin: 0 auto;
            display: block; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel #package-photo {
              display: inline-block;
              vertical-align: top; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel #package-photo img {
                width: 200px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel #package-text-wrapper {
              display: inline-block;
              width: 260px;
              vertical-align: top;
              padding-top: 80px; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel #package-text-wrapper #package-text-2 {
                font-size: 12px; } }
        @media (max-width: 500px) {
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel {
            width: 100%; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel #package-photo {
              display: block;
              vertical-align: top; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel #package-photo img {
                width: 200px;
                margin-bottom: 0; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel #package-text-wrapper {
              display: block;
              width: 100%;
              vertical-align: top;
              padding-top: 0;
              margin-bottom: 50px; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-right-panel #package-text-wrapper #package-text-2 {
                font-size: 12px; } }
      #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel {
        text-align: center; }
        #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel img {
          width: 200px;
          margin-bottom: 20px; }
        #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #step-delivery-week {
          font-size: 24px;
          font-family: 'sofia-pro', sans-serif;
          font-weight: 300;
          margin-bottom: 30px; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #step-delivery-week .c-datepicker__select {
            font-size: 28px; }
        #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #datepicker_commande {
          width: 560px;
          margin: 0 auto; }
          @media (max-width: 768px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #datepicker_commande {
              width: 100%; } }
          @media (max-width: 550px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #datepicker_commande {
              padding: 30px 15px; } }
          @media (max-width: 768px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #datepicker_commande.hasSalesPeriod .sale:first-child::before {
              left: 1px;
              width: 50px; } }
          @media (max-width: 570px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #datepicker_commande.hasSalesPeriod .sale:first-child::before {
              font-size: 12px;
              width: 40px; } }
          @media (max-width: 550px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #datepicker_commande.hasSalesPeriod {
              padding: 30px 5px 30px 42px; } }
        #commander-ma-box-split .step-content-wrapper #step-content #step-2-panel #step-delivery-week-info {
          font-size: 16px;
          font-family: 'sofia-pro', sans-serif;
          font-weight: 300; }
      #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel {
        font-family: 'sofia-pro', sans-serif;
        font-weight: 300; }
        #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-top-text {
          font-size: 14px;
          width: 515px;
          margin: 0 auto;
          text-align: center; }
          @media (max-width: 562px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-top-text {
              width: 100%; } }
        #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper {
          width: 385px;
          margin: 50px auto; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option {
            border: 2px solid #dcdcdd;
            width: 180px;
            height: 180px;
            display: inline-block;
            vertical-align: top;
            padding: 40px 5px 5px 5px;
            margin-right: 10px;
            text-align: center;
            position: relative;
            cursor: pointer;
            transition: all ease 0.1s;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option:hover {
              background: #e7e8e9; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option.selected-subscription {
              background: #ff988c;
              border-color: #ff988c;
              color: #fff; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option.selected-subscription:hover {
                background: #ff897b; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option.selected-subscription .most-popular:before {
                border-color: white transparent transparent transparent;
                border-width: 23px 23px 23px 0;
                left: 0;
                top: 0; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option .subscription-option-check {
              display: none; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option.selected-subscription .subscription-option-check {
              display: block;
              background: #fff;
              width: 30px;
              height: 30px;
              border-radius: 30px;
              margin: 0 auto;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 10px;
              color: #ff988c;
              line-height: 30px;
              font-size: 22px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option:last-child {
              margin-right: 0; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option .most-popular {
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
              font-family: 'sofia-pro', sans-serif;
              font-weight: 700;
              padding-top: 5px;
              font-size: 16px; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option .most-popular:before {
                content: "\A";
                border-style: solid;
                border-width: 25px 25px 25px 0;
                border-color: black transparent transparent transparent;
                position: absolute;
                left: -1px;
                top: -1px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option .subscription-option-title {
              font-family: 'sofia-pro', sans-serif;
              font-weight: 700;
              font-size: 20px;
              margin-bottom: 10px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option .subscription-option-text {
              font-size: 13px;
              font-family: 'sofia-pro', sans-serif;
              font-weight: 300; }
          @media (max-width: 992px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper {
              width: 515px;
              margin: 25px auto; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option {
                width: 250px;
                margin-bottom: 10px;
                height: 160px; }
                #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option:nth-child(2), #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option:nth-child(4) {
                  margin-right: 0; } }
          @media (max-width: 562px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper {
              width: 100%;
              margin: 25px auto; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option {
                width: 100%;
                margin: 0 auto 20px auto;
                display: block;
                height: 110px;
                padding-top: 30px;
                transition: all ease 0.1s; }
                #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option.selected-subscription {
                  height: 140px; }
                #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option.selected-subscription .most-popular:before {
                  border-width: 13px 13px 13px 0; }
                #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option .most-popular {
                  /*text-align: left;*/
                  padding-left: 10px;
                  padding-top: 3px;
                  font-size: 14px; }
                  #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option .most-popular:before {
                    border-width: 15px 15px 15px 0; } }
          @media (max-width: 436px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-options-wrapper .subcription-option .most-popular {
              padding-top: 3px;
              font-size: 14px; } }
        #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-bottom-text {
          font-size: 14px;
          width: 515px;
          margin: 0 auto; }
          @media (max-width: 562px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-1-2-panel .subscription-bottom-text {
              width: 100%; } }
      #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel {
        text-align: center; }
        #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv {
          border-bottom: none; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap {
            background: #f4f4f4;
            padding: 15px;
            text-align: left; }
            @media (min-width: 769px) {
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap .recap-subscription-frequency {
                width: 550px;
                margin: 0 auto;
                display: block; } }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap > table {
              width: 100%; }
              @media (min-width: 769px) {
                #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap > table {
                  width: 550px;
                  margin: 0 auto; } }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap > table.border-bottom-gray {
                border-bottom: 2px solid #bcbfbd; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap > table tr td {
                text-align: left;
                font-size: 18px;
                font-family: 'sofia-pro', sans-serif;
                font-weight: 300;
                padding-bottom: 20px; }
                #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap > table tr td.second-td {
                  text-align: right; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap > table tr .total-margin {
                padding-bottom: 0px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap > table.credit-referral {
              color: grey; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-block--nv #stylism-fees-recap > table.credit-referral td {
                padding-top: 20px;
                font-size: 16px;
                max-width: 100px;
                padding-bottom: 0px; }
        #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper {
          padding: 10px;
          border: 1px solid #e5e5e5; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block-title {
            text-align: left;
            font-size: 32px;
            font-family: 'sofia-pro', sans-serif;
            font-weight: 400;
            margin-bottom: 10px;
            padding-left: 15px;
            cursor: pointer; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block.last-step-collapsible-block-collapsed {
            display: none; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper {
            box-sizing: border-box; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .tooltip {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .tooltip .tooltiptext {
              visibility: hidden;
              width: 100%;
              background-color: #fadfda;
              color: #000;
              font-size: 0.8em;
              text-align: center;
              padding: 10px 2px;
              z-index: 1;
              top: 130%;
              margin-left: 2px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .tooltip-absolute {
              position: absolute; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .tooltip-bloc {
              position: relative; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .tooltip .tooltiptext::after {
              content: "";
              position: absolute;
              bottom: 100%;
              left: 30%;
              border-width: 11px;
              border-style: solid;
              border-color: transparent transparent #fadfda transparent; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper #widthdrawal-tooltip::after {
              margin-left: -5px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper #home-tooltip::after {
              margin-left: 305px; }
              @media (max-width: 700px) {
                #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper #home-tooltip::after {
                  margin-left: 100px; } }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .tooltip .tooltiptext_hover {
              visibility: visible;
              opacity: 1; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper:first-child {
              padding-right: 5px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper:nth-child(2) {
              padding-right: 5px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .fa-leaf {
              color: #8cc646; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .c-delivery-mode-toggle-parent {
              width: 50%; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .c-delivery-mode-toggle {
              width: 100%; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-delivery-mode-toggle-wrapper .c-delivery-mode-toggle-label {
              padding-left: 20px; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-btn--red, #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-btn--black {
            font-family: 'sofia-pro', sans-serif;
            font-weight: 700;
            font-size: 16px;
            min-width: 270px;
            width: auto;
            border: none;
            padding: 5px 0; }
            @media (max-width: 600px) {
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-btn--red, #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block .c-btn--black {
                width: 100%; } }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-home-panel {
            margin-top: 10px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-home-panel #address-recap-block {
              border: 1px solid #e5e5e5;
              line-height: 40px;
              padding-left: 10px;
              padding-right: 75px;
              position: relative;
              text-align: left; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-home-panel #address-recap-block #modify-address-button {
                position: absolute;
                right: 10px;
                top: 0;
                color: #939192;
                font-size: 12px;
                cursor: pointer; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .c-form__line {
            width: 33%; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .c-form__line .c-form__input {
              border-radius: 0;
              background: #ffffff;
              padding-right: 10px;
              background-origin: content-box;
              height: 40px;
              line-height: 40px; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .c-form__line .c-form__input:hover, #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .c-form__line .c-form__input:focus, #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .c-form__line .c-form__input:active {
                background-repeat: no-repeat;
                background-position: right center; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .c-btn--white, #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .c-btn--black {
            width: 100%;
            height: 50px; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .gm-bundled-control .gmnoprint {
            transform: scale(0.9);
            transform-origin: top left;
            left: 15px !important;
            top: 22px !important; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel .gm-bundled-control .gmnoprint div {
              transform: scale(0.9);
              transform-origin: top left; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel #map {
            height: 340px; }
            @media (min-width: 768px) {
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #deliver-withdrawal-point-panel #map {
                height: 440px; } }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form {
            padding-top: 10px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div {
              margin: 0 auto;
              font-family: 'sofia-pro', sans-serif;
              font-weight: 300; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .wrap {
                max-width: 410px;
                margin-left: auto;
                margin-right: auto; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .kr-payment-button {
                background: #ffab9e !important;
                text-align: center;
                font-family: 'sofia-pro', sans-serif;
                font-weight: 700;
                font-size: 16px;
                transition: all ease 0.3s;
                width: 270px;
                display: block;
                margin: 0 auto;
                cursor: pointer;
                padding: 0 15px;
                height: 40px;
                white-space: nowrap; }
                @media (max-width: 480px) {
                  #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .kr-payment-button {
                    width: 100% !important; } }
                #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .kr-payment-button:focus {
                  box-shadow: none; }
                #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .kr-payment-button:hover {
                  background: #FF8573 !important; }
                #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .kr-payment-button > span {
                  font-family: 'sofia-pro', sans-serif;
                  font-weight: 700;
                  font-size: 16px; }
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .kr-form-error {
                text-align: left !important;
                border-radius: 4px !important;
                position: relative !important;
                margin-top: 15px !important;
                border: 1px solid #FF0000 !important;
                color: #FF0000 !important;
                background: #ffffff !important;
                font-size: 14px !important;
                padding: 10px !important; }
                #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .kr-form-error span {
                  padding: 0 !important; }
                  #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .last-step-collapsible-block-wrapper .last-step-collapsible-block #payzen-payment-form .kr-smart-form > div .kr-form-error span span {
                    padding: 0 !important;
                    color: #FF0000 !important; }
        #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-useSamePaymentMethod {
          max-width: 410px;
          width: 100%;
          margin: 20px auto;
          text-align: left; }
          @media (max-width: 620px) {
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-useSamePaymentMethod {
              width: 100%; } }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-useSamePaymentMethod #saved-payment-card {
            display: none;
            border: 1px solid rgba(50, 50, 93, 0.1);
            padding: 5px 10px;
            width: 410px;
            position: relative;
            margin-top: 10px; }
            @media (max-width: 340px) {
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-useSamePaymentMethod #saved-payment-card {
                width: 100%; } }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-useSamePaymentMethod #saved-payment-card #saved-payment-card-img {
              position: absolute;
              right: 5px;
              top: 8px;
              width: 25px; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-useSamePaymentMethod #validate-same-payment-method {
            font-family: 'sofia-pro', sans-serif;
            font-weight: 700;
            font-size: 16px;
            width: 270px;
            display: block;
            margin: 0 auto; }
            @media (max-width: 480px) {
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-useSamePaymentMethod #validate-same-payment-method {
                width: 100%; } }
        #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-use-same-payment-method-parent {
          max-width: 410px;
          width: 100%;
          margin: 0 auto;
          font-size: 14px; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-use-same-payment-method-parent label {
            text-align: left; }
          #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-use-same-payment-method-parent .c-use-same-payment-method-wrapper {
            width: 50%;
            display: inline-block; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-use-same-payment-method-parent .c-use-same-payment-method-wrapper:first-child {
              padding-right: 5px; }
            #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-use-same-payment-method-parent .c-use-same-payment-method-wrapper:nth-child(2) {
              padding-left: 5px; }
            @media (max-width: 620px) {
              #commander-ma-box-split .step-content-wrapper #step-content #step-3-panel .c-use-same-payment-method-parent .c-use-same-payment-method-wrapper {
                width: 100%; } }
    #commander-ma-box-split .step-content-wrapper #next-button-wrapper {
      text-align: center;
      margin-bottom: 55px; }
      #commander-ma-box-split .step-content-wrapper #next-button-wrapper #spinner {
        height: 40px;
        min-width: 270px;
        border-radius: 0;
        border: 0;
        font-size: 20px; }
        @media (max-width: 480px) {
          #commander-ma-box-split .step-content-wrapper #next-button-wrapper #spinner {
            width: 100%; } }
      #commander-ma-box-split .step-content-wrapper #next-button-wrapper #next-step-button {
        border-radius: 0;
        border: 0;
        font-size: 16px;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 700;
        min-width: 270px; }
        @media (max-width: 480px) {
          #commander-ma-box-split .step-content-wrapper #next-button-wrapper #next-step-button {
            width: 100%; } }
      #commander-ma-box-split .step-content-wrapper #next-button-wrapper #select-other-date-calendar {
        border-radius: 0;
        font-size: 16px;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 400;
        min-width: 270px; }
        @media (max-width: 480px) {
          #commander-ma-box-split .step-content-wrapper #next-button-wrapper #select-other-date-calendar {
            width: 100%; } }
  #commander-ma-box-split .address-container {
    width: 700px; }
    @media (max-width: 768px) {
      #commander-ma-box-split .address-container {
        width: 100%; } }
    #commander-ma-box-split .address-container .step-content-wrapper .delivery-options-wrapper {
      margin-bottom: 30px; }
    @media (max-width: 768px) {
      #commander-ma-box-split .address-container .step-content-wrapper {
        width: 500px;
        margin: 0 auto; }
        #commander-ma-box-split .address-container .step-content-wrapper #delivery-options {
          /*width: 500px;*/ } }
    @media (max-width: 767px) {
      #commander-ma-box-split .address-container .step-content-wrapper #delivery-options {
        /*width: 250px;*/ } }
    @media (max-width: 530px) {
      #commander-ma-box-split .address-container .step-content-wrapper {
        width: 100%;
        min-width: 305px; } }
  #commander-ma-box-split .subscription-popup-background {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(37, 40, 45, 0.7);
    z-index: 10;
    display: none; }
    #commander-ma-box-split .subscription-popup-background .subscription-popup {
      width: 750px;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 200px;
      position: fixed;
      background: #fff;
      border: 2px solid #dcdcdd;
      padding: 20px 30px; }
      #commander-ma-box-split .subscription-popup-background .subscription-popup .subscription-popup-title {
        text-align: left;
        font-size: 30px;
        color: #000;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 700;
        margin-bottom: 20px; }
      #commander-ma-box-split .subscription-popup-background .subscription-popup .subscription-button-keep {
        border-radius: 0;
        border: 0;
        font-size: 16px;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 700;
        min-width: 200px;
        padding-top: 3px;
        padding-bottom: 3px;
        width: 280px; }
      #commander-ma-box-split .subscription-popup-background .subscription-popup .subscription-button-cancel {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer; }
      @media (max-width: 750px) {
        #commander-ma-box-split .subscription-popup-background .subscription-popup {
          width: 100%; } }

#stripe-payment-form {
  width: 100%;
  align-self: center;
  box-shadow: none;
  background-color: #fff;
  margin: 0 auto;
  max-width: 410px;
  /* spinner/processing state, errors */ }
  @media (max-width: 992px) {
    #stripe-payment-form {
      padding: 10px 0; } }
  #stripe-payment-form .bordered-label {
    padding: 5px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #F08366;
    color: #F08366; }
  #stripe-payment-form .c-form__field--radio {
    margin-bottom: 15px; }
    #stripe-payment-form .c-form__field--radio > div {
      display: inline-block; }
      @media (max-width: 1200px) {
        #stripe-payment-form .c-form__field--radio > div {
          width: 80px;
          max-width: none; } }
      @media (max-width: 356px) {
        #stripe-payment-form .c-form__field--radio > div {
          padding: 0;
          width: 70px; }
          #stripe-payment-form .c-form__field--radio > div > .c-form__label {
            padding-left: 20px; } }
  #stripe-payment-form #submit {
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700;
    width: 100%;
    font-size: 16px; }
  #stripe-payment-form input[type=tel], #stripe-payment-form input[type=text] {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    font-size: 16px;
    width: 100%;
    background: white; }
  #stripe-payment-form .result-message {
    line-height: 22px;
    font-size: 16px; }
  #stripe-payment-form .result-message a {
    color: #596fd6;
    font-weight: 600;
    text-decoration: none; }
  #stripe-payment-form .hidden {
    display: none; }
  #stripe-payment-form #card-error {
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #FF0000;
    padding: 15px;
    color: #FF0000;
    display: none; }
  #stripe-payment-form #card-wrapper #card-element-number, #stripe-payment-form #card-wrapper #card-element-expiry, #stripe-payment-form #card-wrapper #card-element-cvc {
    border-radius: 4px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    width: 100%;
    background: #f9f9f9;
    margin: 10px 0; }
  #stripe-payment-form #payment-request-button {
    margin-bottom: 32px; }
  #stripe-payment-form .spinner,
  #stripe-payment-form .spinner:before,
  #stripe-payment-form .spinner:after {
    border-radius: 50%; }
  #stripe-payment-form .spinner {
    color: #ffffff;
    font-size: 22px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: transparent;
    /*box-shadow: inset 0 0 0 2px;*/
    box-shadow: none;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease; }
    #stripe-payment-form .spinner > img {
      width: 100%;
      height: 100%; }
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  @media only screen and (max-width: 600px) {
    #stripe-payment-form form {
      width: 80vw; } }
  #stripe-payment-form .c--stylism-fees-info .c--stylism-fees-info-line {
    position: relative;
    display: block;
    padding-left: 20px;
    font-size: 14px; }
    #stripe-payment-form .c--stylism-fees-info .c--stylism-fees-info-line .page--order__check {
      position: absolute;
      left: 0;
      top: 3px; }

.page--order-return .c-title {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 700;
  font-size: 28px; }
.page--order-return textarea.c-form__input {
  border-radius: 0;
  background: white;
  resize: none;
  margin-top: 10px; }
.page--order-return .order-validation-left-block {
  text-align: center;
  color: #000; }
  .page--order-return .order-validation-left-block > .c-block {
    box-shadow: none; }
    .page--order-return .order-validation-left-block > .c-block .pink-text {
      color: #f39d8f;
      font-family: 'sofia-pro', sans-serif;
      font-weight: 700; }
    .page--order-return .order-validation-left-block > .c-block .address-text-big {
      font-family: 'sofia-pro', sans-serif;
      font-weight: 400;
      font-size: 24px; }
      .page--order-return .order-validation-left-block > .c-block .address-text-big .pink-text {
        color: #f39d8f;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 400; }
    .page--order-return .order-validation-left-block > .c-block #send-message-styliste {
      border-radius: 0;
      border: 0;
      font-size: 16px;
      font-family: 'sofia-pro', sans-serif;
      font-weight: 700;
      min-width: 200px;
      padding-top: 3px;
      padding-bottom: 3px; }
.page--order-return .order-validation-right-block > .c-block {
  box-shadow: none;
  height: auto;
  border: 1px solid #dadada;
  color: #000;
  padding: 15px;
  position: relative; }
  .page--order-return .order-validation-right-block > .c-block > .referrals-title:after {
    content: "";
    width: 315px;
    height: 15px;
    background: rgba(255, 237, 0, 0.3);
    position: absolute;
    left: 13px;
    top: 35px; }
  .page--order-return .order-validation-right-block > .c-block .pink-text {
    color: #f39d8f;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700; }
  .page--order-return .order-validation-right-block > .c-block .container > .row {
    display: block;
    padding-left: 10px; }
    .page--order-return .order-validation-right-block > .c-block .container > .row > div {
      display: inline-block;
      width: 40px; }
      .page--order-return .order-validation-right-block > .c-block .container > .row > div > .c-referal {
        padding: 0;
        border-radius: 25px; }
        .page--order-return .order-validation-right-block > .c-block .container > .row > div > .c-referal > .c-referal__social {
          border-radius: 25px; }

/*
-----------------
Payer ma box
-----------------
*/
@media screen and (min-width: 767px) {
  .header-categorie .title-recap-mob {
    display: none; } }
#payer-ma-selection-formulaire .c-table__row .c-table__cell:first-child {
  padding: 10px 10px 10px 0; }

#payer-ma-selection-formulaire .c-table__row .c-table__cell:last-child {
  padding: 10px 0 10px 0; }

#payer-ma-selection-formulaire .total {
  padding: 7.5px 0; }

#payer-ma-selection-formulaire hr.line-break {
  background: #ddd;
  height: 1px;
  border-color: transparent;
  margin: 20px 0; }

#payer-ma-selection-formulaire .selection-wrap {
  background-color: #f4f4f4;
  padding: 10px 10px;
  margin: 0 20px 10px 20px; }
#payer-ma-selection-formulaire .c-table {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 200; }
  #payer-ma-selection-formulaire .c-table .c-table__cell {
    font-family: 'sofia-pro', sans-serif;
    font-weight: 200; }
#payer-ma-selection-formulaire .c-title {
  font-size: 1.5rem; }
#payer-ma-selection-formulaire .title-recap-desktop {
  margin-left: 30px; }
@media screen and (max-width: 768px) {
  #payer-ma-selection-formulaire .title-recap-desktop {
    display: none; } }
#payer-ma-selection-formulaire .title-payment {
  margin-left: 20px; }
#payer-ma-selection-formulaire .discount-amount-recap {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  display: inline-block;
  color: #fff;
  border-radius: 4px;
  padding: 0 5px 2px 5px;
  background: #26CB78;
  white-space: nowrap; }
#payer-ma-selection-formulaire .price {
  white-space: nowrap; }
#payer-ma-selection-formulaire .total {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  display: flex;
  justify-content: space-between; }
  #payer-ma-selection-formulaire .total .price,
  #payer-ma-selection-formulaire .total .name {
    display: inline-block;
    font-size: 1rem;
    color: #000000; }
  #payer-ma-selection-formulaire .total .disabled {
    color: #aaaaaa  !important; }
#payer-ma-selection-formulaire .hr-dot {
  margin-left: 0; }
#payer-ma-selection-formulaire .validation-wrap {
  max-width: 525px; }
  #payer-ma-selection-formulaire .validation-wrap .c-btn--red, #payer-ma-selection-formulaire .validation-wrap .c-btn--black {
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700;
    font-size: 16px;
    min-width: 270px;
    width: 100%;
    border: none;
    padding: 5px 0; }
    @media (max-width: 600px) {
      #payer-ma-selection-formulaire .validation-wrap .c-btn--red, #payer-ma-selection-formulaire .validation-wrap .c-btn--black {
        width: 100%; } }
#payer-ma-selection-formulaire .move-back-wrap {
  margin: 0px 20px; }

#payer-ma-selection-formulaire .texte_reduction {
  color: #E91B00;
  font-size: 0.9rem;
  padding-bottom: 1px !important; }

#payer-ma-selection-formulaire .disabled {
  color: #000000A1; }

#payer-ma-selection-formulaire #payzen-payment-form.feedback {
  padding-top: 0px;
  margin-left: 20px;
  margin-right: 20px; }
  #payer-ma-selection-formulaire #payzen-payment-form.feedback .kr-smart-form button {
    margin-top: 0px !important; }

#payer-ma-selection .header-categorie {
  box-shadow: none;
  margin-left: 7.5px; }
  @media (max-width: 992px) {
    #payer-ma-selection .header-categorie {
      text-align: left; } }
  @media (max-width: 768px) {
    #payer-ma-selection .header-categorie {
      text-align: left; } }
  @media (max-width: 768px) {
    #payer-ma-selection .header-categorie {
      text-align: left; } }
  #payer-ma-selection .header-categorie .bi-chevron-left {
    margin-left: -20px; }
  #payer-ma-selection .header-categorie .bi-chevron-left:hover {
    transform: none;
    scale: 1.2; }
  #payer-ma-selection .header-categorie .header-wrap {
    margin: 0px 10px 0px 10px;
    text-align: left; }
    @media (min-width: 768px) {
      #payer-ma-selection .header-categorie .header-wrap {
        text-align: center;
        margin-right: auto;
        margin-left: auto;
        width: auto; } }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    #payer-ma-selection .header-categorie .header-wrap {
      margin-left: 25px; } }
  #payer-ma-selection .header-categorie .c-title {
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700; }
  #payer-ma-selection .header-categorie .text {
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300;
    color: #000000;
    font-size: 1.25rem; }
    #payer-ma-selection .header-categorie .text .info-isReferred {
      color: #E91B00; }

#payer-ma-selection .page-success {
  margin-top: 20px; }
#payer-ma-selection .facebook {
  background: #3b5998;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px; }
  #payer-ma-selection .facebook:hover {
    background: #2d4373; }
  #payer-ma-selection .facebook i {
    margin-right: 5px; }

.gray-underline-link {
  text-decoration: underline;
  color: #000; }

.next-deadline-display {
  font-size: 26px; }
  .next-deadline-display .pink-text {
    color: #ff988c; }

.fake-link {
  font-size: 13px;
  cursor: pointer; }

.fake-link:hover {
  text-decoration: underline; }

.ui-dialog-titlebar-close {
  background-image: url("/public/css/images/ui-icons_444444_256x240.png");
  background-position: -95px -127px;
  border: 1px solid #ccc;
  cursor: pointer; }

#open-colissimo-pass-panel {
  position: absolute;
  right: 10px;
  top: 3px; }

#open-colissimo-pass {
  padding-top: 10px;
  position: relative;
  padding-left: 20px; }

.c--colissimo-pass-logo {
  width: 15px;
  position: absolute;
  top: 10px;
  left: 0; }

.c--colissimo-pass-logo-inline {
  width: 15px;
  position: absolute;
  top: 0px;
  left: 0; }

.c--colissimo-pass-info {
  font-size: 13px;
  padding: 25px 15px 15px 15px;
  box-shadow: none;
  border-radius: 4px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  /*display: none;*/ }

/*.c--colissimo-pass-info.open{
	display: block;
}*/
@media screen and (max-width: 500px) {
  #open-colissimo-pass-panel {
    top: -30px; } }
@media screen and (min-width: 770px) and (max-width: 990px) {
  #open-colissimo-pass-panel {
    top: -30px; } }
.colissimo-pass-response {
  width: 100%;
  text-align: center;
  padding-top: 20px; }

.c--colissimo-pass-link {
  text-decoration: underline dashed;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  padding-left: 18px;
  color: #555;
  cursor: pointer; }

.c--stylism-fees-more-info, .c--stylism-fees-less-info {
  color: #FF5859;
  text-decoration: underline;
  cursor: pointer;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  padding-left: 25px; }

.c--stylism-fees-more-info:hover, .c--stylism-fees-less-info:hover {
  color: #be0001; }

.c--stylism-fees-info {
  border-top: 1px solid #ddd;
  margin: 20px 0;
  padding: 20px 20px 0 25px; }

.c--error-message {
  border: 1px solid #ff5859;
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 2px 4px rgba(50, 50, 50, 0.1);
  padding: 20px 40px;
  margin: 10px auto; }

#payment-return .c-title {
  font-size: 1.5rem;
  color: #000000;
  font-family: "sofia-pro", sans-serif;
  font-weight: 700; }
  @media (min-width: 768px) {
    #payment-return .c-title {
      font-size: 2.1rem; } }

#payment-return .text {
  font-size: 1.2rem;
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  color: #000000; }
  @media (min-width: 768px) {
    #payment-return .text {
      font-size: 1.25rem; } }

#payment-return .text.bold {
  font-weight: bold; }

#payment-return .text .badge-alma {
  background-color: #FA5022;
  border-radius: 6px;
  padding: 2px 4px;
  color: white;
  font-weight: bold; }

#block-payment .bloc-shadow {
  margin-bottom: 0px;
  box-shadow: none; }
  @media (min-width: 768px) {
    #block-payment .bloc-shadow {
      margin-bottom: 0px;
      padding-bottom: 0px; } }

#block-payment .articles .hr-line {
  border-top: 1px solid #d2d2d2; }
  @media (min-width: 768px) {
    #block-payment .articles .hr-line {
      padding-bottom: 30px; } }

@media (min-width: 768px) {
  #block-payment .articles .top-line {
    display: none; } }

#block-payment .articles .informations {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    #block-payment .articles .informations {
      margin-bottom: 20px; } }

#block-payment .articles .informations .informations-left-block {
  width: 100%;
  display: flex;
  flex-direction: row; }
  @media (min-width: 768px) {
    #block-payment .articles .informations .informations-left-block {
      width: 50%; } }
  #block-payment .articles .informations .informations-left-block .inner-wrap-left {
    width: 50%; }
  #block-payment .articles .informations .informations-left-block .inner-wrap-right {
    width: 50%;
    text-align: center;
    justify-content: center;
    display: flex; }
    @media (min-width: 768px) {
      #block-payment .articles .informations .informations-left-block .inner-wrap-right {
        justify-content: right;
        padding-right: 20px; } }
    @media (min-width: 992px) {
      #block-payment .articles .informations .informations-left-block .inner-wrap-right {
        justify-content: right;
        padding-right: 40px; } }

#block-payment .articles .informations .prix .discount-amount {
  margin: 0; }

#block-payment .articles .informations .informations-left-block .inner-wrap-right .product-image {
  max-height: 160px; }

#block-payment .informations .titre {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.25rem; }

#block-payment .informations .prix {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 1.25rem; }

#block-payment .informations .original-price-crossed {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 20px; }

#block-payment .articles .informations .je_le_veux {
  position: absolute;
  bottom: 0px;
  text-align: center; }
  @media (min-width: 768px) {
    #block-payment .articles .informations .je_le_veux {
      position: absolute;
      top: 0;
      right: 0; } }

#block-payment .articles .informations .je_le_veux .choix .oui {
  float: left;
  width: 60px;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  cursor: pointer; }

#block-payment .articles .informations .je_le_veux .choix .oui.active {
  background-color: #26CB78;
  color: #FFFFFF;
  cursor: pointer; }

#block-payment .articles .informations .je_le_veux .choix .oui.inactive {
  background-color: #F4F4F4;
  color: #9c9c9c;
  cursor: pointer; }

#block-payment .articles .informations .je_le_veux .choix .non {
  float: left;
  width: 60px;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  cursor: pointer; }

#block-payment .articles .informations .je_le_veux .choix .non.active {
  background-color: #FF9900;
  color: #FFFFFF;
  cursor: pointer; }

#block-payment .articles .informations .je_le_veux .choix .non.inactive {
  background-color: #F4F4F4;
  color: #9c9c9c;
  cursor: pointer; }

#block-payment .articles .informations .je_le_veux .choix .cache {
  position: absolute;
  top: 0;
  left: 0px;
  width: 50px;
  height: 30px;
  background: #eee; }

#block-payment .articles .informations .je_le_veux .choix .cache .lines {
  display: inline-block;
  width: 1px;
  height: 10px;
  vertical-align: middle;
  background: #BABABA;
  top: 10px; }

#block-payment .articles .avis .texte {
  font-size: 16px; }

#block-payment .articles .avis .commentaire {
  width: 100%;
  height: 80px;
  padding: 10px;
  margin: 10px 0 0;
  resize: none;
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

#block-payment .articles .avis .commentaire:focus {
  border: 1px solid #B2DFDB; }

#block-payment .check .informations.feedback {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    #block-payment .check .informations.feedback {
      flex-direction: column-reverse;
      row-gap: 0px; } }
  #block-payment .check .informations.feedback .stylist-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 22px; }
    @media (min-width: 768px) {
      #block-payment .check .informations.feedback .stylist-image {
        margin: 22px; } }
  #block-payment .check .informations.feedback h2 {
    width: 80%;
    font-size: 1.6rem;
    font-family: DomaineDisp-Regular;
    letter-spacing: 0;
    text-align: left; }
    @media (min-width: 768px) {
      #block-payment .check .informations.feedback h2 {
        text-align: center; } }
  #block-payment .check .informations.feedback .single {
    width: 100% !important; }

#block-payment .articles.check .informations .je_le_veux .choix .cache {
  left: 50px; }

#block-payment .articles ::-webkit-input-placeholder,
#block-payment .articles :-moz-placeholder,
#block-payment .articles :-ms-input-placeholder {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  color: #000000; }

#retour-client .bloc-container-retour .container-left {
  display: block;
  width: 100%; }
  @media (min-width: 768px) {
    #retour-client .bloc-container-retour .container-left {
      display: inline-block;
      width: calc(50% - 20px);
      margin-right: 20px; } }
  @media (min-width: 992px) {
    #retour-client .bloc-container-retour .container-left {
      display: inline-block;
      width: calc(50% - 40px);
      margin-right: 40px; } }
#retour-client .bloc-container-retour .container-right {
  display: block;
  width: 100%; }
  @media (min-width: 768px) {
    #retour-client .bloc-container-retour .container-right {
      display: inline-block;
      width: calc(50% - 20px);
      margin-left: 20px; } }
  @media (min-width: 992px) {
    #retour-client .bloc-container-retour .container-right {
      display: inline-block;
      width: calc(50% - 40px);
      margin-left: 40px; } }

#retour-client .bloc .title {
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 1.5rem;
  font-family: "Segoe UI";
  color: #000000; }
  @media (min-width: 768px) {
    #retour-client .bloc .title {
      font-size: 1.25rem; } }
#retour-client .bloc .retour-global {
  font-size: 1.25rem; }
  @media (min-width: 768px) {
    #retour-client .bloc .retour-global {
      text-align: center; } }
#retour-client .bloc .range {
  font-size: 0.7rem; }
#retour-client .bloc .cadre2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px; }
  @media (min-width: 768px) {
    #retour-client .bloc .cadre2 {
      max-width: 453px;
      margin: 0 auto;
      grid-column-gap: 80px; } }
  #retour-client .bloc .cadre2 .choice {
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300; }
#retour-client .bloc .cadre3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px; }
#retour-client .bloc .cadre4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5px; }
#retour-client .bloc .cadre {
  border: none;
  overflow: hidden;
  background: transparent; }
#retour-client .bloc label {
  float: left;
  text-align: center;
  padding: 10px 5px;
  border-right: none;
  background: #F9F9F9;
  font-size: 14px;
  position: relative;
  height: 100%; }
  #retour-client .bloc label:last-child {
    border: none; }
#retour-client .bloc .rating-stars-container {
  width: 70%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    #retour-client .bloc .rating-stars-container {
      width: 300px;
      margin: 0 auto; } }
  #retour-client .bloc .rating-stars-container .stars5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 15px; }
    #retour-client .bloc .rating-stars-container .stars5 label.star {
      float: left;
      text-align: center;
      padding: 10px 0px;
      border-right: none;
      background: transparent;
      font-size: 14px;
      position: relative;
      height: 100%; }
      #retour-client .bloc .rating-stars-container .stars5 label.star:last-child {
        border: none; }
#retour-client input[type="radio"]:not(:checked),
#retour-client input[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
  display: none; }
#retour-client input[type="radio"]:not(:checked) + label,
#retour-client input[type="radio"]:checked + label {
  position: relative;
  cursor: pointer; }
#retour-client input[type="radio"]:checked + label {
  background: #f3a090;
  color: #0f0f0f; }
#retour-client .invisible {
  display: none; }
#retour-client .visible {
  display: inline-block; }
#retour-client .selection-feedback input[type="radio"]:not(:checked) + label {
  background: transparent; }
#retour-client .selection-feedback input[type="radio"]:checked + label {
  background: transparent; }

#payment-return .c-form .title {
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 1.5rem;
  font-family: "Segoe UI";
  color: #000000; }
  @media (min-width: 768px) {
    #payment-return .c-form .title {
      font-size: 1.25rem;
      padding-top: 30px; } }
#payment-return .c-form .retour-global {
  font-size: 1.25rem; }
  @media (min-width: 768px) {
    #payment-return .c-form .retour-global {
      width: 100%;
      text-align: center; } }
#payment-return .c-form__input.c-form__input {
  border-radius: 0px !important;
  border: none !important;
  box-shadow: none !important; }
#payment-return .c-form__input.c-form__input:focus {
  border: 1px solid #ddd !important; }

#resume {
  text-align: left;
  background-color: #f4f4f4;
  margin: 20px; }
  @media (min-width: 768px) {
    #resume {
      margin-right: 30px;
      margin-left: 30px; } }

#resume .bloc {
  padding-bottom: 20px;
  padding-left: 12.5px;
  padding-right: 12.5px; }

#resume .bloc.bloc1, #resume .bloc.bloc2 .js-eligible-to-reduction, #resume .bloc.bloc3 .reduction {
  display: flex;
  justify-content: space-between; }

#resume .bloc.bloc1, #resume .bloc.bloc3 .reduction:not(:first-child) {
  padding-top: 20px; }

#resume .bloc .titre {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: #000000; }

#resume .bloc .price {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: #000000; }

#resume .bloc .texte_reduction {
  font-family: 'sofia-pro', sans-serif;
  font-weight: 300;
  color: #E91B00;
  font-size: 1rem; }

#resume .bloc .c-not-eligible {
  color: RGBA(0, 0, 0, 63) !important; }

#resume .bloc.bloc4 .total-bloc {
  text-align: right;
  padding-top: 20px; }
  #resume .bloc.bloc4 .total-bloc .titre, #resume .bloc.bloc4 .total-bloc .price {
    color: #000000;
    font-family: "sofia-pro", sans-serif;
    font-weight: 700; }

#resume .btn {
  display: block; }

#validate {
  margin: 20px; }
  #validate .c-btn--red {
    display: block;
    font-family: "sofia-pro", sans-serif;
    font-weight: 700;
    font-size: 16px;
    min-width: 270px;
    width: auto;
    border: none;
    margin: 0 auto; }
    @media (max-width: 480px) {
      #validate .c-btn--red {
        width: 100%; } }

@media (max-width: 768px) {
  #block-payment .articles .informations .titre {
    padding-right: 0;
    padding-bottom: 0;
    font-size: 15px !important; } }
#propos-content .job {
  font-size: 16px;
  color: #ff5859; }

#propos-content .texte {
  font-size: 16px;
  padding-top: 5px; }

body {
  background: #F8F8F8; }

#formulaire-style input,
#formulaire-style select {
  outline: none; }

/* SAFARI */
#formulaire-style input,
#formulaire-style input[type="submit"],
#formulaire-style textarea,
#formulaire-style select,
#formulaire-style option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

#formulaire-style {
  margin: 0 auto 20px; }

#formulaire-style .question {
  margin-bottom: 20px; }

#formulaire-style .question.error .question-title {
  color: #f0415b; }

#formulaire-style .question.error textarea,
#formulaire-style .question.error input,
#formulaire-style .question.error .select-content {
  border: 1px solid #f0415b; }

#formulaire-style .question.error .select-content:after {
  border-top: 6px solid #f0415b; }

#formulaire-style .question.error .champ-requis {
  clear: both;
  display: block;
  color: #f0415b;
  padding-top: 10px;
  font-size: 16px;
  font-style: italic; }

#formulaire-style .question.error .info-input {
  color: #f0415b; }

#formulaire-style .question .champ-requis {
  display: none; }

#formulaire-style .question-title {
  padding-bottom: 10px;
  display: block;
  font-size: 20px;
  color: #4F9893; }

#formulaire-style .question-title .note-title {
  display: block;
  color: #828ca4;
  font-size: 16px;
  padding-top: 5px; }

#formulaire-style .question textarea {
  resize: none;
  width: 100%;
  height: 120px;
  border: 1px solid #000000;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  padding: 10px;
  position: relative;
  color: #000000; }

#formulaire-style .question input {
  border: 1px solid #000000;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  padding: 5px;
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #000000; }

#formulaire-style .question select {
  position: relative;
  width: 300px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 2;
  background: transparent;
  font-size: 16px;
  color: #000000; }

#formulaire-style .question select option {
  border: none;
  padding: 5px; }

#formulaire-style .question .info-input {
  padding-left: 10px;
  display: inline-block; }

#formulaire-style .question .select-content {
  overflow: hidden;
  border: 1px solid #000000;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  margin-right: 5px; }

#formulaire-style .question .select-content:after {
  z-index: 1;
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  right: 10px;
  border-top: 6px solid #000000;
  border-left: 6px solid #FFF;
  border-right: 6px solid #FFF; }

#formulaire-style .question .select-width-60 {
  width: 60px; }

#formulaire-style .question .select-width-80 {
  width: 80px; }

#formulaire-style .question .select-width-140 {
  width: 140px; }

#formulaire-style .question .select-width-160 {
  width: 160px; }

#formulaire-style .question .a .select-width-60,
#formulaire-style .question .a .select-width-80 {
  width: 140px; }

#formulaire-style .question .select-width-220 {
  width: 220px; }

#formulaire-style .question-commun .select-width-60 {
  background: #fff;
  margin-left: 10px;
  margin-top: 15px;
  border: 1px solid #ccc; }

#formulaire-style .question .question-commun {
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  text-align: left;
  padding: 0 0 20px;
  background: #f8f8f8;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  vertical-align: top; }

#formulaire-style .question .question-commun.planche-style {
  background: #fff;
  padding: 40px 0;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  float: none; }

#formulaire-style .question .question-commun.question-width-40 {
  max-width: 48%; }

#formulaire-style .question .question-commun.question-width-40 .choix-question-commun {
  display: block; }

#formulaire-style .question .question-commun .title-question-commun {
  font-size: 22px;
  padding: 20px 20px 0;
  text-align: center; }

#formulaire-style .question .question-commun .choix-question-commun {
  display: inline-block;
  padding: 20px 20px 0; }

#formulaire-style .question .question-commun .choix-question-commun input {
  margin: 0 auto;
  margin-top: 10px;
  display: none; }

#formulaire-style .question .image-planche {
  max-width: 1200px;
  width: 100%;
  height: auto;
  padding-top: 20px; }

#formulaire-style .question .image-planche img {
  width: 100%;
  height: auto; }

#formulaire-style [type="radio"]:not(:checked),
#formulaire-style [type="radio"]:checked,
#formulaire-style [type="checkbox"]:not(:checked),
#formulaire-style [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
  display: none; }

#formulaire-style [type="radio"]:not(:checked) + label,
#formulaire-style [type="radio"]:checked + label,
#formulaire-style [type="checkbox"]:not(:checked) + label,
#formulaire-style [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer; }

#formulaire-style [type="radio"]:not(:checked) + label:before,
#formulaire-style [type="radio"]:checked + label:before,
#formulaire-style [type="checkbox"]:not(:checked) + label:before,
#formulaire-style [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 1px solid #aaa;
  background: #f8f8f8;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); }

#formulaire-style [type="checkbox"]:not(:checked) + label:before,
#formulaire-style [type="checkbox"]:checked + label:before {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }

#formulaire-style [type="radio"]:not(:checked) + label:after,
#formulaire-style [type="radio"]:checked + label:after,
#formulaire-style [type="checkbox"]:not(:checked) + label:after,
#formulaire-style [type="checkbox"]:checked + label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 100%;
  width: 19px;
  height: 19px;
  background: #b2dfdb;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); }

#formulaire-style [type="checkbox"]:not(:checked) + label:after,
#formulaire-style [type="checkbox"]:checked + label:after {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }

#formulaire-style [type="radio"]:not(:checked) + label:after,
#formulaire-style [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

#formulaire-style [type="radio"]:checked + label:after,
#formulaire-style [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

#formulaire-style .question #question-social-titre {
  padding: 20px 0; }

#formulaire-style .question .question-commun-social {
  padding: 20px 0; }

#formulaire-style .question .question-commun-social input {
  max-width: 300px;
  width: 100%; }

#formulaire-style .question .question-commun-social .champ-info {
  display: block;
  padding-top: 10px;
  font-size: 16px;
  font-style: italic; }

#formulaire-style .question .question-commun-social .champ-info a {
  display: inline-block; }

#formulaire-style .question .question-commun-social .champ-info .fa {
  font-size: 22px; }

#formulaire-style .question .question-commun-social .champ-info .fa-instagram {
  color: #125688; }

#formulaire-style .question .question-commun-social .champ-info .fa-pinterest {
  color: #CB2027; }

#aide_tableau_correspondance_taille {
  cursor: pointer; }

#tableau_correspondance_taille {
  height: 0;
  overflow: hidden; }

#tableau_correspondance_taille.show {
  height: auto; }

#tableau_correspondance_taille table {
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  width: 100%;
  text-align: center;
  padding: 20px;
  border-collapse: collapse;
  margin-top: 20px; }

#tableau_correspondance_taille table tr:nth-child(odd) {
  background: #fff; }

#tableau_correspondance_taille table tr:first-child td,
#tableau_correspondance_taille table tr td:first-child {
  font-weight: bold;
  color: #80BFBA; }

#tableau_correspondance_taille table td {
  padding: 5px;
  border-right: 1px solid #e0e0e0; }

@media (max-width: 992px) {
  #formulaire-style .question .question-commun,
  #formulaire-style .question .question-commun.question-width-40 {
    max-width: 100%;
    width: 100%; }

  #formulaire-style .question input,
  #formulaire-style .question select,
  #formulaire-style .question select option {
    height: 40px;
    line-height: 40px;
    font-size: 18px; } }
.page--profil-steps {
  min-width: 320px; }
  .page--profil-steps .profile-header {
    height: 155px;
    margin-bottom: 0; }
    .page--profil-steps .profile-header .profile-header-title {
      font-size: 36px;
      text-align: center;
      color: #000;
      font-family: "sofia-pro", sans-serif;
      font-weight: 700;
      padding: 75px;
      height: 110px; }
    .page--profil-steps .profile-header .profile-header-steps {
      position: relative;
      height: 90px; }
      .page--profil-steps .profile-header .profile-header-steps hr {
        position: absolute;
        border: 1px solid #F3A090;
        width: 100%;
        left: 0;
        top: 85px;
        margin: 0; }
      .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container {
        width: 760px;
        max-width: initial;
        margin: 0 auto;
        padding-top: 75px;
        position: absolute;
        left: 0;
        right: 0; }
        .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
          justify-content: space-between; }
          .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex .profile-steps-space {
            flex: 2; }
          .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex .step-wrapper {
            /*width: 25%;
            display: inline-block;
            text-align: left;*/
            width: auto;
            flex: 0 0 auto;
            text-align: center;
            font-family: 'sofia-pro', sans-serif;
            font-weight: 300;
            color: #000;
            vertical-align: top;
            /*&:first-child{
                .step-circle{
                    margin-left: 12px;
                }
            }
            &:nth-child(2){
                .step-circle{
                    margin-left: 17px;
                }
            }
            &:nth-child(3){
                .step-circle{
                    margin-left: 11px;
                }
            }
            &:nth-child(4){
                .step-circle{
                    margin-left: 7px;
                }
            }
            &:nth-child(5){
                .step-circle{
                    margin-left: 30px;
                }
            }*/ }
            .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex .step-wrapper .step-circle {
              border: 2px solid #F3A090;
              width: 20px;
              height: 20px;
              border-radius: 50px;
              margin: 0 auto 10px auto;
              background: #fff; }
            .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex .step-wrapper.active {
              color: #F3A090; }
              .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex .step-wrapper.active .step-circle {
                background: #F3A090; }
            .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex .step-wrapper.passed {
              color: #E1DBD4; }
              .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex .step-wrapper.passed .step-circle {
                background: #E1DBD4;
                border-color: #FFFFFF;
                position: relative; }
                .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .profile-steps-flex .step-wrapper.passed .step-circle::before {
                  font-family: FontAwesome;
                  content: "\f00c";
                  color: #fff;
                  vertical-align: initial;
                  font-size: 12px;
                  position: absolute;
                  left: 2px;
                  top: 1px; }
        .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container.five-steps .step-wrapper {
          /*width: 20%;*/ }
        .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .chevron-wrap a {
          display: inline-block;
          cursor: pointer;
          height: 25px;
          width: 25px; }
  .page--profil-steps .profile-before .profile-title {
    font-family: "Montserrat-Regular", sans-serif; }
    .page--profil-steps .profile-before .profile-title .c-title {
      font-family: "sofia-pro", sans-serif;
      font-weight: 700; }
  .page--profil-steps .profile-body {
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300; }
    .page--profil-steps .profile-body .c-title {
      font-family: "sofia-pro", sans-serif !important;
      font-weight: 700;
      font-size: 20px; }
    .page--profil-steps .profile-body .s-hr {
      background: #fff;
      margin-top: 10px !important; }
    .page--profil-steps .profile-body input[type=number] {
      background: #fff;
      border: 1px solid #000;
      border-radius: 10px 0 0 10px !important;
      -moz-appearance: textfield; }
      .page--profil-steps .profile-body input[type=number]::-webkit-outer-spin-button, .page--profil-steps .profile-body input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .page--profil-steps .profile-body input[type=number]:hover {
        border: 1px solid #9b9b9b; }
        .page--profil-steps .profile-body input[type=number]:hover + .c-form__completion {
          border: 1px solid #9b9b9b !important;
          border-left: none !important; }
      .page--profil-steps .profile-body input[type=number]#question-6, .page--profil-steps .profile-body input[type=number]#question-11 {
        width: 115px;
        max-width: 115px; }
    .page--profil-steps .profile-body input[type=text], .page--profil-steps .profile-body textarea {
      background: #fff;
      border: 1px solid #000;
      border-radius: 10px !important; }
      .page--profil-steps .profile-body input[type=text]:hover, .page--profil-steps .profile-body textarea:hover {
        border: 1px solid #9b9b9b; }
      .page--profil-steps .profile-body input[type=text]#question-7, .page--profil-steps .profile-body textarea#question-7 {
        width: 200px; }
    .page--profil-steps .profile-body .c-form__completion {
      background: transparent;
      border: 1px solid #000 !important;
      border-left: none !important;
      border-radius: 0 10px 10px 0 !important;
      margin-left: -20px;
      padding-left: 30px;
      width: 65px;
      text-align: center; }
    .page--profil-steps .profile-body select {
      border: 1px solid #000;
      border-radius: 10px !important;
      width: 200px; }
      .page--profil-steps .profile-body select#question-20, .page--profil-steps .profile-body select.question-30, .page--profil-steps .profile-body select.question-31, .page--profil-steps .profile-body select.question-79, .page--profil-steps .profile-body select.question-80, .page--profil-steps .profile-body select.question-34, .page--profil-steps .profile-body select.question-35 {
        width: 75px;
        margin-left: 9px; }
      .page--profil-steps .profile-body select#question-19 {
        width: 75px; }
    .page--profil-steps .profile-body .radio-field .c-form__label.title-label {
      font-family: "sofia-pro", sans-serif;
      font-weight: 700;
      margin-bottom: 20px;
      font-size: 20px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio > div {
      display: inline-block;
      margin-right: 20px;
      height: 40px;
      width: 100px;
      vertical-align: top;
      text-align: left; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio > div .c-form__label {
        font-family: 'sofia-pro', sans-serif;
        font-weight: 300;
        position: relative; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio > div .c-form__label:after {
          width: 20px;
          height: 20px;
          color: #F3A090;
          padding: 1px 0 0 2px;
          top: 8px;
          left: 0;
          margin: 0 auto;
          border: 1px solid #000;
          transition: none; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio > div .c-form__input:checked + .c-form__label {
        color: #F3A090; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio > div .c-form__input:checked + .c-form__label:after {
          background: white;
          content: "\f00c";
          font-family: FontAwesome;
          vertical-align: middle;
          border: 1px solid #F3A090; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio > div .c-form__label:before {
        visibility: hidden;
        content: none; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.c__question-42 > div {
        width: 150px;
        display: inline-block;
        vertical-align: top; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.c__question-42 > div .c-form__label {
          width: 105px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-101 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-103 > div {
      display: inline-block;
      margin-right: 20px;
      height: 40px;
      width: 161px;
      vertical-align: top;
      text-align: left; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-59 > div {
      width: 150px !important;
      margin-right: 36px !important; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-59 > div .c-form__label {
        width: auto !important; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70 > div {
      margin-bottom: 15px !important; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-55 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-56 > div {
      width: 150px !important;
      margin-right: 36px !important; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70 > div .c-form__label, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-55 > div .c-form__label, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-56 > div .c-form__label {
        width: auto !important; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-24 > div {
      width: 150px !important; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-55, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-56, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-101, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-103 {
      padding-left: 15px; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-55 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-56 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-101 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-103 > div {
        width: 150px; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-55 > div .c-form__label, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-56 > div .c-form__label, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70 > div .c-form__label, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-101 > div .c-form__label, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-103 > div .c-form__label {
          width: 120px; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-55 > div .c-form__input:checked + .c-form__label:after, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-56 > div .c-form__input:checked + .c-form__label:after, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70 > div .c-form__input:checked + .c-form__label:after, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-101 > div .c-form__input:checked + .c-form__label:after, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-103 > div .c-form__input:checked + .c-form__label:after {
          padding-left: 2px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-41 {
      padding-left: 15px; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-41 > div .c-form__input:checked + .c-form__label:after {
        padding-left: 2px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio > .c__question-42 > div .c-form__input:checked + .c-form__label:after {
      padding-right: 1px; }
    .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox {
      margin-bottom: 0; }
      .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox .row > div {
        display: inline-block;
        margin-right: 40px;
        height: 40px;
        width: 150px;
        vertical-align: top;
        text-align: left; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox .row > div .c-form__label {
          font-family: 'sofia-pro', sans-serif;
          font-weight: 300;
          padding-left: 25px;
          position: relative;
          text-align: left; }
          .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox .row > div .c-form__label:after {
            width: 20px;
            height: 20px;
            color: #F3A090;
            padding: 1px 0 0 2px;
            top: 8px;
            left: 0;
            margin: 0 auto;
            border: 1px solid #000;
            transition: none; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox .row > div .c-form__input:checked + .c-form__label {
          color: #F3A090; }
          .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox .row > div .c-form__input:checked + .c-form__label:before {
            visibility: hidden;
            content: none; }
          .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox .row > div .c-form__input:checked + .c-form__label:after {
            background: white;
            content: "\f00c";
            font-family: FontAwesome;
            vertical-align: middle;
            border: 1px solid #F3A090; }
      .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-32 > div > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-36 > div > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-37 > div > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-38 > div > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-39 > div > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-40 > div > div {
        width: 100px; }
      .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div {
        display: inline-block;
        height: 128.5px;
        width: 150px;
        vertical-align: top;
        text-align: left;
        margin-bottom: 55px; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div .c-form__label {
          margin-bottom: 5px !important;
          padding-right: 25px;
          padding-left: 0px; }
          .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div .c-form__label:after {
            display: none; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div .c-form__input:checked + .c-form__label:after {
          content: unset;
          font-family: unset;
          border: unset; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div input[type="checkbox"][id^="answer"] {
          display: none; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div label {
          display: block;
          position: relative;
          cursor: pointer;
          width: 100%;
          height: 100%; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div label:before {
          display: none; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div label img {
          height: 100%;
          width: 100%;
          border: 1px solid transparent; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div :checked + label {
          border-color: #F3A090; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div :checked + label:before {
          background-color: #F3A090;
          border: 2px solid #F3A090;
          z-index: -2; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div :checked + label img {
          z-index: -1;
          border: 2px solid #F3A090;
          border-radius: 50%; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div > div {
          font-size: 14px;
          padding-right: 25px;
          margin-bottom: 20px;
          text-align: center; }
      .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div {
        display: inline-block;
        height: 63px;
        width: 88px;
        vertical-align: top;
        text-align: left;
        position: relative;
        margin-bottom: 40px; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .c-form__label {
          padding-right: 25px;
          padding-left: 0; }
          .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .c-form__label:after {
            display: none;
            border: unset; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .c-form__input:checked + .c-form__label:after {
          content: unset;
          font-family: unset;
          border: unset; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div input[type="checkbox"][id^="answer"] {
          display: none; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div label {
          display: block;
          position: relative;
          cursor: pointer;
          width: 100%;
          height: 100%; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div label:before {
          background-color: #F3A090;
          color: white;
          display: block;
          border: 2px solid #F3A090;
          position: absolute;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          transition-duration: 0.4s; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div label div {
          padding: 3px;
          height: 100%;
          width: 100%;
          border: 1px solid transparent;
          border-radius: 50%; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div :checked + label {
          border-color: #F3A090; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div :checked + label:before {
          background-color: #F3A090;
          border: 2px solid #F3A090;
          z-index: -2; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div :checked + label:after {
          top: -15px;
          left: -15px;
          right: -15px;
          bottom: -15px; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div :checked + label div {
          z-index: -1;
          border: 2px solid #F3A090;
          border-radius: 50%; }
          .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div :checked + label div > div {
            border: unset; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div > div {
          font-size: 14px;
          /* Center vertically and horizontally */
          position: absolute;
          top: 110%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-right: 25px;
          margin-bottom: 20px;
          padding-top: 10px; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .orange {
          background-color: #F46523; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .bordeaux {
          background-color: #8c0c36; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .moutarde {
          background-color: #dc9c03; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .kaki {
          background-color: #4a5533; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .noir {
          background-color: #000000; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .violet {
          background-color: #ECB6EA; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .rose {
          background-color: #F4989D; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .vert {
          background-color: #9AD4AB; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .beige {
          background-color: #EBD5C0; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .gris {
          background-color: #D9D7D8; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .jaune {
          background-color: #F9FC93; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .rouge {
          background-color: #E40404; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .marron {
          background-color: #744229; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .bleu {
          background-color: #04227C; }
        .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .blanc {
          background-color: #FFFFFF;
          border: 1px solid #000000; }
    .page--profil-steps .profile-body .validation-button-wrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red {
        font-family: "sofia-pro", sans-serif;
        font-weight: 700;
        width: 270px;
        background: var(--black);
        border: 1px solid white;
        font-size: 18px;
        padding-top: 4px;
        padding-bottom: 8px;
        min-width: 230px;
        margin: 0 auto; }
        .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red:hover, .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red:active, .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red:focus {
          transform: none;
          background: var(--black);
          border: 1px solid black; }
      .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red.disabled {
        font-family: "sofia-pro", sans-serif;
        font-weight: 700;
        background: #d0cecf;
        border: none; }
        .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red.disabled:hover, .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red.disabled:active, .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red.disabled:focus {
          transform: none;
          cursor: not-allowed; }
      @media only screen and (max-width: 480px) {
        .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red, .page--profil-steps .profile-body .validation-button-wrapper .c-btn--red.disabled {
          width: 100%; } }
      .page--profil-steps .profile-body .validation-button-wrapper .link-text-orange {
        color: #F97759;
        padding-top: 10px;
        text-decoration: underline;
        font-size: 1.1rem;
        text-underline-offset: 0.2em; }
    .page--profil-steps .profile-body .styles-field {
      margin-bottom: 0 !important; }
      .page--profil-steps .profile-body .styles-field > .c-form__line {
        margin-bottom: 0; }
        .page--profil-steps .profile-body .styles-field > .c-form__line > .c-form__field--radio {
          margin-top: 20px;
          text-align: center; }
          .page--profil-steps .profile-body .styles-field > .c-form__line > .c-form__field--radio > .row {
            max-width: initial;
            width: 100%;
            text-align: center; }
            .page--profil-steps .profile-body .styles-field > .c-form__line > .c-form__field--radio > .row > .col-md-3 {
              display: inline-block;
              vertical-align: top;
              max-width: 24%;
              text-align: center; }
      .page--profil-steps .profile-body .styles-field > .p-imageStyle > img {
        width: 100%; }
  @media (max-width: 1200px) {
    .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container {
      width: 640px; }
      .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .step-wrapper {
        /*&:nth-child(5) {
            .step-circle {
                margin-left: 30px;
            }
        }*/ }
    .page--profil-steps .profile-body .radio-field.styles-field .c-form__field--radio > .row > div {
      width: 23% !important; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70 > div {
      height: auto !important;
      margin-top: 20px; } }
  @media (max-width: 990px) {
    .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container {
      width: 720px; }
      .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .step-wrapper {
        /*&:nth-child(5) {
            .step-circle {
                margin-left: 30px;
            }
        }*/ } }
  @media (max-width: 768px) {
    .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container {
      width: 540px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio > div, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div {
      height: auto !important; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio > div .c-form__label::after, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div .c-form__label::after, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div .c-form__label::after, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div .c-form__label::after {
        font-size: 14.5px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-53 .row > div, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox.c__question-53 .row > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-53 .row > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div {
      display: inline-block;
      height: 90px !important;
      width: 110px !important;
      vertical-align: top;
      text-align: left;
      margin-top: unset;
      margin-bottom: 50px; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-53 .row > div .c-form__label, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox.c__question-53 .row > div .c-form__label, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-53 .row > div .c-form__label, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div .c-form__label {
        margin-bottom: 5px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div {
      width: 110px !important; }
    .page--profil-steps .profile-body .radio-field.styles-field .c-form__field--radio > .row {
      width: 100% !important; }
      .page--profil-steps .profile-body .radio-field.styles-field .c-form__field--radio > .row > div {
        width: 23% !important; } }
  @media (max-width: 575px) {
    .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container {
      width: 100%; }
    .page--profil-steps .profile-body .radio-field > .c-title, .page--profil-steps .profile-body .checkbox-field > .c-title {
      margin-bottom: 0 !important;
      margin-top: 10px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox {
      padding-top: 15px; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio > div, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div {
        margin-right: 10px; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div {
      height: 100px; } }
  @media (max-width: 500px) {
    .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .step-wrapper {
      font-size: 14px; }
    .page--profil-steps .profile-body .s-hr.c__question-59-hr {
      margin-bottom: 10px !important; }
    .page--profil-steps .profile-body .radio-field, .page--profil-steps .profile-body .checkbox-field {
      padding-left: 0; }
      .page--profil-steps .profile-body .radio-field .c__question-1-label, .page--profil-steps .profile-body .radio-field .c__question-21-label, .page--profil-steps .profile-body .radio-field .c__question-24-label, .page--profil-steps .profile-body .checkbox-field .c__question-1-label, .page--profil-steps .profile-body .checkbox-field .c__question-21-label, .page--profil-steps .profile-body .checkbox-field .c__question-24-label {
        padding-left: 6px !important;
        margin-bottom: 0 !important; }
      .page--profil-steps .profile-body .radio-field .c__question-59-label, .page--profil-steps .profile-body .checkbox-field .c__question-59-label {
        padding-left: 8px !important;
        margin-bottom: 0 !important; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div {
        text-align: left;
        display: block;
        height: 30px !important;
        max-width: none;
        width: auto !important; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.row > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div.row > div {
          display: block;
          max-width: none;
          margin: 15px 0 0 0;
          text-align: left;
          width: auto !important; }
          .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.row > div .c-form__label, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div.row > div .c-form__label {
            height: auto;
            padding: 0 0 0 30px;
            margin: 0;
            width: auto !important; }
            .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.row > div .c-form__label::after, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div.row > div .c-form__label::after {
              right: auto;
              position: absolute;
              left: 0;
              top: 10px; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio > div .c-form__label, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div .c-form__label {
          height: auto;
          padding: 0 0 0 30px;
          margin: 0;
          width: auto !important;
          text-align: left; }
          .page--profil-steps .profile-body .radio-field .c-form__field--radio > div .c-form__label::after, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div .c-form__label::after {
            right: auto;
            position: absolute;
            left: 0;
            top: 10px; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.c__question-41, .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.c__question-55, .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.c__question-56, .page--profil-steps .profile-body .radio-field .c-form__field--radio > div.c__question-70, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div.c__question-41, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div.c__question-55, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div.c__question-56, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio > div.c__question-70 {
          padding-left: 7px; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-1, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-21, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-24, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-59, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-1, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-21, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-24, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-59 {
        margin-left: 8px !important; }
        .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-1 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-21 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-24 > div, .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-59 > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-1 > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-21 > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-24 > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-59 > div {
          width: auto !important; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio.c__question-70 > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--radio.c__question-70 > div {
        width: 100% !important;
        margin-top: 0 !important; }
      .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div {
        text-align: left;
        padding-top: 0 !important;
        display: block;
        max-width: none;
        width: auto !important; }
        .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div.row > label::before, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div.row > label::before {
          top: 10px !important; }
        .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div.row > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div.row > div {
          display: block;
          max-width: none;
          margin: 0 0 15px 0;
          text-align: left;
          width: auto !important;
          height: 20px !important; }
          .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div.row > div .c-form__label, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div.row > div .c-form__label {
            height: auto;
            padding: 0 0 0 30px;
            margin: 0;
            width: auto !important; }
            .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div.row > div .c-form__label::after, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div.row > div .c-form__label::after {
              right: auto;
              position: absolute;
              left: 0;
              top: 10px; }
        .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div .c-form__label, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div .c-form__label {
          height: auto;
          padding: 0 0 0 30px;
          margin: 0;
          width: auto !important;
          text-align: left; }
          .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div .c-form__label::after, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div .c-form__label::after {
            right: auto;
            position: absolute;
            left: 0;
            top: 10px; }
        .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div.c__question-41, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div.c__question-55, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div.c__question-56, .page--profil-steps .profile-body .radio-field .c-form__field--checkbox > div.c__question-70, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div.c__question-41, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div.c__question-55, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div.c__question-56, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox > div.c__question-70 {
          padding-left: 7px; }
      .page--profil-steps .profile-body .radio-field .c-form__field--checkbox.c__question-53 .row > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-53 .row > div {
        display: inline-block;
        height: 90px !important;
        width: 120px !important;
        vertical-align: top;
        text-align: left;
        margin-top: 10px; }
      .page--profil-steps .profile-body .radio-field .c-form__field--checkbox.c__question-54 .row > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div {
        display: inline-block;
        height: 30px !important;
        width: 49% !important;
        text-align: center;
        margin-bottom: 15px; }
        .page--profil-steps .profile-body .radio-field .c-form__field--checkbox.c__question-54 .row > div .c-form__label, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div .c-form__label {
          height: 47px;
          width: 77px !important; }
        .page--profil-steps .profile-body .radio-field .c-form__field--checkbox.c__question-54 .row > div > div, .page--profil-steps .profile-body .checkbox-field .c-form__field--checkbox.c__question-54 .row > div > div {
          transform: unset;
          top: 64%;
          left: 70px;
          padding-top: 0px; }
      .page--profil-steps .profile-body .radio-field.styles-field .c-form__field--radio, .page--profil-steps .profile-body .checkbox-field.styles-field .c-form__field--radio {
        display: inline-block;
        height: 100px; }
        .page--profil-steps .profile-body .radio-field.styles-field .c-form__field--radio > .row > div, .page--profil-steps .profile-body .checkbox-field.styles-field .c-form__field--radio > .row > div {
          display: inline-block;
          text-align: center;
          width: 23% !important; }
          .page--profil-steps .profile-body .radio-field.styles-field .c-form__field--radio > .row > div .c-form__label, .page--profil-steps .profile-body .checkbox-field.styles-field .c-form__field--radio > .row > div .c-form__label {
            height: auto;
            padding: 25px 0 0 0;
            margin: 0;
            font-size: 12px;
            width: 100% !important;
            text-align: center; }
            .page--profil-steps .profile-body .radio-field.styles-field .c-form__field--radio > .row > div .c-form__label::after, .page--profil-steps .profile-body .checkbox-field.styles-field .c-form__field--radio > .row > div .c-form__label::after {
              position: absolute;
              top: 0;
              left: 0;
              right: 0; }
    .page--profil-steps .profile-body .radio-field > div > div > .c-form__label.title-label {
      padding-left: 0; }
    .page--profil-steps .profile-body .radio-field .c-form__field--radio {
      margin-left: 0 !important; }
      .page--profil-steps .profile-body .radio-field .c-form__field--radio > div > .c-form__label {
        padding-left: 25px !important; } }
  @media (max-width: 400px) {
    .page--profil-steps .profile-header .profile-header-steps .profile-header-steps-container .step-wrapper {
      font-size: 12px;
      /*&:first-child {
          .step-circle {
              margin-left: 6px;
          }
      }
      &:nth-child(2) {
          .step-circle {
              margin-left: 10px;
          }
      }
      &:nth-child(3) {
          .step-circle {
              margin-left: 5px;
          }
      }
      &:nth-child(4) {
          .step-circle {
              margin-left: 2px;
          }
      }
      &:nth-child(5) {
          .step-circle {
              margin-left: 19px;
          }
      }*/ } }
  @media (max-width: 360px) {
    .page--profil-steps .profile-body .styles-field > .c-form__line > .c-form__field--radio > .row > .col-md-3 {
      max-width: 23%; } }

/*
-----------------
Abonnements
-----------------
*/
#pause-abonnement .pause_abonnement_content .alert-order-in-progress, #resilier-abonnement .pause_abonnement_content .alert-order-in-progress, #add-payment-method .pause_abonnement_content .alert-order-in-progress {
  font-weight: bold;
  color: #f05d61; }
#pause-abonnement .pause_abonnement_content #pause_end_date, #resilier-abonnement .pause_abonnement_content #pause_end_date, #add-payment-method .pause_abonnement_content #pause_end_date {
  width: 200px;
  margin: 20px auto 0 auto;
  background: #fff; }
#pause-abonnement .pause_abonnement_content .missed-selections, #resilier-abonnement .pause_abonnement_content .missed-selections, #add-payment-method .pause_abonnement_content .missed-selections {
  margin-top: 20px;
  display: none; }
#pause-abonnement .next-selections-table, #resilier-abonnement .next-selections-table, #add-payment-method .next-selections-table {
  margin-top: 20px;
  width: 100%; }
  #pause-abonnement .next-selections-table td, #pause-abonnement .next-selections-table th, #resilier-abonnement .next-selections-table td, #resilier-abonnement .next-selections-table th, #add-payment-method .next-selections-table td, #add-payment-method .next-selections-table th {
    border: 1px solid #d7d7d7;
    padding: 5px 10px; }
  #pause-abonnement .next-selections-table td, #resilier-abonnement .next-selections-table td, #add-payment-method .next-selections-table td {
    width: 33%; }
    #pause-abonnement .next-selections-table td .canceled-label, #resilier-abonnement .next-selections-table td .canceled-label, #add-payment-method .next-selections-table td .canceled-label {
      display: none; }
    #pause-abonnement .next-selections-table td.grey-td, #resilier-abonnement .next-selections-table td.grey-td, #add-payment-method .next-selections-table td.grey-td {
      background: #f0f0f0;
      color: #9f9f9f; }
      #pause-abonnement .next-selections-table td.grey-td .canceled-label, #resilier-abonnement .next-selections-table td.grey-td .canceled-label, #add-payment-method .next-selections-table td.grey-td .canceled-label {
        color: #9f9f9f;
        font-size: 12px;
        float: right;
        display: block; }
#pause-abonnement #add-payment-method-block, #resilier-abonnement #add-payment-method-block, #add-payment-method #add-payment-method-block {
  text-align: center;
  padding: 70px 0; }
  #pause-abonnement #add-payment-method-block .c-btn--red, #resilier-abonnement #add-payment-method-block .c-btn--red, #add-payment-method #add-payment-method-block .c-btn--red {
    padding: 10px 15px 15px 15px;
    min-height: auto;
    line-height: normal;
    box-shadow: none;
    margin-top: 1px;
    color: #fff;
    background: #FFAB9E;
    border: 2px solid #FFAB9E;
    font-size: 18px;
    border-radius: 0;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300; }
    #pause-abonnement #add-payment-method-block .c-btn--red:hover, #pause-abonnement #add-payment-method-block .c-btn--red:active, #pause-abonnement #add-payment-method-block .c-btn--red:focus, #resilier-abonnement #add-payment-method-block .c-btn--red:hover, #resilier-abonnement #add-payment-method-block .c-btn--red:active, #resilier-abonnement #add-payment-method-block .c-btn--red:focus, #add-payment-method #add-payment-method-block .c-btn--red:hover, #add-payment-method #add-payment-method-block .c-btn--red:active, #add-payment-method #add-payment-method-block .c-btn--red:focus {
      transform: none;
      background: #FF8573; }
  #pause-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form, #resilier-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form, #add-payment-method #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form {
    margin: 0 auto;
    width: 100%;
    max-width: 410px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300; }
    #pause-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button, #resilier-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button, #add-payment-method #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button {
      background: #ffab9e !important;
      text-align: center;
      font-family: "sofia-pro", sans-serif;
      font-weight: 700;
      transition: all ease 0.3s; }
      #pause-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button:focus, #resilier-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button:focus, #add-payment-method #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button:focus {
        box-shadow: none; }
      #pause-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button:hover, #resilier-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button:hover, #add-payment-method #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button:hover {
        background: #FF8573 !important; }
      #pause-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button > span, #resilier-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button > span, #add-payment-method #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-payment-button > span {
        font-family: "sofia-pro", sans-serif;
        font-weight: 700; }
    #pause-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error, #resilier-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error, #add-payment-method #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error {
      text-align: left !important;
      border-radius: 4px !important;
      position: relative !important;
      margin-top: 15px !important;
      border: 1px solid #FFA758 !important;
      color: #FFA758 !important;
      background: #ffffff !important;
      font-size: 14px !important;
      padding: 10px !important; }
      #pause-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error span, #resilier-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error span, #add-payment-method #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error span {
        padding: 0 !important; }
        #pause-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error span span, #resilier-abonnement #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error span span, #add-payment-method #add-payment-method-block .add-payment-method-form #payzen-payment-form .kr-smart-form .kr-form-error span span {
          padding: 0 !important;
          color: #FFA758 !important; }
  #pause-abonnement #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__field--checkbox .c-form__label, #resilier-abonnement #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__field--checkbox .c-form__label, #add-payment-method #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__field--checkbox .c-form__label {
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    cursor: pointer; }
    #pause-abonnement #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__field--checkbox .c-form__label a, #resilier-abonnement #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__field--checkbox .c-form__label a, #add-payment-method #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__field--checkbox .c-form__label a {
      color: #FF5859;
      text-decoration: underline;
      font-family: "sofia-pro", sans-serif;
      font-weight: 400;
      cursor: pointer;
      font-size: 14px; }
  #pause-abonnement #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__info--error, #resilier-abonnement #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__info--error, #add-payment-method #add-payment-method-block .add-payment-method-form #cgv-block .c-form__line .c-form__info--error {
    text-align: left;
    display: block;
    border-radius: 4px;
    position: relative;
    margin-top: 5px;
    border: 1px solid #FFA758;
    padding: 15px;
    color: #FFA758;
    font-size: 14px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400; }

#accueil-client .c-title, #cf_core_validation_payment .c-title {
  font-family: "sofia-pro", sans-serif;
  font-weight: 700;
  font-size: 28px; }
#accueil-client textarea.c-form__input, #cf_core_validation_payment textarea.c-form__input {
  border-radius: 0;
  background: white;
  resize: none;
  margin-top: 10px; }
#accueil-client .order-validation-left-block, #cf_core_validation_payment .order-validation-left-block {
  text-align: center;
  color: #000; }
  #accueil-client .order-validation-left-block > .c-block, #cf_core_validation_payment .order-validation-left-block > .c-block {
    box-shadow: none; }
    #accueil-client .order-validation-left-block > .c-block .pink-text, #cf_core_validation_payment .order-validation-left-block > .c-block .pink-text {
      color: #f39d8f;
      font-family: "sofia-pro", sans-serif;
      font-weight: 700; }
    #accueil-client .order-validation-left-block > .c-block .address-text-big, #cf_core_validation_payment .order-validation-left-block > .c-block .address-text-big {
      font-family: "sofia-pro", sans-serif;
      font-weight: 400;
      font-size: 24px; }
      #accueil-client .order-validation-left-block > .c-block .address-text-big .pink-text, #cf_core_validation_payment .order-validation-left-block > .c-block .address-text-big .pink-text {
        color: #f39d8f;
        font-family: "sofia-pro", sans-serif;
        font-weight: 400; }
    #accueil-client .order-validation-left-block > .c-block #send-message-styliste, #accueil-client .order-validation-left-block > .c-block #preorder-box, #cf_core_validation_payment .order-validation-left-block > .c-block #send-message-styliste, #cf_core_validation_payment .order-validation-left-block > .c-block #preorder-box {
      border-radius: 0;
      border: 0;
      font-size: 16px;
      font-family: "sofia-pro", sans-serif;
      font-weight: 700;
      min-width: 200px;
      padding-top: 3px;
      padding-bottom: 3px; }
#accueil-client .order-validation-right-block > .c-block, #cf_core_validation_payment .order-validation-right-block > .c-block {
  box-shadow: none;
  height: auto;
  border: 1px solid #dadada;
  color: #000;
  padding: 15px;
  position: relative; }
  #accueil-client .order-validation-right-block > .c-block .c-title h1, #cf_core_validation_payment .order-validation-right-block > .c-block .c-title h1 {
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-bold);
    font-size: var(--title-size);
    line-height: 1.2;
    margin-bottom: 15px; }
  #accueil-client .order-validation-right-block > .c-block .c-title h2, #cf_core_validation_payment .order-validation-right-block > .c-block .c-title h2 {
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-light);
    font-size: var(--text-size); }

.homepage .block_1 {
  width: 100%;
  height: 700px;
  position: relative;
  text-align: center;
  background: #eeeeee url("/public/images/box-vetement-femme.jpg") no-repeat right center;
  background-size: auto 100%; }
  .homepage .block_1.referral-margin {
    margin-bottom: 550px;
    background-position: center top;
    background-size: auto 100%;
    background-image: url("/public/images/box-vetement-femme-2.jpg"); }
  .homepage .block_1 .content {
    position: relative;
    margin: 0 auto;
    height: 100%; }
    .homepage .block_1 .content .homepage-title-block {
      height: 100%;
      padding: 250px 0;
      position: relative; }
      .homepage .block_1 .content .homepage-title-block .title {
        font-size: 30px;
        color: #272C30;
        letter-spacing: 1px;
        line-height: 1;
        margin-bottom: 20px;
        font-family: "DomaineDisp-Semibold", sans-serif; }
      .homepage .block_1 .content .homepage-title-block .text {
        padding: 0 0 30px;
        font-size: 18px;
        color: #545454; }
      .homepage .block_1 .content .homepage-title-block .sub_title {
        font-weight: normal;
        font-size: 18px;
        padding: 0 15px 45px;
        color: #000;
        font-family: "Montserrat-Regular", sans-serif;
        margin-bottom: 0; }
      .homepage .block_1 .content .homepage-title-block .js-homepage__scroll {
        padding: 10px 30px 15px 30px;
        min-height: auto;
        line-height: normal;
        box-shadow: none;
        margin-top: 1px;
        background: #FFAB9E;
        border: none;
        font-size: 18px;
        border-radius: 0;
        font-weight: bold; }
        .homepage .block_1 .content .homepage-title-block .js-homepage__scroll:hover, .homepage .block_1 .content .homepage-title-block .js-homepage__scroll:active, .homepage .block_1 .content .homepage-title-block .js-homepage__scroll:focus {
          transform: none;
          background: #FF8573;
          border: none; }
      .homepage .block_1 .content .homepage-title-block .referral_enveloppe_back {
        position: absolute;
        top: 580px;
        left: 0;
        right: 0;
        width: 914px;
        margin: 0 auto; }
      .homepage .block_1 .content .homepage-title-block .referral_block {
        text-align: center;
        background: #fff;
        border-radius: 30px;
        position: absolute;
        top: 100px;
        width: 700px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 30px;
        color: #000;
        -webkit-box-shadow: 0px 0px 7px 0px #d9d9d9;
        -moz-box-shadow: 0px 0px 7px 0px #d9d9d9;
        box-shadow: 0px 0px 7px 0px #d9d9d9;
        z-index: 3; }
        .homepage .block_1 .content .homepage-title-block .referral_block .referral_title {
          font-family: "DomaineDisp-Semibold", sans-serif;
          font-size: 30px;
          margin-bottom: 20px; }
        .homepage .block_1 .content .homepage-title-block .referral_block .referral_content {
          font-family: "Montserrat-Regular", sans-serif;
          margin-bottom: 50px; }
        .homepage .block_1 .content .homepage-title-block .referral_block .referral_arrow {
          margin-bottom: 50px;
          position: relative;
          height: 100px; }
          .homepage .block_1 .content .homepage-title-block .referral_block .referral_arrow img {
            width: 100px;
            position: absolute;
            left: -15px;
            right: 0;
            top: 0;
            margin: 0 auto; }
        .homepage .block_1 .content .homepage-title-block .referral_block .referral_subtitle {
          font-family: "DomaineDisp-Semibold", sans-serif;
          font-size: 22px; }
        .homepage .block_1 .content .homepage-title-block .referral_block .js-homepage__scroll {
          margin-bottom: 50px; }
        .homepage .block_1 .content .homepage-title-block .referral_block .referral_illustration {
          margin-bottom: 190px; }
          .homepage .block_1 .content .homepage-title-block .referral_block .referral_illustration img {
            width: 150px; }
        .homepage .block_1 .content .homepage-title-block .referral_block .referral_enveloppe_front {
          position: absolute;
          bottom: -175px;
          left: -107px;
          right: 0;
          width: 914px; }
  .homepage .block_1.referred-homepage {
    height: 1182px; }
  @media (max-width: 1800px) {
    .homepage .block_1 {
      background-size: auto 700px; } }
  @media (max-width: 1650px) {
    .homepage .block_1 .content .homepage-title-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%; }
    .homepage .block_1.referred-homepage .content .homepage-title-block {
      position: relative;
      top: auto;
      left: auto;
      width: auto; } }
  @media (max-width: 1400px) {
    .homepage .block_1 .content {
      padding: 150px 0;
      height: 826px; }
    .homepage .block_1.referral-margin {
      margin-bottom: 700px; } }
  @media (max-width: 1240px) {
    .homepage .block_1.referral-margin {
      margin-bottom: 800px; } }
  @media (max-width: 1115px) {
    .homepage .block_1 .content {
      padding: 150px 0; }
      .homepage .block_1 .content .homepage-title-block {
        background: white; }
    .homepage .block_1.referred-homepage .content .homepage-title-block {
      background: none; } }
  @media (max-width: 920px) {
    .homepage .block_1.referral-margin {
      margin-bottom: 1150px;
      height: 775px; }
    .homepage .block_1 .content .homepage-title-block .referral_enveloppe_back {
      top: 976px;
      width: 714px; }
    .homepage .block_1 .content .homepage-title-block .referral_block {
      width: 500px; }
      .homepage .block_1 .content .homepage-title-block .referral_block .referral_enveloppe_front {
        width: 714px; } }
  @media (max-width: 865px) {
    .homepage .block_1 .content {
      padding: 100px 0; }
    .homepage .block_1.referred-homepage .content {
      padding: 150px 0; } }
  @media (max-width: 767px) {
    .homepage .block_1 .content  {
      padding: 60px 0; } }
  @media (max-width: 712px) {
    .homepage .block_1 {
      height: 300px; }
      .homepage .block_1.referral-margin {
        margin-bottom: 1100px; }
      .homepage .block_1 .content {
        height: auto;
        padding: 100px 0; }
        .homepage .block_1 .content .homepage-title-block {
          padding: 40px;
          width: 100%;
          height: 300px; }
          .homepage .block_1 .content .homepage-title-block .referral_enveloppe_back {
            top: 1050px;
            width: 514px; }
          .homepage .block_1 .content .homepage-title-block .referral_block {
            width: 400px; }
            .homepage .block_1 .content .homepage-title-block .referral_block .referral_enveloppe_front {
              bottom: -105px;
              left: -55px;
              width: 514px; }
            .homepage .block_1 .content .homepage-title-block .referral_block .referral_illustration {
              margin-bottom: 100px; }
      .homepage .block_1.referral-margin {
        background-size: auto 100%;
        height: 625px; }
        .homepage .block_1.referral-margin .content {
          padding: 100px 0; } }
  @media (max-width: 520px) {
    .homepage .block_1.referral-margin {
      margin-bottom: 1150px;
      background-color: #fff;
      background-image: url("/public/images/box-vetement-femme-3.jpg");
      background-size: 100% auto; }
    .homepage .block_1.referred-homepage {
      height: 468px; }
      .homepage .block_1.referred-homepage .content {
        padding: 52px 0; }
        .homepage .block_1.referred-homepage .content .homepage-title-block .referral_enveloppe_back {
          width: 440px;
          top: 1086px; }
        .homepage .block_1.referred-homepage .content .homepage-title-block .referral_block {
          width: 400px; }
          .homepage .block_1.referred-homepage .content .homepage-title-block .referral_block .referral_enveloppe_front {
            width: 440px;
            left: -20px;
            bottom: -135px; }
          .homepage .block_1.referred-homepage .content .homepage-title-block .referral_block .referral_illustration {
            margin-bottom: 20px; }
          .homepage .block_1.referred-homepage .content .homepage-title-block .referral_block .referred-register .form-container {
            min-width: 240px; } }
  @media (max-width: 440px) {
    .homepage .block_1 .content {
      background-size: 0;
      height: 260px;
      min-width: 320px; }
      .homepage .block_1 .content .homepage-title-block {
        height: 330px; }
    .homepage .block_1.referred-homepage .content .homepage-title-block .referral_enveloppe_back {
      width: 330px;
      top: 1210px; }
    .homepage .block_1.referred-homepage .content .homepage-title-block .referral_block {
      width: 300px; }
      .homepage .block_1.referred-homepage .content .homepage-title-block .referral_block .referral_enveloppe_front {
        width: 330px;
        left: -16px;
        bottom: -92px; }
      .homepage .block_1.referred-homepage .content .homepage-title-block .referral_block #real-inscription .container {
        min-width: auto; } }
  @media (max-width: 400px) {
    .homepage .block_1.referred-homepage .content {
      padding: 13px 0; } }
  @media (max-width: 380px) {
    .homepage .block_1 .content {
      height: 300px; }
      .homepage .block_1 .content .homepage-title-block {
        height: 380px; } }
.homepage .block_2 {
  background: #fff; }
  .homepage .block_2 .header {
    background: #FFF;
    padding: 60px 10px;
    text-align: center; }
    .homepage .block_2 .header .title {
      color: #272C30;
      font-size: 28px;
      text-transform: uppercase;
      font-family: "DomaineDisp-Regular", sans-serif; }
  .homepage .block_2 .container {
    padding: 0; }
  .homepage .block_2 .ul {
    margin: 0 auto;
    list-style-type: none;
    height: 500px;
    padding: 0 50px; }
  .homepage .block_2 .row {
    text-align: center;
    padding: 0 0 60px 0; }
    .homepage .block_2 .row .img-wrapper {
      position: relative;
      margin: 30px 0 50px 0;
      height: 140px; }
      .homepage .block_2 .row .img-wrapper .img-subwrapper {
        height: 140px; }
        .homepage .block_2 .row .img-wrapper .img-subwrapper .img {
          width: 198px; }
          .homepage .block_2 .row .img-wrapper .img-subwrapper .img.img-1 {
            padding-top: 25px; }
    .homepage .block_2 .row .etape {
      font-size: 48px;
      color: #F3A090;
      margin: 0 0 20px 0;
      display: none; }
    .homepage .block_2 .row .text h3 {
      font-family: "DomaineDisp-Semibold", sans-serif;
      margin-bottom: 20px;
      font-size: 18px;
      text-transform: uppercase;
      color: #000; }
    .homepage .block_2 .row .text h4 {
      font-family: "Montserrat-Regular", sans-serif;
      color: #000; }
      .homepage .block_2 .row .text h4 b {
        font-family: "Montserrat-Bold", sans-serif; }
  @media (max-width: 767px) {
    .homepage .block_2 {
      margin-top: 50px;
      padding-bottom: 30px; } }
  @media (max-width: 575px) {
    .homepage .block_2 {
      padding-bottom: 0; } }
  @media (max-width: 576px) {
    .homepage .block_2 .container {
      width: 540px;
      max-width: 100%; } }
  @media (max-width: 768px) {
    .homepage .block_2 .container {
      width: 720px;
      max-width: 100%; } }
  @media (max-width: 992px) {
    .homepage .block_2 .container {
      width: 960px;
      max-width: 100%; }
    .homepage .block_2 .col-md-4 {
      display: block;
      flex: auto;
      max-width: none;
      margin-bottom: 50px; }
      .homepage .block_2 .col-md-4 .etape {
        margin: 10px 0; }
      .homepage .block_2 .col-md-4 .img-wrapper {
        height: auto;
        margin: 0; }
        .homepage .block_2 .col-md-4 .img-wrapper .img-subwrapper {
          position: initial; } }
  @media (max-width: 1200px) {
    .homepage .block_2 .container {
      width: 90%;
      max-width: 100%; } }
.homepage .block_privilege {
  background: #F3A090;
  background: #FFE8E3;
  padding: 60px 0 20px;
  text-align: center;
  margin-bottom: 40px; }
  .homepage .block_privilege.block_privilege_bottom {
    background: #F7F5F2; }
  .homepage .block_privilege .title {
    font-size: 28px;
    text-transform: uppercase;
    color: #000; }
  .homepage .block_privilege .h3 {
    color: #000;
    font-size: 18px;
    padding: 20px 0 20px 0;
    font-weight: normal;
    font-family: "DomaineDisp-Semibold", sans-serif;
    text-transform: uppercase; }
  .homepage .block_privilege .p {
    font-size: 16px;
    color: #000;
    max-width: 400px;
    margin: 0 auto;
    font-family: "Montserrat-Regular", sans-serif; }
    .homepage .block_privilege .p b {
      font-family: "Montserrat-Bold", sans-serif; }
  .homepage .block_privilege .content_img {
    height: auto; }
    .homepage .block_privilege .content_img .img {
      display: block;
      height: auto;
      width: auto;
      margin: 0 auto;
      max-width: 45px;
      max-height: 45px; }
  @media (max-width: 1200px) {
    .homepage .block_privilege .container {
      width: 90%;
      max-width: 100%; } }
  @media (max-width: 992px) {
    .homepage .block_privilege .container {
      width: 960px;
      max-width: 100%; }
    .homepage .block_privilege .col-sm-4 {
      display: block;
      flex: auto;
      max-width: none;
      margin-bottom: 50px; }
      .homepage .block_privilege .col-sm-4 .etape {
        margin: 10px 0; }
      .homepage .block_privilege .col-sm-4 .img-wrapper {
        height: auto;
        margin: 0; }
        .homepage .block_privilege .col-sm-4 .img-wrapper .img-subwrapper {
          position: initial; } }
  @media (max-width: 768px) {
    .homepage .block_privilege .container {
      width: 720px;
      max-width: 100%; } }
  @media (max-width: 576px) {
    .homepage .block_privilege .container {
      width: 540px;
      max-width: 100%; } }
.homepage .block_styliste {
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 0;
  text-align: center;
  position: relative; }
  .homepage .block_styliste .h2 {
    font-size: 20px;
    color: #272C30; }
  .homepage .block_styliste .p {
    font-size: 16px;
    padding: 20px 0; }
  .homepage .block_styliste .styliste {
    font-size: 16px;
    color: #272C30; }
  .homepage .block_styliste .back {
    padding: 0 20px;
    position: relative;
    z-index: 2;
    background: #FFF;
    display: inline-block;
    vertical-align: middle;
    width: 50%; }
  .homepage .block_styliste picture {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    padding: 0; }
  .homepage .block_styliste .img {
    width: 100%; }
  @media (max-width: 768px) {
    .homepage .block_styliste {
      display: none; } }
.homepage .block_avis {
  padding: 50px 0 0 0;
  text-align: center;
  position: relative;
  max-width: 800px;
  margin: 0 auto; }
  .homepage .block_avis .block_avis_sub {
    width: 100%;
    display: block;
    margin: 0 auto;
    height: 220px;
    position: relative;
    padding-top: 20px; }
  .homepage .block_avis h2 {
    font-size: 28px;
    font-family: "DomaineDisp-Regular", sans-serif;
    color: #000;
    position: absolute;
    top: -20px;
    text-align: center;
    text-transform: uppercase;
    width: 100%; }
  .homepage .block_avis span {
    position: relative;
    background: #fff;
    padding: 0 10px; }
  .homepage .block_avis .slick-carousel {
    padding: 0 40px;
    margin-top: 45px; }
    .homepage .block_avis .slick-carousel .slick-prev {
      left: 60px; }
    .homepage .block_avis .slick-carousel .slick-next {
      right: 70px; }
    .homepage .block_avis .slick-carousel .slick-prev::before {
      content: "";
      width: 20px;
      height: 20px;
      border-left: 2px solid black;
      border-top: 2px solid black;
      display: block;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    .homepage .block_avis .slick-carousel .slick-next::before {
      content: "";
      width: 20px;
      height: 20px;
      border-left: 2px solid black;
      border-top: 2px solid black;
      display: block;
      -moz-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg); }
    .homepage .block_avis .slick-carousel .slick-prev, .homepage .block_avis .slick-carousel .slick-next {
      top: 50px;
      z-index: 100; }
    .homepage .block_avis .slick-carousel .slick-prev::before, .homepage .block_avis .slick-carousel .slick-next::before {
      color: #F3A090;
      font-size: 30px;
      opacity: 1; }
  .homepage .block_avis .slick-slide::before {
    content: "\201D";
    font-size: 160px;
    color: #F3A090;
    font-family: "DomaineDisp-Regular", sans-serif;
    display: none; }
  .homepage .block_avis .avis_wrapper {
    /*margin-top: -80px;*/
    font-size: 16px; }
    .homepage .block_avis .avis_wrapper .avis_etoiles {
      color: #F3A090;
      margin: 20px 0; }
  .homepage .block_avis .avis {
    font-style: italic;
    color: #000; }
    .homepage .block_avis .avis h3 {
      font-family: "Montserrat-Bold", sans-serif;
      margin-bottom: 20px;
      font-size: 18px; }
    .homepage .block_avis .avis h4 {
      font-family: "Montserrat-Regular", sans-serif;
      width: 600px;
      margin: 0 auto; }
      .homepage .block_avis .avis h4 > span {
        position: relative;
        display: block; }
        .homepage .block_avis .avis h4 > span::before {
          content: "“";
          position: absolute;
          left: 0;
          top: -70px;
          color: #eee;
          font-size: 120px;
          font-family: "DomaineDisp-Regular", sans-serif; }
        .homepage .block_avis .avis h4 > span::after {
          content: "”";
          position: absolute;
          right: 0;
          color: #eee;
          font-size: 120px;
          font-family: "DomaineDisp-Regular", sans-serif; }
  .homepage .block_avis .avis_nom {
    margin-top: 15px;
    font-size: 18px;
    color: #f38f6d;
    font-style: italic; }
  .homepage .block_avis .slick-dots {
    left: 0;
    bottom: -60px; }
  .homepage .block_avis .slick-dots > li {
    margin: 0 5px; }
  .homepage .block_avis .slick-dots > li > button::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1.5px solid #F3A090;
    border-radius: 25px;
    opacity: 1; }
  .homepage .block_avis .slick-dots > li.slick-active > button::before {
    background: #F3A090; }
  @media (max-width: 768px) {
    .homepage .block_avis {
      width: 100%; }
      .homepage .block_avis .slick-carousel .slick-prev {
        left: 10px; }
      .homepage .block_avis .slick-carousel .slick-next {
        right: 20px; }
      .homepage .block_avis .slick-carousel .avis h4 {
        width: auto; }
        .homepage .block_avis .slick-carousel .avis h4 span::before {
          left: 20px; }
        .homepage .block_avis .slick-carousel .avis h4 span::after {
          right: 45px; } }
  @media (max-width: 650px) {
    .homepage .block_avis {
      height: 500px; } }
  @media (max-width: 400px) {
    .homepage .block_avis {
      height: 560px; } }
  @media (max-width: 350px) {
    .homepage .block_avis {
      width: 100%;
      min-width: 320px; } }
.homepage .block_appreciation {
  overflow: hidden; }
  .homepage .block_appreciation h2 {
    font-size: 28px;
    color: #000;
    font-family: "DomaineDisp-Regular", sans-serif;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2.5vh; }
    .homepage .block_appreciation h2 .appreciation-title-text::after {
      content: "";
      height: 3px;
      width: 60px;
      display: none;
      background: #F3A090;
      position: absolute;
      left: 260px;
      top: 30px; }
  .homepage .block_appreciation .appreciation_subblock {
    text-align: center;
    position: relative; }
    .homepage .block_appreciation .appreciation_subblock .appreciation-carousel {
      padding: 0;
      box-shadow: 0 3px 6px #00000029;
      border: 3px solid #F3A090;
      width: 665px;
      position: relative;
      margin: 0 auto;
      height: 511px;
      background: #eeeeee; }
    .homepage .block_appreciation .appreciation_subblock .appreciation-img {
      width: 659px;
      position: absolute;
      top: 0;
      left: 0; }
    .homepage .block_appreciation .appreciation_subblock .appreciation-last-slide {
      background: #ffe8e3;
      width: 659px;
      height: 505px;
      position: absolute;
      top: 0;
      left: 0;
      color: #000;
      display: none;
      z-index: 30; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-last-slide .appreciation-last-slide-title {
        font-family: "DomaineDisp-Semibold", sans-serif;
        font-size: 32px;
        padding: 50px 0 0 0; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-last-slide .appreciation-last-slide-text {
        font-family: "Montserrat-Regular", sans-serif;
        padding: 30px 20px; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-last-slide .appreciation-last-slide-logo img {
        width: 70px;
        padding-top: 70px; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-last-slide .appreciation-last-slide-arrow-wrapper {
        position: absolute;
        bottom: 50px;
        width: 100%;
        text-align: center; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-last-slide .appreciation-last-slide-arrow {
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        width: 30px;
        height: 30px;
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin: 0 auto; }
    .homepage .block_appreciation .appreciation_subblock .coeurs {
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 5px;
      z-index: 200;
      left: 0;
      right: 0;
      margin: 0 auto; }
    .homepage .block_appreciation .appreciation_subblock .coeur-barre {
      float: left;
      margin-right: 10px; }
    .homepage .block_appreciation .appreciation_subblock .coeur {
      float: right;
      margin-left: 10px; }
    .homepage .block_appreciation .appreciation_subblock .coeur, .homepage .block_appreciation .appreciation_subblock .coeur-barre {
      background: #fff;
      border: 2px solid #F3A090;
      border-radius: 50%;
      cursor: pointer;
      padding: 5px;
      transition: all ease 0.3s;
      display: flex;
      justify-content: center;
      align-items: center; }
    .homepage .block_appreciation .appreciation_subblock .coeur:hover, .homepage .block_appreciation .appreciation_subblock .coeur-barre:hover {
      opacity: 0.8; }
    .homepage .block_appreciation .appreciation_subblock .coeur img, .homepage .block_appreciation .appreciation_subblock .coeur-barre img {
      width: 55px;
      height: 55px; }
  @media (max-width: 690px) {
    .homepage .block_appreciation .appreciation_subblock .appreciation-carousel {
      width: 100%;
      height: auto; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-img:first-of-type {
        position: initial; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-img {
        width: 100%;
        height: 100%; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-last-slide {
        width: 100%;
        height: 100%; }
        .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-last-slide .appreciation-last-slide-logo img {
          padding-top: 30px; }
        .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-last-slide .appreciation-last-slide-arrow-wrapper {
          position: initial;
          bottom: auto;
          padding-top: 20px; } }
  @media (max-width: 500px) {
    .homepage .block_appreciation .appreciation_subblock .appreciation-carousel h2 {
      font-size: 22px; }
    .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-title-text::after {
      left: 205px;
      top: 25px; }
    .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-last-slide .appreciation-last-slide-title {
      padding-top: 20px;
      font-size: 22px; }
    .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-last-slide .appreciation-last-slide-text {
      font-size: 14px; }
    .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-last-slide .appreciation-last-slide-logo img {
      padding-top: 0;
      width: 45px; }
    .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-last-slide .appreciation-last-slide-arrow {
      width: 20px;
      height: 20px; } }
  @media (max-width: 390px) {
    .homepage .block_appreciation {
      min-width: 320px; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-carousel h2 {
        font-size: 16px; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-title-text::after {
        left: 148px;
        top: 20px; }
      .homepage .block_appreciation .appreciation_subblock .appreciation-carousel .appreciation-last-slide .appreciation-last-slide-text {
        padding: 15px 20px; } }
  @media (max-width: 300px) {
    .homepage .block_appreciation {
      width: 300px; } }
.homepage .MontSerratRegular {
  font-family: "Montserrat-Regular", sans-serif; }
.homepage .MontSerratBold {
  font-family: "Montserrat-Bold", sans-serif; }
.homepage .c-form__label {
  color: #000; }

.homepage .form-1 {
  color: #000;
  width: 100%;
  position: relative;
  padding: 60px 0; }
  .homepage .form-1 .container {
    width: 1100px;
    margin: 0 auto; }
  .homepage .form-1 .bloc-shadow {
    padding: 40px; }
  .homepage .form-1 .title-form-1 {
    font-family: "DomaineDisp-Regular", sans-serif !important;
    font-size: 22px;
    text-transform: uppercase; }
  .homepage .form-1 .img-full {
    width: 550px;
    display: inline-block;
    height: 550px;
    background: url("/public/images/Box-Vetements-Femmes-2.jpg") no-repeat center;
    background-size: cover; }
  .homepage .form-1 .form-container {
    background: #fafafa;
    /*border: 1px solid #000;*/
    width: 550px;
    display: inline-block;
    height: 550px;
    position: relative;
    min-width: 320px; }
    .homepage .form-1 .form-container > .bloc-shadow {
      box-shadow: none;
      background: transparent; }
      .homepage .form-1 .form-container > .bloc-shadow .row-form-design {
        position: relative; }
        .homepage .form-1 .form-container > .bloc-shadow .row-form-design .label-form-1 {
          position: absolute;
          top: 15px;
          left: 0;
          color: #5b5852;
          cursor: text;
          display: none;
          background: #fafafa; }
        .homepage .form-1 .form-container > .bloc-shadow .row-form-design .input-form-1 {
          width: 100%;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #c0bdba;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: "Montserrat-Regular", sans-serif;
          background: #fafafa;
          box-shadow: none !important;
          transition: all ease 0.1s; }
          .homepage .form-1 .form-container > .bloc-shadow .row-form-design .input-form-1:focus {
            background: #fdfdfd; }
  .homepage .form-1.referred-register {
    padding: 0; }
    .homepage .form-1.referred-register .img-full {
      background-image: none; }
    .homepage .form-1.referred-register .form-container {
      background: #fff;
      width: 100%;
      height: 500px; }
      .homepage .form-1.referred-register .form-container > .bloc-shadow .row-form-design .input-form-1 {
        background: #fff; }
  .homepage .form-1 .c-form__label {
    color: #000;
    font-family: "Montserrat-Regular", sans-serif; }
  .homepage .form-1 .c-btn--register {
    background: #FFAB9E;
    border: none;
    font-size: 18px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 700;
    padding-top: 4px;
    padding-bottom: 8px;
    min-width: 230px; }
    @media (max-width: 480px) {
      .homepage .form-1 .c-btn--register {
        width: 100%; } }
    .homepage .form-1 .c-btn--register:hover, .homepage .form-1 .c-btn--register:active, .homepage .form-1 .c-btn--register:focus {
      transform: none;
      background: #FF8573;
      border: none; }
  .homepage .form-1 .optin-field .optin-newletter {
    font-family: "Montserrat-LightItalic", sans-serif;
    font-size: 13px;
    cursor: pointer; }
  .homepage .form-1 .optin-field .c-form__label::after {
    border: 1px solid #c0bdba !important;
    color: #f3a090 !important;
    width: 17px !important;
    height: 17px !important; }
  .homepage .form-1 .optin-field .c-form__input {
    color: #f3a090 !important; }
  .homepage .form-1 .optin-field .c-form__input:checked + .c-form__label::after {
    border-color: #f3a090 !important;
    color: #f3a090 !important; }
  .homepage .form-1 .register-link {
    color: #000;
    position: absolute;
    right: 20px;
    bottom: 130px; }
    .homepage .form-1 .register-link a {
      color: #555151;
      font-size: 14px;
      font-weight: normal; }
  .homepage .form-1 .country-name {
    display: none; }
  .homepage .form-1 .c-form__field--mimic-select-dropdown .country-name {
    display: initial; }
  @media (max-width: 1240px) {
    .homepage .form-1 {
      padding: 60px 0 !important; } }
  @media (max-width: 1100px) {
    .homepage .form-1 .container .img-full-container {
      width: 100%; }
      .homepage .form-1 .container .img-full-container .img-full {
        width: 100%; }
    .homepage .form-1 .container .form-container {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      z-index: 2; }
    .homepage .form-1.referred-register .container .form-container {
      position: initial;
      margin: 0;
      left: auto;
      right: auto;
      z-index: 2; } }
  @media (max-width: 1000px) {
    .homepage .form-1 .container {
      width: 100%; }
      .homepage .form-1 .container .register-link {
        bottom: 125px; }
        .homepage .form-1 .container .register-link a {
          font-size: 14px; } }
  @media (max-width: 768px) {
    .homepage .form-1 .form-container {
      height: auto; }
      .homepage .form-1 .form-container .bloc-shadow {
        padding: 40px 20px 20px 20px; }
        .homepage .form-1 .form-container .bloc-shadow h2 {
          font-size: 20px; }
    .homepage .form-1 .row-form {
      padding: 10px 0; }
    .homepage .form-1 #submit {
      margin: 10px auto; }
    .homepage .form-1 .text {
      padding: 10px 0; } }
  @media (max-width: 600px) {
    .homepage .form-1 {
      padding: 60px 0 !important; }
      .homepage .form-1 .container .form-container {
        width: 100%;
        position: initial; }
        .homepage .form-1 .container .form-container .input-form-1 {
          width: 65%; }
      .homepage .form-1 .container .img-full-container {
        display: none; } }
  @media (max-width: 450px) {
    .homepage .form-1 .form-container .link-text-grey {
      display: block !important; } }
  @media (max-width: 350px) {
    .homepage .form-1 .container {
      width: 100%;
      min-width: 320px; } }

.homepage .button-arrow {
  width: 20px;
  vertical-align: middle;
  margin-left: 0; }
.homepage .arrow-text {
  vertical-align: middle;
  display: inline-block; }

.homepage2022 {
  /*max-width: 1920px;*/
  color: #000;
  /*@media (min-width: 1200px) {
  	.block_1_2_wrapper{
  		.block_1{
  			.homepage_main_title{
  				width: 550px;
  			}
  		}
  	}
  }*/ }
  .homepage2022 .block_1 {
    text-align: center;
    padding-bottom: 30px;
    background: #fff; }
    .homepage2022 .block_1 .homepage_title {
      font-size: 34px;
      font-family: "DomaineDisp-Regular", sans-serif;
      padding: 20px; }
    .homepage2022 .block_1 .homepage_top_advantages {
      font-size: 16px;
      font-family: 'sofia-pro', sans-serif;
      font-weight: 300;
      padding: 10px 25px; }
      .homepage2022 .block_1 .homepage_top_advantages .homepage_top_advantage {
        text-align: left;
        padding-bottom: 20px;
        padding-left: 25px;
        position: relative; }
        .homepage2022 .block_1 .homepage_top_advantages .homepage_top_advantage::before {
          background: url("/public/images/homepage-pink-check.png");
          width: 20px;
          height: 20px;
          background-size: 20px 20px;
          padding-top: 5px;
          display: inline-block;
          content: "";
          position: absolute;
          top: 4px;
          left: 0; }
    .homepage2022 .block_1 .homepage_top_register .c-btn--red {
      background: #FFAB9E;
      border: none;
      font-size: 18px;
      border-radius: 0;
      font-family: "sofia-pro", sans-serif;
      font-weight: 700;
      padding-top: 4px;
      padding-bottom: 8px;
      min-width: 230px; }
      .homepage2022 .block_1 .homepage_top_register .c-btn--red:hover, .homepage2022 .block_1 .homepage_top_register .c-btn--red:active, .homepage2022 .block_1 .homepage_top_register .c-btn--red:focus {
        transform: none;
        background: #FF8573;
        border: none; }
  .homepage2022 .block_2 {
    overflow: hidden; }
    .homepage2022 .block_2 .homepage_img_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative; }
      .homepage2022 .block_2 .homepage_img_wrapper .block_carrousel {
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        margin: 20px auto 0 auto;
        position: relative; }
        .homepage2022 .block_2 .homepage_img_wrapper .block_carrousel::-webkit-scrollbar {
          display: none; }
        .homepage2022 .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel {
          width: 2120px; }
          .homepage2022 .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel .carrousel-img {
            width: 250px;
            margin-right: 10px; }
      .homepage2022 .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left {
        position: absolute;
        left: 5px;
        height: 50px;
        width: auto;
        cursor: pointer;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: scaleX(-1); }
      .homepage2022 .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right {
        position: absolute;
        right: 5px;
        height: 50px;
        width: auto;
        cursor: pointer;
        top: 0;
        bottom: 0;
        margin: auto; }
  @media (min-width: 700px) {
    .homepage2022 .block_1_2_wrapper {
      height: 450px;
      position: relative;
      overflow: hidden; }
      .homepage2022 .block_1_2_wrapper .block_2 {
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        max-height: 500px;
        max-width: 500px; }
        .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper {
          position: relative;
          width: 100%; }
          .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel {
            width: 100%;
            overflow-x: scroll;
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
            margin: 20px auto 0 auto;
            position: relative; }
            .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel::-webkit-scrollbar {
              display: none; }
            .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel {
              width: 3320px; }
              .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel .carrousel-img {
                width: 400px; }
          .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left {
            position: absolute;
            left: 10px;
            transform: scaleX(-1);
            height: 70px;
            width: auto; }
          .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right {
            position: absolute;
            right: 10px;
            height: 70px;
            width: auto; }
      .homepage2022 .block_1_2_wrapper .block_1 {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%; } }
  @media (min-width: 1000px) {
    .homepage2022 .block_1_2_wrapper {
      height: calc(100vh - 50px);
      position: relative;
      overflow: hidden; }
      .homepage2022 .block_1_2_wrapper .block_2 {
        max-height: none;
        max-width: none; }
        .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper {
          height: calc(100vh - 50px);
          position: relative;
          width: 100%; }
          .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel {
            width: 100%;
            overflow-x: scroll;
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
            margin: 0px auto 0 auto;
            position: relative; }
            .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel::-webkit-scrollbar {
              display: none; }
            .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel {
              width: 6520px; }
              .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel .carrousel-img {
                width: 800px;
                max-height: calc(100vh - 50px); }
          .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left {
            position: absolute;
            left: 10px;
            transform: scaleX(-1);
            height: 70px;
            width: auto; }
          .homepage2022 .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right {
            position: absolute;
            right: 10px;
            height: 70px;
            width: auto; }
      .homepage2022 .block_1_2_wrapper .block_1 {
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
        .homepage2022 .block_1_2_wrapper .block_1 .homepage_main_title {
          font-size: 60px;
          width: 500px;
          margin: 0 auto;
          text-align: left; }
        .homepage2022 .block_1_2_wrapper .block_1 .homepage_top_advantages {
          width: 500px;
          margin: 0 auto;
          font-size: 24px; }
          .homepage2022 .block_1_2_wrapper .block_1 .homepage_top_advantages .homepage_top_advantage {
            padding-left: 30px; }
            .homepage2022 .block_1_2_wrapper .block_1 .homepage_top_advantages .homepage_top_advantage::before {
              width: 25px;
              height: 25px;
              background-size: 25px 25px;
              padding-top: 5px;
              top: 5px; } }
  .homepage2022 .block_3 {
    text-align: center; }
    .homepage2022 .block_3 .homepage_title {
      font-size: 34px;
      font-family: "DomaineDisp-Regular", sans-serif;
      padding: 20px 10px; }
    .homepage2022 .block_3 .homepage_steps {
      width: 250px;
      margin: 0 auto;
      text-align: left; }
      .homepage2022 .block_3 .homepage_steps .homepage_step {
        color: #949599;
        padding-bottom: 30px; }
        .homepage2022 .block_3 .homepage_steps .homepage_step .homepage_step_title {
          font-family: "sofia-pro", sans-serif;
          font-weight: 700;
          color: #000;
          position: relative;
          display: inline; }
          .homepage2022 .block_3 .homepage_steps .homepage_step .homepage_step_title::before {
            font-family: "Roustel", sans-serif;
            color: #f0cec8;
            font-size: 34px;
            position: absolute;
            z-index: -1; }
        .homepage2022 .block_3 .homepage_steps .homepage_step.homepage_step_1 .homepage_step_title:before {
          content: "1";
          top: -14px;
          left: -5px; }
        .homepage2022 .block_3 .homepage_steps .homepage_step.homepage_step_2 .homepage_step_title:before {
          content: "2";
          top: -16px;
          left: -10px; }
        .homepage2022 .block_3 .homepage_steps .homepage_step.homepage_step_3 .homepage_step_title:before {
          content: "3";
          top: -17px;
          left: -9px; }
    @media (min-width: 600px) {
      .homepage2022 .block_3 {
        padding-top: 20px; }
        .homepage2022 .block_3 .homepage_steps .homepage_step .homepage_step_text {
          width: 250px;
          margin: 0 auto; } }
    @media (min-width: 1000px) {
      .homepage2022 .block_3 {
        margin: 50px 0 50px 0; }
        .homepage2022 .block_3 .homepage_steps {
          width: 100%;
          max-width: 1500px;
          margin: 0 auto;
          padding: 0 10px 40px 10px; }
          .homepage2022 .block_3 .homepage_steps .homepage_step {
            display: inline-block;
            width: 33%;
            padding: 10px;
            text-align: center;
            vertical-align: top; }
            .homepage2022 .block_3 .homepage_steps .homepage_step.homepage_step_1 .homepage_step_title:before {
              content: "1";
              top: -2vw;
              left: -0.3vw;
              font-size: 3.5vw; }
            .homepage2022 .block_3 .homepage_steps .homepage_step.homepage_step_2 .homepage_step_title:before {
              content: "2";
              top: -1.7vw;
              left: -1vw;
              font-size: 3.5vw; }
            .homepage2022 .block_3 .homepage_steps .homepage_step.homepage_step_3 .homepage_step_title:before {
              content: "3";
              top: -1.7vw;
              left: -0.8vw;
              font-size: 3.5vw; }
            .homepage2022 .block_3 .homepage_steps .homepage_step .homepage_step_title {
              font-size: 1.5vw; }
            .homepage2022 .block_3 .homepage_steps .homepage_step .homepage_step_text {
              font-size: 1.5vw;
              width: 75%; } }
  .homepage2022 .block_4 {
    background: #f0f0f1;
    text-align: center; }
    .homepage2022 .block_4 .homepage_title {
      font-size: 34px;
      font-family: "DomaineDisp-Regular", sans-serif;
      padding: 20px; }
    .homepage2022 .block_4 .stylist_photo_wrapper {
      width: 100%;
      height: 350px;
      position: relative; }
      .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label {
        width: 50%;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 300;
        color: #a7a09f;
        padding: 0 20px;
        text-align: left;
        position: relative;
        font-size: 14px; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label .homepage_arrow {
          position: absolute;
          width: 70px; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_1 {
          margin-bottom: 60px; }
          .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_1 .homepage_arrow {
            right: 10px;
            bottom: -53px; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_2 .homepage_arrow {
          right: -20px;
          bottom: -10px;
          -moz-transform: rotate(-55deg);
          -webkit-transform: rotate(-55deg);
          -o-transform: rotate(-55deg);
          -ms-transform: rotate(-55deg);
          transform: rotate(-55deg); }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label .stylist_photo_label_title {
          font-size: 16px;
          font-family: "sofia-pro", sans-serif;
          font-weight: 700;
          color: #000; }
      .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        max-height: 350px;
        max-width: 292px; }
    @media (min-width: 400px) {
      .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label {
        padding-right: 40px; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_1 .homepage_arrow {
          right: 0; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_2 .homepage_arrow {
          right: 0; } }
    @media (min-width: 500px) {
      .homepage2022 .block_4 .stylist_photo_wrapper {
        height: 380px; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label {
          padding-right: 60px; } }
    @media (min-width: 600px) {
      .homepage2022 .block_4 {
        max-width: 700px;
        margin: 0 auto; }
        .homepage2022 .block_4 .stylist_photo_wrapper {
          padding: 20px 50px; }
          .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label {
            padding-right: 30px;
            padding-left: 0;
            width: 200px; }
          .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo {
            right: 50px; } }
    @media (min-width: 700px) {
      .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label {
        font-size: 16px; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_1 .homepage_arrow {
          right: -60px;
          width: 100px; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_2 .homepage_arrow {
          right: -60px;
          width: 100px;
          top: 50px; }
        .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label .stylist_photo_label_title {
          font-size: 18px; } }
    @media (min-width: 1000px) {
      .homepage2022 .block_4 {
        width: 1000px;
        max-width: none; }
        .homepage2022 .block_4 .stylist_photo_wrapper {
          height: 590px; }
          .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label {
            font-size: 20px;
            width: calc(50% - 100px); }
            .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_1 {
              margin-bottom: 100px;
              margin-top: 80px; }
              .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_1 .homepage_arrow {
                right: -60px;
                width: 100px; }
            .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label.stylist_photo_label_2 .homepage_arrow {
              right: -60px;
              width: 100px;
              top: 50px; }
            .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo_label_wrapper .stylist_photo_label .stylist_photo_label_title {
              font-size: 22px; }
          .homepage2022 .block_4 .stylist_photo_wrapper .stylist_photo {
            max-height: none;
            max-width: none;
            width: calc(50% - 50px); } }
  .homepage2022 .block_5 {
    text-align: center;
    padding: 0 30px; }
    .homepage2022 .block_5 .homepage_title {
      font-size: 34px;
      font-family: "DomaineDisp-Regular", sans-serif;
      padding: 20px; }
    .homepage2022 .block_5 .homepage_advantages {
      text-align: left; }
      .homepage2022 .block_5 .homepage_advantages .homepage_advantage {
        position: relative;
        padding-left: 25px;
        padding-bottom: 10px; }
        .homepage2022 .block_5 .homepage_advantages .homepage_advantage .homepage_advantage_plus {
          position: absolute;
          width: 20px;
          left: 0;
          top: 0; }
    @media (min-width: 600px) {
      .homepage2022 .block_5 .homepage_advantages {
        width: 310px;
        margin: 0 auto; } }
    @media (min-width: 1000px) {
      .homepage2022 .block_5 {
        margin-top: 30px;
        margin-bottom: 50px;
        background: #f4f4f4;
        padding-bottom: 30px;
        /*.homepage_advantages{
        	width: 500px;
        	font-size: 1.3vw;
        	position: relative;
        }*/ }
        .homepage2022 .block_5 .homepage_advantages {
          width: 1000px;
          font-size: 1.3vw;
          position: relative;
          height: 12vw; }
          .homepage2022 .block_5 .homepage_advantages .homepage_advantages_first_part {
            position: absolute;
            top: 0;
            right: 0;
            width: 500px; }
            .homepage2022 .block_5 .homepage_advantages .homepage_advantages_first_part .homepage_advantage {
              margin-bottom: 1vw; }
          .homepage2022 .block_5 .homepage_advantages .homepage_advantages_second_part {
            position: absolute;
            top: 0;
            left: 0;
            width: 500px;
            padding-right: 10px; }
            .homepage2022 .block_5 .homepage_advantages .homepage_advantages_second_part .homepage_advantage {
              margin-bottom: 1vw; } }
    @media (min-width: 1280px) {
      .homepage2022 .block_5 .homepage_advantages .homepage_advantage .homepage_advantage_plus {
        width: 1.6vw;
        left: -0.2vw;
        top: 0.2vw; } }
  .homepage2022 .block_6 {
    width: 100%;
    margin-top: 20px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    margin: 20px auto 0 auto;
    position: relative; }
    .homepage2022 .block_6::-webkit-scrollbar {
      display: none; }
    .homepage2022 .block_6 .homepage_carrousel {
      width: 2150px; }
      .homepage2022 .block_6 .homepage_carrousel .carrousel-img {
        width: 250px;
        margin-right: 10px; }
    @media (min-width: 1000px) {
      .homepage2022 .block_6 .homepage_carrousel {
        width: 4120px; }
        .homepage2022 .block_6 .homepage_carrousel .carrousel-img {
          width: 500px; } }
  .homepage2022 .block_6_overlay {
    position: relative;
    width: 100%;
    /*max-width: 1200px;*/
    margin: 0 auto; }
    .homepage2022 .block_6_overlay #carrousel-arrow-left {
      position: absolute;
      left: 0;
      top: -150px;
      height: 50px;
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      cursor: pointer; }
    .homepage2022 .block_6_overlay #carrousel-arrow-right {
      position: absolute;
      right: 0;
      top: -150px;
      height: 50px;
      cursor: pointer; }
    @media (min-width: 1000px) {
      .homepage2022 .block_6_overlay #carrousel-arrow-left {
        position: absolute;
        left: 0;
        top: -300px;
        height: 70px;
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        cursor: pointer; }
      .homepage2022 .block_6_overlay #carrousel-arrow-right {
        position: absolute;
        right: 0;
        top: -300px;
        height: 70px;
        cursor: pointer; } }
  .homepage2022 .block_7 {
    text-align: center;
    padding: 30px 0 20px 0;
    max-width: 800px;
    margin: 0 auto; }
    .homepage2022 .block_7 .homepage_title {
      font-size: 34px;
      font-family: "DomaineDisp-Regular", sans-serif;
      padding: 20px; }
    .homepage2022 .block_7 .trustpilot-widget {
      -moz-transform: scale(0.8);
      -webkit-transform: scale(0.8);
      -o-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8); }
      @media (min-width: 1000px) {
        .homepage2022 .block_7 .trustpilot-widget {
          margin-top: 30px;
          -moz-transform: scale(1.2);
          -webkit-transform: scale(1.2);
          -o-transform: scale(1.2);
          -ms-transform: scale(1.2);
          transform: scale(1.2); } }
  @media (min-width: 1000px) {
    .homepage2022 .homepage_title, .homepage2022 .title-form-1 {
      font-size: 2.5vw !important; }
    .homepage2022 .homepage_main_title {
      font-size: 9vh !important;
      line-height: 9vh; } }
  .homepage2022 .MontSerratRegular {
    font-family: "Montserrat-Regular", sans-serif; }
  .homepage2022 .MontSerratBold {
    font-family: "Montserrat-Bold", sans-serif; }
  .homepage2022 .c-form__label {
    color: #000; }

.homepage2022 .form-inscription, #connexion .form-inscription {
  color: #000;
  width: 100%;
  position: relative;
  background: #fff; }
  .homepage2022 .form-inscription .container, #connexion .form-inscription .container {
    width: 1100px;
    margin: 0 auto; }
  .homepage2022 .form-inscription .bloc-shadow, #connexion .form-inscription .bloc-shadow {
    padding: 40px 20px; }
  .homepage2022 .form-inscription .title-form-1, #connexion .form-inscription .title-form-1 {
    font-family: "DomaineDisp-Regular", sans-serif !important;
    font-size: 34px;
    text-align: center;
    margin-bottom: 0px; }
  .homepage2022 .form-inscription .sub-title-form-1, #connexion .form-inscription .sub-title-form-1 {
    text-align: center;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 30px; }
  .homepage2022 .form-inscription .img-full, #connexion .form-inscription .img-full {
    width: 550px;
    display: inline-block;
    height: 550px;
    background: #fff;
    background-size: cover; }
  .homepage2022 .form-inscription .form-container, #connexion .form-inscription .form-container {
    background: #fff;
    /*border: 1px solid #000;*/
    width: 550px;
    position: relative;
    min-width: 320px;
    margin: 0 auto; }
    .homepage2022 .form-inscription .form-container.register-form, #connexion .form-inscription .form-container.register-form {
      height: auto; }
    .homepage2022 .form-inscription .form-container > .bloc-shadow, #connexion .form-inscription .form-container > .bloc-shadow {
      box-shadow: none;
      background: transparent; }
      .homepage2022 .form-inscription .form-container > .bloc-shadow .row-form-design, #connexion .form-inscription .form-container > .bloc-shadow .row-form-design {
        position: relative; }
        .homepage2022 .form-inscription .form-container > .bloc-shadow .row-form-design.error-field-open .error-field, #connexion .form-inscription .form-container > .bloc-shadow .row-form-design.error-field-open .error-field {
          display: block;
          font-size: 14px; }
        .homepage2022 .form-inscription .form-container > .bloc-shadow .row-form-design .label-form-1, #connexion .form-inscription .form-container > .bloc-shadow .row-form-design .label-form-1 {
          position: absolute;
          top: 15px;
          left: 0;
          color: #000000;
          cursor: text;
          display: none;
          background: #fff;
          font-family: "sofia-pro", sans-serif;
          font-weight: 700;
          font-size: 1.25em; }
        .homepage2022 .form-inscription .form-container > .bloc-shadow .row-form-design .input-form-1, #connexion .form-inscription .form-container > .bloc-shadow .row-form-design .input-form-1 {
          width: 100%;
          border-top: none;
          border-left: none;
          border-right: none;
          bordr-bottom: 1px solid #707070;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: "Montserrat-Regular", sans-serif;
          background: #fff;
          box-shadow: none !important;
          transition: all ease 0.1s; }
          .homepage2022 .form-inscription .form-container > .bloc-shadow .row-form-design .input-form-1::placeholder, #connexion .form-inscription .form-container > .bloc-shadow .row-form-design .input-form-1::placeholder {
            color: #4d4d4d;
            font-size: 0.9em;
            font-family: 'sofia-pro', sans-serif;
            font-weight: 300; }
          .homepage2022 .form-inscription .form-container > .bloc-shadow .row-form-design .input-form-1:focus, #connexion .form-inscription .form-container > .bloc-shadow .row-form-design .input-form-1:focus {
            background: #fdfdfd; }
        .homepage2022 .form-inscription .form-container > .bloc-shadow .row-form-design .error-field, #connexion .form-inscription .form-container > .bloc-shadow .row-form-design .error-field {
          display: none;
          color: #f0a918; }
  .homepage2022 .form-inscription .c-form__label, #connexion .form-inscription .c-form__label {
    color: #000;
    font-family: "Montserrat-Regular", sans-serif; }
  .homepage2022 .form-inscription .c-btn--register, #connexion .form-inscription .c-btn--register {
    background: #FFAB9E;
    border: none;
    font-size: 18px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 700;
    min-width: 230px;
    padding-top: 4px;
    padding-bottom: 8px; }
    @media (max-width: 480px) {
      .homepage2022 .form-inscription .c-btn--register, #connexion .form-inscription .c-btn--register {
        width: 100%; } }
    .homepage2022 .form-inscription .c-btn--register:hover, .homepage2022 .form-inscription .c-btn--register:active, .homepage2022 .form-inscription .c-btn--register:focus, #connexion .form-inscription .c-btn--register:hover, #connexion .form-inscription .c-btn--register:active, #connexion .form-inscription .c-btn--register:focus {
      transform: none;
      background: #FF8573;
      border: none; }
  .homepage2022 .form-inscription .teal-color, #connexion .form-inscription .teal-color {
    color: #26b783 !important;
    font-family: "sofia-pro", sans-serif;
    font-weight: 700; }
  .homepage2022 .form-inscription .optin-field .optin-newletter, #connexion .form-inscription .optin-field .optin-newletter {
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300;
    font-size: 0.9em;
    color: #000000;
    cursor: pointer; }
  .homepage2022 .form-inscription .optin-field .c-form__label::after, #connexion .form-inscription .optin-field .c-form__label::after {
    border: 1px solid #c0bdba !important;
    color: #f3a090 !important;
    width: 17px !important;
    height: 17px !important; }
  .homepage2022 .form-inscription .optin-field .c-form__input, #connexion .form-inscription .optin-field .c-form__input {
    color: #f3a090 !important; }
  .homepage2022 .form-inscription .optin-field .c-form__input:checked + .c-form__label::after, #connexion .form-inscription .optin-field .c-form__input:checked + .c-form__label::after {
    border-color: #f3a090 !important;
    color: #f3a090 !important; }
  .homepage2022 .form-inscription .block_trustpilot_widget, #connexion .form-inscription .block_trustpilot_widget {
    margin: 20px 0; }
  .homepage2022 .form-inscription .register-link, #connexion .form-inscription .register-link {
    color: #000; }
    .homepage2022 .form-inscription .register-link a, #connexion .form-inscription .register-link a {
      color: #000;
      font-size: 14px;
      font-weight: normal; }
  .homepage2022 .form-inscription .country-name, #connexion .form-inscription .country-name {
    display: none; }
  .homepage2022 .form-inscription .c-form__field--mimic-select-dropdown .country-name, #connexion .form-inscription .c-form__field--mimic-select-dropdown .country-name {
    display: initial; }
  @media (max-width: 1240px) {
    .homepage2022 .form-inscription, #connexion .form-inscription {
      padding: 0 !important; } }
  @media (max-width: 1100px) {
    .homepage2022 .form-inscription .container .img-full-container, #connexion .form-inscription .container .img-full-container {
      width: 100%; }
      .homepage2022 .form-inscription .container .img-full-container .img-full, #connexion .form-inscription .container .img-full-container .img-full {
        width: 100%; }
    .homepage2022 .form-inscription .container .form-container, #connexion .form-inscription .container .form-container {
      margin: 0 auto; }
    .homepage2022 .form-inscription.referred-register .container .form-container, #connexion .form-inscription.referred-register .container .form-container {
      position: initial;
      margin: 0;
      left: auto;
      right: auto;
      z-index: 2; } }
  @media (max-width: 1000px) {
    .homepage2022 .form-inscription .container, #connexion .form-inscription .container {
      width: 100%; }
      .homepage2022 .form-inscription .container .register-link a, #connexion .form-inscription .container .register-link a {
        font-size: 14px; } }
  @media (max-width: 768px) {
    .homepage2022 .form-inscription .form-container, #connexion .form-inscription .form-container {
      height: auto; }
      .homepage2022 .form-inscription .form-container .bloc-shadow, #connexion .form-inscription .form-container .bloc-shadow {
        padding: 40px 20px 20px 20px; }
    .homepage2022 .form-inscription .row-form, #connexion .form-inscription .row-form {
      padding: 10px 0; }
    .homepage2022 .form-inscription #submit, #connexion .form-inscription #submit {
      margin: 10px auto; }
    .homepage2022 .form-inscription .text, #connexion .form-inscription .text {
      padding: 10px 0; } }
  @media (max-width: 600px) {
    .homepage2022 .form-inscription, #connexion .form-inscription {
      padding: 0 !important; }
      .homepage2022 .form-inscription .container .form-container, #connexion .form-inscription .container .form-container {
        width: 100%;
        position: initial; }
        .homepage2022 .form-inscription .container .form-container .input-form-1, #connexion .form-inscription .container .form-container .input-form-1 {
          width: 65%; }
      .homepage2022 .form-inscription .container .img-full-container, #connexion .form-inscription .container .img-full-container {
        display: none; } }
  @media (max-width: 450px) {
    .homepage2022 .form-inscription .form-container .link-text-grey, #connexion .form-inscription .form-container .link-text-grey {
      display: block !important; } }
  @media (max-width: 350px) {
    .homepage2022 .form-inscription .container, #connexion .form-inscription .container {
      width: 100%;
      min-width: 320px; } }

.homepage2022 .button-arrow, #connexion .button-arrow {
  width: 20px;
  vertical-align: middle;
  margin-left: 0; }
.homepage2022 .arrow-text, #connexion .arrow-text {
  vertical-align: middle;
  display: inline-block; }

.homepage2023 {
  color: #000; }
  .homepage2023 .block_1_2_wrapper .block_1 {
    padding-left: 20px;
    padding-right: 20px; }
    .homepage2023 .block_1_2_wrapper .block_1 h1 {
      font-size: var(--title-size);
      color: var(--black);
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-bold);
      padding-top: 1.25em;
      line-height: var(--line-height-base); }
      .homepage2023 .block_1_2_wrapper .block_1 h1 span.manuscript {
        font-family: var(--text-font-manuscrit);
        font-size: 2rem;
        font-weight: 400; }
    .homepage2023 .block_1_2_wrapper .block_1 h2 {
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-light);
      font-size: var(--text-size);
      line-height: var(--line-height-base);
      color: var(--black);
      padding: 1.25em 0;
      max-width: 480px;
      margin: 0 auto; }
    .homepage2023 .block_1_2_wrapper .block_1 .homepage_top_register .c-btn--red {
      background: var(--black);
      border: 1px solid white;
      font-size: 18px;
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-bold);
      padding-top: 4px;
      padding-bottom: 8px;
      min-width: 230px; }
      @media (max-width: 535px) {
        .homepage2023 .block_1_2_wrapper .block_1 .homepage_top_register .c-btn--red {
          width: 100%; } }
      .homepage2023 .block_1_2_wrapper .block_1 .homepage_top_register .c-btn--red:hover, .homepage2023 .block_1_2_wrapper .block_1 .homepage_top_register .c-btn--red:active, .homepage2023 .block_1_2_wrapper .block_1 .homepage_top_register .c-btn--red:focus {
        transform: none;
        border: 1px solid black; }
  .homepage2023 .block_1_2_wrapper .block_trustpilot_widget {
    margin-top: 5px; }
    .homepage2023 .block_1_2_wrapper .block_trustpilot_widget .trustpilot-widget {
      text-align: center; }
      .homepage2023 .block_1_2_wrapper .block_trustpilot_widget .trustpilot-widget img {
        width: 240px; }
  .homepage2023 .block_1_2_wrapper .block_2 {
    overflow: hidden; }
    .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative; }
      .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel2 {
        width: 100%;
        overflow-x: scroll;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        margin: 20px auto 0 auto;
        position: relative; }
        .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel2::-webkit-scrollbar {
          display: none; }
        .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel2 .homepage_carrousel2 {
          width: 2120px; }
          .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel2 .homepage_carrousel2 .carrousel-img {
            width: 250px;
            margin-right: 10px; }
      .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left2 {
        position: absolute;
        left: 5px;
        height: 50px;
        width: auto;
        cursor: pointer;
        top: 20px;
        bottom: 0;
        margin: auto; }
      .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right2 {
        position: absolute;
        right: 5px;
        height: 50px;
        width: auto;
        cursor: pointer;
        top: 20px;
        bottom: 0;
        margin: auto; }
      @media (min-width: 768px) {
        .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left2 {
          left: 15px; }
        .homepage2023 .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right2 {
          right: 15px; } }
  .homepage2023 .block_1_2_wrapper h2.how-to-title {
    font-size: var(--title-size);
    color: var(--black);
    font-family: 'sofia-pro', sans-serif;
    font-weight: var(--text-font-bold);
    padding-top: 20px;
    line-height: var(--line-height-base);
    text-align: center; }
  .homepage2023 .block_1_2_wrapper .bi-chevron-down {
    margin-top: 40px; }
  .homepage2023 .block_3 {
    padding-left: 20px;
    padding-right: 20px; }
    .homepage2023 .block_3 h2.how-to-title {
      font-size: var(--title-size);
      color: var(--black);
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-bold);
      padding-top: 20px;
      line-height: var(--line-height-base); }
    .homepage2023 .block_3 .bi-chevron-down {
      margin-top: 30px;
      margin-bottom: 10px;
      width: 20px;
      height: 20px; }
    @media (min-width: 768px) {
      .homepage2023 .block_3 .bi-chevron-down {
        width: 30px;
        height: 30px; } }
    .homepage2023 .block_3 .step-card-wrap {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .homepage2023 .block_3 .step-card-wrap .step-card {
        display: flex;
        flex: 1 1 0px;
        flex-direction: row-reverse;
        align-items: center;
        flex-grow: 1;
        gap: 10px;
        margin-bottom: 5px;
        width: 100%; }
        .homepage2023 .block_3 .step-card-wrap .step-card h4, .homepage2023 .block_3 .step-card-wrap .step-card h3 {
          font-family: 'sofia-pro', sans-serif;
          font-weight: var(--text-font-light);
          font-size: var(--text-size);
          color: var(--black);
          line-height: var(--line-height-base);
          text-align: left;
          width: 70%; }
        .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap {
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30%; }
          .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap .img-step.pic-phone {
            height: auto;
            max-height: 100%;
            max-width: 100%;
            object-fit: contain; }
          .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap .img-step.pic-clothes {
            height: auto;
            max-width: 100%;
            object-fit: contain; }
          .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap .img-step.pic-box {
            height: auto;
            max-width: 100%;
            object-fit: contain;
            align-self: center; }
          .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap .img-step.pic-box-euros {
            height: auto;
            width: 107px;
            align-self: center; }
        @media (min-width: 430px) and (max-width: 535px) {
          .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap {
            height: 165px; } }
        @media (min-width: 535px) {
          .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap {
            height: 190px; } }
        .homepage2023 .block_3 .step-card-wrap .step-card .bi-chevron-down {
          margin-top: 40px; }
        .homepage2023 .block_3 .step-card-wrap .step-card .hidden {
          display: none; }
      @media (max-width: 768px) {
        .homepage2023 .block_3 .step-card-wrap .euros-card {
          height: 210px; } }
      @media (min-width: 768px) {
        .homepage2023 .block_3 .step-card-wrap .step-card {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          text-align: center;
          flex-grow: 1;
          margin-bottom: 0px; } }
    @media (min-width: 535px) and (max-width: 710px) {
      .homepage2023 .block_3 .step-card-wrap .step-card {
        width: 90%;
        gap: 20px;
        margin-bottom: 5px; }
        .homepage2023 .block_3 .step-card-wrap .step-card h4, .homepage2023 .block_3 .step-card-wrap .step-card h3 {
          font-size: 1.2rem;
          width: 65%; }
        .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap {
          width: 35%;
          height: 185px; } }
    @media (min-width: 711px) and (max-width: 768px) {
      .homepage2023 .block_3 .step-card-wrap .step-card {
        width: 65%;
        gap: 30px; }
        .homepage2023 .block_3 .step-card-wrap .step-card h4, .homepage2023 .block_3 .step-card-wrap .step-card h3 {
          font-size: 1.2rem;
          width: 60%; }
        .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap {
          width: 40%; } }
    @media (min-width: 768px) {
      .homepage2023 .block_3 .step-card-wrap.col3-grid-wrap {
        gap: 25px; }
      .homepage2023 .block_3 .step-card-wrap.col4-grid-wrap {
        gap: 25px; }
      .homepage2023 .block_3 .step-card-wrap {
        flex-direction: row;
        align-items: flex-start;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); }
        .homepage2023 .block_3 .step-card-wrap .step-card {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          text-align: center;
          flex-grow: 1;
          margin-bottom: 0px;
          gap: 0px; }
          .homepage2023 .block_3 .step-card-wrap .step-card h4, .homepage2023 .block_3 .step-card-wrap .step-card h3 {
            max-width: 300px;
            width: auto;
            text-align: center;
            margin-top: 10px; }
          .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap {
            min-width: 150px;
            max-width: 180px;
            height: 160px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap .img-step.pic-phone {
              height: auto;
              width: 175px;
              align-self: end; }
            .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap .img-step.pic-clothes {
              height: auto;
              width: 175px;
              align-self: end; }
            .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap .img-step.pic-box {
              height: auto;
              width: 175px;
              align-self: end; }
            .homepage2023 .block_3 .step-card-wrap .step-card .img-step-wrap .img-step.pic-box-euros {
              height: auto;
              width: 175px;
              align-self: end; } }
    @media (min-width: 1200px) {
      .homepage2023 .block_3 .step-card-wrap.col3-grid-wrap {
        margin: 0 10vw; }
      .homepage2023 .block_3 .step-card-wrap.col4-grid-wrap {
        margin: 0 5vw; } }
    .homepage2023 .block_3 .homepage_top_register {
      margin: 20px auto; }
      .homepage2023 .block_3 .homepage_top_register .c-btn--red {
        background: var(--black);
        border: 1px solid white;
        font-size: 18px;
        font-family: 'sofia-pro', sans-serif;
        font-weight: var(--text-font-bold);
        padding-top: 4px;
        padding-bottom: 8px;
        min-width: 230px; }
        @media (max-width: 535px) {
          .homepage2023 .block_3 .homepage_top_register .c-btn--red {
            width: 100%; } }
        .homepage2023 .block_3 .homepage_top_register .c-btn--red:hover, .homepage2023 .block_3 .homepage_top_register .c-btn--red:active, .homepage2023 .block_3 .homepage_top_register .c-btn--red:focus {
          transform: none;
          border: 1px solid black; }
    @media (min-width: 768px) {
      .homepage2023 .block_3 .homepage_top_register {
        margin: 40px auto 25px auto; } }
  .homepage2023 .block_4 .section .text-block {
    width: 100%; }
    .homepage2023 .block_4 .section .text-block h2 {
      font-size: var(--title-size);
      color: var(--black);
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-bold);
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: var(--line-height-base);
      text-align: center; }
    .homepage2023 .block_4 .section .text-block .text-block-ul {
      width: 100%; }
      .homepage2023 .block_4 .section .text-block .text-block-ul ul {
        margin: 0 auto;
        font-size: var(--text-size);
        font-family: 'sofia-pro', sans-serif;
        font-weight: 300;
        padding: 10px 25px 0 25px; }
        .homepage2023 .block_4 .section .text-block .text-block-ul ul.first-ul {
          max-width: 400px; }
        .homepage2023 .block_4 .section .text-block .text-block-ul ul.second-ul {
          max-width: 500px; }
        .homepage2023 .block_4 .section .text-block .text-block-ul ul .homepage_advantage {
          text-align: left;
          padding-bottom: 20px;
          padding-left: 25px;
          position: relative; }
          .homepage2023 .block_4 .section .text-block .text-block-ul ul .homepage_advantage::before {
            background: url("/public/images/homepage-pink-check.png");
            width: 20px;
            height: 20px;
            background-size: 20px 20px;
            padding-top: 5px;
            display: inline-block;
            content: "";
            position: absolute;
            top: 4px;
            left: 0; }
  @media (min-width: 768px) {
    .homepage2023 .block_4 .section .text-block {
      width: 50%; } }
  @media (max-width: 768px) {
    .homepage2023 .block_4 .section .img-advantage {
      margin-top: 20px; } }
  .homepage2023 .block_4 .section .img-advantage img {
    height: auto;
    max-width: 100%; }
  @media (min-width: 768px) {
    .homepage2023 .block_4 .section .img-advantage {
      height: 272px; }
      .homepage2023 .block_4 .section .img-advantage img {
        height: 272px; } }
  .homepage2023 .block_4 .section .section-2 .img-advantage {
    display: none; }
  @media (min-width: 768px) {
    .homepage2023 .block_4 .section .section-2 .img-advantage {
      display: block; } }
  @media (min-width: 768px) {
    .homepage2023 .block_4 .section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 312px; }
    .homepage2023 .block_4 .section:nth-child(2) {
      background-color: #FBEEE8; }
    .homepage2023 .block_4 .section-1 {
      width: 80vw;
      margin: 0px auto;
      gap: 20px; }
    .homepage2023 .block_4 .section-2 {
      padding: 20px 0;
      display: flex;
      width: 80vw;
      margin: 0px auto;
      flex-direction: row-reverse;
      gap: 20px;
      align-items: center; } }
  .homepage2023 .block_6 h2 {
    font-size: var(--title-size);
    color: var(--black);
    font-family: 'sofia-pro', sans-serif;
    font-weight: var(--text-font-bold);
    padding-top: 15px;
    padding-bottom: 20px;
    line-height: var(--line-height-base);
    text-align: center; }
    .homepage2023 .block_6 h2 span.manuscript {
      font-family: var(--text-font-manuscrit);
      font-size: 2rem;
      font-weight: 400; }
  .homepage2023 .block_6 .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    margin: 20px 20px 40px 20px; }
    .homepage2023 .block_6 .grid-container .grid-item {
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
    .homepage2023 .block_6 .grid-container .grid-item:nth-child(1), .homepage2023 .block_6 .grid-container .grid-item:nth-child(3), .homepage2023 .block_6 .grid-container .grid-item:nth-child(5) {
      background-color: #F7CABC;
      width: 90px;
      height: 90px;
      border-radius: 15px;
      border: 1px solid #F7CABC;
      font-family: 'sofia-pro', sans-serif;
      font-weight: 300;
      font-size: 0.9rem; }
    .homepage2023 .block_6 .grid-container .grid-item:nth-child(2), .homepage2023 .block_6 .grid-container .grid-item:nth-child(4) {
      color: grey;
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-light);
      font-size: 0.9rem; }
  @media (min-width: 400px) and (max-width: 479px) {
    .homepage2023 .block_6 .grid-container .grid-item:nth-child(1), .homepage2023 .block_6 .grid-container .grid-item:nth-child(3), .homepage2023 .block_6 .grid-container .grid-item:nth-child(5) {
      width: 110px;
      height: 110px;
      font-size: 1rem; }
    .homepage2023 .block_6 .grid-container .grid-item:nth-child(2), .homepage2023 .block_6 .grid-container .grid-item:nth-child(4) {
      font-size: 1rem; } }
  @media (min-width: 480px) and (max-width: 619px) {
    .homepage2023 .block_6 .grid-container {
      max-width: 600px;
      margin: 20px 20px 40px 20px; }
      .homepage2023 .block_6 .grid-container .grid-item:nth-child(1), .homepage2023 .block_6 .grid-container .grid-item:nth-child(3), .homepage2023 .block_6 .grid-container .grid-item:nth-child(5) {
        width: 130px;
        height: 130px;
        font-size: 1.1rem; }
      .homepage2023 .block_6 .grid-container .grid-item:nth-child(2), .homepage2023 .block_6 .grid-container .grid-item:nth-child(4) {
        font-size: 1.1rem; } }
  @media (min-width: 620px) {
    .homepage2023 .block_6 .grid-container {
      max-width: 600px;
      margin: 20px auto 40px auto; }
      .homepage2023 .block_6 .grid-container .grid-item:nth-child(1), .homepage2023 .block_6 .grid-container .grid-item:nth-child(3), .homepage2023 .block_6 .grid-container .grid-item:nth-child(5) {
        width: 150px;
        height: 150px;
        font-size: 1.2rem; }
      .homepage2023 .block_6 .grid-container .grid-item:nth-child(2), .homepage2023 .block_6 .grid-container .grid-item:nth-child(4) {
        font-size: 1.2rem; } }
  .homepage2023 .block_6 .homepage_top_register {
    margin: 20px auto;
    padding: 0 20px; }
    .homepage2023 .block_6 .homepage_top_register .c-btn--red {
      background: var(--black);
      border: 1px solid white;
      font-size: 18px;
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-bold);
      padding-top: 4px;
      padding-bottom: 8px;
      min-width: 230px; }
      @media (max-width: 535px) {
        .homepage2023 .block_6 .homepage_top_register .c-btn--red {
          width: 100%; } }
      .homepage2023 .block_6 .homepage_top_register .c-btn--red:hover, .homepage2023 .block_6 .homepage_top_register .c-btn--red:active, .homepage2023 .block_6 .homepage_top_register .c-btn--red:focus {
        transform: none;
        border: 1px solid black; }
  .homepage2023 .block_7 {
    margin: 40px 20px; }
    .homepage2023 .block_7 h2 {
      font-family: "sofia-pro", sans-serif !important;
      font-weight: 700;
      font-size: var(--title-size);
      color: #000000;
      margin-bottom: 40px; }
    .homepage2023 .block_7 .wrap {
      max-width: 600px;
      margin: 0 auto; }
      .homepage2023 .block_7 .wrap .inner-wrap-question {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: 'sofia-pro', sans-serif;
        font-weight: var(--text-font-light);
        font-size: 16px;
        line-height: 1.3;
        cursor: pointer; }
        .homepage2023 .block_7 .wrap .inner-wrap-question h5 {
          text-align: left; }
        .homepage2023 .block_7 .wrap .inner-wrap-question .icon {
          cursor: pointer;
          width: 50px; }
        .homepage2023 .block_7 .wrap .inner-wrap-question .icon-1:hover, .homepage2023 .block_7 .wrap .inner-wrap-question .icon-2:hover, .homepage2023 .block_7 .wrap .inner-wrap-question .icon-3:hover, .homepage2023 .block_7 .wrap .inner-wrap-question .icon-4:hover {
          transform: translateX(15px) !important;
          transition: all 0.25s cubic-bezier(0.5, 0, 0.32, 1); }
        .homepage2023 .block_7 .wrap .inner-wrap-question .icon-1, .homepage2023 .block_7 .wrap .inner-wrap-question .icon-2, .homepage2023 .block_7 .wrap .inner-wrap-question .icon-3, .homepage2023 .block_7 .wrap .inner-wrap-question .icon-4 {
          transition: transform 0.5s cubic-bezier(0.17, 0.8, 0.32, 1.2); }
      @media (min-width: 420px) {
        .homepage2023 .block_7 .wrap .inner-wrap-question {
          line-height: 2;
          font-size: 16px; } }
      .homepage2023 .block_7 .wrap hr {
        border-top: none;
        border-left: none;
        border-right: none; }
      .homepage2023 .block_7 .wrap .questions-line {
        max-width: 600px;
        border-bottom-color: #ededed;
        border-width: 1px;
        padding-top: 1px; }
      .homepage2023 .block_7 .wrap .inner-wrap-answer {
        font-size: 16px;
        padding-top: 15px;
        line-height: 1.5;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 300; }
      .homepage2023 .block_7 .wrap .inner-wrap-answer.invisible {
        display: none; }

/*--------------------
La zone externe d'Inscription, Connexion
--------------------*/
.homepage2023 .form-inscription, .homepage2023 #real-inscription, #connexion .form-inscription, #connexion #real-inscription {
  color: #000;
  width: 100%;
  position: relative;
  background: #fff; }
  .homepage2023 .form-inscription .trustpilot-widget, .homepage2023 #real-inscription .trustpilot-widget, #connexion .form-inscription .trustpilot-widget, #connexion #real-inscription .trustpilot-widget {
    text-align: center; }
    .homepage2023 .form-inscription .trustpilot-widget img, .homepage2023 #real-inscription .trustpilot-widget img, #connexion .form-inscription .trustpilot-widget img, #connexion #real-inscription .trustpilot-widget img {
      width: 240px; }
  .homepage2023 .form-inscription .container, .homepage2023 #real-inscription .container, #connexion .form-inscription .container, #connexion #real-inscription .container {
    width: 1100px;
    margin: 0 auto; }
  .homepage2023 .form-inscription .bloc-shadow, .homepage2023 #real-inscription .bloc-shadow, #connexion .form-inscription .bloc-shadow, #connexion #real-inscription .bloc-shadow {
    padding: 40px 20px; }
  .homepage2023 .form-inscription .img-full, .homepage2023 #real-inscription .img-full, #connexion .form-inscription .img-full, #connexion #real-inscription .img-full {
    width: 550px;
    display: inline-block;
    height: 550px;
    background: #fff;
    background-size: cover; }
  .homepage2023 .form-inscription .form-container, .homepage2023 #real-inscription .form-container, #connexion .form-inscription .form-container, #connexion #real-inscription .form-container {
    background: #fff;
    max-width: 550px;
    position: relative;
    min-width: 320px;
    margin: 0 auto; }
    .homepage2023 .form-inscription .form-container.register-form, .homepage2023 #real-inscription .form-container.register-form, #connexion .form-inscription .form-container.register-form, #connexion #real-inscription .form-container.register-form {
      height: auto; }
    .homepage2023 .form-inscription .form-container .bloc-shadow, .homepage2023 #real-inscription .form-container .bloc-shadow, #connexion .form-inscription .form-container .bloc-shadow, #connexion #real-inscription .form-container .bloc-shadow {
      box-shadow: none;
      background: transparent;
      font-family: DomaineDisp-Regular;
      /*--------------------
      SIGN UP FORM
      --------------------*/ }
      .homepage2023 .form-inscription .form-container .bloc-shadow .bi-chevron-left:hover, .homepage2023 #real-inscription .form-container .bloc-shadow .bi-chevron-left:hover, #connexion .form-inscription .form-container .bloc-shadow .bi-chevron-left:hover, #connexion #real-inscription .form-container .bloc-shadow .bi-chevron-left:hover {
        transform: scale(1.1); }
      .homepage2023 .form-inscription .form-container .bloc-shadow h2.c-title, .homepage2023 #real-inscription .form-container .bloc-shadow h2.c-title, #connexion .form-inscription .form-container .bloc-shadow h2.c-title, #connexion #real-inscription .form-container .bloc-shadow h2.c-title {
        font-family: "sofia-pro", sans-serif !important;
        font-weight: 700;
        font-size: 1.8em;
        color: #000000;
        padding-top: 20px;
        padding-left: 35px; }
      .homepage2023 .form-inscription .form-container .bloc-shadow ul.inscription-steps, .homepage2023 #real-inscription .form-container .bloc-shadow ul.inscription-steps, #connexion .form-inscription .form-container .bloc-shadow ul.inscription-steps, #connexion #real-inscription .form-container .bloc-shadow ul.inscription-steps {
        padding-bottom: 30px; }
        .homepage2023 .form-inscription .form-container .bloc-shadow ul.inscription-steps li.homepage_top_advantage, .homepage2023 #real-inscription .form-container .bloc-shadow ul.inscription-steps li.homepage_top_advantage, #connexion .form-inscription .form-container .bloc-shadow ul.inscription-steps li.homepage_top_advantage, #connexion #real-inscription .form-container .bloc-shadow ul.inscription-steps li.homepage_top_advantage {
          font-family: 'sofia-pro', sans-serif;
          font-weight: 300;
          font-size: 20px;
          color: #000000;
          text-align: left;
          padding-left: 35px;
          position: relative;
          line-height: 2; }
          .homepage2023 .form-inscription .form-container .bloc-shadow ul.inscription-steps li.homepage_top_advantage::before, .homepage2023 #real-inscription .form-container .bloc-shadow ul.inscription-steps li.homepage_top_advantage::before, #connexion .form-inscription .form-container .bloc-shadow ul.inscription-steps li.homepage_top_advantage::before, #connexion #real-inscription .form-container .bloc-shadow ul.inscription-steps li.homepage_top_advantage::before {
            background: url("/public/images/homepage-pink-check.png");
            width: 25px;
            height: 25px;
            background-size: 25px 25px;
            padding-top: 5px;
            display: inline-block;
            content: "";
            position: absolute;
            top: 4px;
            left: 0; }
      .homepage2023 .form-inscription .form-container .bloc-shadow .row-form-design, .homepage2023 #real-inscription .form-container .bloc-shadow .row-form-design, #connexion .form-inscription .form-container .bloc-shadow .row-form-design, #connexion #real-inscription .form-container .bloc-shadow .row-form-design {
        position: relative; }
        .homepage2023 .form-inscription .form-container .bloc-shadow .row-form-design.error-field-open .error-field, .homepage2023 #real-inscription .form-container .bloc-shadow .row-form-design.error-field-open .error-field, #connexion .form-inscription .form-container .bloc-shadow .row-form-design.error-field-open .error-field, #connexion #real-inscription .form-container .bloc-shadow .row-form-design.error-field-open .error-field {
          display: block;
          font-size: 14px; }
        .homepage2023 .form-inscription .form-container .bloc-shadow .row-form-design .label-form-1, .homepage2023 #real-inscription .form-container .bloc-shadow .row-form-design .label-form-1, #connexion .form-inscription .form-container .bloc-shadow .row-form-design .label-form-1, #connexion #real-inscription .form-container .bloc-shadow .row-form-design .label-form-1 {
          display: block !important;
          color: #000000;
          cursor: text;
          background: #fff;
          font-family: 'sofia-pro', sans-serif;
          font-weight: 700;
          font-size: 20px;
          position: relative;
          top: 0;
          left: 0;
          text-transform: none !important;
          width: 100%; }
        .homepage2023 .form-inscription .form-container .bloc-shadow .row-form-design .label-form-1:after, .homepage2023 #real-inscription .form-container .bloc-shadow .row-form-design .label-form-1:after, #connexion .form-inscription .form-container .bloc-shadow .row-form-design .label-form-1:after, #connexion #real-inscription .form-container .bloc-shadow .row-form-design .label-form-1:after {
          content: "*"; }
        .homepage2023 .form-inscription .form-container .bloc-shadow .row-form-design .input-form-1, .homepage2023 #real-inscription .form-container .bloc-shadow .row-form-design .input-form-1, #connexion .form-inscription .form-container .bloc-shadow .row-form-design .input-form-1, #connexion #real-inscription .form-container .bloc-shadow .row-form-design .input-form-1 {
          display: block;
          width: 100%;
          border-top: none;
          border-left: none;
          border-right: none;
          bordr-bottom: 1px solid #707070;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: "Montserrat-Regular", sans-serif;
          background: #fff;
          box-shadow: none !important;
          transition: all ease 0.1s; }
          .homepage2023 .form-inscription .form-container .bloc-shadow .row-form-design .input-form-1::placeholder, .homepage2023 #real-inscription .form-container .bloc-shadow .row-form-design .input-form-1::placeholder, #connexion .form-inscription .form-container .bloc-shadow .row-form-design .input-form-1::placeholder, #connexion #real-inscription .form-container .bloc-shadow .row-form-design .input-form-1::placeholder {
            color: #4d4d4d;
            font-size: 0.9em;
            font-family: 'sofia-pro', sans-serif;
            font-weight: 300; }
          .homepage2023 .form-inscription .form-container .bloc-shadow .row-form-design .input-form-1:focus, .homepage2023 #real-inscription .form-container .bloc-shadow .row-form-design .input-form-1:focus, #connexion .form-inscription .form-container .bloc-shadow .row-form-design .input-form-1:focus, #connexion #real-inscription .form-container .bloc-shadow .row-form-design .input-form-1:focus {
            background: #fdfdfd; }
        .homepage2023 .form-inscription .form-container .bloc-shadow .row-form-design .error-field, .homepage2023 #real-inscription .form-container .bloc-shadow .row-form-design .error-field, #connexion .form-inscription .form-container .bloc-shadow .row-form-design .error-field, #connexion #real-inscription .form-container .bloc-shadow .row-form-design .error-field {
          display: none;
          color: #f0a918; }
      .homepage2023 .form-inscription .form-container .bloc-shadow .c-link--color-red, .homepage2023 #real-inscription .form-container .bloc-shadow .c-link--color-red, #connexion .form-inscription .form-container .bloc-shadow .c-link--color-red, #connexion #real-inscription .form-container .bloc-shadow .c-link--color-red {
        color: #F97759;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 700;
        font-size: 15px;
        text-decoration: underline !important;
        display: block;
        margin: 0 auto;
        padding-top: 10px; }
      .homepage2023 .form-inscription .form-container .bloc-shadow .google-terms, .homepage2023 #real-inscription .form-container .bloc-shadow .google-terms, #connexion .form-inscription .form-container .bloc-shadow .google-terms, #connexion #real-inscription .form-container .bloc-shadow .google-terms {
        font-size: 10px;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 300; }
      .homepage2023 .form-inscription .form-container .bloc-shadow .c-btn--register, .homepage2023 #real-inscription .form-container .bloc-shadow .c-btn--register, #connexion .form-inscription .form-container .bloc-shadow .c-btn--register, #connexion #real-inscription .form-container .bloc-shadow .c-btn--register {
        background: #000000;
        font-size: 18px;
        font-family: 'sofia-pro', sans-serif;
        font-weight: 700;
        min-width: 230px;
        padding-top: 4px;
        padding-bottom: 8px;
        border: 1px solid white; }
        @media (max-width: 480px) {
          .homepage2023 .form-inscription .form-container .bloc-shadow .c-btn--register, .homepage2023 #real-inscription .form-container .bloc-shadow .c-btn--register, #connexion .form-inscription .form-container .bloc-shadow .c-btn--register, #connexion #real-inscription .form-container .bloc-shadow .c-btn--register {
            width: 100%; } }
        .homepage2023 .form-inscription .form-container .bloc-shadow .c-btn--register:hover, .homepage2023 .form-inscription .form-container .bloc-shadow .c-btn--register:active, .homepage2023 .form-inscription .form-container .bloc-shadow .c-btn--register:focus, .homepage2023 #real-inscription .form-container .bloc-shadow .c-btn--register:hover, .homepage2023 #real-inscription .form-container .bloc-shadow .c-btn--register:active, .homepage2023 #real-inscription .form-container .bloc-shadow .c-btn--register:focus, #connexion .form-inscription .form-container .bloc-shadow .c-btn--register:hover, #connexion .form-inscription .form-container .bloc-shadow .c-btn--register:active, #connexion .form-inscription .form-container .bloc-shadow .c-btn--register:focus, #connexion #real-inscription .form-container .bloc-shadow .c-btn--register:hover, #connexion #real-inscription .form-container .bloc-shadow .c-btn--register:active, #connexion #real-inscription .form-container .bloc-shadow .c-btn--register:focus {
          transform: none;
          border: 1px solid black; }
  .homepage2023 .form-inscription .c-form__label, .homepage2023 #real-inscription .c-form__label, #connexion .form-inscription .c-form__label, #connexion #real-inscription .c-form__label {
    color: #000;
    font-family: "Montserrat-Regular", sans-serif; }
  .homepage2023 .form-inscription .teal-color, .homepage2023 #real-inscription .teal-color, #connexion .form-inscription .teal-color, #connexion #real-inscription .teal-color {
    color: #26b783 !important;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700; }
  .homepage2023 .form-inscription .optin-field .optin-newletter, .homepage2023 #real-inscription .optin-field .optin-newletter, #connexion .form-inscription .optin-field .optin-newletter, #connexion #real-inscription .optin-field .optin-newletter {
    padding-left: 30px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300;
    font-size: 0.7em;
    color: #000000;
    cursor: pointer; }
  @media (min-width: 435px) {
    .homepage2023 .form-inscription .optin-field .optin-newletter, .homepage2023 #real-inscription .optin-field .optin-newletter, #connexion .form-inscription .optin-field .optin-newletter, #connexion #real-inscription .optin-field .optin-newletter {
      font-size: 0.9em;
      cursor: pointer; } }
  @media (min-width: 768px) and (max-width: 872px) {
    .homepage2023 .form-inscription .optin-field .optin-newletter, .homepage2023 #real-inscription .optin-field .optin-newletter, #connexion .form-inscription .optin-field .optin-newletter, #connexion #real-inscription .optin-field .optin-newletter {
      font-size: 0.75em;
      cursor: pointer; } }
  .homepage2023 .form-inscription .optin-field #user_newsletter, .homepage2023 #real-inscription .optin-field #user_newsletter, #connexion .form-inscription .optin-field #user_newsletter, #connexion #real-inscription .optin-field #user_newsletter {
    position: relative;
    margin-bottom: 15px; }
    .homepage2023 .form-inscription .optin-field #user_newsletter .c-form__label, .homepage2023 #real-inscription .optin-field #user_newsletter .c-form__label, #connexion .form-inscription .optin-field #user_newsletter .c-form__label, #connexion #real-inscription .optin-field #user_newsletter .c-form__label {
      position: absolute;
      top: -30px;
      left: 0; }
    .homepage2023 .form-inscription .optin-field #user_newsletter .c-form__label::after, .homepage2023 #real-inscription .optin-field #user_newsletter .c-form__label::after, #connexion .form-inscription .optin-field #user_newsletter .c-form__label::after, #connexion #real-inscription .optin-field #user_newsletter .c-form__label::after {
      border: 1px solid #c0bdba !important;
      color: #f3a090 !important;
      width: 17px !important;
      height: 17px !important; }
    .homepage2023 .form-inscription .optin-field #user_newsletter .c-form__input, .homepage2023 #real-inscription .optin-field #user_newsletter .c-form__input, #connexion .form-inscription .optin-field #user_newsletter .c-form__input, #connexion #real-inscription .optin-field #user_newsletter .c-form__input {
      color: #f3a090 !important; }
    .homepage2023 .form-inscription .optin-field #user_newsletter .c-form__input:checked + .c-form__label::after, .homepage2023 #real-inscription .optin-field #user_newsletter .c-form__input:checked + .c-form__label::after, #connexion .form-inscription .optin-field #user_newsletter .c-form__input:checked + .c-form__label::after, #connexion #real-inscription .optin-field #user_newsletter .c-form__input:checked + .c-form__label::after {
      border-color: #f3a090 !important;
      color: #f3a090 !important; }
  .homepage2023 .form-inscription .block_trustpilot_widget, .homepage2023 #real-inscription .block_trustpilot_widget, #connexion .form-inscription .block_trustpilot_widget, #connexion #real-inscription .block_trustpilot_widget {
    margin: 20px 0; }
  .homepage2023 .form-inscription .register-link, .homepage2023 #real-inscription .register-link, #connexion .form-inscription .register-link, #connexion #real-inscription .register-link {
    color: #000; }
    .homepage2023 .form-inscription .register-link a, .homepage2023 #real-inscription .register-link a, #connexion .form-inscription .register-link a, #connexion #real-inscription .register-link a {
      color: #000;
      font-size: 14px;
      font-weight: normal; }
  .homepage2023 .form-inscription .country-name, .homepage2023 #real-inscription .country-name, #connexion .form-inscription .country-name, #connexion #real-inscription .country-name {
    display: none; }
  .homepage2023 .form-inscription .c-form__field--mimic-select-dropdown, .homepage2023 #real-inscription .c-form__field--mimic-select-dropdown, #connexion .form-inscription .c-form__field--mimic-select-dropdown, #connexion #real-inscription .c-form__field--mimic-select-dropdown {
    position: absolute;
    top: 40px;
    left: -140px; }
    .homepage2023 .form-inscription .c-form__field--mimic-select-dropdown .country-name, .homepage2023 #real-inscription .c-form__field--mimic-select-dropdown .country-name, #connexion .form-inscription .c-form__field--mimic-select-dropdown .country-name, #connexion #real-inscription .c-form__field--mimic-select-dropdown .country-name {
      font-family: 'sofia-pro', sans-serif;
      font-weight: 300;
      display: initial; }
  @media (min-width: 900px) {
    .homepage2023 .form-inscription .c-form__field--mimic-select-dropdown, .homepage2023 #real-inscription .c-form__field--mimic-select-dropdown, #connexion .form-inscription .c-form__field--mimic-select-dropdown, #connexion #real-inscription .c-form__field--mimic-select-dropdown {
      position: absolute;
      top: 40px;
      left: 0; } }
  @media (max-width: 1240px) {
    .homepage2023 .form-inscription, .homepage2023 #real-inscription, #connexion .form-inscription, #connexion #real-inscription {
      padding: 0 !important; } }
  @media (max-width: 1100px) {
    .homepage2023 .form-inscription .container .img-full-container, .homepage2023 #real-inscription .container .img-full-container, #connexion .form-inscription .container .img-full-container, #connexion #real-inscription .container .img-full-container {
      width: 100%; }
      .homepage2023 .form-inscription .container .img-full-container .img-full, .homepage2023 #real-inscription .container .img-full-container .img-full, #connexion .form-inscription .container .img-full-container .img-full, #connexion #real-inscription .container .img-full-container .img-full {
        width: 100%; }
    .homepage2023 .form-inscription .container .form-container, .homepage2023 #real-inscription .container .form-container, #connexion .form-inscription .container .form-container, #connexion #real-inscription .container .form-container {
      margin: 0 auto; }
    .homepage2023 .form-inscription.referred-register .container .form-container, .homepage2023 #real-inscription.referred-register .container .form-container, #connexion .form-inscription.referred-register .container .form-container, #connexion #real-inscription.referred-register .container .form-container {
      position: initial;
      margin: 0;
      left: auto;
      right: auto;
      z-index: 2; } }
  @media (max-width: 1000px) {
    .homepage2023 .form-inscription .container, .homepage2023 #real-inscription .container, #connexion .form-inscription .container, #connexion #real-inscription .container {
      width: 100%; }
      .homepage2023 .form-inscription .container .register-link a, .homepage2023 #real-inscription .container .register-link a, #connexion .form-inscription .container .register-link a, #connexion #real-inscription .container .register-link a {
        font-size: 14px; } }
  @media (max-width: 768px) {
    .homepage2023 .form-inscription .form-container, .homepage2023 #real-inscription .form-container, #connexion .form-inscription .form-container, #connexion #real-inscription .form-container {
      height: auto; }
      .homepage2023 .form-inscription .form-container .bloc-shadow, .homepage2023 #real-inscription .form-container .bloc-shadow, #connexion .form-inscription .form-container .bloc-shadow, #connexion #real-inscription .form-container .bloc-shadow {
        padding: 40px 20px 20px 20px; }
    .homepage2023 .form-inscription .row-form, .homepage2023 #real-inscription .row-form, #connexion .form-inscription .row-form, #connexion #real-inscription .row-form {
      padding: 10px 0; }
    .homepage2023 .form-inscription #submit, .homepage2023 #real-inscription #submit, #connexion .form-inscription #submit, #connexion #real-inscription #submit {
      margin: 10px auto; }
    .homepage2023 .form-inscription .text, .homepage2023 #real-inscription .text, #connexion .form-inscription .text, #connexion #real-inscription .text {
      padding: 10px 0; } }
  @media (max-width: 600px) {
    .homepage2023 .form-inscription, .homepage2023 #real-inscription, #connexion .form-inscription, #connexion #real-inscription {
      padding: 0 !important; }
      .homepage2023 .form-inscription .container .form-container, .homepage2023 #real-inscription .container .form-container, #connexion .form-inscription .container .form-container, #connexion #real-inscription .container .form-container {
        width: 100%;
        position: initial; }
        .homepage2023 .form-inscription .container .form-container .input-form-1, .homepage2023 #real-inscription .container .form-container .input-form-1, #connexion .form-inscription .container .form-container .input-form-1, #connexion #real-inscription .container .form-container .input-form-1 {
          width: 65%; }
      .homepage2023 .form-inscription .container .img-full-container, .homepage2023 #real-inscription .container .img-full-container, #connexion .form-inscription .container .img-full-container, #connexion #real-inscription .container .img-full-container {
        display: none; } }
  @media (max-width: 450px) {
    .homepage2023 .form-inscription .form-container .link-text-grey, .homepage2023 #real-inscription .form-container .link-text-grey, #connexion .form-inscription .form-container .link-text-grey, #connexion #real-inscription .form-container .link-text-grey {
      display: block !important; } }
  @media (max-width: 350px) {
    .homepage2023 .form-inscription .container, .homepage2023 #real-inscription .container, #connexion .form-inscription .container, #connexion #real-inscription .container {
      width: 100%;
      min-width: 320px; } }

/*--------------------
CONNEXION FORM
--------------------*/
#connexion .form-1 .bloc-shadow {
  background: none; }
  #connexion .form-1 .bloc-shadow .bi-chevron-left:hover {
    transform: scale(1.1); }
  #connexion .form-1 .bloc-shadow .c-title {
    font-family: 'sofia-pro', sans-serif;
    font-weight: var(--text-font-bold);
    text-transform: none;
    text-align: center;
    font-size: 1.8em; }
  #connexion .form-1 .bloc-shadow #form_login .row-form-design {
    position: relative;
    padding: 0px; }
    #connexion .form-1 .bloc-shadow #form_login .row-form-design .label-form-1 {
      position: relative;
      top: 0;
      left: 0;
      display: block !important;
      background: none;
      text-transform: none;
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-bold);
      font-size: 20px;
      color: black;
      width: auto; }
    #connexion .form-1 .bloc-shadow #form_login .row-form-design .label-form-1:after {
      content: "*"; }
    #connexion .form-1 .bloc-shadow #form_login .row-form-design .input-form-1 {
      background: none;
      padding: 0px; }
    #connexion .form-1 .bloc-shadow #form_login .row-form-design ::placeholder {
      font-size: 0.9em; }
  #connexion .form-1 .bloc-shadow #form_login button {
    text-align: center; }
  #connexion .form-1 .bloc-shadow #form_login .c-btn--register {
    min-width: 230px;
    padding-top: 4px;
    padding-bottom: 8px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700;
    font-size: 18px;
    background-color: black;
    border: 1px solid white; }
    #connexion .form-1 .bloc-shadow #form_login .c-btn--register:hover, #connexion .form-1 .bloc-shadow #form_login .c-btn--register:active, #connexion .form-1 .bloc-shadow #form_login .c-btn--register:focus {
      transform: none;
      border: 1px solid black; }
  #connexion .form-1 .bloc-shadow #form_login .text {
    text-align: center; }
    #connexion .form-1 .bloc-shadow #form_login .text .link-text {
      color: #F97759;
      font-family: 'sofia-pro', sans-serif;
      font-weight: 700;
      font-size: 0.9rem;
      text-decoration: underline !important;
      display: block; }
    #connexion .form-1 .bloc-shadow #form_login .text .link-text-grey {
      font-size: 0.9rem !important;
      display: block; }

.homepage2 .block_1 {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  background: #fff url("/public/images/exemple-box-vetement.jpg") no-repeat center center;
  background-size: 85%; }
  .homepage2 .block_1 .content {
    position: relative;
    margin: 0 auto;
    max-width: 460px;
    padding: 140px 0; }
  .homepage2 .block_1 .title {
    font-size: 32px;
    color: #272C30;
    letter-spacing: 1px;
    line-height: 1; }
  .homepage2 .block_1 .text {
    padding: 0 0 30px;
    font-size: 18px;
    color: #545454; }
  .homepage2 .block_1 .sub_title {
    font-weight: normal;
    font-size: 18px;
    padding: 0 15px 45px;
    color: #545454; }
  @media (max-width: 767px) {
    .homepage2 .block_1 {
      background: #fff; }
      .homepage2 .block_1 .content  {
        padding: 60px 0; } }
.homepage2 .block_2 {
  background: #fafafa; }
  @media (max-width: 767px) {
    .homepage2 .block_2 {
      padding-bottom: 30px; } }
  @media (max-width: 575px) {
    .homepage2 .block_2 {
      padding-bottom: 0; } }
  .homepage2 .block_2 .header {
    background: #F7F5F2;
    padding: 60px 10px;
    text-align: center; }
    .homepage2 .block_2 .header .title {
      color: #272C30;
      font-size: 28px;
      text-transform: uppercase; }
  .homepage2 .block_2 .ul {
    margin: 0 auto;
    list-style-type: none; }
  .homepage2 .block_2 .block_step {
    text-align: center; }
    .homepage2 .block_2 .block_step.block_step1 .left, .homepage2 .block_2 .block_step.block_step3 .left {
      float: left;
      left: 0; }
    .homepage2 .block_2 .block_step.block_step1 .img, .homepage2 .block_2 .block_step.block_step3 .img {
      float: right; }
    .homepage2 .block_2 .block_step.block_step2 .img {
      float: left; }
    .homepage2 .block_2 .block_step.block_step2 .left {
      float: right;
      right: 0; }
    .homepage2 .block_2 .block_step .right {
      min-height: 300px; }
    .homepage2 .block_2 .block_step .img {
      display: block;
      max-width: 500px;
      width: 100%;
      height: auto;
      margin: 0 auto; }
    .homepage2 .block_2 .block_step .left {
      position: absolute;
      top: 50%;
      width: 100%;
      max-width: 500px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      overflow: hidden; }
    .homepage2 .block_2 .block_step .content {
      padding: 0 15px;
      text-align: center; }
      .homepage2 .block_2 .block_step .content .etape {
        font-size: 48px;
        color: #d4d4d4;
        text-shadow: 1px 1px 0px #FFF; }
      .homepage2 .block_2 .block_step .content .h3 {
        font-size: 24px;
        color: #272C30;
        font-weight: normal; }
      .homepage2 .block_2 .block_step .content .p {
        font-size: 16px;
        padding-top: 10px;
        color: #545454; }
    @media (max-width: 991px) {
      .homepage2 .block_2 .block_step .etape {
        display: none; }
      .homepage2 .block_2 .block_step .content {
        padding: 0 30px;
        text-align: left; }
        .homepage2 .block_2 .block_step .content .h3 {
          line-height: 1;
          padding-bottom: 10px; } }
    @media (max-width: 767px) {
      .homepage2 .block_2 .block_step.block_step1 {
        padding-top: 30px; }
      .homepage2 .block_2 .block_step.block_step1, .homepage2 .block_2 .block_step.block_step2, .homepage2 .block_2 .block_step.block_step3 {
        padding-bottom: 45px; }
        .homepage2 .block_2 .block_step.block_step1 .left, .homepage2 .block_2 .block_step.block_step2 .left, .homepage2 .block_2 .block_step.block_step3 .left {
          position: relative;
          top: 0;
          max-width: 100%;
          -webkit-transform: translateY(0%);
          -moz-transform: translateY(0%);
          -ms-transform: translateY(0%);
          -o-transform: translateY(0%);
          transform: translateY(0%); }
        .homepage2 .block_2 .block_step.block_step1 .img, .homepage2 .block_2 .block_step.block_step2 .img, .homepage2 .block_2 .block_step.block_step3 .img {
          float: none;
          max-width: 400px;
          margin-top: 15px; }
        .homepage2 .block_2 .block_step.block_step1 .right, .homepage2 .block_2 .block_step.block_step2 .right, .homepage2 .block_2 .block_step.block_step3 .right {
          min-height: auto; }
      .homepage2 .block_2 .block_step .etape {
        display: block; }
      .homepage2 .block_2 .block_step .content {
        padding: 0 10px;
        text-align: center; }
      .homepage2 .block_2 .block_step .h3 {
        padding-bottom: 0px; } }
.homepage2 .block_privilege {
  background: #F3EFEC;
  padding: 60px 0 20px;
  text-align: center; }
  .homepage2 .block_privilege.block_privilege_bottom {
    background: #F7F5F2; }
  .homepage2 .block_privilege .title {
    font-size: 24px;
    text-transform: uppercase;
    color: #272C30; }
  .homepage2 .block_privilege .ul {
    padding-top: 40px;
    list-style: none; }
    .homepage2 .block_privilege .ul .li {
      margin-bottom: 40px; }
      .homepage2 .block_privilege .ul .li .h3 {
        color: #272C30;
        font-size: 18px;
        padding: 20px 0 5px;
        font-weight: bold; }
      .homepage2 .block_privilege .ul .li .p {
        font-size: 16px; }
      .homepage2 .block_privilege .ul .li .content_img {
        height: auto; }
        .homepage2 .block_privilege .ul .li .content_img .img {
          display: block;
          height: auto;
          width: auto;
          margin: 0 auto;
          max-width: 45px;
          max-height: 45px; }
.homepage2 .block_styliste {
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 0;
  text-align: center;
  position: relative; }
  .homepage2 .block_styliste .h2 {
    font-size: 20px;
    color: #272C30; }
  .homepage2 .block_styliste .p {
    font-size: 16px;
    padding: 20px 0; }
  .homepage2 .block_styliste .styliste {
    font-size: 16px;
    color: #272C30; }
  .homepage2 .block_styliste .back {
    padding: 0 20px;
    position: relative;
    z-index: 2;
    background: #FFF;
    display: inline-block;
    vertical-align: middle;
    width: 50%; }
  .homepage2 .block_styliste picture {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    padding: 0; }
  .homepage2 .block_styliste .img {
    width: 100%; }
  @media (max-width: 768px) {
    .homepage2 .block_styliste {
      display: none; } }
.homepage2 .form-1 .container {
  max-width: 970px; }
.homepage2 .form-1 .bloc-shadow {
  padding: 40px; }
.homepage2 .form-1 .title-form-1 {
  text-align: center;
  font-size: 26px; }
.homepage2 .form-1 .img-full {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }
@media (max-width: 768px) {
  .homepage2 .form-1 .bloc-shadow {
    padding: 20px 20px; }
  .homepage2 .form-1 .row-form {
    padding: 10px 0px; }
  .homepage2 .form-1 #submit {
    margin: 10px auto; }
  .homepage2 .form-1 .text {
    padding: 10px 0; } }
@media (max-width: 992px) {
  .homepage2 .form-1 .img-full-container {
    display: none;
    visibility: hidden; } }

#personal-shopper {
  min-width: 320px; }
  #personal-shopper strong {
    font-family: "Montserrat-Bold", sans-serif; }
  #personal-shopper .header {
    background: #fff url("/public/images/personal-shopper-header.jpg");
    height: 620px;
    text-align: center;
    padding: 200px 100px;
    box-sizing: border-box; }
    #personal-shopper .header .header-text {
      color: #000;
      font-family: "DomaineDisp-Semibold", sans-serif;
      font-size: 42px;
      margin-bottom: 100px; }
    #personal-shopper .header .c-btn--register {
      padding: 10px 30px 15px 30px;
      min-height: auto;
      line-height: normal;
      box-shadow: none;
      margin-top: 1px;
      background: #FFAB9E;
      border: none;
      font-size: 18px;
      border-radius: 0;
      font-weight: bold; }
  #personal-shopper .container {
    width: 1400px; }
    #personal-shopper .container .show-990 {
      display: none; }
    #personal-shopper .container .mt60minus {
      margin-top: -60px; }
    #personal-shopper .container .c-title {
      font-family: "DomaineDisp-Regular", sans-serif;
      text-transform: uppercase;
      font-size: 32px;
      text-align: left; }
    #personal-shopper .container .page--frontTemplate__text {
      color: #000; }
    #personal-shopper .container .slick-carousel-3 .slick-slide {
      height: 249px;
      text-align: center;
      transition: all ease 0.1s; }
      #personal-shopper .container .slick-carousel-3 .slick-slide > img {
        width: 200px;
        margin: 0 auto 15px auto; }
      #personal-shopper .container .slick-carousel-3 .slick-slide > b {
        color: #000; }
      #personal-shopper .container .slick-carousel-3 .slick-slide:hover {
        opacity: 0.8; }
    #personal-shopper .container .slick-carousel-3 .slick-prev {
      left: 60px; }
    #personal-shopper .container .slick-carousel-3 .slick-next {
      right: 70px; }
    #personal-shopper .container .slick-carousel-3 .slick-prev::before {
      content: "";
      width: 20px;
      height: 20px;
      border-left: 2px solid black;
      border-top: 2px solid black;
      display: block;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg); }
    #personal-shopper .container .slick-carousel-3 .slick-next::before {
      content: "";
      width: 20px;
      height: 20px;
      border-left: 2px solid black;
      border-top: 2px solid black;
      display: block;
      -moz-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg); }
    #personal-shopper .container .slick-carousel-3 .slick-prev, #personal-shopper .container .slick-carousel-3 .slick-next {
      top: 100px;
      z-index: 100; }
    #personal-shopper .container .carousel-wrapper {
      position: relative; }
      #personal-shopper .container .carousel-wrapper .slick-carousel-photos {
        border: 1px solid #F3A090; }
        #personal-shopper .container .carousel-wrapper .slick-carousel-photos .slick-slide img {
          width: 100%; }
        #personal-shopper .container .carousel-wrapper .slick-carousel-photos .slick-dots {
          margin: -10px 0; }
          #personal-shopper .container .carousel-wrapper .slick-carousel-photos .slick-dots > li {
            margin: 0; }
            #personal-shopper .container .carousel-wrapper .slick-carousel-photos .slick-dots > li > button:before {
              content: "";
              width: 13px;
              height: 13px;
              border: 1.5px solid #F3A090;
              border-radius: 25px;
              opacity: 1; }
            #personal-shopper .container .carousel-wrapper .slick-carousel-photos .slick-dots > li.slick-active > button:before {
              background: #F3A090; }
      #personal-shopper .container .carousel-wrapper .carousel-text {
        position: absolute;
        bottom: 31px;
        left: 8px;
        width: 96.5%;
        background: rgba(255, 255, 255, 0.8);
        color: #000;
        padding: 10px;
        font-size: 20px;
        font-family: "DomaineDisp-Semibold", sans-serif;
        text-transform: uppercase;
        text-align: center; }
    #personal-shopper .container .need-relooking {
      text-align: center;
      position: relative;
      border: 2px solid #F3A090; }
      #personal-shopper .container .need-relooking h2 {
        display: block;
        font-size: 28px;
        color: #000;
        width: 100%;
        font-family: "DomaineDisp-Regular", sans-serif;
        text-transform: uppercase;
        margin-bottom: 40px;
        margin-top: -18px; }
        #personal-shopper .container .need-relooking h2 span {
          background: #fff; }
      #personal-shopper .container .need-relooking > div {
        color: #000;
        font-family: "Montserrat-Regular", sans-serif;
        width: 100%; }
      #personal-shopper .container .need-relooking .c-btn--register {
        padding: 10px 30px 15px 30px;
        min-height: auto;
        line-height: normal;
        box-shadow: none;
        background: #FFAB9E;
        border: none;
        font-size: 18px;
        border-radius: 0;
        font-weight: bold;
        margin: 60px auto -25px auto; }
  #personal-shopper #real-inscription .container {
    width: 1100px; }
    #personal-shopper #real-inscription .container h2 {
      font-size: 22px; }
  @media (max-width: 990px) {
    #personal-shopper .container .show-990 {
      display: initial; }
    #personal-shopper .container .hide-990 {
      display: none; }
    #personal-shopper .container .c-title {
      font-size: 24px;
      text-align: center; }
      #personal-shopper .container .c-title.mt60minus {
        margin-top: auto; }
    #personal-shopper .container .page--frontTemplate__text {
      text-align: center; }
    #personal-shopper .container .carousel-wrapper {
      width: 300px;
      margin: 0 auto; } }
  @media (max-width: 630px) {
    #personal-shopper .slick-carousel-3 .slick-list.draggable {
      width: 200px;
      margin: 0 auto; }
      #personal-shopper .slick-carousel-3 .slick-list.draggable .slick-next {
        right: 60px; } }
  @media (max-width: 575px) {
    #personal-shopper .header {
      padding: 100px 50px; }
      #personal-shopper .header .header-text {
        font-size: 36px; }
    #personal-shopper .container {
      width: 95%; }
    #personal-shopper #real-inscription .container {
      width: 100%; } }
  @media (max-width: 450px) {
    #personal-shopper .container .slick-carousel-3 .slick-prev {
      left: 10px; }
    #personal-shopper .container .slick-carousel-3 .slick-next {
      right: 10px; } }

#additional_questions {
  font-family: "DomaineDisp-Regular", sans-serif;
  background-color: #E8E8E8; }
  #additional_questions .popup-container {
    padding: 30px;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -20%);
    background-color: white; }
    #additional_questions .popup-container .popup-title {
      font-size: 3em;
      margin: auto;
      text-align: center;
      font-weight: normal; }
    #additional_questions .popup-container .popup-p {
      text-align: center;
      font-weight: normal;
      font-size: large; }
    #additional_questions .popup-container .form-rows-container {
      display: flex;
      flex-direction: row;
      justify-content: center; }
      #additional_questions .popup-container .form-rows-container .label-column {
        font-size: x-large;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-around; }
        #additional_questions .popup-container .form-rows-container .label-column .label-custom {
          margin-top: 5px; }
      #additional_questions .popup-container .form-rows-container .widget-column {
        font-size: x-large;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: space-around; }
        #additional_questions .popup-container .form-rows-container .widget-column .select-custom {
          margin-top: 15px;
          margin-bottom: 15px;
          background-color: #E7E7E6;
          border-color: #ACACAB;
          width: 150px;
          height: 50px; }
    #additional_questions .popup-container .c-form__label-custom {
      font-size: large !important; }
    #additional_questions .popup-container .c-form__field-custom {
      text-align: center; }
    #additional_questions .popup-container .validation-button-wrapper {
      display: flex;
      justify-content: center; }
      #additional_questions .popup-container .validation-button-wrapper .c-btn-custom {
        width: 75% !important;
        height: 75px !important;
        font-size: x-large !important;
        font-weight: bold !important; }
  #additional_questions .u-color--orange-custom {
    text-align: center !important; }
  #additional_questions .error-size {
    border-color: red !important; }

:root {
  --title-font: "DomaineDisp-Regular", sans-serif;
  --text-font-light: 300;
  --text-font-bold: 700;
  --text-font-manuscrit: "Roustel", sans-serif;
  --text: sans-serif;
  --main-title-size: 2.125em;
  --lg-main-title-size: 9vh;
  --lg-subtitle-size: 2.5vw;
  --title-size: 1.1em;
  --text-size: 1em;
  --sm-title-size: 1.5em;
  --sm-text-size: 1.1em;
  --lg-text-size: 1.5em;
  --desktop-text-size: 1.5em;
  --inscription-subtitle-size: 1.25em;
  --bg-white: #fff;
  --bg-grey: #f4f4f5;
  --black: #000000;
  --text-color-grey-dark: #666;
  --text-color-grey-shady: #9b9b9b;
  --btn-melon: #FFAB9E;
  --btn-salmon-hover: #FF8573;
  --border-grey: #e9eaea; }

.homepage-referral .block_1 {
  align-items: center; }
  .homepage-referral .block_1 .ref-intro-bloc .ref-title {
    padding-top: 1.25em;
    padding-left: 1.25em;
    padding-right: 1.25em; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-title h1 {
      font-size: var(--main-title-size);
      text-align: center;
      line-height: 1.4em;
      color: var(--black);
      font-family: var(--title-font); }
  .homepage-referral .block_1 .ref-intro-bloc .ref-content {
    padding: 2em 0; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-content h2 {
      font-size: var(--text-size);
      text-align: center;
      line-height: 1.3em;
      color: var(--black);
      font-family: "sofia-pro", sans-serif;
      font-weight: var(--text-font-light); }
  .homepage-referral .block_1 .ref-intro-bloc .ref-cta {
    text-align: center;
    padding-bottom: 2.5em; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-cta .c-btn--red {
      background: var(--btn-melon);
      border: none;
      font-size: 18px;
      font-family: "sofia-pro", sans-serif;
      font-weight: var(--text-font-bold);
      padding-top: 4px;
      padding-bottom: 8px;
      min-width: 230px; }
      .homepage-referral .block_1 .ref-intro-bloc .ref-cta .c-btn--red:hover, .homepage-referral .block_1 .ref-intro-bloc .ref-cta .c-btn--red:active, .homepage-referral .block_1 .ref-intro-bloc .ref-cta .c-btn--red:focus {
        transform: none;
        background: var(--btn-salmon-hover);
        border: none; }
  .homepage-referral .block_1 .ref-intro-bloc .ref-intro-image-wrapper {
    max-width: 1680px;
    min-width: 320px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: baseline; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-intro-image-wrapper .intro-img {
      width: 100%;
      max-height: 1120px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 0;
      margin: 0; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-intro-image-wrapper svg.bi-chevron-down {
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .homepage-referral .block_1 .ref-intro-bloc .ref-intro-image-wrapper svg.hidden {
      display: none; }
@media only screen and (min-width: 400px) {
  .homepage-referral .block_1 .ref-intro-bloc .ref-title {
    padding-left: 1.25em;
    padding-right: 1.25em; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-title h1 {
      font-size: var(--main-title-size); }
  .homepage-referral .block_1 .ref-intro-bloc .ref-content h2 {
    font-size: var(--sm-text-size); } }
@media only screen and (min-width: 600px) {
  .homepage-referral .block_1 .ref-intro-bloc .ref-title {
    padding-left: 2em;
    padding-right: 2em; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-title h1 {
      font-size: var(--main-title-size); }
  .homepage-referral .block_1 .ref-intro-bloc .ref-content h2 {
    font-size: var(--sm-text-size); } }
@media only screen and (min-width: 800px) {
  .homepage-referral .block_1 .ref-intro-bloc .ref-title {
    padding-left: 8em;
    padding-right: 8em; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-title h1 {
      font-size: var(--main-title-size); }
  .homepage-referral .block_1 .ref-intro-bloc .ref-content h2 {
    font-size: var(--sm-text-size); } }
@media only screen and (min-width: 992px) {
  .homepage-referral .block_1 {
    height: auto;
    margin-bottom: 100px; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-title {
      padding-left: 1.5em;
      padding-right: 1.5em; }
      .homepage-referral .block_1 .ref-intro-bloc .ref-title h1 {
        font-size: var(--lg-main-title-size);
        line-height: 9vh; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-content h2 {
      font-size: var(--lg-text-size); }
    .homepage-referral .block_1 .ref-intro-bloc .ref-intro-image-wrapper .intro-img {
      width: 70%; } }
@media only screen and (min-width: 1200px) {
  .homepage-referral .block_1 {
    height: auto;
    margin-bottom: 100px; }
    .homepage-referral .block_1 .ref-intro-bloc .ref-title {
      padding-left: 10em;
      padding-right: 10em; }
      .homepage-referral .block_1 .ref-intro-bloc .ref-title h1 {
        font-size: var(--lg-main-title-size); }
    .homepage-referral .block_1 .ref-intro-bloc .ref-content h2 {
      font-size: var(--desktop-text-size); }
    .homepage-referral .block_1 .ref-intro-bloc .ref-intro-image-wrapper .intro-img {
      width: 70%; } }
.homepage-referral .block_2 {
  background-color: var(--bg-grey); }
  .homepage-referral .block_2 .ref-title {
    text-align: center;
    padding: 4em 0.8em 2.5em 0.8em; }
    .homepage-referral .block_2 .ref-title h1 {
      font-family: var(--title-font);
      font-size: var(--main-title-size);
      color: var(--black); }
    @media only screen and (min-width: 400px) {
      .homepage-referral .block_2 .ref-title h1 {
        font-size: var(--main-title-size); } }
    @media only screen and (min-width: 600px) {
      .homepage-referral .block_2 .ref-title h1 {
        font-size: var(--main-title-size); } }
    @media only screen and (min-width: 992px) {
      .homepage-referral .block_2 .ref-title h1 {
        font-size: var(--lg-subtitle-size); } }
    @media only screen and (min-width: 1200px) {
      .homepage-referral .block_2 .ref-title h1 {
        font-size: var(--lg-subtitle-size); } }
  .homepage-referral .block_2 .ref-content .ref-card h2 {
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-bold);
    font-size: var(--text-size);
    color: var(--black);
    line-height: 1.3; }
  .homepage-referral .block_2 .ref-content .ref-card h3 {
    font-family: var(--text);
    font-size: var(--text-size);
    color: var(--text-color-grey-shady);
    padding: 0 1em;
    line-height: 1.3; }
  .homepage-referral .block_2 .ref-content .ref-card .img-ref {
    margin: 40px 0;
    height: 120px;
    width: 120px; }
  @media only screen and (min-width: 992px) {
    .homepage-referral .block_2 .ref-content .ref-card h2 {
      font-size: 1.6vw; }
    .homepage-referral .block_2 .ref-content .ref-card h3 {
      font-size: 1.6vw; } }
  @media only screen and (min-width: 2100px) {
    .homepage-referral .block_2 .ref-content .ref-card .img-ref {
      height: 200px;
      width: 200px; } }
  @media only screen and (min-width: 400px) and (max-width: 600px) {
    .homepage-referral .block_2 .ref-content .ref-card {
      padding: 0 40px; } }
  @media only screen and (min-width: 600px) and (max-width: 800px) {
    .homepage-referral .block_2 .ref-content .ref-card {
      padding: 0 180px; } }
  @media only screen and (min-width: 800px) and (max-width: 991px) {
    .homepage-referral .block_2 .ref-content .ref-card {
      padding: 0 270px; } }
  @media only screen and (min-width: 992px) and (max-width: 1049px) {
    .homepage-referral .block_2 .ref-content .ref-card-left h3 {
      padding: 0 40px; } }
  @media only screen and (min-width: 1050px) and (max-width: 1339px) {
    .homepage-referral .block_2 .ref-content .ref-card-left h3 {
      padding: 0 50px; } }
  @media only screen and (min-width: 1340px) and (max-width: 1470px) {
    .homepage-referral .block_2 .ref-content .ref-card {
      padding: 0 30px; }
    .homepage-referral .block_2 .ref-content .ref-card-left h3 {
      padding: 0 50px; }
    .homepage-referral .block_2 .ref-content .ref-card-right h3 {
      padding: 0 50px; } }
  @media only screen and (min-width: 1470px) and (max-width: 1594px) {
    .homepage-referral .block_2 .ref-content .ref-card {
      padding: 0 50px; }
    .homepage-referral .block_2 .ref-content .ref-card-left h3 {
      padding: 0 50px; } }
  @media only screen and (min-width: 1594px) and (max-width: 1700px) {
    .homepage-referral .block_2 .ref-content .ref-card {
      padding: 0 70px; } }
  @media only screen and (min-width: 1700px) and (max-width: 2050px) {
    .homepage-referral .block_2 .ref-content .ref-card {
      padding: 0 90px; } }
  @media only screen and (min-width: 2050px) {
    .homepage-referral .block_2 .ref-content .ref-card {
      padding: 0 110px; } }
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .homepage-referral .block_2 {
    padding: 0 5em; } }
@media only screen and (min-width: 1200px) and (max-width: 1340px) {
  .homepage-referral .block_2 {
    padding: 0 5em; }
    .homepage-referral .block_2 .ref-title {
      padding: 2em 0.8em 2em 0.8em; } }
.homepage-referral .block_3_slick_desktop {
  display: block;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 10px; }
  .homepage-referral .block_3_slick_desktop .slide-show {
    height: 15vh;
    text-align: center;
    margin: 0 5px; }
    .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper {
      display: flex;
      height: 15vh;
      justify-content: center;
      align-items: center; }
      .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
        z-index: 6;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center; }
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp img.profile-styliste {
          border-radius: 50%;
          height: 50px; }
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp .top-right {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          padding: 0;
          text-align: start; }
          .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp .top-right h2 {
            font-family: var(--title-font);
            font-size: var(--title-size);
            color: var(--black);
            font-weight: bolder;
            padding-right: 0.5em; }
            .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp .top-right h2 span {
              font-family: var(--text-font-manuscrit);
              font-size: var(--title-size); }
          .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp .top-right h3 {
            font-size: var(--text-size);
            color: var(--black);
            font-family: "sofia-pro", sans-serif;
            font-weight: var(--text-font-light); }
        @media only screen and (min-width: 1500px) {
          .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp .top-right h2 {
            font-size: var(--sm-title-size); }
            .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp .top-right h2 span {
              font-size: var(--sm-title-size); }
          .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp .top-right h3 {
            font-size: var(--sm-text-size); } }
      @media only screen and (min-width: 400px) and (max-width: 500px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          width: 85%; } }
      @media only screen and (min-width: 500px) and (max-width: 600px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          width: 70%; } }
      @media only screen and (min-width: 600px) and (max-width: 700px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          width: 60%; } }
      @media only screen and (min-width: 700px) and (max-width: 799px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          width: 60%; } }
      @media only screen and (min-width: 800px) and (max-width: 899px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          padding-left: 30.3vw; } }
      @media only screen and (min-width: 900px) and (max-width: 950px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          padding-left: 30.3vw; } }
      @media only screen and (min-width: 950px) and (max-width: 975px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          padding-left: 30.6vw; } }
      @media only screen and (min-width: 975px) and (max-width: 1100px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          padding-left: 35vw; } }
      @media only screen and (min-width: 1100px) {
        .homepage-referral .block_3_slick_desktop .slide-show .profile-wrapper .sharp {
          width: 30.3%; } }
  .homepage-referral .block_3_slick_desktop .slider-nav {
    justify-content: center;
    align-items: center; }
    .homepage-referral .block_3_slick_desktop .slider-nav .slick-prev {
      left: 0px;
      z-index: 1; }
    .homepage-referral .block_3_slick_desktop .slider-nav .slick-next {
      right: 0px; }
    .homepage-referral .block_3_slick_desktop .slider-nav .slick-next::before {
      content: "";
      background: url("/public/images/carrousel-arrow-right.png") no-repeat;
      height: 50px;
      width: 25px;
      background-size: contain;
      position: absolute;
      right: 2px; }
    .homepage-referral .block_3_slick_desktop .slider-nav .slick-prev::before {
      content: "";
      background: url("/public/images/carrousel-arrow-left.png") no-repeat;
      height: 50px;
      width: 25px;
      background-size: contain;
      position: absolute;
      left: 2px; }
    @media only screen and (min-width: 500px) {
      .homepage-referral .block_3_slick_desktop .slider-nav .slick-next::before {
        right: 20px; }
      .homepage-referral .block_3_slick_desktop .slider-nav .slick-prev::before {
        left: 20px; } }
    .homepage-referral .block_3_slick_desktop .slider-nav .slick-slide .ref-carousel-img {
      margin: 0 auto;
      width: 100%;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; }
    @media only screen and (min-width: 400px) and (max-width: 500px) {
      .homepage-referral .block_3_slick_desktop .slider-nav .slick-slide .ref-carousel-img {
        width: 85%; } }
    @media only screen and (min-width: 500px) and (max-width: 600px) {
      .homepage-referral .block_3_slick_desktop .slider-nav .slick-slide .ref-carousel-img {
        width: 70%; } }
    @media only screen and (min-width: 600px) and (max-width: 700px) {
      .homepage-referral .block_3_slick_desktop .slider-nav .slick-slide .ref-carousel-img {
        width: 60%; } }
    @media only screen and (min-width: 700px) and (max-width: 800px) {
      .homepage-referral .block_3_slick_desktop .slider-nav .slick-slide .ref-carousel-img {
        width: 60%; } }
    @media only screen and (min-width: 2000px) {
      .homepage-referral .block_3_slick_desktop .slider-nav .slick-slide .ref-carousel-img {
        height: 650px; } }
    .homepage-referral .block_3_slick_desktop .slider-nav .slick-center {
      display: block;
      opacity: 1; }
  @media only screen and (min-width: 800px) {
    .homepage-referral .block_3_slick_desktop .slider-nav .slick-slide {
      margin: 0px 15px;
      opacity: 0.2; }
    .homepage-referral .block_3_slick_desktop .slider-nav .slick-center {
      display: block;
      opacity: 1; } }
  @media only screen and (min-width: 800px) and (max-width: 900px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 240px; } }
  @media only screen and (min-width: 900px) and (max-width: 1000px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 270px; } }
  @media only screen and (min-width: 1000px) and (max-width: 1100px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 310px; } }
  @media only screen and (min-width: 1100px) and (max-width: 1200px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 360px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 380px; } }
  @media only screen and (min-width: 1300px) and (max-width: 1400px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 420px; } }
  @media only screen and (min-width: 1400px) and (max-width: 1600px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 470px; } }
  @media only screen and (min-width: 1600px) and (max-width: 1800px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 540px; } }
  @media only screen and (min-width: 1800px) and (max-width: 2000px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 620px; } }
  @media only screen and (min-width: 2000px) {
    .homepage-referral .block_3_slick_desktop .slider-nav {
      height: 650px; } }
.homepage-referral .slick-dots li.slick-active button::before {
  color: var(--btn-salmon-hover); }
.homepage-referral .slick-dots li button::before {
  color: var(--btn-salmon-hover);
  font-size: 16px; }
.homepage-referral .form-inscription .bloc-shadow {
  padding: 40px 20px; }
  .homepage-referral .form-inscription .bloc-shadow h2.ref-title {
    font-size: var(--main-title-size);
    font-family: var(--title-font);
    text-align: center; }
  .homepage-referral .form-inscription .bloc-shadow h3.ref-content {
    font-size: var(--inscription-subtitle-size);
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-light); }
  @media only screen and (max-width: 480px) {
    .homepage-referral .form-inscription .bloc-shadow .c-btn--register {
      width: 100%; } }
  @media only screen and (min-width: 400px) {
    .homepage-referral .form-inscription .bloc-shadow h2.ref-title {
      font-size: var(--main-title-size); } }
  @media only screen and (min-width: 992px) {
    .homepage-referral .form-inscription .bloc-shadow h2.ref-title {
      font-size: var(--lg-subtitle-size); } }
.homepage-referral .form-inscription .form-container {
  width: 700px;
  min-width: 320px;
  position: relative; }
@media (min-width: 320px) {
  .homepage-referral .form-inscription .form-container {
    width: 20em; } }
@media (min-width: 360px) {
  .homepage-referral .form-inscription .form-container {
    width: 22em; } }
@media (min-width: 400px) {
  .homepage-referral .form-inscription .form-container {
    width: 25em; } }
@media (min-width: 500px) {
  .homepage-referral .form-inscription .form-container {
    width: 31em; } }
.homepage-referral .block_7 {
  margin: 2.5em 0; }

:root {
  --text-font-light: 300;
  --text-font-bold: 700;
  --text-font-manuscrit: "Roustel", sans-serif;
  --text-font-domaineDisp: "DomaineDisp-Regular", sans-serif;
  --text: sans-serif;
  --title-size: 1.6rem;
  --subtitle-size: 1.2rem;
  --text-size: 1.1rem;
  --line-height-base: 1.4em;
  --bg-white: #fff;
  --bg-grey: #f4f4f5;
  --black: #000000;
  --text-color-grey-dark: #666;
  --text-color-grey-shady: #9b9b9b;
  --btn-melon: #FFAB9E;
  --btn-salmon-hover: #FF8573;
  --bg-rose: #FBEEE8;
  --border-grey: #e9eaea;
  --yellow: #FDFD37; }

/*
-----------------
Referral-temp, Referrer
-----------------
*/
.homepage-referral-temp .block_1_2_wrapper .block_1, .homepage-referrer .block_1_2_wrapper .block_1 {
  padding-left: 20px;
  padding-right: 20px; }
  .homepage-referral-temp .block_1_2_wrapper .block_1 h1, .homepage-referrer .block_1_2_wrapper .block_1 h1 {
    font-size: var(--title-size);
    color: var(--black);
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-bold);
    padding-top: 1.25em;
    line-height: var(--line-height-base); }
    .homepage-referral-temp .block_1_2_wrapper .block_1 h1 span.referrer, .homepage-referrer .block_1_2_wrapper .block_1 h1 span.referrer {
      font-family: var(--text-font-manuscrit);
      font-size: 1.7rem; }
    .homepage-referral-temp .block_1_2_wrapper .block_1 h1 span.referred, .homepage-referrer .block_1_2_wrapper .block_1 h1 span.referred {
      padding-right: .3em; }
  .homepage-referral-temp .block_1_2_wrapper .block_1 h2, .homepage-referrer .block_1_2_wrapper .block_1 h2 {
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-bold);
    font-size: var(--title-size);
    line-height: var(--line-height-base);
    color: var(--black); }
  .homepage-referral-temp .block_1_2_wrapper .block_1 h3, .homepage-referrer .block_1_2_wrapper .block_1 h3 {
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-light);
    font-size: var(--text-size);
    line-height: var(--line-height-base);
    color: var(--black);
    padding: 1.25em 0; }
  .homepage-referral-temp .block_1_2_wrapper .block_1 .ref-cta, .homepage-referrer .block_1_2_wrapper .block_1 .ref-cta {
    text-align: center;
    padding-bottom: 1.5em; }
    .homepage-referral-temp .block_1_2_wrapper .block_1 .ref-cta .c-btn--red, .homepage-referrer .block_1_2_wrapper .block_1 .ref-cta .c-btn--red {
      background: var(--black);
      border: none;
      font-size: 18px;
      font-family: "sofia-pro", sans-serif;
      font-weight: var(--text-font-bold);
      padding-top: 4px;
      padding-bottom: 8px;
      min-width: 230px; }
      .homepage-referral-temp .block_1_2_wrapper .block_1 .ref-cta .c-btn--red:hover, .homepage-referral-temp .block_1_2_wrapper .block_1 .ref-cta .c-btn--red:active, .homepage-referral-temp .block_1_2_wrapper .block_1 .ref-cta .c-btn--red:focus, .homepage-referrer .block_1_2_wrapper .block_1 .ref-cta .c-btn--red:hover, .homepage-referrer .block_1_2_wrapper .block_1 .ref-cta .c-btn--red:active, .homepage-referrer .block_1_2_wrapper .block_1 .ref-cta .c-btn--red:focus {
        transform: none;
        background: var(--black);
        border: none; }
.homepage-referral-temp .block_1_2_wrapper .block_2, .homepage-referrer .block_1_2_wrapper .block_2 {
  overflow: hidden; }
  .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative; }
    .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel {
      width: 100%;
      overflow-x: scroll;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      margin: 20px auto 0 auto;
      position: relative; }
      .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel::-webkit-scrollbar, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel::-webkit-scrollbar {
        display: none; }
      .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel {
        width: 2120px; }
        .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel .carrousel-img, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper .block_carrousel .homepage_carrousel .carrousel-img {
          width: 250px;
          margin-right: 10px; }
    .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left {
      position: absolute;
      left: 5px;
      height: 50px;
      width: auto;
      cursor: pointer;
      top: 20px;
      bottom: 0;
      margin: auto; }
    .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right {
      position: absolute;
      right: 5px;
      height: 50px;
      width: auto;
      cursor: pointer;
      top: 20px;
      bottom: 0;
      margin: auto; }
    @media (min-width: 768px) {
      .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-left {
        left: 15px; }
      .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper #intro-carrousel-arrow-right {
        right: 15px; } }
    .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper .label-offer, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper .label-offer {
      position: absolute;
      z-index: 2;
      bottom: 25px;
      right: 25px;
      height: 56px;
      width: 135px;
      border-radius: 10px;
      background-color: var(--yellow);
      font-size: var(--text-size);
      font-family: var(--text-font-domaineDisp);
      font-weight: bold;
      text-align: center;
      padding: 9px 6px 0px 6px;
      color: var(--black); }
      .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper .label-offer span.offer, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper .label-offer span.offer {
        font-size: 1.5em; }
    @media (min-width: 768px) {
      .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper .label-offer, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper .label-offer {
        position: absolute;
        z-index: 2;
        bottom: 25px;
        right: 30%; }
        .homepage-referral-temp .block_1_2_wrapper .block_2 .homepage_img_wrapper .label-offer span.offer, .homepage-referrer .block_1_2_wrapper .block_2 .homepage_img_wrapper .label-offer span.offer {
          font-size: 1.6em; } }
.homepage-referral-temp .block_3, .homepage-referrer .block_3 {
  padding-left: 20px;
  padding-right: 20px; }
  .homepage-referral-temp .block_3 h2, .homepage-referrer .block_3 h2 {
    font-size: var(--title-size);
    color: var(--black);
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-bold);
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: var(--line-height-base); }
  @media (min-width: 768px) {
    .homepage-referral-temp .block_3 h2, .homepage-referrer .block_3 h2 {
      padding-bottom: 0px; } }
  .homepage-referral-temp .block_3 .bi-chevron-down, .homepage-referrer .block_3 .bi-chevron-down {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 30px;
    height: 30px; }
  @media (min-width: 768px) {
    .homepage-referral-temp .block_3 .bi-chevron-down, .homepage-referrer .block_3 .bi-chevron-down {
      width: 30px;
      height: 30px; } }
  .homepage-referral-temp .block_3 .hidden, .homepage-referrer .block_3 .hidden {
    display: none; }
  .homepage-referral-temp .block_3 .ref-card-wrap, .homepage-referrer .block_3 .ref-card-wrap {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .homepage-referral-temp .block_3 .ref-card-wrap .ref-card, .homepage-referrer .block_3 .ref-card-wrap .ref-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      margin-bottom: 5px;
      gap: 10px; }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .text-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .text-ref-wrap {
        text-align: left;
        width: 70%; }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .text-ref-wrap h3, .homepage-referrer .block_3 .ref-card-wrap .ref-card .text-ref-wrap h3 {
          font-family: "sofia-pro", sans-serif;
          font-weight: var(--text-font-bold);
          font-size: var(--text-size);
          color: var(--black);
          line-height: var(--line-height-base); }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .text-ref-wrap h4, .homepage-referrer .block_3 .ref-card-wrap .ref-card .text-ref-wrap h4 {
          font-family: "sofia-pro", sans-serif;
          font-weight: var(--text-font-light);
          font-size: var(--text-size);
          color: var(--black);
          line-height: var(--line-height-base); }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap {
        height: 120px;
        width: 30%;
        display: flex;
        align-items: center; }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-phone, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-phone {
          height: auto;
          max-width: 100%;
          object-fit: contain; }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-clothes, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-clothes {
          height: auto;
          max-width: 100%;
          align-self: center;
          object-fit: contain; }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-box, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-box {
          height: auto;
          max-width: 100%;
          object-fit: contain;
          align-self: center; }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros-referral, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros-referral {
          height: auto;
          max-width: 100%;
          object-fit: contain;
          align-self: center; }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros {
          height: auto;
          max-width: 100%;
          object-fit: contain;
          align-self: center; }
        @media (min-width: 768px) {
          .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros {
            align-self: center; } }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-box-euros, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-box-euros {
          height: auto;
          max-width: 100%;
          object-fit: contain;
          align-self: center; }
      @media (min-width: 430px) and (max-width: 535px) {
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap {
          height: 180px; } }
      @media (min-width: 535px) {
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap {
          height: 210px; } }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .bi-chevron-down, .homepage-referrer .block_3 .ref-card-wrap .ref-card .bi-chevron-down {
        margin-top: 40px; }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .hidden, .homepage-referrer .block_3 .ref-card-wrap .ref-card .hidden {
        display: none; }
    @media (max-width: 768px) {
      .homepage-referral-temp .block_3 .ref-card-wrap .euros-card, .homepage-referrer .block_3 .ref-card-wrap .euros-card {
        height: 210px; } }
  @media (min-width: 535px) and (max-width: 710px) {
    .homepage-referral-temp .block_3 .ref-card-wrap .ref-card, .homepage-referrer .block_3 .ref-card-wrap .ref-card {
      width: 90%;
      gap: 20px;
      margin-bottom: 5px; }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .text-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .text-ref-wrap {
        font-size: 1.2rem;
        width: 65%; }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap {
        width: 35%; } }
  @media (min-width: 711px) and (max-width: 768px) {
    .homepage-referral-temp .block_3 .ref-card-wrap .ref-card, .homepage-referrer .block_3 .ref-card-wrap .ref-card {
      width: 65%;
      gap: 30px; }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .text-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .text-ref-wrap {
        font-size: 1.2rem;
        width: 60%; }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap {
        width: 40%; } }
  @media (min-width: 768px) {
    .homepage-referral-temp .block_3 .ref-card-wrap, .homepage-referrer .block_3 .ref-card-wrap {
      flex-direction: row;
      align-items: flex-start;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); }
      .homepage-referral-temp .block_3 .ref-card-wrap .ref-card, .homepage-referrer .block_3 .ref-card-wrap .ref-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        flex-grow: 1;
        margin-bottom: 0px; }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .text-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .text-ref-wrap {
          max-width: 300px;
          width: 100%;
          margin-top: 10px;
          text-align: center; }
        .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap {
          min-width: 150px;
          max-width: 180px;
          height: 210px;
          justify-content: center; }
          .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-phone, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-phone {
            width: 175px;
            align-self: end; }
          .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-clothes, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-clothes {
            width: 175px;
            align-self: end; }
          .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-box, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-box {
            height: auto;
            width: 175px;
            align-self: end; }
          .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros-referral, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros-referral {
            height: auto;
            width: 175px;
            align-self: end; }
          .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-euros {
            height: auto;
            width: 175px;
            align-self: center; }
          .homepage-referral-temp .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-box-euros, .homepage-referrer .block_3 .ref-card-wrap .ref-card .img-ref-wrap .img-ref.pic-box-euros {
            height: auto;
            width: 175px;
            align-self: center; }
    .homepage-referral-temp .block_3 .ref-card-wrap.col3-grid-wrap, .homepage-referrer .block_3 .ref-card-wrap.col3-grid-wrap {
      gap: 25px; }
    .homepage-referral-temp .block_3 .ref-card-wrap.col4-grid-wrap, .homepage-referrer .block_3 .ref-card-wrap.col4-grid-wrap {
      gap: 25px; } }
  @media (min-width: 1200px) {
    .homepage-referral-temp .block_3 .ref-card-wrap.col3-grid-wrap, .homepage-referrer .block_3 .ref-card-wrap.col3-grid-wrap {
      margin: 0 10vw; }
    .homepage-referral-temp .block_3 .ref-card-wrap.col4-grid-wrap, .homepage-referrer .block_3 .ref-card-wrap.col4-grid-wrap {
      margin: 0 5vw; } }
  .homepage-referral-temp .block_3 .ref-card-text, .homepage-referrer .block_3 .ref-card-text {
    padding: 1.25em 0; }
.homepage-referral-temp .block_4, .homepage-referrer .block_4 {
  padding-left: 20px;
  padding-right: 20px; }
  .homepage-referral-temp .block_4 .referrals-title h2, .homepage-referrer .block_4 .referrals-title h2 {
    font-size: 1.25rem;
    color: var(--black);
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-bold);
    padding-top: 30px;
    line-height: 1; }
  .homepage-referral-temp .block_4 .parrainage-bloc-lien, .homepage-referrer .block_4 .parrainage-bloc-lien {
    padding-right: 0px;
    padding-left: 0px; }
@media (min-width: 768px) {
  .homepage-referral-temp .block_4, .homepage-referrer .block_4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-rose);
    margin-top: 1.25em;
    padding-bottom: 30px; }
    .homepage-referral-temp .block_4 .referrals-title, .homepage-referrer .block_4 .referrals-title {
      text-align: center; }
      .homepage-referral-temp .block_4 .referrals-title h2, .homepage-referrer .block_4 .referrals-title h2 {
        font-size: 2rem;
        line-height: 1; } }
@media (min-width: 1200px) {
  .homepage-referral-temp .block_4 .referrals-title h2, .homepage-referrer .block_4 .referrals-title h2 {
    font-size: 2.2rem; } }
.homepage-referral-temp .form-inscription, .homepage-referrer .form-inscription {
  margin: 0 auto;
  padding-bottom: 0; }
  .homepage-referral-temp .form-inscription .ref-cta, .homepage-referrer .form-inscription .ref-cta {
    text-align: center;
    padding-bottom: 1.5em; }
    .homepage-referral-temp .form-inscription .ref-cta .c-btn--red, .homepage-referrer .form-inscription .ref-cta .c-btn--red {
      background: var(--black);
      border: none;
      font-size: 18px;
      font-family: "sofia-pro", sans-serif;
      font-weight: var(--text-font-bold);
      padding-top: 4px;
      padding-bottom: 8px;
      min-width: 230px; }
      .homepage-referral-temp .form-inscription .ref-cta .c-btn--red:hover, .homepage-referral-temp .form-inscription .ref-cta .c-btn--red:active, .homepage-referral-temp .form-inscription .ref-cta .c-btn--red:focus, .homepage-referrer .form-inscription .ref-cta .c-btn--red:hover, .homepage-referrer .form-inscription .ref-cta .c-btn--red:active, .homepage-referrer .form-inscription .ref-cta .c-btn--red:focus {
        transform: none;
        background: var(--black);
        border: none; }
.homepage-referral-temp .block_7, .homepage-referrer .block_7 {
  margin-top: 5px; }
  .homepage-referral-temp .block_7 .trustpilot-widget, .homepage-referrer .block_7 .trustpilot-widget {
    text-align: center; }
    .homepage-referral-temp .block_7 .trustpilot-widget img, .homepage-referrer .block_7 .trustpilot-widget img {
      width: 240px; }

:root {
  --title-font: "DomaineDisp-Regular", sans-serif;
  --text-font-light: 300;
  --text-font-bold: 700;
  --text-font-manuscrit: "Roustel", sans-serif;
  --text: sans-serif;
  --grey: #f4f3f2;
  --gray-border: #707070;
  --white: #ffffff;
  --black: #000000; }

.parrainage-bloc-lien.c-block {
  box-shadow: none;
  padding: 5px; }

.parrainage-bloc .c-referal, .parrainage-bloc-lien .c-referal {
  background-color: var(--white);
  border: 1px solid var(--gray-border); }
  .parrainage-bloc .c-referal .c-referal__btn, .parrainage-bloc-lien .c-referal .c-referal__btn {
    background-color: var(--black);
    width: 102px; }
.parrainage-bloc .soc-icons-wrap, .parrainage-bloc-lien .soc-icons-wrap {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center; }
  .parrainage-bloc .soc-icons-wrap .c-referral-wrapper, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--gray-border);
    width: 33%;
    background-color: var(--white); }
    .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--mail, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--facebook, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--messenger, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--pinterest, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--twitter, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--whatsapp, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--mail, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--facebook, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--messenger, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--pinterest, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--twitter, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--whatsapp {
      border: none;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100%; }
      .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--mail .c-referal__social, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--facebook .c-referal__social, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--messenger .c-referal__social, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--pinterest .c-referal__social, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--twitter .c-referal__social, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--whatsapp .c-referal__social, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--mail .c-referal__social, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--facebook .c-referal__social, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--messenger .c-referal__social, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--pinterest .c-referal__social, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--twitter .c-referal__social, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--whatsapp .c-referal__social {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: auto;
        top: 0px;
        left: 0px; }
        .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--mail .c-referal__social img, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--facebook .c-referal__social img, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--messenger .c-referal__social img, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--pinterest .c-referal__social img, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--twitter .c-referal__social img, .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--whatsapp .c-referal__social img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--mail .c-referal__social img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--facebook .c-referal__social img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--messenger .c-referal__social img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--pinterest .c-referal__social img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--twitter .c-referal__social img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--whatsapp .c-referal__social img {
          max-height: 100%;
          width: auto;
          display: block;
          margin: 0 auto; }
    .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--messenger .c-referal__social, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--messenger .c-referal__social {
      top: 0px;
      left: 0px; }
      .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--messenger .c-referal__social img.social-messenger-img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--messenger .c-referal__social img.social-messenger-img {
        display: block;
        position: relative;
        max-height: 100%;
        margin: 0 auto;
        width: 40px;
        height: 40px;
        padding-bottom: 1px; }
    .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--mail .c-referal__social img.social-email-img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--mail .c-referal__social img.social-email-img {
      padding: 4px; }
    .parrainage-bloc .soc-icons-wrap .c-referral-wrapper .c-referal--facebook .c-referal__social img.social-fb-img, .parrainage-bloc-lien .soc-icons-wrap .c-referral-wrapper .c-referal--facebook .c-referal__social img.social-fb-img {
      padding: 2px; }

.parrainage .block_4_8_wrap .block_4 {
  margin-bottom: 1.25em;
  margin-right: 20px;
  margin-left: 20px; }
  .parrainage .block_4_8_wrap .block_4 .c-title h1 {
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-bold);
    font-size: var(--title-size);
    line-height: 1.2;
    margin-bottom: 15px; }
  .parrainage .block_4_8_wrap .block_4 .c-title h2 {
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-light);
    font-size: var(--text-size); }
.parrainage .block_4_8_wrap .block_8 {
  margin-right: 20px;
  margin-left: 20px; }
  .parrainage .block_4_8_wrap .block_8 .c-block {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0; }
    .parrainage .block_4_8_wrap .block_8 .c-block .c-title {
      margin: 0; }
      .parrainage .block_4_8_wrap .block_8 .c-block .c-title h1 {
        font-family: var(--text-font-bold);
        font-size: var(--title-size); }
      .parrainage .block_4_8_wrap .block_8 .c-block .c-title h2 {
        font-family: "sofia-pro", sans-serif;
        font-weight: var(--text-font-light);
        font-size: var(--text-size); }
    .parrainage .block_4_8_wrap .block_8 .c-block .emailAgainButton {
      background: white;
      border: 1px solid #70707029;
      border-radius: 10px;
      padding: 4px 15px;
      cursor: pointer;
      transition: all ease 0.2s; }
      .parrainage .block_4_8_wrap .block_8 .c-block .emailAgainButton:hover {
        background: #70707029; }
      .parrainage .block_4_8_wrap .block_8 .c-block .emailAgainButton:disabled {
        cursor: not-allowed; }
    .parrainage .block_4_8_wrap .block_8 .c-block .tooltip {
      background: black;
      color: white;
      font-size: 13px;
      width: auto;
      text-align: center;
      border-radius: 5px;
      padding: 5px 0;
      opacity: 0.8;
      transition: all ease 0s; }
@media (min-width: 768px) {
  .parrainage .block_4_8_wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 40px;
    margin: 0 20px; }
    .parrainage .block_4_8_wrap .block_4 {
      width: 50%; }
    .parrainage .block_4_8_wrap .block_8 {
      width: 50%; }
      .parrainage .block_4_8_wrap .block_8 .c-block {
        padding: 0;
        box-shadow: none; } }
@media (min-width: 1200px) {
  .parrainage .block_4_8_wrap {
    margin: 0 10vw; } }
@media (min-width: 1500px) {
  .parrainage .block_4_8_wrap {
    margin: 0 20vw; } }
.parrainage .table-header > td:not(:last-child) {
  border-right: solid 10px var(--white); }
.parrainage .table-header .info-table {
  font-family: "sofia-pro", sans-serif;
  font-weight: var(--text-font-light);
  font-size: 1em;
  color: var(--black); }
  .parrainage .table-header .info-table h2 {
    font-family: "sofia-pro", sans-serif;
    font-weight: var(--text-font-bold);
    font-size: var(--text-size); }
  .parrainage .table-header .info-table i {
    color: var(--white); }
@media (min-width: 360px) and (max-width: 480px) {
  .parrainage .table-header .info-table {
    font-size: 0.8em; }
    .parrainage .table-header .info-table h2 {
      font-size: 1em; } }
.parrainage .table-body td.info-table.filleul {
  font-family: "sofia-pro", sans-serif;
  font-weight: var(--text-font-light);
  font-size: var(--text-size);
  color: var(--black); }
.parrainage .table-body td.info-table i {
  color: var(--white); }
@media (min-width: 360px) and (max-width: 480px) {
  .parrainage .table-body td.info-table.filleul {
    font-size: 0.9em; } }
.parrainage .info-table.border-bottom-gray {
  border-bottom: 2px solid var(--grey); }

#cgv-block .c-form__line .c-form__field--checkbox .c-form__label {
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  cursor: pointer; }
  #cgv-block .c-form__line .c-form__field--checkbox .c-form__label a {
    color: #FF5859;
    text-decoration: underline;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    cursor: pointer;
    font-size: 14px; }
#cgv-block .c-form__line .c-form__info--error {
  text-align: left;
  display: block;
  border-radius: 4px;
  position: relative;
  margin-top: 5px;
  border: 1px solid #FF0000;
  padding: 15px;
  color: #FF0000;
  font-size: 14px;
  font-family: "sofia-pro", sans-serif;
  font-weight: 400; }

#payzen-payment-form {
  padding-top: 10px; }
  #payzen-payment-form .kr-smart-form {
    margin: 0 auto;
    width: 100%;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300; }
    #payzen-payment-form .kr-smart-form .kr-payment-button {
      background: #000 !important;
      text-align: center;
      font-family: "sofia-pro", sans-serif;
      font-weight: 700;
      transition: all ease 0.3s; }
      #payzen-payment-form .kr-smart-form .kr-payment-button:focus {
        box-shadow: none; }
      #payzen-payment-form .kr-smart-form .kr-payment-button > span {
        font-family: "sofia-pro", sans-serif;
        font-weight: 700; }
    #payzen-payment-form .kr-smart-form .kr-form-error {
      text-align: left !important;
      border-radius: 4px !important;
      position: relative !important;
      margin-top: 15px !important;
      border: 1px solid #FFA758 !important;
      color: #FFA758 !important;
      background: #ffffff !important;
      font-size: 14px !important;
      padding: 10px !important; }
      #payzen-payment-form .kr-smart-form .kr-form-error span {
        padding: 0 !important; }
        #payzen-payment-form .kr-smart-form .kr-form-error span span {
          padding: 0 !important;
          color: #FFA758 !important; }

.MR-Widget {
  font-family: 'Montserrat', Verdana !important;
  font-size: 10px !important;
  border: solid 1px #ddd !important;
  margin: 5px;
  background: #fff;
  width: 650px; }

.MR-Widget .MRW-Title {
  color: #666;
  font-weight: 700;
  text-align: center;
  background: #eee;
  padding: 3px;
  border-bottom: solid 1px #ddd; }

.MR-Widget .MRW-Results {
  overflow: auto;
  padding: 10px; }

.MR-Widget input {
  background: #fefefe;
  border: solid 1px #ddd; }

.MR-Widget .MRW-Search {
  padding: 25px 10px 25px 10px;
  text-align: center;
  background: #fafafa; }

.es-ES .MRW-Search {
  padding: 25px 0px 25px 20px !important; }

.MR-Widget .MRW-Line {
  display: block;
  margin-bottom: 3px; }

.MR-Widget .MRW-BtGo {
  font-family: 'Montserrat',Verdana !important;
  border: solid 1px #96154a;
  background: #96154a;
  color: #FFFFFF; }

.MR-Widget .MRW-BtGeoGo {
  font-family: 'Montserrat',Verdana !important;
  border: solid 1px #96154a;
  background: #FFFFFF;
  border-radius: 5px;
  color: #96154a; }

.MR-Widget .PR-List-Item {
  border-left: solid 2px #f6abb6;
  padding: 3px 3px 3px 10px;
  margin-bottom: 4px;
  cursor: pointer;
  text-align: left; }

.MR-Widget .PR-Name {
  color: #96154a;
  font-weight: 700; }

.MR-Widget .PR-hover,
.MR-Widget .PR-Selected {
  border-left: solid 2px #96154a;
  background: #f6abb6; }

.MR-Widget .MRW-Errors {
  color: #ff8c44;
  display: block;
  background: #ffede2;
  border: solid 1px #ff8c44;
  font-weight: 100;
  padding: 4px;
  display: none; }

.MR-Widget .PR-Warning {
  font-family: 'Montserrat',Verdana !important;
  color: #4dc1be;
  font-weight: 700;
  text-align: center;
  padding: 2px; }

.MR-Widget .PR-Hours {
  width: 100%;
  color: #555;
  border: solid 1px #eee;
  font-size: 10px; }

.MR-Widget .PR-Hours td {
  width: 35%;
  text-align: center; }

.MR-Widget .PR-Hours .d {
  background: #eee; }

.MR-Widget .MRW-fl-Select,
.MR-Widget .PR-AutoCplCity {
  position: absolute;
  min-width: 250px;
  text-align: left;
  display: none;
  border: solid 1px #ff5c84;
  background: #fff;
  z-index: 8000; }

.MR-Widget .MRW-fl-Select .MRW-fl-Item,
.MR-Widget .PR-City {
  padding: 3px; }

.MR-Widget .MRW-fl-Select MRW-fl-Item:hover,
.MR-Widget .PR-City:hover {
  cursor: pointer;
  background: #f6abb6;
  font-weight: 700; }

.MR-Widget .MRW-fl-Select .MRW-fl-Item span {
  margin-left: 5px; }

.MR-Widget .AutoCpl-Hover {
  background: #4dc1be;
  font-weight: 700; }

.MR-Widget #MRW-Map {
  overflow: hidden !important;
  font-size: 8pt !important; }

.MR-Widget .MRW-Map {
  height: 350px; }

.MR-Widget .InfoWindow {
  width: auto !important; }

.MR-Widget .PR-Name {
  padding-bottom: 2px; }

.MR-Widget .Tabs-Btns {
  overflow: auto; }

.MR-Widget .Tabs-Btn {
  display: inline-block;
  padding: 2px 5px;
  float: left;
  background-color: #eee;
  cursor: pointer;
  color: #96154a;
  border: 1px solid #eee; }

.MR-Widget .Tabs-Btn:first-child {
  border-radius: 5px 0px 0px 5px; }

.MR-Widget .Tabs-Btn:last-child {
  border-radius: 0px 5px 5px 0px; }

.MR-Widget .Tabs-Btn-Selected {
  font-weight: bold;
  background-color: #eee;
  border: 1px solid #ccc; }

.MR-Widget .Tabs-Tabs {
  margin-top: 4px; }

.MR-Widget .Tabs-Tab {
  display: none;
  visibility: hidden; }

.MR-Widget .Tabs-Tab-Selected {
  display: inline;
  visibility: visible; }

.MRW-LockerModal {
  background: #fff;
  position: fixed;
  width: 230px;
  top: 110px;
  left: 24px;
  height: 380px;
  z-index: 99999;
  float: left;
  background: #f7f7f7; }

.MRW-Container {
  white-space: nowrap; }

.MRW-Container label {
  width: 45px;
  display: inline-block;
  text-align: right; }

.new .btn {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-family: 'Quicksand', sans-serif;
  padding: 10px 22px !important;
  border: solid 1px;
  line-height: 17px;
  box-shadow: none !important;
  text-align: center;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  color: #ffffff;
  font-weight: 700;
  white-space: normal !important;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s;
  transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s; }

.new .alert-info-consigne.consigne {
  border: none; }

.new .alert-info-consigne .surligne {
  background-color: #ca0047;
  color: #ffffff;
  border-radius: 3px;
  padding: 3px;
  text-align: center;
  width: 100%;
  display: block; }

.new .alert-info-consigne .three span.picto {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
  margin-top: 2px; }

.new .alert-info-consigne .three p {
  text-align: justify;
  padding-left: 15px;
  padding-right: 15px; }

.new .alert-info-consigne .three p.titre {
  text-align: center;
  padding: 3px 0;
  color: #ca0047; }

.new .grid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 768px) {
  .new .grid {
    width: 100%; } }
@media (min-width: 992px) {
  .new .grid {
    width: 970px; } }
@media (min-width: 1200px) {
  .new .grid {
    width: 1170px; } }
.new .grid-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.new .row {
  margin-left: -15px;
  margin-right: -15px; }

.new .col-md-1,
.new .col-md-2,
.new .col-md-3,
.new .col-md-4,
.new .col-md-5,
.new .col-md-6,
.new .col-md-7,
.new .col-md-8,
.new .col-md-9,
.new .col-md-10,
.new .col-md-11,
.new .col-md-12 {
  float: left; }

.new .col-md-12 {
  width: 100%; }

.new .col-md-11 {
  width: 91.66666667%; }

.new .col-md-10 {
  width: 83.33333333%; }

.new .col-md-9 {
  width: 75%; }

.new .col-md-8 {
  width: 66.66666667%; }

.new .col-md-7 {
  width: 58.33333333%; }

.new .col-md-6 {
  width: 50%; }

.new .col-md-5 {
  width: 41.66666667%; }

.new .col-md-4 {
  width: 33.33333333%; }

.new .col-md-3 {
  width: 25%; }

.new .col-md-2 {
  width: 16.66666667%; }

.new .col-md-1 {
  width: 8.33333333%; }

.new .col-md-pull-12 {
  right: 100%; }

.new .col-md-pull-11 {
  right: 91.66666667%; }

.new .col-md-pull-10 {
  right: 83.33333333%; }

.new .col-md-pull-9 {
  right: 75%; }

.new .col-md-pull-8 {
  right: 66.66666667%; }

.new .col-md-pull-7 {
  right: 58.33333333%; }

.new .col-md-pull-6 {
  right: 50%; }

.new .col-md-pull-5 {
  right: 41.66666667%; }

.new .col-md-pull-4 {
  right: 33.33333333%; }

.new .col-md-pull-3 {
  right: 25%; }

.new .col-md-pull-2 {
  right: 16.66666667%; }

.new .col-md-pull-1 {
  right: 8.33333333%; }

.new .col-md-pull-0 {
  right: auto; }

.new .col-md-push-12 {
  left: 100%; }

.new .col-md-push-11 {
  left: 91.66666667%; }

.new .col-md-push-10 {
  left: 83.33333333%; }

.new .col-md-push-9 {
  left: 75%; }

.new .col-md-push-8 {
  left: 66.66666667%; }

.new .col-md-push-7 {
  left: 58.33333333%; }

.new .col-md-push-6 {
  left: 50%; }

.new .col-md-push-5 {
  left: 41.66666667%; }

.new .col-md-push-4 {
  left: 33.33333333%; }

.new .col-md-push-3 {
  left: 25%; }

.new .col-md-push-2 {
  left: 16.66666667%; }

.new .col-md-push-1 {
  left: 8.33333333%; }

.new .col-md-push-0 {
  left: auto; }

.new .col-md-offset-12 {
  margin-left: 100%; }

.new .col-md-offset-11 {
  margin-left: 91.66666667%; }

.new .col-md-offset-10 {
  margin-left: 83.33333333%; }

.new .col-md-offset-9 {
  margin-left: 75%; }

.new .col-md-offset-8 {
  margin-left: 66.66666667%; }

.new .col-md-offset-7 {
  margin-left: 58.33333333%; }

.new .col-md-offset-6 {
  margin-left: 50%; }

.new .col-md-offset-5 {
  margin-left: 41.66666667%; }

.new .col-md-offset-4 {
  margin-left: 33.33333333%; }

.new .col-md-offset-3 {
  margin-left: 25%; }

.new .col-md-offset-2 {
  margin-left: 16.66666667%; }

.new .col-md-offset-1 {
  margin-left: 8.33333333%; }

.new .col-md-offset-0 {
  margin-left: 0%; }

.new .modal {
  display: none;
  background: #ffffff;
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99999;
  left: 0; }

.new .modal.pink h4 {
  padding: 0 !important;
  float: none !important;
  width: auto !important; }

.new .modal.pink h4 strong {
  color: #ca0047; }

.new .modal.pink p strong {
  color: #ca0047; }

.new .modal h4 {
  padding: 0 !important;
  float: none !important;
  width: auto !important; }

.new .modal h4 strong {
  color: #3ecf8e; }

.new .modal h4 strong.pink {
  color: #ca0047; }

.new .modal .close-pop {
  margin-top: -20px;
  margin-right: -10px; }

.new .modal-header,
.new .modal-body {
  padding: 10px 20px; }

.new .modal-header p,
.new .modal-body p {
  text-transform: none;
  line-height: 14px; }

.new .modal-footer {
  padding: 20px; }

.new .modal-footer a.btn,
.new .modal-footer input.btn {
  width: auto; }

.fade-modal,
.fade-modal-menu {
  display: none;
  background: rgba(50, 50, 93, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8000; }

.new .clearfix:before,
.new .clearfix:after,
.new .grid:before,
.new .grid:after,
.new .grid-fluid:before,
.new .grid-fluid:after,
.new .row:before,
.new .row:after {
  content: " ";
  display: table; }

.new .clearfix:after,
.new .grid:after,
.new .grid-fluid:after,
.new .row:after {
  clear: both; }

.new .center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.new .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.new .close-pop {
  position: relative;
  float: right;
  margin-top: 0;
  margin-right: 0;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer; }

.new .close-pop:before {
  position: absolute;
  top: 5px;
  bottom: 0;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  content: "";
  display: block;
  background: #546a79;
  width: 15px;
  height: 2px;
  transition: top .250s ease, transform .250s ease; }

.new .close-pop:after {
  position: absolute;
  bottom: 11px;
  content: "";
  display: block;
  background: #546a79;
  width: 15px;
  height: 2px;
  transition: bottom .100s ease;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.new .close-pop:hover:after,
.new .close-pop:hover:before {
  background: #ca0047; }

.new .line-b {
  border-bottom: solid 1px #f3f3f3; }

.new .pink {
  color: #ca0047 !important; }

.new .btn.green {
  color: #ffffff !important;
  border: solid 1px #3ecf8e;
  background-color: #3ecf8e;
  text-shadow: 0 1px 3px rgba(36, 180, 126, 0.4); }

.new .btn.pink-border {
  color: #ca0047 !important;
  border: solid 1px #ca0047;
  background-color: transparent; }

.new .btn.pink-border:hover,
.new .btn.pink-border.selected {
  color: #fff !important;
  border: solid 1px #f55169;
  background-color: #f55169; }

.new .modal .close {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 5px;
  right: 5px; }

.new .modal .close:before,
.new .modal .close:after {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background: #000;
  top: 50%;
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg); }

.new .modal .close:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.new .modal .close:hover:before,
.new .modal .close:hover:after {
  background: crimson; }

.new .modal-header h1,
.new .modal-header h2,
.new .modal-header h3,
.new .modal-header h4 {
  margin-right: 30px; }

.new .modal-header,
.new .modal-body,
.new .modal-footer {
  padding: 5px 20px; }

html .fade {
  position: absolute; }

html .modal {
  position: absolute; }

.new .overlay {
  left: 15% !important;
  width: 70% !important;
  bottom: 5% !important;
  padding: 15px 15px 30px 15px; }

.MR-Widget .leaflet-control-zoom-out,
.MR-Widget .leaflet-control-zoom-in {
  opacity: 0.7; }

.MR-Widget .PR-List-Item.PR-Disabled {
  opacity: 0.4; }

.MR-Widget .PR-List-Item.PR-Disabled:hover {
  opacity: 0.4; }

.MR-Widget .MRW-RList {
  max-height: 150px;
  overflow: auto; }

.MR-Widget .marker-container {
  height: 100%;
  width: 100%;
  position: relative; }

.MR-Widget .marker-image {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
  text-align: center; }

.MR-Widget .marker-text {
  z-index: 100;
  position: absolute;
  color: white;
  font-size: 15px;
  font-weight: bold;
  left: 50%;
  top: 15%;
  transform: translateX(-50%); }

.MR-Widget .leaflet-pane,
.MR-Widget .leaflet-tile,
.MR-Widget .leaflet-marker-icon,
.MR-Widget .leaflet-marker-shadow,
.MR-Widget .leaflet-tile-container,
.MR-Widget .leaflet-pane > svg,
.MR-Widget .leaflet-pane > canvas,
.MR-Widget .leaflet-zoom-box,
.MR-Widget .leaflet-image-layer,
.MR-Widget .leaflet-layer {
  position: absolute;
  left: 0;
  top: 0; }

.MR-Widget .leaflet-tile,
.MR-Widget .leaflet-marker-icon,
.MR-Widget .leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none; }

.MR-Widget .leaflet-marker-icon,
.MR-Widget .leaflet-marker-shadow {
  display: block; }

.MR-Widget .leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.MR-Widget .leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1); }

.MR-Widget .leaflet-interactive {
  cursor: pointer; }

.MR-Widget .leaflet-crosshair,
.MR-Widget .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair; }

.MR-Widget .leaflet-dragging .leaflet-grab,
.MR-Widget .leaflet-dragging .leaflet-grab .leaflet-interactive,
.MR-Widget .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing; }

.MR-Widget .leaflet-marker-icon,
.MR-Widget .leaflet-marker-shadow,
.MR-Widget .leaflet-image-layer,
.MR-Widget .leaflet-pane > svg path,
.MR-Widget .leaflet-tile-container {
  pointer-events: none; }

.MR-Widget .leaflet-marker-icon.leaflet-interactive,
.MR-Widget .leaflet-image-layer.leaflet-interactive,
.MR-Widget .leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted;
  pointer-events: auto; }

.gm-style-iw {
  width: 280px !important;
  max-width: none !important;
  min-width: none !important; }

.MR-Widget.MR-Widget-Responsive .MRW-ButtonContainer button.MRW-BtGeoGo {
  padding: 0;
  width: 28px;
  height: 27px; }

.MR-Widget.MR-Widget-Responsive .MRW-ButtonContainer button.MRW-BtGeoGo span {
  background-image: url("/public/images/ico-target.png");
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-size: 20px; }

.MR-Widget.MR-Widget-Responsive .MRW-Search .MRW-BtGeoGo {
  display: none; }

.MR-Widget .MRW-Search {
  padding: 10px; }
  .MR-Widget .MRW-Search .MRW-Line .MRW-Container {
    text-align: left;
    display: inline-block;
    width: 32.5%; }
    .MR-Widget .MRW-Search .MRW-Line .MRW-Container.MRW-CountryContainer {
      display: none; }
    .MR-Widget .MRW-Search .MRW-Line .MRW-Container .MRW-BtGo {
      width: 100%;
      cursor: pointer; }
    .MR-Widget .MRW-Search .MRW-Line .MRW-Container label {
      width: auto;
      text-align: left;
      display: none; }
    .MR-Widget .MRW-Search .MRW-Line .MRW-Container input[type="text"] {
      width: 100%; }
@media (min-width: 768px) {
  .MR-Widget .MRW-Results {
    height: 440px;
    overflow: hidden; }
    .MR-Widget .MRW-Results .MRW-RList {
      max-height: none;
      overflow: auto;
      height: 100%;
      display: inline-block;
      width: 49%; }
    .MR-Widget .MRW-Results .MRW-Map {
      height: 100%;
      display: inline-block;
      width: 49%;
      vertical-align: top; } }
@media (max-width: 768px) {
  .MR-Widget {
    width: 100%; } }

.MRW-ButtonCircle {
  display: none; }

/*

.MR-Widget {
  position: relative;
  height: 99%;
  width: 99%;
}
.MR-Widget .MRW-Content {
  position: relative;
  width: 100%;
  height: calc(100% - 25px);
}
.MR-Widget .MRW-Results {
  height: auto;
}
.MR-Widget.MR-Widget-Responsive {
  font-size: 12px !important;
  overflow: auto;
}
.MR-Widget.MR-Widget-Responsive .PR-Hours {
  font-size: 10px !important;
  border: 0;
  height: 22px;
}
.MR-Widget.MR-Widget-Responsive .MRW-Search {
  padding: 25px 10px 30px 95px;
  text-align: left;
  background: url(./imgs/mr-64.png) no-repeat 15px 5px;
}
.MR-Widget.MR-Widget-Responsive .MRW-Results {
  padding: 0;
  padding-left: 5px;
  position: relative;
  height: 400px !important;
}
.MR-Widget.MR-Widget-Responsive .MRW-Map {
  position: relative;
  width: 60%;
  height: 100%;
  float: left;
}
.MR-Widget.MR-Widget-Responsive .MRW-RList {
  position: relative;
  width: 40%;
  height: 100%;
  overflow-y: auto;
  float: left;
  max-height: 450px;
}
.MR-Widget.MR-Widget-Responsive .MRW-ShowList {
  display: none;
}
.MR-Widget.MR-Widget-Responsive .InfoWindow {
  width: auto !important;
}
.MR-Widget.MR-Widget-Responsive .leaflet-popup-content .InfoWindow {
  width: 320px !important;
}
.MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle {
  display: none;
}
.MR-Widget.MR-Widget-Responsive .MRW-Line .MRW-Container {
  display: inline-block;
  padding: 2px;
}
.MR-Widget.MR-Widget-Responsive .MRW-Search .MRW-Line .MRW-CityContainer input {
  width: 120px;
  box-sizing: content-box;
}
.MR-Widget.MR-Widget-Responsive .MRW-Search .MRW-Line .MRW-CPContainer input {
  width: 80px;
  box-sizing: content-box;
}
.MR-Widget.MR-Widget-Responsive .MRW-ButtonContainer img {
  vertical-align: middle;
}
.MR-Widget.MR-Widget-Responsive .MRW-ButtonContainer button {
  background-color: #96154a;
  border: solid 1px #96154a;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  padding: 5px 15px 5px 15px;
  color: white;
  font-weight: bold;
  outline-width: 0;
  cursor: pointer;
  vertical-align: middle;
}
.MR-Widget.MR-Widget-Responsive .MRW-ButtonContainer button:hover {
  border: solid 1px #96154a;
  background-color: #4dc1be;
}
.MR-Widget.MR-Widget-Responsive .MRW-ButtonContainer button:active {
  transform: translateY(1px);
}
.MR-Widget.MR-Widget-Responsive input[type=text] {
  padding: 5px 10px;
  outline-width: 0;
}

.MR-Widget.MR-Widget-Responsive button.PR-SelectButton {
  background-color: #3ecf8e;
  border: solid 1px #3ecf8e;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  padding: 5px 15px 5px 15px;
  color: white;
  font-weight: bold;
  outline-width: 0;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 10px;
  margin-bottom: 20px;
}
@media screen and (min-device-width: 800px), screen and (min-width: 800px) {
  .MR-Widget.MR-Widget-Responsive {
    margin: 0
  }
  .MR-Widget.MR-Widget-Responsive .MRW-RList {
    display: block !important;
  }
}
@media screen and (max-width: 800px) and (min-width: 565px), screen and (device-max-width: 800px) and (device-min-width: 565px) {
  .MR-Widget.MR-Widget-Responsive .MRW-Container.MRW-ButtonContainer {
    width: 100%;
  }
}
@media screen and (max-device-width: 800px), screen and (max-width: 800px) {
  .MR-Widget.MR-Widget-Responsive {
    width: 99%;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-Search {
    padding: 5px;
    padding-left: 80px;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-Map {
    width: 100%;
    max-width: 100%;
    position: absolute !important;
    left: 0;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-RList {
    overflow-y: auto;
    width: 280px;
    height: 100%;
    position: absolute;
    z-index: 1110;
    background: #fff;
    box-shadow: 40px 0 15px rgba(50, 50, 93, 0.1), 35px 0 0 rgba(0, 0, 0, 0.07);
    transform: translateX(0px);
    -webkit-transition: transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-RList.Hide {
    transform: translateX(-280px);
  }
  .MR-Widget.MR-Widget-Responsive .MRW-Results {
    height: 100%;
    position: relative;
    min-height: 300px;
    padding: 0;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle.MRW-ShowList {
    right: 10px;
    bottom: 25px;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle.MRW-ShowList span {
    background-image: url("/public/images/ico-list.png");
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle.MRW-BtGeoGo {
    right: 10px;
    top: 10px;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle.MRW-BtGeoGo span {
    background-image: url("/public/images/ico-target.png");
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-Search .MRW-BtGeoGo {
    display: none;
  }
  .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle {
    display: block;
    position: absolute;
    border: solid 1px #3ecf8e;
    background-color: #3ecf8e;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1100;
    height: 50px;
    width: 50px;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    filter: none !important;
  }
}
@media screen and (max-device-width: 360px), screen and (max-width: 360px) {
  .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle {
    opacity: 0.6;
    z-index: 1110;
  }
}*/
:root {
  --rose-light: #faded9;
  --btn-menu: #F3A090;
  --melon: #FFAB9E;
  --salmon-hover: #FF8573;
  --black: #000;
  --text-dark: #272C30;
  --grey-light: #f2f2f2;
  --hr-grey: #DDD;
  --grey-platinum: #e6e6e6;
  --white: #fff;
  --title-font: "DomaineDisp-Regular", sans-serif;
  --text-font-light: 300;
  --cercle-font: 'Arial', sans-serif; }

.c-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  height: 52px;
  width: 100%;
  background: var(--white);
  min-width: 320px; }
  @media (max-width: 768px) {
    .c-navigation {
      height: 50px; } }
  @media (max-width: 768px) {
    .c-navigation .u-hide-ss {
      display: none; } }
  .c-navigation .u-show-ss {
    display: none; }
    @media (max-width: 768px) {
      .c-navigation .u-show-ss {
        display: block; } }
  .c-navigation .c-navigation__container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: space-between;
    align-items: center; }
    .c-navigation .c-navigation__container .c-navigation__item-dd {
      position: relative;
      font-size: 16px;
      display: flex;
      width: 250px;
      color: var(--text-dark); }
      .c-navigation .c-navigation__container .c-navigation__item-dd .list-item {
        padding-left: 20px; }
        .c-navigation .c-navigation__container .c-navigation__item-dd .list-item .drop-down {
          width: 240px;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .c-navigation .c-navigation__container .c-navigation__item-dd .list-item .drop-down .char-wrapp {
            position: relative;
            background-color: var(--melon);
            border-radius: 50%;
            width: 35px;
            height: 35px;
            margin-right: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center; }
            .c-navigation .c-navigation__container .c-navigation__item-dd .list-item .drop-down .char-wrapp .char {
              color: var(--white);
              font-weight: bold;
              font-family: var(--cercle-font);
              font-size: 18px; }
          .c-navigation .c-navigation__container .c-navigation__item-dd .list-item .drop-down .char-wrapp:hover {
            background-color: var(--salmon-hover); }
          .c-navigation .c-navigation__container .c-navigation__item-dd .list-item .drop-down .auth-user {
            font-family: 'sofia-pro', sans-serif;
            font-weight: var(--text-font-light);
            font-size: 16px;
            cursor: pointer; }
      .c-navigation .c-navigation__container .c-navigation__item-dd.c-navigation__item_name {
        height: 50px;
        line-height: 50px;
        font-family: 'sofia-pro', sans-serif;
        font-weight: var(--text-font-light); }
        .c-navigation .c-navigation__container .c-navigation__item-dd.c-navigation__item_name ul.c-tooltip {
          padding-left: 0;
          padding-right: 0; }
        .c-navigation .c-navigation__container .c-navigation__item-dd.c-navigation__item_name .c-tooltip {
          line-height: 1.6;
          text-align: start;
          width: 262px; }
          .c-navigation .c-navigation__container .c-navigation__item-dd.c-navigation__item_name .c-tooltip .dropdown-header {
            text-transform: uppercase;
            font-weight: bold;
            padding-left: 20px; }
          .c-navigation .c-navigation__container .c-navigation__item-dd.c-navigation__item_name .c-tooltip .dropdown-link {
            color: var(--text-dark);
            padding-left: 20px;
            cursor: pointer; }
            .c-navigation .c-navigation__container .c-navigation__item-dd.c-navigation__item_name .c-tooltip .dropdown-link span {
              background-color: var(--grey-light);
              padding-left: 5px; }
          .c-navigation .c-navigation__container .c-navigation__item-dd.c-navigation__item_name .c-tooltip .c-navigation-dropdown__hr {
            margin: 10px auto 10px;
            height: 1px;
            width: 100%;
            background: var(--hr-grey); }
      .c-navigation .c-navigation__container .c-navigation__item-dd:hover .c-tooltip {
        opacity: 1;
        visibility: visible;
        line-height: 1.6;
        width: 262px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(28, 44, 79, 0.1);
        border-top-color: var(--white); }
        .c-navigation .c-navigation__container .c-navigation__item-dd:hover .c-tooltip .dropdown-header {
          text-transform: uppercase;
          font-weight: bold;
          color: var(--text-dark); }
        .c-navigation .c-navigation__container .c-navigation__item-dd:hover .c-tooltip .dropdown-link {
          color: var(--text-dark); }
        .c-navigation .c-navigation__container .c-navigation__item-dd:hover .c-tooltip .dropdown-item:link, .c-navigation .c-navigation__container .c-navigation__item-dd:hover .c-tooltip .dropdown-item:active, .c-navigation .c-navigation__container .c-navigation__item-dd:hover .c-tooltip .dropdown-item:hover {
          background-color: var(--grey-light); }
  @media (min-width: 1170px) {
    .c-navigation .c-navigation__container {
      padding: 0; } }
  .c-navigation .c-navigation__logo {
    font-size: 24px;
    color: var(--text-dark);
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 50px;
    font-family: "DomaineDisp-Semibold", sans-serif; }
    .c-navigation .c-navigation__logo .c-navigation__logo_text {
      font-family: 'sofia-pro', sans-serif;
      font-weight: 300; }
    @media (max-width: 768px) {
      .c-navigation .c-navigation__logo {
        font-size: 16px; }
        .c-navigation .c-navigation__logo .c-navigation__logo_text {
          font-size: 16px;
          display: block;
          min-width: 110px; } }
    @media (max-width: 480px) {
      .c-navigation .c-navigation__logo .c-navigation__logo_text {
        font-size: 16px;
        display: block;
        min-width: 110px; } }
  .c-navigation .c-navigation__list {
    list-style: none;
    text-align: center;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
    @media (max-width: 992px) {
      .c-navigation .c-navigation__list {
        display: none; }
        .c-navigation .c-navigation__list.c-navigation__list_account {
          display: none; } }
    @media (max-width: 768px) {
      .c-navigation .c-navigation__list.c-navigation__list_account {
        display: none; } }
    .c-navigation .c-navigation__list .c-navigation__item {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      padding: 0 15px; }
  .c-navigation .c-navigation__link {
    color: var(--text-dark);
    transition: all ease 0.1s;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 300; }
    .c-navigation .c-navigation__link:hover:after {
      content: "";
      display: block;
      margin: 0 auto;
      width: 30%;
      border-bottom: 3px solid var(--btn-menu);
      position: absolute;
      border-radius: 5px; }
  .c-navigation .c-btn {
    margin: 0;
    margin-left: 5px; }
  .c-navigation .c-btn--register-wrapper {
    width: 100%;
    background: var(--white);
    position: fixed;
    top: 50px;
    left: 0;
    text-align: center;
    padding: 10px;
    height: 0; }
  .c-navigation .c-btn--register {
    background: var(--melon);
    border: none;
    font-size: 18px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700;
    padding-top: 4px;
    padding-bottom: 8px;
    min-width: 230px; }
    .c-navigation .c-btn--register:hover, .c-navigation .c-btn--register:active, .c-navigation .c-btn--register:focus {
      transform: none;
      background: var(--salmon-hover);
      border: none; }
  .c-navigation .c-btn--menu {
    padding: 1.5px 0px;
    margin: 0px 10px 0px 15px;
    min-height: auto;
    line-height: normal;
    box-shadow: none;
    font-size: 24px;
    cursor: pointer;
    display: none;
    height: 40px; }
    .c-navigation .c-btn--menu i {
      padding-top: 6px;
      color: black; }
    .c-navigation .c-btn--menu:hover, .c-navigation .c-btn--menu:active, .c-navigation .c-btn--menu:focus {
      transform: none; }
    @media (max-width: 992px) {
      .c-navigation .c-btn--menu {
        display: inline-block; } }
  .c-navigation .c-btn--login {
    background-color: black;
    border: 1px solid white;
    padding: 5px 10px;
    min-height: auto;
    line-height: normal;
    box-shadow: none;
    margin-top: 1px;
    transition: none;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700;
    display: inline-block; }
    .c-navigation .c-btn--login:hover, .c-navigation .c-btn--login:active, .c-navigation .c-btn--login:focus {
      border: 1px solid black;
      transform: none; }
    @media (max-width: 992px) {
      .c-navigation .c-btn--login {
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        margin: 5px auto 0 auto;
        width: 130px; } }
    @media (max-width: 480px) {
      .c-navigation .c-btn--login {
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        margin: 5px auto 0 auto;
        width: 105px;
        font-size: 12px; } }
  .c-navigation .js-btn__show {
    visibility: hidden;
    opacity: 0; }
  .c-navigation .js-btn__active {
    visibility: visible;
    opacity: 1;
    transition: all ease 0.1s; }
  .c-navigation.js-navigation__active {
    height: 50px; }
    .c-navigation.js-navigation__active .c-navigation__logo {
      margin-top: -1px; }
    .c-navigation.js-navigation__active .c-navigation__logo, .c-navigation.js-navigation__active .c-navigation__list, .c-navigation.js-navigation__active .c-navigation__right {
      margin-top: -1px; }
    .c-navigation.js-navigation__active .c-btn--register-wrapper {
      transition: all ease 0.5s;
      height: auto;
      top: 50px; }
      @media (max-width: 700px) {
        .c-navigation.js-navigation__active .c-btn--register-wrapper {
          bottom: 0;
          top: auto;
          height: 75px; } }

.c-navigation .c-navigation__right {
  display: flex;
  align-items: center; }
  .c-navigation .c-navigation__right .c-btn {
    width: 105px;
    min-height: 25px;
    line-height: normal;
    padding: 5px 10px;
    border: 1px solid white; }
    .c-navigation .c-navigation__right .c-btn:hover, .c-navigation .c-navigation__right .c-btn:active, .c-navigation .c-navigation__right .c-btn:focus {
      border: 1px solid black;
      transform: none; }

.c-navigation .c-navigation__right .connexion {
  text-decoration: none;
  color: var(--black);
  display: flex;
  align-items: center; }

.c-navigation .c-navigation__right .bi-person {
  margin-right: 10px;
  margin-left: 10px;
  color: black; }

.menu-auth-mob-closed {
  display: none;
  visibility: hidden; }
  @media (max-width: 992px) {
    .menu-auth-mob-closed {
      display: block;
      visibility: visible; } }
  @media (max-width: 768px) {
    .menu-auth-mob-closed {
      display: block;
      visibility: visible; } }
  .menu-auth-mob-closed .wrapper {
    display: flex;
    align-items: center;
    padding-right: 5px; }
    .menu-auth-mob-closed .wrapper .char-wrapp {
      position: relative;
      background-color: var(--melon);
      border-radius: 50%;
      width: 35px;
      height: 35px;
      margin-right: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center; }
      .menu-auth-mob-closed .wrapper .char-wrapp .char {
        color: var(--white);
        font-weight: bold;
        font-family: var(--cercle-font);
        font-size: 18px; }
    .menu-auth-mob-closed .wrapper .char-wrapp:hover {
      background-color: var(--salmon-hover); }
    .menu-auth-mob-closed .wrapper .auth-user {
      font-family: 'sofia-pro', sans-serif;
      font-weight: var(--text-font-light);
      font-size: 16px;
      cursor: pointer;
      color: var(--black); }

.c-message {
  top: 50px;
  height: auto;
  padding: 7px 20px; }

.c-navigation-mobile {
  display: none;
  visibility: hidden;
  position: fixed;
  top: -240px;
  width: 240px;
  right: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.1s ease-in-out;
  background: var(--white);
  border-left: 1px solid var(--hr-grey);
  border-bottom: 1px solid var(--hr-grey);
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.08), 0 0px 3px rgba(28, 44, 79, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 10px;
  padding-bottom: 20px;
  z-index: 999;
  transform: translate(0, 0px);
  -webkit-transform: translate(0, 0px);
  -moz-transform: translate(0, 0px);
  -o-transform: translate(0, 0px);
  -ms-transform: translate(0, 0px);
  font-family: 'sofia-pro', sans-serif;
  font-weight: var(--text-font-light);
  -webkit-transition: all 0.15s ease-in 0s;
  -moz-transition: all 0.15s ease-in 0s;
  -o-transition: all 0.15s ease-in 0s;
  transition: all 0.15s ease-in 0s; }
  @media (max-width: 992px) {
    .c-navigation-mobile {
      display: block;
      visibility: visible; } }
  .c-navigation-mobile.open {
    transform: translateY(240px);
    -webkit-transform: translateY(240px);
    -moz-transform: translateY(240px);
    -o-transform: translateY(240px);
    -ms-transform: translateY(240px);
    transition: height 0.1s ease-in-out;
    height: auto;
    overflow-y: scroll;
    max-height: 100%; }
  .c-navigation-mobile .c-navigation-mobile__hr {
    margin: 10px auto 10px;
    height: 1px;
    width: 100%;
    background: var(--hr-grey); }
  .c-navigation-mobile .c-navigation-mobile__item {
    display: block;
    line-height: 2.5;
    font-size: 16px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: var(--text-font-light);
    -webkit-transition: all 0.15s ease-in 0s;
    -moz-transition: all 0.15s ease-in 0s;
    -o-transition: all 0.15s ease-in 0s;
    transition: all 0.15s ease-in 0s; }
    .c-navigation-mobile .c-navigation-mobile__item:hover {
      background-color: var(--grey-light); }
  .c-navigation-mobile .dropdown-mobile-header {
    text-transform: uppercase;
    color: var(--text-dark);
    padding-left: 20px;
    line-height: 2.2;
    font-size: 16px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: var(--text-font-light); }
  .c-navigation-mobile .c-navigation-mobile__link {
    color: var(--text-dark);
    padding-left: 20px;
    cursor: pointer; }
    .c-navigation-mobile .c-navigation-mobile__link span {
      background-color: var(--grey-light); }
  .c-navigation-mobile .c-btn {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px; }
  .c-navigation-mobile .close-menu-wrapp {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-bottom: 10px; }
    .c-navigation-mobile .close-menu-wrapp .menu-auth-mob-opened {
      display: flex; }
      .c-navigation-mobile .close-menu-wrapp .menu-auth-mob-opened .wrapper {
        display: flex;
        align-items: center;
        padding-right: 5px; }
        .c-navigation-mobile .close-menu-wrapp .menu-auth-mob-opened .wrapper .char-wrapp {
          position: relative;
          background-color: var(--melon);
          border-radius: 50%;
          width: 35px;
          height: 35px;
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center; }
          .c-navigation-mobile .close-menu-wrapp .menu-auth-mob-opened .wrapper .char-wrapp .char {
            color: var(--white);
            font-weight: bold;
            font-family: var(--cercle-font);
            font-size: 18px; }
        .c-navigation-mobile .close-menu-wrapp .menu-auth-mob-opened .wrapper .char-wrapp:hover {
          background-color: var(--salmon-hover); }
        .c-navigation-mobile .close-menu-wrapp .menu-auth-mob-opened .wrapper .auth-user {
          font-family: 'sofia-pro', sans-serif;
          font-weight: var(--text-font-light);
          font-size: 16px;
          cursor: pointer;
          color: var(--black); }
    .c-navigation-mobile .close-menu-wrapp .c-navigation-mobile__close-menu {
      width: 40px;
      height: 40px;
      font-size: 30px;
      font-weight: lighter;
      line-height: 32px;
      cursor: pointer;
      margin-right: 10px;
      width: 100%; }
      .c-navigation-mobile .close-menu-wrapp .c-navigation-mobile__close-menu i {
        float: right; }
